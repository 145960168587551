<template>
  <div
    layout-align="center center"
    class="mb-6"
  >
    <div class="page-topborder" />

    <div
      v-if="session.id && !loading"
      class="page-width pb-3"
    >
      <div
        class="py-3"
        :class="{'background-draft': session.status === Consts.DRAFT, 'background-onhold': session.status === Consts.ONHOLD}"
      >
        <div
          class="headline font-weight-bold"
          layout="row"
          layout-align="start center"
        >
          <v-avatar
            :size="32"
            :title="sessionStatusStrLong(session.status)"
            :color="sessionStatusColor(session.status)"
            style="font-size: 16px"
            class="black--text font-weight-medium ml-3"
          >
            {{ sessionStatusStr(session.status) }}
          </v-avatar>
          <v-icon
            color="black"
            class="mx-2"
            small
          >
            <template v-if="session.application === Consts.APPLICATION.BEESECURE">
              icon-bee48
            </template>
            <template v-else>
              icon-kayak48
            </template>
          </v-icon>
          #{{ session.reference }}
          <span class="text-uppercase ml-1"> [{{ session.lang_id ? session.lang_id : '?' }}]</span>
          <v-chip
            :color="sessionTypeColor(session.session_type)"
            class="lighten-3 ml-3"
            small
          >
            {{ sessionTypeStr(session.session_type) }}
          </v-chip>
          <v-icon
            v-if="session.package_id"
            class="ml-2"
            color="primary"
            title="In a package"
          >
            mdi-timetable
          </v-icon>
          <v-rating
            v-if="session.organization"
            background-color="#AAAAAA"
            dense
            small
            class="subheading mr-3 ml-2"
            readonly
            :value="parseInt(session.organization.mark)"
          />
          <span
            v-if="sessionDates()"
            class="subheading"
            style="color: grey"
          > {{ sessionDates() }} </span>

          <span v-if="session.class_name">
            <span class="ml-2 mr-1">/</span>
            <b>{{ session.class_name }}</b>
          </span>

          <template v-if="session.ticket_number && (isAdmin || isCoord)">
            <v-icon
              class="ml-3"
              color="primary"
              title="Ticket number"
            >
              mdi-ticket
            </v-icon>{{ session.ticket_number }}
          </template>
        </div>
        <div
          class="headline pl-4 pt-2 font-weight-bold"
          layout-gt-md="row"
          layout-align="start center"
        >
          <span v-if="session.organization">{{ session.organization.name }}</span>
          <span
            v-if="session.event_name"
            class="font-weight-light"
          >
            <span
              v-if="session.organization"
              class="ml-2 mr-1"
            >/</span>
            {{ session.event_name }}
          </span>
          <span
            v-if="session.center"
            class="font-weight-light"
          >
            <span
              v-if="session.organization"
              class="ml-2 mr-1"
            >/</span>
            {{ session.center.name }}
          </span>
        </div>
      </div>
      <router-link
        :to="{name: $route.query.from ? $route.query.from : 'sessions-index'}"
        class="link-unstyled py-2 ml-4 no-printable"
        style="width: max-content"
      >
        <v-icon
          color="primary"
          size="16"
        >
          mdi-arrow-left
        </v-icon>
        Back to the list
      </router-link>
      <div
        layout="row"
        layout-align="start start"
        class="mb-3 no-printable"
        style="background-color: #fff"
      >
        <v-tabs
          class="ml-3 grey"
          flex
        >
          <v-tabs-slider color="#f4b944" />
          <v-tab
            router-link
            :to="{name: 'sessions-show-info', params: {id: session.id}}"
          >
            Information
          </v-tab>
          <v-tab
            v-if="!isInternal && !isFreelances"
            router-link
            :to="{name: 'sessions-show-settings', params: {id: session.id}}"
          >
            Settings / Special activities
          </v-tab>
          <v-tab
            v-if="isEvent"
            router-link
            :to="{name: 'sessions-show-review', params: {id: session.id}}"
          >
            Event review
          </v-tab>
          <v-tab
            v-if="session.package_id"
            router-link
            :to="{name: 'sessions-show-package', params: {id: session.id}}"
          >
            Package
          </v-tab>
          <v-tab
            v-if="isAdmin || isCoord"
            router-link
            :to="{name: 'sessions-show-historic', params: {id: session.id}}"
          >
            Historic
          </v-tab>
          <v-tab
            router-link
            :to="{name: 'sessions-show-maps', params: {id: session.id}}"
          >
            MAP
          </v-tab>
        </v-tabs>

        <v-btn
          v-if="session.id && (isAdmin || isAppCoord(session))"
          large
          title="Save"
          class="mx-0"
          icon
          @click="save"
        >
          <v-icon
            large
            color="primary"
          >
            mdi-content-save
          </v-icon>
        </v-btn>

        <template v-if="(isAdmin || isAppCoord(session))">
          <v-menu
            v-if="(session.status != Consts.TOVALIDATE && session.status != Consts.BLOCKED)"
            v-model="upgradeModal"
            :close-on-content-click="false"
            :disabled="!isUpgradable(session.status)"
          >
            <v-btn
              slot="activator"
              large
              title="Upgrade"
              class="mx-0"
              icon
              :disabled="!isUpgradable(session.status)"
            >
              <v-icon
                large
                color="success"
              >
                mdi-check
              </v-icon>
            </v-btn>
            <v-card style="max-width: 450px">
              <v-card-title class="title">
                Upgrade session
              </v-card-title>
              <v-card-text>
                {{ textUpgrade(session) }}
              </v-card-text>
              <v-card-actions
                layout="row"
                layout-align="end center"
              >
                <v-btn
                  depressed
                  color="error"
                  @click="upgradeModal = false"
                >
                  No
                </v-btn>
                <v-btn
                  depressed
                  color="success"
                  @click="validate(session.id, false)"
                >
                  Yes without notifying
                </v-btn>
                <v-btn
                  depressed
                  color="primary"
                  @click="validate(session.id, true)"
                >
                  Yes
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          <v-btn
            v-else-if="session.status == Consts.TOVALIDATE"
            large
            title="Upgrade"
            class="mx-0"
            icon
            :disabled="!isUpgradable(session.status)"
            @click="blockSession(session)"
          >
            <v-icon
              large
              color="success"
            >
              mdi-check
            </v-icon>
          </v-btn>
          <v-btn
            v-else-if="session.status == Consts.BLOCKED"
            large
            title="Upgrade"
            class="mx-0"
            icon
            :disabled="!isUpgradable(session.status)"
            @click="evalSession(session)"
          >
            <v-icon
              large
              color="success"
            >
              mdi-check
            </v-icon>
          </v-btn>

          <v-menu
            v-model="downgradeModal"
            :close-on-content-click="false"
            :disabled="!isDowngradable(session.status)"
          >
            <v-btn
              slot="activator"
              large
              class="mx-0"
              icon
              :disabled="!isDowngradable(session.status)"
            >
              <v-icon
                large
                title="Downgrade"
                color="error"
              >
                mdi-download
              </v-icon>
            </v-btn>
            <v-card style="max-width: 450px">
              <v-card-title class="title">
                Downgrade session
              </v-card-title>
              <v-card-text>
                {{ textDowngrade(session) }}
              </v-card-text>
              <v-card-actions
                layout="row"
                layout-align="end center"
              >
                <v-btn
                  depressed
                  color="error"
                  @click="downgradeModal = false"
                >
                  No
                </v-btn>
                <v-btn
                  depressed
                  color="success"
                  @click="unvalidate(session.id, false)"
                >
                  Yes without notifying
                </v-btn>
                <v-btn
                  depressed
                  color="primary"
                  @click="unvalidate(session.id, true)"
                >
                  Yes
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>

          <v-btn
            v-if="session.status !== Consts.ONHOLD"
            title="Put on hold"
            large
            class="mx-0"
            icon
            @click="toggleOnHold(session.id)"
          >
            <v-icon
              large
              color="indigo"
            >
              mdi-pause-circle-outline
            </v-icon>
          </v-btn>
          <v-btn
            v-else
            title="Resume"
            large
            class="mx-0"
            icon
            @click="toggleOnHold(session.id)"
          >
            <v-icon
              large
              color="indigo"
            >
              mdi-play-circle-outline
            </v-icon>
          </v-btn>

          <v-btn
            v-if="session.status == Consts.RELEASE"
            title="Notify trainers manually"
            large
            class="mx-0"
            icon
            @click="notify"
          >
            <v-icon large>
              mdi-send
            </v-icon>
          </v-btn>

          <v-menu
            v-if="(session.status != Consts.CANCELLED && session.status != Consts.CLOSED)"
            v-model="cancelModal"
            :close-on-content-click="false"
          >
            <v-btn
              slot="activator"
              large
              title="Cancel"
              class="mx-0"
              icon
            >
              <v-icon
                large
                color="error"
              >
                mdi-cancel
              </v-icon>
            </v-btn>
            <v-card style="max-width: 450px">
              <v-card-title class="title">
                Cancel session
              </v-card-title>
              <v-card-text>
                Are you sure you want to cancel this session?
              </v-card-text>
              <v-card-actions
                layout="row"
                layout-align="end center"
              >
                <v-btn
                  depressed
                  color="error"
                  @click="cancelModal = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  depressed
                  color="success"
                  @click="cancel(session.id, false)"
                >
                  Yes without notifying
                </v-btn>
                <v-btn
                  depressed
                  color="primary"
                  @click="cancel(session.id, true)"
                >
                  Yes
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </template>

        <v-btn
          v-if="canBeTrainerEval(session)"
          large
          title="Evaluate"
          class="mx-0"
          icon
          @click="trainerEvalOpen(session)"
        >
          <v-icon
            large
            color="primary"
          >
            mdi-thumbs-up-down
          </v-icon>
        </v-btn>
        <v-btn
          v-else-if="canBeTeacherEval(session)"
          title="Evaluate"
          class="mx-0"
          icon
          :to="{name: 'sessions-show-teacher-evals', params: {id: session.id}}"
        >
          <v-icon color="primary">
            mdi-thumbs-up-down
          </v-icon>
        </v-btn>

        <v-btn
          v-if="isTrainer && (!isCandidate || isFreelances) && canDeliverSess(session)"
          :title="isAlreadyTrainer(session) ? 'Edit my presence' : 'Join the session'"
          large
          class="mx-0"
          icon
          :disabled="noJoinable(session)"
          @click="joinSession(session)"
        >
          <v-icon
            large
            :color="isAlreadyTrainer(session) ? 'orange' : 'primary'"
          >
            mdi-account-{{ isAlreadyTrainer(session) ? 'edit' : 'plus' }}
          </v-icon>
        </v-btn>
        <v-btn
          large
          class="mx-0"
          icon
          @click="print"
        >
          <v-icon
            large
            title="Print"
          >
            mdi-printer
          </v-icon>
        </v-btn>

        <v-btn
          v-if="(isAdmin || isAppCoord(session)) && !isFreelances"
          large
          class="mx-0"
          icon
          @click="typeDialog = true"
        >
          <v-icon
            color="green"
            large
            title="Change the session type"
          >
            mdi-autorenew
          </v-icon>
        </v-btn>

        <v-btn
          v-if="!isInternal && (isAdmin || isAppCoord(session))"
          large
          class="mx-0"
          icon
          @click="duplicate(session.id)"
        >
          <v-icon
            large
            title="Duplicate"
          >
            mdi-content-duplicate
          </v-icon>
        </v-btn>
        <v-menu
          v-if="isAdmin || isAppCoord(session)"
          v-model="deleteModal"
          max-width="500"
        >
          <v-btn
            slot="activator"
            title="Delete the session"
            large
            class="mx-0"
            icon
          >
            <v-icon
              large
              color="error"
            >
              mdi-delete
            </v-icon>
          </v-btn>
          <v-card>
            <v-card-text>
              <div layout="row">
                Do you want to delete this session?
              </div>
              <div
                v-if="session.package_id"
                layout="row"
              >
                This session is part of a package, if you want you can delete all sessions of this package.
              </div>
            </v-card-text>
            <v-card-actions
              layout="row"
              layout-align="end center"
            >
              <v-btn
                flat
                @click="deleteModal = false"
              >
                Cancel
              </v-btn>
              <v-btn
                v-if="session.package_id"
                flat
                color="error"
                @click="deleteAllSess"
              >
                Delete all package sessions
              </v-btn>
              <v-btn
                flat
                color="error"
                @click="deleteSess"
              >
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>
      <router-view
        :key="routerViewKey"
        :session.sync="session"
        :is-admin.sync="isAdmin"
        :is-coord.sync="isCoord"
        :is-trainer.sync="isTrainer"
        :is-candidate.sync="isCandidate"
        @save="onSessionSave"
      />
    </div>
    <div
      v-else
      layout="row"
      class="ma-5"
      layout-align="center center"
    >
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      />
    </div>
    <join-dialog
      :visible.sync="joinDialog"
      :session-id="editingSession.id"
      :value="{}"
      :is-edit="isAlreadyTrainer(session)"
      @updated="fetch(null)"
    />
    <trainer-eval-dialog
      :visible.sync="trainerEvalDialog"
      :session="editingSession"
      :value="editingTrainerEval"
      @created="fetch(null)"
      @updated="fetch(null)"
    />
    <block-dialog
      :visible.sync="blockDialog"
      :sess="editingSession"
      @created="fetch(null)"
      @updated="fetch(null)"
    />
    <to-eval-dialog
      :visible.sync="toEvalDialog"
      :session="editingSession"
      @created="fetch(null)"
      @updated="fetch(null)"
    />
    <v-dialog
      v-model="typeDialog"
      width="500"
    >
      <v-card>
        <v-card-title class="title">
          Change the session type
        </v-card-title>
        <v-card-text>
          <v-form ref="typeForm">
            <p>When changing the type of a session, data will be kept or transfered (organization, dates) but some data might be lost (event settings, activities)</p>
            <div
              layout="row"
              layout-align="start end"
            >
              Select the new session type:
              <span flex />
              <v-select
                v-model="changingType"
                v-focus
                hide-details
                label="Session type"
                style="width: 200px"
                :items="sessionTypesLong"
                class="ml-1"
                single-line
              />
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions
          layout="row"
          layout-align="end center"
        >
          <v-btn
            flat
            @click.prevent="typeDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            flat
            color="primary"
            @click="changeType"
          >
            Validate
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Sessions from "@/api/sessions";
import Auth from "@/plugins/auth";
import Consts from "@/consts";
import moment from "moment";
import FormDataMixin from "@/mixins/formdata";
import SessionsMixin from "@/mixins/sessions";
import GlobalMixin from "@/mixins/global";
import JoinDialog from "@/components/shared/JoinSessionDialog.vue";
import TrainerEvalDialog from "@/components/shared/TrainerEvalDialog.vue";
import BlockDialog from "@/components/shared/BlockDialog.vue";
import ToEvalDialog from "@/components/shared/ToEvalDialog.vue";
import EventBus from "@/plugins/eventbus";

export default {
  components: { JoinDialog, TrainerEvalDialog, BlockDialog, ToEvalDialog },
  mixins: [FormDataMixin, SessionsMixin, GlobalMixin],

  beforeRouteUpdate(to, from, next) {
    if (from.params.id !== to.params.id) {
      this.fetch(to.params.id); // Prevent tab change
    }
    next();
  },
  data() {
    return {
      loading: false,
      session: {
        organization_id: null,
        organization: null,
        contact_id: null,
        contact: null,
        contact2_id: null,
        contact2: null,
        session_type: 0,
        dates: [],
        users_teams: [],
        events_settings: [],
        activities: [],
        ticket_number: null,
        delivery_address: null
      },
      isTrainer: false,
      isCandidate: false,
      isCoord: false,
      isAdmin: false,
      upgradeModal: false,
      downgradeModal: false,
      joinDialog: false,
      editingSession: {},
      editingTrainerEval: {},
      trainerEvalDialog: false,
      isEvent: false,
      deleteModal: false,
      blockDialog: false,
      toEvalDialog: false,
      cancelModal: false,
      changingType: null,
      typeDialog: false,
      routerViewKey: 1,
      Consts
    };
  },

  computed: {
    isInternal() {
      if (parseInt(this.session.session_type) == Consts.INTERNAL) {
        return true;
      }
      return false;
    },
    isFreelances() {
      if (this.session.application == Consts.APPLICATION.FREELANCES) {
        return true;
      }

      return false;
    },
    sessionTypesLong() {
      let tb = [
        { value: Consts.EVENTS, text: this.sessionTypeStrLong(Consts.EVENTS) },
        { value: Consts.ADULTS, text: this.sessionTypeStrLong(Consts.ADULTS) },
        { value: Consts.SCHOOL, text: this.sessionTypeStrLong(Consts.SCHOOL) },
        {
          value: Consts.TEACHERS,
          text: this.sessionTypeStrLong(Consts.TEACHERS)
        },
        { value: Consts.YOUTH, text: this.sessionTypeStrLong(Consts.YOUTH) },
        {
          value: Consts.INTERNAL,
          text: this.sessionTypeStrLong(Consts.INTERNAL)
        },
        { value: Consts.OTHER, text: this.sessionTypeStrLong(Consts.OTHER) }
      ];

      for (let i = 0; i < tb.length; i++) {
        if (tb[i].value == this.session.session_type) {
          tb.splice(i, 1);
          break;
        }
      }

      return tb;
    }
  },

  watch: {
    joinDialog(val) {
      if (!val) {
        this.editingSession = {};
      }
    },
    trainerEvalDialog(val) {
      if (!val) {
        this.editingTrainerEval = {};
        this.editingSession = {};
      }
    }
  },

  async mounted() {
    this.loading = true;
    this.fetch();
    await Auth.refresh();
    this.loadUser();
  },

  methods: {
    isAlreadyTrainer(item) {
      for (let i in item.trainers) {
        if (item.trainers[i].id == Auth.user.id) {
          return true;
        }
      }

      return false;
    },
    save() {
      EventBus.$emit("session-updated", false);
    },
    fetch(id = null) {
      this.loading = true
      Sessions.get(id ? id : this.$route.params.id).then(
        res => {
          this.session = res.data;
          if (this.session.dates && this.session.dates.length > 0) {
            this.session.dates.sort(
              (a, b) => (a.start > b.start ? 1 : b.start > a.start ? -1 : 0)
            );
          }
          this.loading = false;
          if (this.session.session_type == Consts.EVENTS) {
            this.isEvent = true;
          } else {
            this.isEvent = false;
          }
          this.routerViewKey++;
          this.session = Object.assign({}, this.session);
        },
        () => {
          this.$router.push({ name: "sessions-index" });
        }
      );
    },
    changeType() {
      this.loading = true;
      Sessions.changeType(this.session.id, this.changingType).then(() => {
        this.session.id = null;
        this.fetch();
        this.typeDialog = false;
        this.$snotify.success("Session type has been changed");
        this.loading = false;
      });
    },
    onSessionSave() {
      /*this.loading = true
      this.$route.params.id = this.session.id
      this.session.id = null
      this.fetch()*/

      this.$router.push({ name: "sessions-index" });
    },
    deleteSess() {
      Sessions.delete(this.$route.params.id).then(() => {
        this.$snotify.success("The session has been deleted");
        this.$router.push({ name: "sessions-index" });
      });
    },
    deleteAllSess(){
      Sessions.delete(this.$route.params.id, {all: true}).then(() => {
        this.$snotify.success("Sessions have been deleted");
        this.$router.push({ name: "sessions-index" });
      });
    },
    print() {
      window.print();
    },
    formatDate(x) {
      return moment(x).format("DD.MM.YYYY");
    },
    formatTime(x) {
      return moment(x).format("HH:mm");
    },
    formatDateHuman(date) {
      return moment(date).format("ddd, MMM DD YYYY, HH:mm");
    },
    getDates(start, end) {
      if (this.formatDate(start) == this.formatDate(end)) {
        return (
          this.formatDate(start) +
          ", " +
          this.formatTime(start) +
          "-" +
          this.formatTime(end)
        );
      } else {
        return (
          this.formatDate(start) +
          ", " +
          this.formatTime(start) +
          " - " +
          this.formatDate(end) +
          ", " +
          this.formatTime(end)
        );
      }
    },
    sessionDates() {
      if (this.session && this.session.dates && this.session.dates.length > 0) {
        return this.getDates(
          this.session.dates[0].start,
          this.session.dates[this.session.dates.length - 1].end
        );
      }
      return false;
    },
    validate(id, toSend = true) {
      Sessions.validate(id, { to_send: toSend }).then(() => {
        this.$snotify.success("The session has been upgraded");
        this.fetch();
        this.upgradeModal = false;
      });
    },
    unvalidate(id, toSend = true) {
      Sessions.unvalidate(id, { to_send: toSend }).then(() => {
        this.$snotify.success("The session has been downgraded");
        this.fetch();
        this.downgradeModal = false;
      });
    },
    toggleOnHold(id) {
      Sessions.toggleOnHold(id, {}).then(() => {
        this.$snotify.success(
          this.session.status === Consts.ONHOLD
            ? "The session has been resumed"
            : "The session has been put on hold"
        );
        this.fetch();
      });
    },
    duplicate(id) {
      this.loading = true;
      this.session.id = null;
      Sessions.duplicate(id).then(res => {
        this.$snotify.success("The session has been duplicated");
        this.$router.push({
          name: "sessions-show-info",
          params: { id: res.data.id }
        });
        this.fetch();
        this.loading = false;
      });
    },
    cancel(id, toSend = false) {
      Sessions.cancel(id, { to_send: toSend }).then(() => {
        this.$snotify.success("The session has been cancelled");
        this.fetch();
        this.cancelModal = false;
      });
    },
    isUpgradable(status) {
      if (
        (status == Consts.RELEASE && !this.session.trainers.length) ||
        status == Consts.CLOSED ||
        status == Consts.ONHOLD
      ) {
        return false;
      }
      if (!this.isAdmin && !this.isCoord) {
        return false;
      }
      return true;
    },
    isDowngradable(status) {
      if (
        status == Consts.CANCELLED ||
        status == Consts.DRAFT ||
        status == Consts.ONHOLD
      ) {
        return false;
      }
      if (!this.isAdmin && !this.isCoord) {
        return false;
      }
      return true;
    },
    isTrainerOnSession(session) {
      if ("trainers" in session) {
        let is = session.trainers
          .map(function(e) {
            return e.id;
          })
          .indexOf(Auth.user.id);

        if (is > -1) {
          return true;
        }
      }
      return false;
    },

    canBeTrainerEval(sess) {
      if (this.isAdmin || this.isAppCoord(sess)) {
        return true;
      } else if (
        sess.session_type != Consts.EVENTS &&
        this.isTrainerOnSession(sess) &&
        (!this.isCandidate || this.isFreelances) &&
        sess.status >= Consts.TOEVALUATE
      ) {
        if (sess.dates && sess.dates.length > 0) {
          if (moment(sess.dates[0].end).isBefore(moment())) {
            return true;
          }
          return false;
        }
        return false;
      }
      return false;
    },

    trainerEvalOpen(s) {
      if (this.isAdmin || this.isAppCoord(s)) {
        if (s.session_type == Consts.EVENTS) {
          this.$router.push({
            name: "sessions-edit-review",
            params: { id: s.id }
          });
        } else {
          this.$router.push({
            name: "sessions-show-trainer-evals",
            params: { id: s.id }
          });
        }
      } else {
        let trainer_id = null;
        let eva = null;
        this.editingSession = s;

        Sessions.get(s.id).then(res => {
          let session = res.data;
          if (session.trainers_presences) {
            let is = session.trainers_presences
              .map(function(e) {
                return e.user_id;
              })
              .indexOf(Auth.user.id);

            if (is > -1) {
              trainer_id = session.trainers_presences[is].id;
            }

            if (trainer_id) {
              Sessions.getTrainerEvals(s.id, {
                session_trainer_id: trainer_id
              }).then(res => {
                if (res.data && res.data[0]) {
                  eva = res.data[0];
                }
                if (eva) {
                  this.editTrainerEval(eva);
                } else {
                  this.newTrainerEval();
                }
              });
            }
          }
        });
      }
    },
    canBeReview(session) {
      if (!this.isAdmin && !this.isAppCoord(session)) {
        return false;
      }
      if (session.session_type != Consts.EVENTS) {
        return false;
      }
      return true;
    },

    blockSession(sess) {
      this.editingSession = sess;
      this.blockDialog = true;
    },

    evalSession(sess) {
      this.editingSession = sess;
      this.toEvalDialog = true;
    },

    canBeTeacherEval(session) {
      if (!this.isAdmin && !this.isAppCoord(session)) {
        return false;
      }
      if (session.session_type != Consts.SCHOOL) {
        return false;
      }
      return true;
    },

    joinSession(session) {
      this.editingSession = Object.assign({}, session);
      this.joinDialog = true;
    },

    noJoinable(session) {
      if (
        session.status !== Consts.RELEASE &&
        session.status !== Consts.TOVALIDATE
      ) {
        return true;
      }
      return false;
    },
    newTrainerEval() {
      this.trainerEvalDialog = true;
    },
    editTrainerEval(e) {
      this.editingTrainerEval = Object.assign({}, e);
      this.trainerEvalDialog = true;
    },
    notify() {
      Sessions.notify(this.$route.params.id).then(() => {
        this.$snotify.success("The notification has been sent");
      });
    }
  }
};
</script>
<style scoped>
.expo {
  vertical-align: super;
  color: lightgrey;
  font-size: 14px;
}
.edit-icon {
  text-decoration: none;
}

@media print {
  .headline > .v-btn,
  .headline > .v-menu--inline,
  .v-tabs {
    display: none;
  }
  .no-printable {
    display: none;
  }
}
</style>
<style>
@media print {
  .no-printable {
    display: none;
  }
}
</style>
