<template>
  <div class="ma-3">
    <div class="title mb-3">
      Sessions <v-icon>mdi-chevron-right</v-icon> Packages management
    </div>

    <div
      layout="column"
      layout-align="center center"
    >
      <div
        layout="column"
        class="field-width"
      >
        <div
          layout="row"
          layout-align="start center"
          class="ml-3"
        >
          <v-text-field
            v-model="filters.keywords"
            solo
            label="Keywords"
            hide-details
            @keyup.enter="fetch"
          />
          <v-btn
            depressed
            color="primary"
            @click="fetch"
          >
            Filter
          </v-btn>
          or
          <a
            href="#"
            class="ml-2 black--text"
            @click="resetFilters"
          >Cancel</a>
        </div>

        <div>
          <v-btn
            flat
            class="mt-3"
            :to="{name: 'admin-sessions-packages-edit', params: {id: 'new'}}"
          >
            <v-icon
              left
              color="primary"
            >
              mdi-plus-box
            </v-icon>
            Add a new package
          </v-btn>
        </div>

        <v-data-table
          :headers="headers"
          :items="packages"
          style="max-width: 1000px"
          :pagination.sync="pagination"
        >
          <template
            slot="items"
            slot-scope="{ item }"
          >
            <td>{{ item.name }}</td>
            <td layout="row">
              <v-btn
                class="mx-0"
                icon
                :to="{name: 'admin-sessions-packages-edit', params: {id: item.id}}"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>

              <delete-button
                :label="item.name"
                @confirm="deleteImpl(item)"
              />
            </td>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import Params from "@/api/params";
import DeleteButton from "@/components/shared/DeleteButton.vue";
import Consts from "@/consts";

export default {
  components: { DeleteButton },
  data() {
    return {
      pagination: {
        rowsPerPage: 10
      },
      headers: [
        { text: "Package", value: "name" },
        { text: "Actions", sortable: false, width: "80px" }
      ],
      filters: {
        keywords: null,
        application: Consts.APPLICATION.FREELANCES
      },
      packages: [],
      Consts
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      Params.getList("packages", this.filters).then(res => {
        this.packages = res.data;
      });
    },

    resetFilters() {
      this.filters = { keywords: null };
      this.fetch();
    },

    deleteImpl(item) {
      Params.delete("packages", item.id).then(() => {
        this.fetch();
        this.$snotify.success(`The package "${item.name}" has been deleted`);
      });
    }
  }
};
</script>
