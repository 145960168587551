import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify, {
  theme: {
    primary: '#F4B944',
    secondary: '#424242',
    accent: '#6DC5D4',
    error: '#F5786A',
    info: '#2196F3',
    success: '#71DBBA',
    warning: '#FFC107',
    activity: {
      base: '#A5BDFF',
      lighten2: '#b3c7ff',
      lighten3: '#ccdaff',
      lighten4: '#e6ecff'
    },
    training: {
      base: '#60FDDE',
      lighten2: '#81fee5',
      lighten3: '#9afeea',
      lighten4: '#b3feef'
    },
  },
});
