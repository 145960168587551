<template>
  <div
    layout-align="center center"
    class="mb-6"
  >
    <div class="page-topborder" />
    <div
      v-if="organization.id && !loading"
      class="page-width pa-2"
    >
      <div layout="row">
        <div
          layout="column"
          layout-align="center center"
        >
          <div
            class="headline font-weight-bold"
            layout="row"
            layout-align="center center"
          >
            <v-rating
              background-color="#AAAAAA"
              dense
              large
              class="mr-1"
              readonly
              :value="parseInt(organization.mark)"
            />
          </div>
        </div>
        <div
          layout="column"
          class="ml-1"
        >
          <div
            v-if="organization.is_school"
            layout="row"
            layout-align="start start"
            class="subheading"
          >
            {{ organization.school_type ? organization.school_type.name : '' }}
          </div>
          <div
            v-else
            layout="row"
            layout-align="start start"
            class="subheading"
          >
            Institution
          </div>
          <div
            class="headline font-weight-bold"
            layout="row"
          >
            <span>{{ organization.name }}</span>
          </div>
        </div>
      </div>
      <div
        layout="row"
        layout-align="start center"
        class="my-2  no-printable"
        style="background-color: #fff"
      >
        <v-tabs
          class="grey"
          centered
          grow
          flex
        >
          <v-tabs-slider color="#f4b944" />
          <v-tab
            router-link
            :to="{name: 'address-show-info', params: {id: organization.id}}"
          >
            Information
          </v-tab>
          <v-tab
            router-link
            :to="{name: 'address-show-evals', params: {id: organization.id}}"
          >
            Evaluations
          </v-tab>
          <v-tab
            router-link
            :to="{name: 'address-show-sessions', params: {id: organization.id}}"
          >
            Sessions
          </v-tab>
        </v-tabs>
        <span flex />
        <v-icon
          v-if="isAdmin || isCoord"
          large
          title="Edit"
          @click="edit"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="isAdmin || isCoord"
          large
          :title="organization.is_enabled ? 'Disable' : 'Enable'"
          @click="toggle()"
        >
          mdi-lock{{ organization.is_enabled ? '' : '-open' }}
        </v-icon>
        <v-icon
          v-if="isAdmin || isCoord"
          title="Delete"
          color="error"
          large
          @click="deleteModal = true"
        >
          mdi-delete
        </v-icon>
      </div>

      <router-view
        :organization.sync="organization"
        :is-admin.sync="isAdmin"
        :is-coord.sync="isCoord"
      />
    </div>
    <div
      v-else
      layout="row"
      class="ma-5"
      layout-align="center center"
    >
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      />
    </div>
    <organization-dialog
      :visible.sync="orgaDialog"
      :value="organization"
      @updated="fetch(null)"
    />
    <v-dialog
      v-model="deleteModal"
      width="400"
    >
      <v-card>
        <v-card-title class="font-weight-bol title">
          Delete the organization
        </v-card-title>
        <v-card-text>
          Are you sure you want to delete this organization? All its sessions and contacts will be also deleted.
        </v-card-text>
        <v-card-actions
          layout="row"
          layout-align="end center"
        >
          <v-btn
            flat
            @click="deleteModal = false"
          >
            Cancel
          </v-btn>
          <v-btn
            flat
            color="error"
            @click="deleteOrga"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Organizations from '@/api/organizations'
import OrganizationDialog from "@/components/shared/OrganizationDialog.vue"
import Auth from '@/plugins/auth'
import Consts from '@/consts'

export default {
  components: { OrganizationDialog },
  mixins: [],

  beforeRouteUpdate (to, from, next) {
    this.fetch(to.params.id)
    next()
  },

  data () {
    return {
      organization: {},
      orgaDialog: false,
      isAdmin: false,
      isCoord: false,
      deleteModal: false,
      loading: false
    }
  },

  mounted () {
    this.fetch()
    this.loadUser()
  },

  methods: {
    fetch (id = null) {
      this.loading = true
      Organizations.get(id ? id : this.$route.params.id).then(res => {
        this.organization = res.data
        this.loading = false
      })
    },
    edit () {
      this.orgaDialog = true
    },
    toggle () {
      if (this.organization.is_enabled) {
        this.organization.is_enabled = 0
        Organizations.disable(this.$route.params.id).then(() => {
          this.$snotify.success('The organization has been disabled')
        })
      } else {
        this.organization.is_enabled = 1
        Organizations.enable(this.$route.params.id).then(() => {
          this.$snotify.success('The organization has been enabled')
        })
      }
    },
    loadUser () {
      if (Auth.user.is_admin) {
        this.isAdmin = true
      }
      if (Auth.user.roles.indexOf(Consts.COORDINATION) > -1) {
        this.isCoord = true
      }
    },
    deleteOrga () {
      Organizations.delete(this.$route.params.id).then(() => {
        this.$snotify.success('The organization has been deleted')
        this.$router.push({ name: 'address-index' })
      })
    }
  }
}
</script>
<style scoped>
.expo {
  font-size: 14px;
  color: black;
}
</style>