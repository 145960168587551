<template>
  <div>
    <div
      layout="column"
      style="padding-left: 0"
    >
      <v-subheader>
        Todos
      </v-subheader>
      <div
        v-for="(t,i) in session.todos"
        :key="i"
        layout="row"
        layout-align="start center"
        style="padding-left: 15px"
      >
        <v-checkbox
          :key="t.id"
          v-model="t.is_done"
          :disabled="!isCoord && !isAdmin"
          hide-details
          class="py-1 my-1 ml-4"
          @change="toggleTodo(t)"
        >
          <div
            slot="label"
            layout="column"
          >
            <span
              v-if="t.user"
              class="sub caption"
            >
              For {{ t.user.firstname }} {{ t.user.lastname }}
            </span>
            {{ t.todo }}
          </div>
        </v-checkbox>
        <v-btn
          left
          flat
          icon
          color="success"
          @click="openTodo(t,i)"
        >
          <v-icon v-if="isAdmin || isCoord">
            mdi-pencil
          </v-icon>
        </v-btn>
        <v-btn
          right
          flat
          icon
          color="error"
          @click="removeTodo(t,i)"
        >
          <v-icon v-if="isAdmin || isCoord">
            mdi-delete
          </v-icon>
        </v-btn>
      </div>
      <v-btn
        v-if="isAdmin || isCoord"
        flat
        style="float: left"
        class="edit-icon"
        @click="dialogTodo=true"
      >
        <v-icon color="primary">
          mdi-plus-box
        </v-icon>
        Add a new todo
      </v-btn>
    </div>
    <v-dialog
      v-model="dialogTodo"
      max-width="500px"
    >
      <v-card>
        <v-card-title v-if="editingTodo == null">
          Add a new todo
        </v-card-title>
        <v-card-title v-else>
          Edit the todo
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="newTodo.todo"
            label="Todo..."
          />
          <v-select
            v-model="newTodo.user_id"
            v-focus
            clearable
            outline
            label="User"
            :items="allUsers"
            item-value="id"
            item-text="fullname"
          />
        </v-card-text>
        <v-card-actions>
          <span flex />
          <v-btn
            color="primary"
            flat
            @click="editingTodo = null; dialogTodo = false"
          >
            Close
          </v-btn>
          <v-btn
            v-if="editingTodo == null"
            :disabled="!newTodo.todo"
            depressed
            color="primary"
            @click="addTodo"
          >
            Add
          </v-btn>
          <v-btn
            v-else
            :disabled="!newTodo.todo"
            depressed
            color="primary"
            @click="saveTodo"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import globalMixin from '@/mixins/global'
import Sessions from '@/api/sessions'
export default {
	mixins: [globalMixin],
	props: {
		session: {
			type: Object,
			required: true,
			default: () => {}
		},
		allUsers: {
			type: Array,
			required: false,
			default: () => []
		}
	},
	data() {
		return {
      dialogTodo: false,
      newTodo: {
        user_id: null,
        todo: null
      },
      editingTodo: null,
		}
	},
	mounted() {
    this.loadUser()
	},
	methods: {
		toggleTodo (todo) {
      if (todo.is_done) {
        Sessions.todoDone(this.session.id, todo.id).then(() => {
          this.$snotify.success('Todo done')
        })
      }
      else {
        Sessions.todoNotDone(this.session.id, todo.id).then(() => {
          this.$snotify.success('Todo not done')
        })
      }
    },
    removeTodo (todo, index) {
      this.session.todos.splice(index, 1)
      if (this.session.todos.length == 0) {
        this.session.todos = [1]
        this.$emit('save')
        this.session.todos = []
      }
      else {
        this.$emit('save')
      }
    },
    openTodo (todo, index) {
      this.newTodo = todo
      this.editingTodo = index
      this.dialogTodo = true
    },
    saveTodo () {
      let is = this.allUsers.map(function (e) { return e.id }).indexOf(this.newTodo.user_id)
      this.newTodo.user = this.allUsers[is]
      this.session.todos[this.editingTodo] = this.newTodo
      this.$emit('save')
      this.newTodo = {}
      this.dialogTodo = false
		},
		addTodo () {
      if (!this.newTodo || !this.newTodo.todo) {
        this.$snotify.error('The todo is required')
      }
      else {
        let is = this.allUsers.map(function (e) { return e.id }).indexOf(this.newTodo.user_id)
        this.newTodo.user = this.allUsers[is]
        this.session.todos.push(this.newTodo)
        this.$emit('save')
        this.newTodo = {}
        this.dialogTodo = false
      }
    },
	},
}
</script>
<style scoped>
	.v-subheader {
		padding: 0 10px;
	}
	.edit-icon {
		text-decoration: none;
	}
	@media print {
		.edit-icon {
			display: none;
		}
	}
</style>