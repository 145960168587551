<template>
  <div
    layout="row"
    layout-align="center center"
    flex
  >
    <v-card
      layout="column"
      layout-align="center center"
      class="login-card"
    >
      <v-card-text
        layout="column"
        class="text-xs-center"
      >
        <div>
          <img
            src="@/assets/logo.png"
            height="80"
          >
        </div>
        <div class="title font-weight-regular my-4">
          Create a new account
        </div>

        <v-form
          ref="createForm"
          v-model="formValid"
          layout="column"
          :class="{'mx-5': !$vuetify.breakpoint.smAndDown}"
        >
          <v-text-field
            v-model="form.firstname"
            outline
            label="First name"
            :rules="[v => !!v || 'This field is required']"
          />
          <v-text-field
            v-model="form.lastname"
            outline
            label="Last name"
            :rules="[v => !!v || 'This field is required']"
          />
          <v-text-field
            v-model="form.phone"
            outline
            label="Phone number"
          />
          <v-text-field
            v-model="form.email"
            outline
            label="E-mail"
            type="email"
          />
          <v-text-field
            v-model="form.password"
            outline
            label="Password"
            type="password"
            :rules="[v => !!v || 'This field is required']"
          />
          <v-text-field
            v-model="form.confirm_password"
            outline
            label="Confirm password"
            type="password"
            :rules="[
              v => !!v || 'This field is required',
              v =>
                (!!v && !!form.password && v === form.password) ||
                'The passwords are not the same'
            ]"
          />
        </v-form>
      </v-card-text>

      <v-card-actions
        layout="row"
        layout-align="end center"
        class="mx-5 mb-3"
      >
        <router-link
          href="#"
          class="grey--text mr-3"
          :to="{ name: 'login' }"
        >
          Back to login page
        </router-link>
        <vue-recaptcha
          v-if="!!Config.GoogleRecaptcha"
          :sitekey="Config.GoogleRecaptcha"
          load-recaptcha-script
          @verify="send"
        >
          <v-btn
            depressed
            color="primary"
            large
          >
            Create
          </v-btn>
        </vue-recaptcha>
        <v-btn
          v-else
          depressed
          color="primary"
          large
          @click="send()"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import Config from "@/config";
import axios from "axios";

export default {
  components: { VueRecaptcha },
  data() {
    return {
      form: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        password: "",
        confirm_password: "",
        token: ""
      },
      Config,
      formValid: false
    };
  },
  methods: {
    send(r) {
      if (!this.$refs.createForm.validate()) {
        return;
      }
      this.form.token = r ? r.slice(0) : "";
      if (this.form.token || !Config.GoogleRecaptcha) {
        axios.post(`/users/create`, this.form).then(res => {
          if (res.data.id) {
            this.$snotify.success(
              `Your account has been created and an email has been sent at ${res.data.email} for validation`
            );
            this.$router.push({ name: "login" });
          }
        });
      }
    }
  }
};
</script>

<style scoped>
.login-card {
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 960px) {
  .login-card {
    width: 800px;
    height: unset;
  }
}
</style>
