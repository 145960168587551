<template>
  <v-menu
    v-model="open"
    :close-on-content-click="false"
    :nudge-width="200"
    :disabled="disabled"
    offset-x
  >
    <v-btn
      slot="activator"
      icon
      :disabled="disabled"
      class="mx-0"
    >
      <v-icon color="error">
        mdi-delete
      </v-icon>
    </v-btn>

    <v-card>
      <v-card-title class="title">
        Confirm deletion?
      </v-card-title>
      <v-card-text>Are you sure you want to delete "{{ label }}"?</v-card-text>
      <v-card-actions
        layout="row"
        layout-align="end center"
      >
        <v-btn
          flat
          @click="open = false"
        >
          Cancel
        </v-btn>
        <v-btn
          flat
          color="error"
          @click="emitConfirm"
        >
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
    export default {
        name: 'DeleteButton',
        props: {
            label: {
                type: String,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data () {
            return {
                open: false
            }
        },
        methods: {
            emitConfirm () {
                this.$emit('confirm');
                this.open = false;
            }
        }
    }
</script>