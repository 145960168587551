<template>
  <v-dialog
    v-model="isVisible"
    persistent
    width="1000"
  >
    <v-card>
      <v-card-title>
        <p class="title mt-2 ml-2">
          Evaluate the session #{{ session.reference }}
        </p>
        <v-spacer />
        <v-btn
          class="ma-0"
          icon
          @click="cancel"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text
        layout="row"
        layout-align="center center"
      >
        <v-form
          ref="evalForm"
          class="field-width"
        >
          <div
            layout="row"
            class="subheading"
          >
            <div
              layout="column"
              class="font-weight-bold"
              flex="40"
            >
              Evaluation of the school
            </div>
            <div layout="column">
              <div
                layout="row"
                layout-align="start center"
              >
                <v-icon
                  color="error"
                  title="No rating"
                  left
                  @click="eval.school_mark = 0"
                >
                  mdi-minus-circle
                </v-icon>
                <v-rating
                  v-model="eval.school_mark"
                  background-color="#AAAAAA"
                  dense
                  size="24"
                />
              </div>
            </div>
          </div>
          <div
            layout="row"
            class="subheading"
          >
            <div
              layout="column"
              flex="40"
            >
              1. Discipline in class
            </div>
            <div layout="column">
              <eval-radio v-model="eval.discipline" />
            </div>
          </div>
          <div
            layout="row"
            class="subheading"
          >
            <div
              layout="column"
              flex="40"
            >
              2. Interactivity
            </div>
            <div layout="column">
              <eval-radio v-model="eval.interactivity" />
            </div>
          </div>
          <div
            layout="row"
            class="subheading"
          >
            <div
              layout="column"
              flex="40"
            >
              3. Knowledge of the Internet
            </div>
            <div layout="column">
              <eval-radio v-model="eval.knowledge" />
            </div>
          </div>
          <div
            v-if="eval.experience"
            layout="row"
            class="subheading"
          >
            <div
              layout="column"
              flex="40"
              class="grey--text"
            >
              4. Experiences of the participants
            </div>
            <div layout="column">
              <eval-radio
                v-model="eval.experience"
                disabled
              />
            </div>
          </div>
          <div
            layout="row"
            class="subheading"
          >
            <div
              layout="column"
              flex="40"
            >
              4. Organisation on behalf the school
            </div>
            <div layout="column">
              <eval-radio v-model="eval.organization" />
            </div>
          </div>
          <div
            layout="row"
            class="subheading"
          >
            <div
              layout="column"
              flex="40"
            >
              5. Involvement of the teachers
            </div>
            <div layout="column">
              <eval-radio v-model="eval.involvement" />
            </div>
          </div>
          <div
            layout="row"
            class="subheading"
          >
            <div
              layout="column"
              flex="40"
            >
              6. Climate in the class
            </div>
            <div layout="column">
              <eval-radio v-model="eval.climate" />
            </div>
          </div>
          <div
            layout="row"
            class="mt-4"
          >
            <div
              layout="column"
              flex="60"
            >
              <div layout="row">
                <v-textarea
                  v-model="eval.pupils_exp"
                  outline
                  label="I noticed the following in this class (positive and/or negative)"
                />
              </div>
              <div
                v-if="eval.orga_aspects"
                layout="row"
              >
                <v-textarea
                  v-model="eval.orga_aspects"
                  outline
                  label="Organisational aspects"
                  disabled
                />
              </div>
              <div
                v-if="eval.other"
                layout="row"
              >
                <v-textarea
                  v-model="eval.other"
                  outline
                  label="Other aspects"
                  disabled
                />
              </div>
            </div>
          </div>
          <div class="text-xs-right">
            <v-btn
              depressed
              color="primary"
              :disabled="loading"
              @click="save"
            >
              Save the evaluation
            </v-btn>
            or
            <a
              href="#"
              class="black--text"
              @click.prevent="cancel"
            >Cancel</a>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Sessions from '@/api/sessions'
import RulesMixin from '@/mixins/rules'
import EvalRadio from "@/components/shared/EvalRadio.vue"

export default {
  components: { EvalRadio },
  mixins: [RulesMixin],
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {}
    },
    visible: {
      type: Boolean,
      required: false,
      default: false
    },
    session: {
      required: true
    },
  },
  data () {
    return {
      loading: false,
    }
  },
  computed: {
    eval: {
      get () {
        return this.value
      },
      set (x) {
        this.$emit('input', x)
      }
    },
    isVisible: {
      get () {
        return this.visible
      },
      set (x) {
        this.$emit('update:visible', x)
      }
    }
  },
  mounted () {

  },
  methods: {
    cancel () {
      this.$nextTick(() => {
        this.$refs['evalForm'].reset()
      })
      this.isVisible = false
    },
    save () {
      if (!this.$refs['evalForm'].validate()) {
        this.$snotify.error('Please check the form for invalid values')
        return
      }

      this.loading = true

      if (!this.eval.id) {
        Sessions.createTrainerEval(this.session.id, this.eval).then(res => {
          this.isVisible = false
          this.loading = false
          this.$emit('created', res.data)
          this.$nextTick(() => {
            this.$refs['evalForm'].reset()
          })
        }, () => {
          this.loading = false
        })
      } else {
        Sessions.updateTrainerEval(this.session.id, this.eval.id, this.eval).then(res => {
          this.isVisible = false
          this.loading = false
          this.$emit('updated', res.data)
          this.$nextTick(() => {
            this.$refs['evalForm'].reset()
          })
        }, () => {
          this.loading = false
        })
      }
    }
  }
}
</script>