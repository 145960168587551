<template>
  <div
    layout="column"
    class="ma-3"
  >
    <div layout="row">
      <div class="title">
        Address Book
      </div>
      <v-select
        v-model="filters.alert_mode"
        v-focus
        :items="modes"
        hide-details
        single-line
        class="mt-0 pt-0 ml-4 select-mode"
        @input="onSelectMode"
      />
    </div>
    <div class="ma-3">
      <div
        layout="row"
        layout-align="start start"
      >
        <div
          layout="column"
          class="field-width"
          flex="70"
        >
          <v-autocomplete
            :hint="(isCoord || isAdmin) ? 'If you want to create a new organisation, start by searching its name to be sure it’s not already in the database' : ''"
            :persistent-hint="(isCoord || isAdmin) ? true : false"
            :hide-details="(isCoord || isAdmin) ? false : true"
            label="organization name, or contact name (at least 3 characters)"
            outline
            :menu-props="{contentClass: 'orga-autocomplete'}"
            prepend-inner-icon="mdi-magnify"
            single-line
            clearable
            :filter="() => true"
            :loading="loadingOrg"
            :items="organizationRes"
            :search-input.sync="searchOrg"
            hide-no-data
            return-object
            @input="onSelectOrg"
          >
            <template
              slot="item"
              slot-scope="{ item }"
            >
              <div
                v-if="item._isNew && (isCoord || isAdmin)"
                layout-align="start center"
                layout="row"
              >
                <v-icon
                  color="primary"
                  class="mr-3"
                >
                  mdi-plus-box
                </v-icon>
                Create a new organization
              </div>
              <div v-else>
                <v-layout
                  align-start
                  row
                >
                  <div class="mr-1">
                    {{ item.name }}
                    <span
                      v-if="item.is_school"
                      class="expo"
                    >
                      {{ item.school_type ? item.school_type.name : '' }}
                    </span>
                    <span
                      v-else
                      class="expo"
                    >
                      (institution)
                    </span>
                    <br><span style="color: grey">{{ item.number }} {{ item.street }} {{ item.zip_code }} {{ item.city }}</span>
                  </div>
                </v-layout>
              </div>
            </template>
            <template
              slot="selection"
              slot-scope="{ item }"
            >
              {{ item.name }}
            </template>
          </v-autocomplete>

          <div class="title font-weight-regular mt-4">
            Last active organizations
          </div>

          <v-data-table
            v-if="init"
            :items="organizations"
            :pagination.sync="pagination"
            class="orga-table"
          >
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td
                class="pt-2 pb-2"
                style="text-align: center"
              >
                <v-rating
                  background-color="#AAAAAA"
                  dense
                  small
                  class="mr-1"
                  readonly
                  :value="parseInt(item.mark)"
                />
                <span class="nb-evals mt-2">(with {{ item.nb_evals }} evaluations)</span>
              </td>
              <td class="pt-2 pb-2">
                <div class="font-weight-bold mb-1 title">
                  <router-link
                    :to="{name: 'address-show-info', params: {id: item.id}}"
                    style="text-decoration: none; color: unset"
                  >
                    {{ item.name }}
                  </router-link>
                </div>
                <div v-if="item.is_school">
                  {{ item.school_type ? item.school_type.name : '' }}
                </div>
                <div v-else>
                  Institution
                </div>
                <div
                  style="color: grey"
                  class="mt-1"
                >
                  {{ item.number }} {{ item.street }} {{ item.zip_code }} {{ item.city }} {{ item.phone }}
                </div>
              </td>
              <td class="pt-2 pb-2">
                <div v-if="item.session_id">
                  <div>
                    <v-avatar
                      :title="sessionStatusStrLong(item.session_status)"
                      :color="sessionStatusColor(item.session_status)"
                      :size="32"
                      class="black--text font-weight-medium"
                    >
                      {{ sessionStatusStr(item.session_status) }}
                    </v-avatar>
                    &nbsp; #<router-link
                      class="black--text"
                      :to="{name: 'sessions-show-info', params: {id: item.session_id}}"
                    >
                      {{ item.session_ref }}
                    </router-link>
                    <span class="text-uppercase"> [{{ item.session_lang ? item.session_lang : '?' }}]</span>
                    <v-chip
                      style="float: right"
                      :color="sessionTypeColor(item.session_type)"
                      class="lighten-3"
                      small
                    >
                      {{ sessionTypeStr(item.session_type) }}
                    </v-chip>
                  </div>
                  <div
                    v-if="item.session_start"
                    class="mt-2"
                  >
                    <span v-if="formatDate(item.session_start) == formatDate(item.session_end)">
                      {{ formatDate(item.session_start) }} {{ formatTime(item.session_start) }}-{{ formatTime(item.session_end) }}
                    </span>
                    <span v-else>
                      {{ formatDate(item.session_start) }} {{ formatTime(item.session_start) }} - {{ formatDate(item.session_end) }} {{ formatTime(item.session_end) }}
                    </span>
                  </div>
                </div>
              </td>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
    <organization-dialog
      :visible.sync="organizationDialog"
      @created="onOrgCreated"
    />
  </div>
</template>

<script>
import Organizations from '@/api/organizations'
import OrganizationDialog from "@/components/shared/OrganizationDialog.vue"
import dateMixin from '@/mixins/date'
import SessionsMixin from '@/mixins/sessions'
import Consts from '@/consts'
import Auth from '@/plugins/auth'

export default {
  components: { OrganizationDialog },
  mixins: [dateMixin, SessionsMixin],
  data () {
    return {
      pagination: {
        rowsPerPage: 25
      },
      filters: {
        keywords: null,
        alert_mode: 0
      },
      organizations: [],
      organizationRes: [],
      searchOrg: null,
      loadingOrg: false,
      organizationDialog: false,
      modes: [
        { value: 0, text: 'All' },
        { value: 1, text: 'Schools to alert' }
      ],
      selectMode: null,
      isAdmin: false,
      isCoord: false,
      storeKey: 'address',
      init: false
    }
  },
  watch: {
    pagination: {
      handler () {
        localStorage.setItem(this.storeKey, JSON.stringify(this.pagination))
      },
      deep: true
    },
    searchOrg (val) {
      val && val !== this.queryOrganizations(val)
    },
  },
  mounted () {
    let json = JSON.parse(localStorage.getItem(this.storeKey))
    if (json) {
      this.pagination = Object.assign({}, json)
    }
    this.fetch()
    this.loadUser()
    this.init = true
  },
  methods: {
    fetch () {
      Organizations.getList(this.filters).then(res => {
        this.organizations = res.data
      })
    },
    loadUser () {
      if (Auth.user.is_admin) {
        this.isAdmin = true
      }
      if (Auth.user.roles.indexOf(Consts.COORDINATION) > -1) {
        this.isCoord = true
      }
    },

    resetFilters () {
      this.filters = { keywords: null }
      this.fetch()
    },

    queryOrganizations (val) {
      this.loadingOrg = true

      Organizations.getList({ keywords: val }).then(res => {
        this.organizationRes = res.data
        if (this.isAdmin || this.isCoord) {
          this.organizationRes.push({ _isNew: true })
        }

        this.loadingOrg = false
      })
    },
    onSelectOrg (val) {
      if (val) {
        if (val._isNew) {
          this.organizationDialog = true
        }
        else {
          this.$router.push({ name: 'address-show-info', params: { id: val.id } })
        }
      }
      else {
        this.organizationRes = []
      }
    },
    onOrgCreated (val) {
      //this.fetch();
      this.$router.push({ name: 'address-show-info', params: { id: val.id } })
    },
    onSelectMode () {
      this.fetch()
    }
  },
}
</script>
<style>
.expo {
  vertical-align: super;
  color: lightgrey;
  font-size: 14px;
}
.select-mode > .v-input__control > .v-input__slot {
  width: fit-content;
}
.nb-evals {
  color: lightgrey;
  font-size: 12px;
}
.orga-table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.orga-autocomplete > .v-select-list > .v-list > div:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.05);
}
.orga-autocomplete > .v-select-list > .v-list > div {
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>