<template>
  <div class="ma-3">
    <div class="title mb-3">
      Planning <v-icon>mdi-chevron-right</v-icon> Periods
    </div>

    <div
      layout="column"
      layout-align="center center"
    >
      <div
        layout="column"
        class="field-width"
      >
        <div
          layout="row"
          layout-align="start center"
          class="ml-3"
        >
          <v-text-field
            v-model="filters.keywords"
            solo
            label="Keywords"
            hide-details
            @keyup.enter="fetch"
          />
          <v-btn
            depressed
            color="primary"
            @click="fetch"
          >
            Filter
          </v-btn>
          or
          <a
            href="#"
            class="ml-2 black--text"
            @click="resetFilters"
          >Cancel</a>
        </div>

        <div>
          <v-btn
            flat
            class="mt-3"
            :to="{name: 'admin-planning-periods-edit', params: {id: 'new'}}"
          >
            <v-icon
              left
              color="primary"
            >
              mdi-plus-box
            </v-icon>
            Add a new period
          </v-btn>
        </div>

        <v-data-table
          :headers="headers"
          :items="periods"
          style="max-width: 1000px"
          :pagination.sync="pagination"
        >
          <template
            slot="items"
            slot-scope="{ item }"
          >
            <td>
              <v-chip :style="`background-color: ${item.period_type.color}`">
                {{ item.period_type.name }}
              </v-chip>
            </td>
            <td>{{ formatDate(item.start) }}</td>
            <td>{{ formatDate(item.end) }}</td>
            <td layout="row">
              <v-btn
                class="mx-0"
                icon
                :to="{name: 'admin-planning-periods-edit', params: {id: item.id}}"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>

              <delete-button
                :label="formatDate(item.start) + ' to ' + formatDate(item.end)"
                @confirm="deleteImpl(item)"
              />
            </td>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import Params from '@/api/params'
import DeleteButton from "@/components/shared/DeleteButton.vue"
import DateMixin from '@/mixins/date'

export default {
  components: { DeleteButton },
  mixins: [DateMixin],
  data () {
    return {
      pagination: {
        rowsPerPage: 10,
        sortBy: 'start',
        descending: true
      },
      headers: [
        { text: 'Type', value: 'period_type.name' },
        { text: 'Begin', value: 'start' },
        { text: 'End', value: 'end' },
        { text: 'Actions', sortable: false, width: "80px" }
      ],
      filters: {
        keywords: null,
        sortBy: 'start',
        descending: 1
      },
      periods: []
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      Params.getList('periods', this.filters).then(res => {
        this.periods = res.data
      })
    },

    resetFilters () {
      this.filters = { keywords: null }
      this.fetch()
    },

    deleteImpl (item) {
      Params.delete('periods', item.id).then(() => {
        this.fetch()
        this.$snotify.success(`The period has been deleted`)
      })
    }
  }
}
</script>