<template>
  <div class="ma-3">
    <div class="title mb-3">
      Planning <v-icon>mdi-chevron-right</v-icon> Period <v-icon>mdi-chevron-right</v-icon> {{ isNew ? 'New' : (formatDate(period.start) + ' to ' + formatDate(period.end)) }}
    </div>

    <v-form
      ref="form"
      v-model="formValid"
      layout="column"
      layout-align="center center"
      @submit.prevent="save"
    >
      <div>
        <div class="title font-weight-light mb-3">
          Period information
        </div>

        <div
          layout="row"
          layout-align="start center"
        >
          <v-menu
            ref="menuBegin"
            v-model="menuBegin"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="period.start"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <v-icon slot="activator">
              event
            </v-icon>
            <v-date-picker
              v-model="period.start"
              first-day-of-week="1"
              @input="$refs.menuBegin.save(period.start)"
            />
          </v-menu>
          <v-text-field
            v-model="period.start"
            outline
            hide-details
            label="Begin date"
            class="field-width ml-2"
            :rules="[ v => !!v || 'This field is required' ]"
          />
        </div>

        <div
          layout="row"
          layout-align="start center"
          class="my-3"
        >
          <v-menu
            ref="menuEnd"
            v-model="menuEnd"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="period.end"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <v-icon slot="activator">
              event
            </v-icon>
            <v-date-picker
              v-model="period.end"
              first-day-of-week="1"
              @input="$refs.menuEnd.save(period.end)"
            />
          </v-menu>
          <v-text-field
            v-model="period.end"
            outline
            hide-details
            label="End date"
            class="field-width ml-2"
            :rules="[ v => !!v || 'This field is required' ]"
          />
        </div>

        <v-select
          v-model="period.period_type_id"
          v-focus
          :items="periodTypes"
          item-text="name"
          item-value="id"
          outline
          label="Period type"
          :rules="[ v => !!v || 'This field is required' ]"
        />
      </div>

      <v-btn
        depressed
        color="primary"
        @click="save"
      >
        Save
      </v-btn>
      <router-link
        :to="{name: 'admin-planning-periods-index'}"
        class="grey--text"
      >
        Cancel
      </router-link>
    </v-form>
  </div>
</template>

<script>
import Params from '@/api/params'
import DateMixin from '@/mixins/date'

export default {
  mixins: [DateMixin],
  data () {
    return {
      period: {
        start: null,
        end: null,
        period_type_id: null
      },
      formValid: false,
      menuBegin: false,
      menuEnd: false,
      periodTypes: []
    }
  },
  computed: {
    isNew () {
      return this.$route.params.id === 'new'
    }
  },
  mounted () {
    if (!this.isNew) {
      this.fetch()
    }

    Params.getList('periods-types').then(res => {
      this.periodTypes = res.data

      if (this.isNew) {
        for (let i = 0; i < this.periodTypes.length; ++i) {
          const p = this.periodTypes[i]

          if (p.is_default) {
            this.period.period_type_id = p.id
          }
        }
      }
    })
  },
  methods: {
    fetch () {
      Params.get('periods', this.$route.params.id).then(res => {
        this.period = res.data
        this.period.start = this.period.start.substring(0, 10)
        this.period.end = this.period.end.substring(0, 10)
      })
    },
    save () {
      if (!this.$refs.form.validate()) {
        return
      }

      if (this.isNew) {
        Params.create('periods', this.period).then(() => {
          this.$router.push({ name: 'admin-planning-periods-index' })
          this.$snotify.success(`The period from ${this.period.start} to ${this.period.end} has been created`)
        })
      } else {
        Params.update('periods', this.period.id, this.period).then(() => {
          this.$router.push({ name: 'admin-planning-periods-index' })
          this.$snotify.success(`The period ${this.period.start} to ${this.period.end} has been updated`)
        })
      }
    }
  }
}
</script>