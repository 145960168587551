<template>
  <div
    id="map"
    :style="'width:' + width + '; height:' + height "
  />
</template>

<script>
import gmapsInit from '@/utils/gmaps'
import MarkerClusterer from '@google/markerclusterer'
import Sessions from '@/api/sessions'

export default {
  props: {
    width: {
      type: String,
      required: false,
      default () {
        return '100%'
      }
    },
    height: {
      type: String,
      required: false,
      default () {
        return '100%'
      }
    },
    zoom: {
      type: Number,
      required: false,
      default () {
        return 10
      }
    },
    from: {
      type: String,
      required: false,
      default: ''
    },
    to: {
      type: String,
      required: false,
      default: ''
    },
  },
  data () {
    return {
      locations: []
    }
  },
  mounted () {
    this.loadSessionLocation()
  },
  methods: {
    loadSessionLocation () {
      let params = {
        json: 1,
        from: this.from,
        to: this.to,
      }

      Sessions.getSessionLocation(params).then(res => {
        this.locations = res.data
        this.loadMap()
      })
    },
    async loadMap () {
      try {
        const google = await gmapsInit()
        const icon = {
          path: "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z",
          scale: 1.2,
          fillOpacity: 1,
          strokeColor: '',
          strokeWeight: 0,
          anchor: new google.maps.Point(12, 22)
        }
        const options = {
          center: new google.maps.LatLng(49.611622, 6.131935), //49.8152995, 6.13332
          zoom: this.zoom,
          streetViewControl: false,
          mapTypeControl: false,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          styles: [
            {
              featureType: "poi",
              stylers: [{ visibility: "off" }],
            }
          ]
        }

        const map = new google.maps.Map(this.$el, options)

        const markerClickHandler = (marker) => {
          map.setZoom(13)
          map.setCenter(marker.getPosition())
        }

        const markers = this.locations
          .map((location) => {
            let markerOptions = {
              position: new google.maps.LatLng(location.latitude, location.longitude),
              title: location.title,
              icon: Object.assign({ fillColor: location.color }, icon)
            }
            const marker = new google.maps.Marker(markerOptions)
            //const marker = new google.maps.Marker({ ...location, map });
            marker.addListener('click', () => markerClickHandler(marker))
            return marker
          })

        new MarkerClusterer(map, markers, {
          imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
          styles: [
            {
              url: window.location.origin + '/assets/images/m1.png',
              height: 53,
              width: 53
            },
            {
              url: window.location.origin + '/assets/images/m2.png',
              height: 56,
              width: 56
            },
            {
              url: window.location.origin + '/assets/images/m3.png',
              height: 66,
              width: 66
            },
            {
              url: window.location.origin + '/assets/images/m4.png',
              height: 78,
              width: 78
            }
          ]
        })
      } catch (error) {
        return
      }
    }
  },
}
</script>

<style scoped>
</style>