<template>
  <div
    layout="column"
    layout-align="start start"
    class="mb-6 mt-2"
  >
    <div
      v-if="evaluations && evaluations.length"
      layout="row"
      layout-align="start start"
      style="width: 100%"
    >
      <div
        layout="column"
        style="width: 100%"
        layout-align="center end"
        class="mb-2"
      >
        <span flex />
        <v-text-field
          v-model="keywords"
          placeholder="Quick search..."
          outline
          hide-details
          prepend-inner-icon="mdi-magnify"
          single-line
          clearable
          :filter="() => true"
        />
      </div>
    </div>
    <div
      v-if="evaluations && evaluations.length"
      layout-gt-md="row"
      layout-align="start stretch"
      layout-wrap
      style="width: 100%"
    >
      <v-card
        v-for="e in evaluations"
        :key="e.id"
        flat
        flex="33"
      >
        <v-card-title
          primary-title
          class="headline font-weight-bold"
        >
          Session #{{ e.session_trainer.session.reference }} -  <v-rating
            background-color="#AAAAAA"
            dense
            large
            readonly
            :value="parseInt(e.school_mark)"
          />
        </v-card-title>
        <v-card-text>
          <div layout="row">
            <div
              layout="column"
              flex="40"
            >
              Discipline in class
            </div>
            <div
              layout="column"
              class="ml-4"
              flex
            >
              <v-rating
                background-color="#AAAAAA"
                dense
                small
                readonly
                :value="parseInt(e.discipline)"
              />
            </diV>
          </div>
          <div layout="row">
            <div
              layout="column"
              flex="40"
            >
              Interactivity
            </div>
            <div
              layout="column"
              class="ml-4"
              flex
            >
              <v-rating
                background-color="#AAAAAA"
                dense
                small
                readonly
                :value="parseInt(e.interactivity)"
              />
            </diV>
          </div>
          <div layout="row">
            <div
              layout="column"
              flex="40"
            >
              Knowledge of the Internet
            </div>
            <div
              layout="column"
              class="ml-4"
              flex
            >
              <v-rating
                background-color="#AAAAAA"
                dense
                small
                readonly
                :value="parseInt(e.knowledge)"
              />
            </diV>
          </div>
          <div layout="row">
            <div
              layout="column"
              flex="40"
            >
              Experiences of the participants
            </div>
            <div
              layout="column"
              class="ml-4"
              flex
            >
              <v-rating
                background-color="#AAAAAA"
                dense
                small
                readonly
                :value="parseInt(e.experience)"
              />
            </diV>
          </div>
          <div layout="row">
            <div
              layout="column"
              flex="40"
            >
              Organisation on behalf the school
            </div>
            <div
              layout="column"
              class="ml-4"
              flex
            >
              <v-rating
                background-color="#AAAAAA"
                dense
                small
                readonly
                :value="parseInt(e.organization)"
              />
            </diV>
          </div>
          <div layout="row">
            <div
              layout="column"
              flex="40"
            >
              Involvement of the teachers
            </div>
            <div
              layout="column"
              class="ml-4"
              flex
            >
              <v-rating
                background-color="#AAAAAA"
                dense
                small
                readonly
                :value="parseInt(e.involvement)"
              />
            </diV>
          </div>
          <div layout="row">
            <div
              layout="column"
              flex="40"
            >
              Pupils experience
            </div>
            <div
              layout="column"
              class="ml-4"
              flex
            >
              {{ e.pupils_exp }}
            </diV>
          </div>
          <div layout="row">
            <div
              layout="column"
              flex="40"
            >
              Organisational aspects
            </div>
            <div
              layout="column"
              class="ml-4"
              flex
            >
              {{ e.orga_aspects }}
            </diV>
          </div>
          <div layout="row">
            <div
              layout="column"
              flex="40"
            >
              Other aspects
            </div>
            <div
              layout="column"
              class="ml-4"
              flex
            >
              {{ e.other }}
            </diV>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <div
      v-else
      layout="row"
      layout-align="center center"
      class="text-xs-center pa-4 white"
      style="width: 100%"
    >
      There is no evaluation yet
    </div>
    <div
      v-if="evaluations && evaluations.length"
      layout="row"
      layout-align="center center"
      style="width: 100%"
      class="text-xs-center pa-3"
    >
      <v-pagination
        v-model="page"
        :length="total"
        :total-visible="7"
      />
    </div>
  </div>
</template>


<script>
    import Organizations from '@/api/organizations'
    import dateMixin from '@/mixins/date'

    export default {
        mixins: [dateMixin],
        props: {
            organization: {
                type: Object,
                required: true
            },
        },

        data() {
            return {
                evaluations: [],
				sortBy: 'sess.updated_at',
				descending: true,
				page: 1,
				rowsPerPage: 6,
				total: 1,
                keywords: null
            }
        },

		watch: {
			page () {
				this.fetch();
			},
			keywords(val){
                if(val && val.length > 2){
					this.page = 1;
                    this.fetch();
                }
                else if(!val){
					this.page = 1;
                    this.fetch();
                }
            },
		},

        mounted() {
            this.fetch();
        },

        methods: {
            fetch () {
                Organizations.getEvals(this.$route.params.id, {page: this.page, rowsPerPage: this.rowsPerPage, sortBy: this.sortBy, descending: this.descending, keywords: this.keywords}).then(res => {
					this.evaluations = res.data.items;
                    this.total = Math.ceil(res.data.total / this.rowsPerPage);
                });
            }
        }
    }
</script>

<style scoped>
</style>