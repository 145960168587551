import axios from 'axios'
import './common'

export default {
    getEntities: (params) => axios.get(`/statistics/entities`, { params: params }),
    getSessionsEvents: (params) => axios.get(`/statistics/sessions/events`, { params: params }),
    getSessionsSchoolTrainings: (params) => axios.get(`/statistics/sessions/school-trainings`, { params: params }),
    getSessionsAdultsTrainings: (params) => axios.get(`/statistics/sessions/adults-trainings`, { params: params }),
    getSessionsYouthTrainings: (params) => axios.get(`/statistics/sessions/youth-trainings`, { params: params }),
    getSessionsTeacherTrainings: (params) => axios.get(`/statistics/sessions/teacher-trainings`, { params: params }),
    getSessionsOthers: (params) => axios.get(`/statistics/sessions/others`, { params: params }),
    getSessionsInternalMeetings: (params) => axios.get(`/statistics/sessions/internal-meetings`, { params: params }),
    getTopics: (params) => axios.get(`/statistics/sessions/topics`, { params: params }),
    getTypes: (params) => axios.get(`/statistics/sessions/types`, { params: params }),
    getSessionsTrainers: (params) => axios.get(`/statistics/trainers/sessions`, { params: params }),
    getSessionTypesTrainers: (params) => axios.get(`/statistics/trainers/session-types`, { params: params }),
    getTrainersEvalsAdults: (params) => axios.get(`/statistics/trainers-evaluations/adults-trainings`, { params: params }),
    getTrainersEvalsSchools: (params) => axios.get(`/statistics/trainers-evaluations/school-trainings`, { params: params }),
    getTrainersEvalsTeachers: (params) => axios.get(`/statistics/trainers-evaluations/teacher-trainings`, { params: params }),
    getTrainersEvalsYouth: (params) => axios.get(`/statistics/trainers-evaluations/youth-trainings`, { params: params }),
    getTrainersEvalsInternals: (params) => axios.get(`/statistics/trainers-evaluations/internal-meetings`, { params: params }),
    getTrainersEvalsOthers: (params) => axios.get(`/statistics/trainers-evaluations/others`, { params: params }),
    getTeachersEvalsAdults: (params) => axios.get(`/statistics/teachers-evaluations/adults-trainings`, { params: params }),
    getTeachersEvalsSchools: (params) => axios.get(`/statistics/teachers-evaluations/school-trainings`, { params: params }),
    getTeachersEvalsTeachers: (params) => axios.get(`/statistics/teachers-evaluations/teacher-trainings`, { params: params }),
    getTeachersEvalsYouth: (params) => axios.get(`/statistics/teachers-evaluations/youth-trainings`, { params: params }),
    getTeachersEvalsInternals: (params) => axios.get(`/statistics/teachers-evaluations/internal-meetings`, { params: params }),
    getTeachersEvalsOthers: (params) => axios.get(`/statistics/teachers-evaluations/others`, { params: params }),
    getTargetGroupsEvents: (params) => axios.get(`/statistics/target-groups/events`, { params: params }),
    getTargetGroupsAdults: (params) => axios.get(`/statistics/target-groups/adults-trainings`, { params: params }),
    getTargetGroupsSchools: (params) => axios.get(`/statistics/target-groups/school-trainings`, { params: params }),
    getTargetGroupsTeachers: (params) => axios.get(`/statistics/target-groups/teacher-trainings`, { params: params }),
    getTargetGroupsYouth: (params) => axios.get(`/statistics/target-groups/youth-trainings`, { params: params }),
    getTargetGroupsOthers: (params) => axios.get(`/statistics/target-groups/others`, { params: params }),
    exportUniv: (params) => axios.get(`/statistics/univ`, { params: params }),
    exportReviews: (params) => axios.get(`/statistics/reviews`, { params: params }),
    exportExcel: (params) => axios.get(`/statistics/export`, { responseType: "arraybuffer", params: params }),
}