<template>
  <div
    layout="column"
    layout-align="start start"
    class="mb-6 ml-4"
  >
    <div
      layout-gt-md="row"
      layout-align="start stretch"
      layout-wrap
    >
      <v-card
        v-if="isAdmin || isCoord"
        ripple
        class="panel third"
        flat
      >
        <v-card-title
          primary-title
          layout="column"
          style="height: 100%"
        >
          <v-avatar
            size="80"
            style="cursor: pointer; margin: auto"
            @click="newContact()"
          >
            <v-icon
              size="60"
              :class="{'circle-container': true, 'primary': true}"
              color="white"
            >
              mdi-plus
            </v-icon>
          </v-avatar>
        </v-card-title>
      </v-card>
      <v-card
        v-for="c in contacts"
        :key="c.id"
        class="panel third"
        flat
      >
        <v-card-title primary-title>
          <div layout="row">
            <div layout="column">
              <v-avatar color="grey">
                <span class="white--text title">{{ c.firstname[0] }}{{ c.lastname[0] }}</span>
              </v-avatar>
            </div>
            <div
              layout="column"
              class="ml-2"
              flex
            >
              <div>
                <span v-show="c.title">{{ c.title }}. </span>
                {{ c.firstname }} {{ c.lastname }}
              </div>
              <div
                v-if="c.function"
                style="color: lightgrey"
              >
                {{ c.function.name }}
              </div>
              <span flex />
            </div>
            <div
              v-if="isAdmin || isCoord"
              layout="column"
              class="ml-4"
            >
              <div layout="row">
                <span flex />
                <v-icon
                  left
                  title="Edit"
                  size="20"
                  @click="editContact(c)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  right
                  :title="c.is_enabled ? 'Disable' : 'Enable'"
                  size="20"
                  @click="toggleContact(c)"
                >
                  mdi-lock{{ c.is_enabled ? '' : '-open' }}
                </v-icon>
              </div>
            </div>
          </div>
        </v-card-title>
        <v-card-text style="margin-left: 48px">
          <!--<div class="ml-4"><v-icon v-show="c.lang" small class="mr-2">mdi-web</v-icon> {{c.lang ? c.lang.name : ''}}</diV>-->
          <div class="ml-4 mt-1">
            <v-icon
              v-show="c.phone"
              small
              class="mr-2"
            >
              mdi-phone
            </v-icon> {{ c.phone }}
          </div>
          <div class="ml-4 mt-1">
            <v-icon
              v-show="c.email"
              small
              class="mr-2"
            >
              mdi-email
            </v-icon>{{ c.email }}
          </div>
        </v-card-text>
      </v-card>
    </div>
    <contact-dialog
      :visible.sync="contactDialog"
      :organization="$route.params.id"
      :value="editingContact"
      @created="fetch"
      @updated="fetch"
    />
  </div>
</template>


<script>
import Organizations from '@/api/organizations'
import dateMixin from '@/mixins/date'
import ContactDialog from "@/components/shared/ContactDialog.vue"

export default {
  components: { ContactDialog },
  mixins: [dateMixin],
  props: ['organization', 'isAdmin', 'isCoord'],

  data () {
    return {
      contacts: [],
      contactDialog: false,
      editingContact: {}
    }
  },

  watch: {
    contactDialog (val) {
      if (!val) {
        this.editingContact = {}
      }
    }
  },

  mounted () {
    this.fetch()
  },

  methods: {
    fetch () {
      Organizations.getContactList(this.$route.params.id).then(res => {
        this.contacts = res.data
      })
    },
    newContact () {
      this.contactDialog = true
    },
    editContact (contact) {
      this.editingContact = Object.assign({}, contact)
      this.contactDialog = true
    },
    toggleContact (contact) {
      if (contact.is_enabled) {
        contact.is_enabled = 0
        Organizations.disableContact(this.$route.params.id, contact.id).then(() => {
          this.$snotify.success('The contact has been disabled')
        })
      } else {
        contact.is_enabled = 1
        Organizations.enableContact(this.$route.params.id, contact.id).then(() => {
          this.$snotify.success('The contact has been enabled')
        })
      }
    }
  }
}
</script>

<style scoped>
.panel {
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 16px;
}
</style>