<template>
  <div
    v-if="loading"
    class="ma-5 text-xs-center"
  >
    <v-progress-circular
      indeterminate
      :size="32"
      color="accent"
    />
  </div>
  <div
    v-else
    class="ma-3"
  >
    <div
      layout="row"
      class="mx-3"
    >
      <div
        class="title pb-2"
        style="width: 300px"
      >
        Statistics
      </div>
      <div
        v-show="showDates"
        class="mx-3"
        layout="row"
        layout-align="start center"
        flex
      >
        <div layout="column">
          Date range:
        </div>
        <div
          layout="column"
          class="ml-4"
        >
          <div
            layout="row"
            layout-align="start center"
          >
            <v-menu
              ref="searchStart"
              :close-on-content-click="false"
              :return-value.sync="start"
              transition="scale-transition"
              offset-y
              lazy
              full-width
            >
              <v-icon slot="activator">
                event
              </v-icon>
              <v-date-picker
                v-model="start"
                first-day-of-week="1"
                @input="$refs.searchStart.save(start); start_text = americanizeDate(start); updateEnd()"
              />
            </v-menu>
            <v-text-field
              v-model="start_text"
              label="from"
              class="ml-2 mr-4"
              @blur="start = parseDate(start_text)"
            />

            <v-menu
              ref="searchEnd"
              :close-on-content-click="false"
              :return-value.sync="end"
              transition="scale-transition"
              offset-y
              full-width
            >
              <v-icon slot="activator">
                event
              </v-icon>
              <v-date-picker
                ref="end"
                v-model="end"
                first-day-of-week="1"
                @input="$refs.searchEnd.save(end); end_text = americanizeDate(end)"
              />
            </v-menu>
            <v-text-field
              v-model="end_text"
              label="to"
              class="ml-2"
              @blur="end = parseDate(end_text)"
            />
          </div>
        </div>
        <div
          layout="column"
          class="ml-4"
        >
          <v-btn
            depressed
            color="primary"
            large
            @click="filter"
          >
            Filter
          </v-btn>
        </div>
        <div
          layout="column"
          class="ml-1"
        >
          <div layout="row">
            or&nbsp;
            <a
              class="grey--text "
              @click.prevent="init"
            >init</a>
          </div>
        </div>
        <div
          layout="column"
          flex
        >
          <div
            layout="row"
            layout-align="end center"
          >
            <v-btn
              color="primary"
              outline
              :loading="loadingXLSX"
              class="ml-5"
              :disabled="!start || !end"
              @click="exportExcel"
            >
              <v-icon>mdi-file-excel</v-icon>
              Export
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <div
      layout="row"
      class="mt-3"
    >
      <v-list class="sidebar">
        <template v-for="(item,k) in sidebarOptions">
          <v-list-tile
            v-if="!item.children"
            :key="k"
            :to="{name: item.link, params: {id: $route.params.id}}"
          >
            <v-list-tile-avatar>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-title>
              {{ item.text }}
            </v-list-tile-title>
          </v-list-tile>
          <v-list-group
            v-else
            :key="k"
          >
            <v-list-tile slot="activator">
              <v-list-tile-avatar>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-title>
                {{ item.text }}
              </v-list-tile-title>
            </v-list-tile>

            <v-list-tile
              v-for="(child, i) in item.children"
              :key="i"
              :to="{name: child.link, params: child.params}"
            >
              <v-list-tile-avatar />
              <v-list-tile-title class="pl-2">
                {{ child.text }}
              </v-list-tile-title>
            </v-list-tile>
          </v-list-group>
        </template>
      </v-list>

      <router-view
        :key="$route.path"
        class="mx-3"
        flex
        :start.sync="start"
        :end.sync="end"
        :start-text.sync="start_text"
        :end-text.sync="end_text"
        :to-filter.sync="toFilter"
        :show-dates.sync="showDates"
      />
    </div>
  </div>
</template>

<script>
import Statistics from '@/api/statistics'
import Consts from '@/consts'
import Auth from '@/plugins/auth'
import moment from 'moment'

export default {
  data () {
    return {
      sidebarOptions: [
        { text: 'Entities / Global Information', icon: 'mdi-bank', link: 'statistics-entities' },
        { text: 'Sessions per type/concept', icon: 'mdi-help-circle', link: 'statistics-types-topics' }
      ],
      loading: false,
      start: moment().startOf('month').format('YYYY-MM-DD'),
      end: moment().endOf('month').format('YYYY-MM-DD'),
      start_text: moment().startOf('month').format('DD.MM.YYYY'),
      end_text: moment().endOf('month').format('DD.MM.YYYY'),
      toFilter: false,
      showDates: true,
      loadingXLSX: false,
      isTrainer: false
    }
  },
  mounted () {
    this.loadUser()
  },
  methods: {
    updateEnd () {
      this.$refs.end.monthClick(this.start)
    },
    americanizeDate (date) {
      if (!date) return null

      if (date.indexOf('.') >= 0) {
        return date
      }

      return moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY')
    },
    parseDate (date) {
      if (!date) return null

      if (date.indexOf('-') >= 0) {
        return date
      }

      return moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD')
    },
    loadUser () {
      if ((Auth.user.roles.indexOf(Consts.TRAINER) > -1) && (Auth.user.roles.length == 1)) {
        this.isTrainer = true
      }

      if (!this.isTrainer) {
        this.sidebarOptions.push({ text: 'Sessions per trainer', icon: 'mdi-account-multiple', link: 'statistics-trainers' })
        this.sidebarOptions.push({          text: 'Evaluation forms', icon: 'mdi-thumbs-up-down', children: [
            { text: 'Adults training', link: 'statistics-evals', params: { sessionType: Consts.ADULTS } },
            { text: 'School training', link: 'statistics-evals', params: { sessionType: Consts.SCHOOL } },
            { text: 'Teacher\'s training', link: 'statistics-evals', params: { sessionType: Consts.TEACHERS } },
            { text: 'Youth training', link: 'statistics-evals', params: { sessionType: Consts.YOUTH } },
            { text: 'Internal meetings', link: 'statistics-evals', params: { sessionType: Consts.INTERNAL } },
            { text: 'Others', link: 'statistics-evals', params: { sessionType: Consts.OTHER } }
          ]        })
        this.sidebarOptions.push({          text: 'Target groups', icon: 'mdi-target', children: [
            { text: 'Events', link: 'statistics-target', params: { sessionType: Consts.EVENTS } },
            { text: 'Adults training', link: 'statistics-target', params: { sessionType: Consts.ADULTS } },
            { text: 'School training', link: 'statistics-target', params: { sessionType: Consts.SCHOOL } },
            { text: 'Teacher\'s training', link: 'statistics-target', params: { sessionType: Consts.TEACHERS } },
            { text: 'Youth training', link: 'statistics-target', params: { sessionType: Consts.YOUTH } },
            { text: 'Others', link: 'statistics-target', params: { sessionType: Consts.OTHER } }
          ]        })
        this.sidebarOptions.push({ text: 'CSV for the University', icon: 'mdi-download-network', link: 'statistics-univ' })
        this.sidebarOptions.push({ text: 'Events', icon: 'mdi-star-circle', link: 'statistics-events' })
      }
    },
    filter () {
      this.toFilter = true
    },
    init () {
      this.start = moment().startOf('month').format('YYYY-MM-DD')
      this.end = moment().endOf('month').format('YYYY-MM-DD')
      this.start_text = moment().startOf('month').format('DD.MM.YYYY')
      this.end_text = moment().endOf('month').format('DD.MM.YYYY')
      this.toFilter = true
    },
    exportExcel () {
      this.loadingXLSX = true
      Statistics.exportExcel({ start: this.start, end: this.end }).then(res => {
        this.loadingXLSX = false
        let blob = new Blob([res.data], { type: "octet/stream" })

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, 'Export-' + this.start + '-' + this.end + ".xlsx")
        } else {
          let a = document.createElement("a")
          document.body.appendChild(a)
          a.style = "display: none"
          let url = window.URL.createObjectURL(blob)
          a.href = url
          a.download = 'Export-' + this.start + '-' + this.end + ".xlsx"
          a.click()
          window.URL.revokeObjectURL(url)
        }
      })
    }
  }
}
</script>

<style scoped>
.sidebar {
  border-right: 1px solid #ddd;
  width: 300px;
  background: transparent;
}
</style>