<template>
  <div>
    <v-subheader>
      Contacts
    </v-subheader>
    <div
      v-if="session.contact"
      layout="row"
      layout-align="start start"
    >
      <div layout="column">
        <div class="title mt-2">
          {{ session.contact.firstname }} {{ session.contact.lastname }}
        </div>
        <div class="font-weight-light title mt-1">
          {{ session.contact.phone }}<br>
          {{ session.contact.email }}
        </div>
      </div>
    </div>
    <div
      v-if="session.contact2"
      layout="row"
      layout-align="start start"
    >
      <div layout="column">
        <div class="title mt-2">
          {{ session.contact2.firstname }} {{ session.contact2.lastname }}
        </div>
        <div class="font-weight-light title mt-1">
          {{ session.contact2.phone }}<br>
          {{ session.contact2.email }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
	props: {
		session: {
			type: Object,
			required: true,
			default: () => {}
		}
	}
}
</script>
<style scoped>
	.v-subheader {
		padding: 0 10px;
	}
</style>