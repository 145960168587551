<template>
  <div class="ma-3 px-2 pb-3">
    <div class="title mb-3">
      Teams
    </div>
    <div
      layout="column"
      layout-align="start start"
    >
      <div
        layout="row"
        layout-align="start center"
        class="mb-3"
      >
        <div
          layout="column"
          flex="25"
        >
          <v-select
            v-model="filters.team_id"
            v-focus
            solo
            class=""
            single-line
            hide-details
            label="Team"
            :items="teams"
            item-text="name"
            item-value="id"
            @change="fetch"
          />
        </div>
        <div
          layout="column"
          flex="25"
          class="ml-4"
        >
          <v-select
            v-model="filters.is_enabled"
            v-focus
            solo
            single-line
            hide-details
            label="Status"
            :items="status"
            @change="fetch"
          />
        </div>
        <div
          layout="column"
          flex
          class="ml-4"
        >
          <div
            layout="row"
            layout-align="start center"
          >
            <v-text-field
              v-model="filters.keywords"
              solo
              label="User"
              hide-details
              @keyup.enter="fetch"
            />
            <v-btn
              depressed
              color="primary"
              @click="fetch"
            >
              Filter
            </v-btn>
            or
            <a
              href="#"
              class="ml-2 black--text"
              @click="resetFilters"
            >Cancel</a>
          </div>
        </div>
        <div layout="column" />
      </div>

      <div
        v-if="isAdmin"
        layout="row"
        layout-align="start center"
        class="mb-3"
      >
        <v-btn
          flat
          :to="{name: 'admin-settings-teams-edit', params: {id: 'new'}}"
          class="ma-0 mr-3 pl-1 pr-2"
        >
          <v-icon
            class="pr-1"
            color="primary"
          >
            mdi-plus-box
          </v-icon>
          Add a new team
        </v-btn>
        <v-btn
          flat
          :to="{name: 'admin-settings-users-edit', params: {id: 'new'}}"
          class="ma-0 pl-1 pr-2"
        >
          <v-icon
            class="pr-1"
            color="primary"
          >
            mdi-plus-box
          </v-icon>
          Add a new user
        </v-btn>
      </div>

      <v-data-table
        :headers="headers"
        :items="users"
        style="max-width: 1200px; width: 70%"
        :pagination.sync="pagination"
      >
        <template
          slot="items"
          slot-scope="{ item }"
        >
          <td>
            <v-icon
              :color="item.is_enabled ? 'success' : 'error'"
              :title="item.is_enabled ? 'Enabled' : 'Disabled'"
            >
              mdi-circle-outline
            </v-icon>
          </td>
          <td>{{ item.firstname }} {{ item.lastname }}</td>
          <td>
            <div
              v-for="t in item.teams"
              :key="item.id + t.id"
            >
              {{ t.name }}
            </div>
          </td>
          <td>
            {{ item.email }}
          </td>
          <td>
            {{ item.phone }}
          </td>
          <td v-if="isAdmin">
            {{ item.comment }}
          </td>
          <td
            v-if="isAdmin"
            layout="row"
          >
            <v-btn
              class="mx-0"
              title="Edit"
              icon
              :to="{name: 'admin-settings-users-show', params: {id: item.id}}"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              :title="item.is_enabled ? 'Disable' : 'Enable'"
              class="mx-0"
              :disabled="Auth.user.id == item.id"
              icon
              @click="toggle(item)"
            >
              <v-icon>{{ item.is_enabled ? 'mdi-lock': 'mdi-lock-open' }}</v-icon>
            </v-btn>
            <v-btn
              v-if="item.is_candidate"
              class="mx-0"
              icon
              title="Transform the candidate to a real trainer"
              @click="transformCandidate(item.id)"
            >
              <v-icon color="green">
                mdi-account-check
              </v-icon>
            </v-btn>
          </td>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import Params from '@/api/params'
import Auth from '@/plugins/auth'

export default {
  data () {
    return {
      headers: [
        { text: 'Status', value: 'is_enabled', sortable: false, width: 75 },
        { text: 'User', value: 'firstname' },
        { text: 'Teams', value: '', sortable: false },
        { text: 'Email', value: 'email' },
        { text: 'Phone', value: 'phone' }
      ],
      filters: {
        keywords: null,
        team_id: null,
        is_enabled: 1
      },
      pagination: {
        rowsPerPage: 25
      },
      teams: [],
      users: [],
      isAdmin: false,
      storeKey: 'teams',
      status: [
        { text: 'All', value: null },
        { text: 'Enabled', value: 1 },
        { text: 'Disabled', value: 0 }
      ],
      Auth
    }
  },
  watch: {
    pagination: {
      handler () {
        localStorage.setItem(this.storeKey, JSON.stringify(this.pagination))
      },
      deep: true
    },
  },
  mounted () {
    let json = JSON.parse(localStorage.getItem(this.storeKey))
    if (json) {
      this.pagination = Object.assign({}, json)
    }
    this.loadUser()
    this.fetchParams()
  },
  methods: {
    fetchParams () {
      Params.getList('teams').then(res => {
        this.teams = res.data
      })
    },

    fetch () {
      Params.getList('users', this.filters).then(res => {
        this.users = res.data
      })
    },

    transformCandidate (id) {
      Params.update('users', id, { is_candidate: 0 }).then(() => {
        this.$snotify.success(`The user has been transformed to a real trainer`)
        this.fetch()
      })
    },

    resetFilters () {
      this.filters.keywords = null
      this.filters.team_id = null
      this.fetch()
    },

    toggle (item) {
      if (item.is_enabled) {
        Params.disable('users', item.id).then(() => {
          this.fetch()
          this.$snotify.success(`The user "${item.firstname} ${item.lastname}" has been disabled`)
        })
      } else {
        Params.enable('users', item.id).then(() => {
          this.fetch()
          this.$snotify.success(`The user "${item.firstname} ${item.lastname}" has been enabled`)
        })
      }
    },
    loadUser () {
      if (Auth.user.is_admin) {
        this.isAdmin = true
        this.headers.push({ text: 'Comment', value: 'comment', sortable: false })
        this.headers.push({ text: 'Actions', sortable: false, width: "80px" })
      }
      else {
        this.filters.is_enabled = 1
      }
      this.fetch()
    },
  }
}
</script>
<style>
.select-team > .v-input__control > .v-input__slot {
  width: fit-content;
}
</style>