<template>
  <div>
    <div class="title mb-3">
      Review
    </div>

    <div
      layout-gt-md="row"
      layout-wrap
      layout-align="start start"
      class="ml-4 mt-4 mr-4"
    >
      <v-form
        ref="form"
        style="width: 100%"
      >
        <div
          layout="column"
          flex="80"
          style="width: 80%"
        >
          <div class="title font-weight-light mb-3">
            Details of the event
          </div>
          <v-text-field
            v-model="review.nb_visitors"
            :disabled="!isAdmin && !isCoord"
            label="Visitors"
            outline
            type="number"
          />
          <v-textarea
            v-model="review.comments"
            :disabled="!isAdmin && !isCoord"
            outline
            label="Comments"
          />
          <v-textarea
            v-model="review.summary"
            :disabled="!isAdmin && !isCoord"
            outline
            label="Summary"
          />
          <v-textarea
            v-model="review.occurences"
            :disabled="!isAdmin && !isCoord"
            outline
            label="Special occurences"
          />
          <v-textarea
            v-model="review.contact_interest"
            :disabled="!isAdmin && !isCoord"
            outline
            label="Contact of special interest"
          />
          <v-textarea
            v-model="review.website_news"
            :disabled="!isAdmin && !isCoord"
            outline
            label="Website news"
          />
        </div>
        <div
          layout="column"
          flex="100"
        >
          <div
            layout="row"
            class="title font-weight-light  mt-2"
          >
            Medias
            <span flex />
            <v-btn
              :disabled="!isAdmin && !isCoord"
              outline
              color="primary"
              @click="newMedia"
            >
              <v-icon>mdi-plus</v-icon>Add a media
            </v-btn>
          </div>
          <v-data-table
            :headers="headers_medias"
            :items="review.medias"
            hide-actions
            class="elevation-1 small-table-padding"
            style="width: 100%"
          >
            <template
              slot="headerCell"
              slot-scope="props"
            >
              {{ props.header.text }}<br v-if="props.header.desc">
              <span class="caption">{{ props.header.desc }}</span>
            </template>
            <template
              slot="items"
              slot-scope="props"
            >
              <td width="50%">
                <v-text-field
                  v-model="props.item.name"
                  full-width
                  label="Name"
                  :disabled="!isAdmin && !isCoord"
                  outline
                  hide-details
                  class="my-1"
                />
                <v-text-field
                  v-model="props.item.title"
                  label="Title"
                  :disabled="!isAdmin && !isCoord"
                  outline
                  hide-details
                  full-width
                  class="my-1"
                />
              </td>
              <td width="20%">
                <v-text-field
                  v-model="dates[props.index].date_day"
                  class="text-media"
                  :disabled="!isAdmin && !isCoord"
                  label="Date"
                  prepend-icon="event"
                />

                <div
                  layout="row"
                  layout-align="start center"
                >
                  <v-text-field
                    v-model="fileName[props.index]"
                    class="text-media"
                    :disabled="!isAdmin && !isCoord"
                    label="File"
                    prepend-icon="mdi-attachment"
                    @click="pickFile(props.index)"
                  />
                </div>
                <input
                  :ref="props.index"
                  type="file"
                  style="display: none"
                  @change="onFilePicked($event)"
                >
              </td>
              <td width="25%">
                <v-text-field
                  v-model="props.item.link"
                  label="Link"
                  :disabled="!isAdmin && !isCoord"
                  outline
                  hide-details
                  full-width
                  class="my-1"
                />
              </td>
              <td>
                <v-icon
                  color="red"
                  @click="removeMedia(props.index)"
                >
                  mdi-delete
                </v-icon>
              </td>
            </template>
          </v-data-table>
        </div>
        <div
          layout="row"
          layout-align="end center"
          class="mt-2"
        >
          <v-btn
            :disabled="!isAdmin && !isCoord"
            depressed
            color="primary"
            @click="save"
          >
            Save
          </v-btn>
          or
          <router-link
            :to="{name: 'sessions-index'}"
            class="ml-2 black--text"
          >
            Cancel
          </router-link>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
    import Sessions from '@/api/sessions'
    import FormDataMixin from '@/mixins/formdata'
    import moment from 'moment';

    export default {
        mixins: [FormDataMixin],
        props: ['session', 'isAdmin', 'isCoord'],
        data () {
            return {
                review: {
                    medias: []
                },
                headers_medias: [
                    { text: 'Name / Title', align: 'left', sortable: false },
                    { text: 'Date / file', align: 'left', sortable: false},
                    { text: 'Link', align: 'left', sortable: false },
                    { text: '', align: 'left', sortable: false, width: 40 },
                ],

                fileName: [],
                fileData: [],
                dates: [],
                existingFile: {},
                currentFilePick: null
            }
        },

        mounted() {
            this.fetch();
        },

        methods: {
            fetch () {
                Sessions.getReviews(this.session.id).then(res => {
                    if(res.data && res.data.length > 0){
                        this.review = res.data[0];
                        if(this.review.medias){
                            for(let i = 0; i < this.review.medias.length; i++){
                                if(this.review.medias[i].file){
                                    this.fileName[i] = this.review.medias[i].file.name;
                                }

                                let d = {};
                                d.date = this.review.medias[i].date;
                                if(d.date && d.date != '0000-00-00 00:00:00'){
                                    d.date_day = moment(d.date).format('YYYY-MM-DD');
                                    d.date_visible = moment(d.date).format('YYYY-MM-DD');
                                }
                                else{
                                    d.date_day = null;
                                    d.date_visible = null;
                                }
                                this.dates[i] = d;
                            }
                        }
                    }
                });
            },
            save() {
                this.loading = true;

                for(let i = 0; i < this.review.medias.length; i++){
                    if(this.dates[i]){
                        this.review.medias[i].date = this.dates[i].date_day;
                    }
                    else{
                        this.review.medias[i].date = moment().format('YYYY-MM-DD');
                    }
                }

                // Convert the data to FormData for proper file upload
                let formData = new FormData();
                this._convertObjectToFormData(formData, this.review);

                for (let index in this.fileData) {
                    const file = this.fileData[index];
                    if (file) {
                        formData.append("files["+ index +"]", file, this.fileName[index]);
                    }
                }

                if (!this.review.id) {
                    Sessions.createReview(this.session.id, formData).then(() => {
                        this.loading = false;
                        this.$snotify.success('The review has been created')
                        this.$router.push({name: 'sessions-show-review'});
                    }, () => {
                        this.loading = false;
                    })
                } else {
                    Sessions.updateReview(this.session.id, this.review.id, formData).then(() => {
                        this.loading = false;
                        this.$snotify.success('The review has been edited')
                        this.$router.push({name: 'sessions-show-review'});
                    })
                }
            },
            newMedia () {
                let d = {};
                d.date_day = null;
                d.date_visible = null;
                this.dates.unshift(d);
                this.fileName.unshift("");
                this.fileData.unshift(null);
                this.review.medias.unshift({});
            },
            removeMedia (i) {
                this.review.medias.splice(i, 1);
                this.dates.splice(i, 1);
                this.fileName.splice(i, 1);
                this.fileData.splice(i, 1);


                /*for(let i = 0; i < this.dates.length; i++){
                    this.dates[i].date_day = moment(this.dates[i].date).format('YYYY-MM-DD');
                    //this.dates[i].date_visible = moment(this.dates[i].date).format('MM/DD/YYYY');
                }*/
            },

            pickFile(ref) {
                this.currentFilePick = ref;
                this.$refs[ref].click();
            },

            onFilePicked(e) {
                const files = e.target.files;
                if (files[0] !== undefined) {
                    this.$set(this.fileName, this.currentFilePick, files[0].name);
                    this.$set(this.fileData, this.currentFilePick, files[0]);
                }
            },
            saveDate(index){
                this.$refs['dates'+index].save(this.dates[index].date_day);
            },
        }
    }
</script>
<style>
.text-media > .v-input__control > .v-input__slot {
  width: max-content;
}
</style>