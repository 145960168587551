import EventBus from "@/plugins/eventbus";
import Params from "@/api/params";
import Auth from "@/plugins/auth";

export default {
  data() {
    return {
      waitingUsers: 0,
      badgesTimeOut: null
    };
  },
  mounted() {
    EventBus.$on("waitingUsers", wu => {
      this.waitingUsers = parseInt(wu);
    });
    this.waitingUsers = parseInt(localStorage.getItem("waitingUsers") || 0);
  },
  methods: {
    createBadgesTimeout() {
      EventBus.$on("login", () => {
        this.createBadgesTimeout();
      });
      EventBus.$on("logout", () => {
        this.clearBadgesTimeout();
      });
      if (Auth.user && Auth.user.is_admin) {
        this.updateWaitingUsers();
        this.badgesTimeOut = setTimeout(() => {
          this.updateWaitingUsers();
        }, 300000);
      }
    },
    clearBadgesTimeout() {
      if (this.badgesTimeOut !== null) {
        clearTimeout(this.badgesTimeOut);
        this.badgesTimeOut = null;
        localStorage.removeItem("waitingUsers");
        this.waitingUsers = 0;
      }
    },
    updateWaitingUsers () {
      if (Auth.user && Auth.user.is_admin) {
        Params.getList(`users`, { validation_waiting: 1 }).then(res => {
          this.waitingUsers = res.data.length
          EventBus.$emit("waitingUsers", this.waitingUsers)
          localStorage.setItem("waitingUsers", this.waitingUsers)
        })
      }
    }
  }
};
