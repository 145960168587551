<template>
  <v-card class="panel full-width ged">
    <v-card-text class="full-height">
      <div layout="row">
        <div
          class="title"
          flex
        >
          Documents
        </div>
        <v-btn
          v-if="isAdmin || isCoord"
          depressed
          :disabled="isCandidate"
          color="success"
          @click="pickFile"
        >
          <v-icon>mdi-plus</v-icon>
          Add new file(s)
        </v-btn>
        <input
          ref="documentFile"
          type="file"
          style="display: none"
          multiple
          accept="*/*"
          @change="onFilePicked"
        >
        <v-btn
          v-if="isAdmin || isCoord"
          outline
          :disabled="isCandidate"
          color="success"
          @click="openFolderDialog"
        >
          <v-icon>mdi-plus</v-icon>
          New folder
        </v-btn>
      </div>
      <div
        layout="row"
        class="full-width"
        style="height: calc(100vh - 280px)"
      >
        <div
          layout="column"
          class="tree-col"
        >
          <div class="v-treeview theme--light">
            <div v-if="!loadingTree">
              <div
                v-for="f in folders"
                :key="'folder_' + f.id"
              >
                <tree-item
                  :item.sync="f"
                  :active="active"
                  :open="open"
                  @openUpdated="onFolderToggle"
                  @activeUpdated="onFolderClick"
                  @drop="handleDropTree"
                  @dragover="onDragOver"
                  @dragleave="onDragLeave"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          layout="column"
          class="table-col"
          flex
        >
          <div
            layout="row"
            layout-align="start center"
          >
            <div
              v-for="(f,i) in fil"
              :key="'fil_'+i"
            >
              <div v-if="i == 0">
                <v-icon
                  color="primary"
                  @click="fetchFiles"
                >
                  mdi-home
                </v-icon>
              </div>
              <div v-else>
                &nbsp;/ <a
                  class="black--text"
                  @click="fetchFolder(f)"
                >{{ f.name }}</a>
              </div>
            </div>
          </div>

          <div
            v-show="loading"
            class="ma-5 text-xs-center"
          >
            <v-progress-circular
              indeterminate
              :size="32"
              color="accent"
            />
          </div>
          <div
            v-show="!loading"
            class="v-table__overflow docs-table"
          >
            <table class="v-datatable v-table theme--light">
              <thead>
                <tr>
                  <th
                    v-for="(h, i) in headersDocuments"
                    :key="'h_' + i"
                    class="column text-xs-left"
                  >
                    {{ h.text }}
                  </th>
                </tr>
              </thead>
              <drag
                v-for="(file, index) in files"
                :key="'file_' + index"
                tag="tr"
                :transfer-data="{file: file, index: index}"
                drop-effect="move"
                effect-allowed="move"
                :class="{ 'over': (file.over && !file.path), 'dropping': file.dropping}"
                @dragover.native="toggleOver(index, true)"
                @dragleave="toggleOver(index, false)"
              >
                <template v-if="!file.path">
                  <drop
                    tag="td"
                    class="clickable"
                    :class="{'zooming': file.zooming}"
                    @drop="handleDrop(file, index, ...arguments)"
                    @click.native="onDocClick(file)"
                  >
                    <v-icon class="ml-2">
                      mdi-folder
                    </v-icon>
                  </drop>
                  <drop
                    tag="td"
                    class="clickable"
                    :class="{'zooming': file.zooming}"
                    @click.native="onDocClick(file)"
                    @drop="handleDrop(file, index, ...arguments)"
                  >
                    {{ file.name }}
                  </drop>
                  <drop
                    tag="td"
                    @drop="handleDrop(file, index, ...arguments)"
                  >
                    /
                  </drop>
                  <drop
                    tag="td"
                    @drop="handleDrop(file, index, ...arguments)"
                  >
                    {{ file.updated_at ? formatDateTimeHuman(file.updated_at) : formatDateTimeHuman(file.created_at) }}
                  </drop>
                  <drop
                    tag="td"
                    @drop="handleDrop(file, index, ...arguments)"
                  >
                    <v-btn
                      icon
                      title="Open"
                      @click="fetchFolder(file)"
                    >
                      <v-icon color="info">
                        mdi-eye
                      </v-icon>
                    </v-btn>
                    <v-btn
                      v-if="isAdmin || isCoord"
                      icon
                      :disabled="isCandidate"
                      title="Edit"
                      @click="editFolder(file)"
                    >
                      <v-icon color="success">
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                    <v-menu
                      v-if="isAdmin || isCoord"
                      v-model="file.deletePopup"
                      :close-on-content-click="false"
                      :disabled="isCandidate"
                    >
                      <v-btn
                        slot="activator"
                        icon
                        :disabled="isCandidate"
                        title="Delete"
                      >
                        <v-icon color="error">
                          mdi-delete
                        </v-icon>
                      </v-btn>

                      <v-card>
                        <v-card-text>Do you want to delete this folder?</v-card-text>
                        <v-card-actions
                          layout="row"
                          layout-align="end center"
                        >
                          <v-btn
                            flat
                            @click="file.deletePopup = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            flat
                            color="error"
                            @click="file.deletePopup = false; deleteFolder(file, false)"
                          >
                            Delete
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-menu>
                  </drop>
                </template>
                <template v-else>
                  <td
                    class="clickable"
                    @click="onDocClick(file)"
                  >
                    <v-icon class="ml-2">
                      mdi-file
                    </v-icon>
                  </td>
                  <td
                    class="clickable"
                    @click="onDocClick(file)"
                  >
                    {{ file.name }}
                  </td>
                  <td>{{ file.size | prettyBytes }}</td>
                  <td>
                    {{ file.updated_at ? formatDateTimeHuman(file.updated_at) : formatDateTimeHuman(file.created_at) }}
                  </td>
                  <td>
                    <v-btn
                      icon
                      title="Download"
                      @click="downloadDocument(file)"
                    >
                      <v-icon color="info">
                        mdi-download
                      </v-icon>
                    </v-btn>
                    <v-btn
                      v-if="isAdmin || isCoord"
                      icon
                      :disabled="isCandidate"
                      title="Edit"
                      @click="editFile(file)"
                    >
                      <v-icon color="success">
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                    <v-menu
                      v-if="isAdmin || isCoord"
                      v-model="file.deletePopup"
                      :close-on-content-click="false"
                      :disabled="isCandidate"
                    >
                      <v-btn
                        slot="activator"
                        icon
                        :disabled="isCandidate"
                        title="Delete"
                      >
                        <v-icon color="error">
                          mdi-delete
                        </v-icon>
                      </v-btn>
                      <v-card>
                        <v-card-text>Do you want to delete this file?</v-card-text>
                        <v-card-actions
                          layout="row"
                          layout-align="end center"
                        >
                          <v-btn
                            flat
                            @click="file.deletePopup = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            flat
                            color="error"
                            @click="file.deletePopup = false; deleteDocument(file)"
                          >
                            Delete
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-menu>
                  </td>
                </template>
              </drag>
            </table>
          </div>
          <v-dialog
            v-model="folderDialog"
            max-width="500px"
          >
            <v-card>
              <v-card-title>
                Create a new folder
              </v-card-title>
              <v-card-text>
                <v-text-field
                  ref="folderName"
                  v-model="newFolderName"
                  label="Name..."
                  @keyup.enter="createFolder"
                />
              </v-card-text>
              <v-card-actions>
                <span flex />
                <v-btn
                  color="primary"
                  flat
                  @click="folderDialog=false"
                >
                  Close
                </v-btn>
                <v-btn
                  depressed
                  color="primary"
                  :disabled="!newFolderName"
                  @click="createFolder"
                >
                  Create
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="folderDialogEdit"
            max-width="500px"
          >
            <v-card>
              <v-card-title>
                Edit the folder {{ editingFolder.name }}
              </v-card-title>
              <v-card-text>
                <v-text-field
                  ref="editFolderName"
                  v-model="newFolderName"
                  label="Name..."
                />
              </v-card-text>
              <v-card-actions>
                <span flex />
                <v-btn
                  color="primary"
                  flat
                  @click="folderDialogEdit=false"
                >
                  Close
                </v-btn>
                <v-btn
                  depressed
                  color="primary"
                  :disabled="!newFolderName"
                  @click="saveFolder"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="fileDialogEdit"
            max-width="500px"
          >
            <v-card>
              <v-card-title>
                Edit the file {{ editingFile.name }}
              </v-card-title>
              <v-card-text>
                <v-text-field
                  v-model="newFileName"
                  label="Name..."
                />
              </v-card-text>
              <v-card-actions>
                <span flex />
                <v-btn
                  color="primary"
                  flat
                  @click="fileDialogEdit=false"
                >
                  Close
                </v-btn>
                <v-btn
                  depressed
                  color="primary"
                  :disabled="!newFileName"
                  @click="saveFile"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import Documents from '@/api/documents'
import FiltersMixin from '@/mixins/filters'
import FormDataMixin from '@/mixins/formdata'
import DateMixin from '@/mixins/date'
import Auth from '@/plugins/auth'
import TreeItem from "@/components/shared/TreeItem.vue"
import { Drag, Drop } from 'vue-drag-drop'
import Consts from '@/consts'

export default {
  components: {
    TreeItem,
    Drag,
    Drop
  },
  mixins: [FiltersMixin, FormDataMixin, DateMixin],
  data () {
    return {
      files: [],
      loading: false,

      headersDocuments: [
        { text: '', align: 'left', sortable: false },
        { text: 'Name', align: 'left', sortable: false },
        { text: 'Size', align: 'left', sortable: false },
        { text: 'Last modification', align: 'left', sortable: false },
        { text: 'Actions', align: 'left', sortable: false }
      ],
      currentFolder: null,
      folderDialog: false,
      newFolderName: null,
      editingFolder: {},
      editingFile: {},
      folderDialogEdit: false,
      fileDialogEdit: false,
      newFileName: null,
      fil: [],
      folders: [],
      open: [],
      rootId: null,
      from: null,
      to: null,
      fromFile: null,
      toFolder: null,
      loadingTree: false,
      isCoord: false,
      isAdmin: false
    }
  },
  computed: {
    isCandidate () {
      return !!Auth.user.is_candidate
    },
    active () {
      if (this.currentFolder) {
        return this.currentFolder.id
      } else if (this.rootId) {
        return this.rootId
      } else {
        return null
      }
    }
  },

  mounted () {
    this.fetchFiles()
    this.getTree(true)
    this.loadUser()
  },
  methods: {
    openFolderDialog () {
      this.folderDialog = true
      this.$nextTick(() => {
        this.$refs.folderName.focus()
      })
    },
    loadUser () {
      if (Auth.user.is_admin) {
        this.isAdmin = true
      }
      if (Auth.user.roles.indexOf(Consts.COORDINATION) > -1) {
        this.isCoord = true
      }
    },
    onDragOver (i) {
      this.toggleOverTree(i, true)
    },
    onDragLeave (i) {
      this.toggleOverTree(i, false)
    },
    toggleOverTree (i, v) {
      this.eachRecursive(this.folders, (k) => {
        if (k.id === i.id) {
          k.over = v
        }

        return k
      })

      this.folders = Object.assign([], this.folders)
    },
    toggleOver (idx, v) {
      this.files[idx].over = v
    },
    handleDrop (to, idx, from) {
      this.files[idx].zooming = true

      if (from.index !== undefined) {
        this.files[from.index].dropping = true
      } else {
        this.eachRecursive(this.folders, (k) => {
          if (k.id === from.file.id) {
            k.dropping = true
          }

          return k
        })

        this.folders = Object.assign([], this.folders)
      }

      this.files[idx].over = false

      setTimeout(() => {
        this.files[idx].zooming = false
        this.saveMove(from, to)
      }, 200)
    },
    handleDropTree (to, from) {
      this.eachRecursive(this.folders, (k) => {
        if (k.id === to.id) {
          k.zooming = true
        }

        return k
      })

      this.folders = Object.assign([], this.folders)

      if (from.index !== undefined) {
        this.files[from.index].dropping = true
      } else {
        this.eachRecursive(this.folders, (k) => {
          if (k.id === from.file.id) {
            k.dropping = true
          }

          return k
        })

        this.folders = Object.assign([], this.folders)
      }

      this.eachRecursive(this.folders, (k) => {
        k.over = false

        return k
      })

      this.folders = Object.assign([], this.folders)

      setTimeout(() => {
        this.eachRecursive(this.folders, (k) => {
          k.zooming = false

          return k
        })
        this.saveMove(from, to)
      }, 200)
    },
    saveMove (from, to) {
      if (from.file.id === this.rootId || from.file.id === to.id) {
        this.getTree()
        this.fetch()
        return
      }

      this.loading = true

      if (from.file.path) {
        Documents.updateFile(from.file.id, { folder_id: to.id }).then(() => {
          this.getTree()
          this.fetch()
          this.loading = false
          this.$snotify.success("File moved")
        }, () => {
          this.getTree()
          this.fetch()
          this.loading = false
        })
      } else {
        Documents.updateFolder(from.file.id, { parent_id: to.id }).then(() => {
          this.getTree()
          this.fetch()
          this.loading = false
          this.$snotify.success("Folder moved")
        }, () => {
          this.getTree()
          this.fetch()
          this.loading = false
        })
      }
    },
    eachRecursive (arr, cb) {
      for (let i = 0; i < arr.length; i++) {
        arr[i] = Object.assign({}, cb(arr[i]))
        if (arr[i].children && arr[i].children.length) {
          this.eachRecursive(arr[i].children, cb)
        }
      }
    },
    loadOvers () {
      for (let i = 0; i < this.files.length; i++) {
        this.files[i].over = false
        this.files[i].dropping = false
        this.files[i].zooming = false
        this.files[i] = Object.assign({}, this.files[i])
      }

      this.files = Object.assign([], this.files)
    },
    fetchFiles () {
      this.loading = true
      this.currentFolder = null
      Documents.getRoot().then(res => {
        this.files = res.data
        this.fil = []
        this.loadOvers()
        this.loading = false
      })
    },
    getTree (first = true) {
      this.loadingTree = true
      Documents.getTree().then(res => {
        this.folders = res.data
        if (first) {
          this.folders[0].is_root = true
          this.open.push(this.folders[0].id)
          this.rootId = this.folders[0].id
        }

        this.eachRecursive(this.folders, (k) => {
          k.over = false
          k.dropping = false
          return k
        })

        this.folders = Object.assign([], this.folders)
        this.loadingTree = false
      })
    },
    onFolderClick (item) {
      if (!this.currentFolder || this.currentFolder.id != item.id) {
        this.fetchFolder(item)
      }
    },
    onFolderToggle (item) {
      let index = this.open.indexOf(item)
      if (index > -1) {
        this.open.splice(index, 1)
      } else {
        this.open.push(item)
      }
    },
    pickFile () {
      this.$refs.documentFile.click()
    },
    onFilePicked (e) {
      const files = e.target.files
      if (files[0] !== undefined) {
        let formData = new FormData()
        for (let i = 0; i < files.length; i++) {
          formData.append("files[" + i + "]", files[i], files[i].name)
        }

        if (this.currentFolder) {
          let param = {            param: {
              folder_id: this.currentFolder.id
            }
          }

          this._convertObjectToFormData(formData, param)
        }

        this.loading = true

        Documents.newFile(formData).then(() => {
          this.loading = false
          this.fetch()
          this.$snotify.success("File(s) uploaded")
        })
      }
    },
    downloadDocument (doc) {
      this.loading = true

      Documents.getFile(doc.id).then(res => {
        this.loading = false

        let a = document.createElement("a")
        document.body.appendChild(a)
        a.style = "display: none"

        let url = res.data.link
        a.href = url
        a.download = doc.name
        a.target = '_blank'
        a.click()
        document.body.removeChild(a)
      })
    },
    deleteDocument (doc) {
      Documents.deleteFile(doc.id).then(() => {
        this.fetch()
      })
    },
    onDocClick (doc) {
      if (doc.path) {
        this.downloadDocument(doc)
      }
      else {
        this.fetchFolder(doc)
      }
    },
    fetchFolder (doc) {
      this.loading = true
      this.currentFolder = doc
      Documents.getFolder(doc.id).then(res => {
        this.files = res.data.results
        this.fil = res.data.fil
        this.loadOvers()
        this.loading = false

        //Quand on clique sur un dossier, on l'ouvre dans le tree
        for (let i = 0; i < this.fil.length; i++) {
          let f = this.fil[i]
          let index = this.open.indexOf(f.id)
          if (index == -1) {
            this.open.push(f.id)
          }
        }
      })
    },
    deleteFolder (folder) {
      Documents.deleteFolder(folder.id).then(() => {
        this.fetch()
        this.getTree()
      })
    },
    fetch () {
      if (this.currentFolder) {
        this.fetchFolder(this.currentFolder)
      }
      else {
        this.fetchFiles()
      }
    },
    createFolder () {
      this.loading = true
      Documents.newFolder({ name: this.newFolderName, parent_id: this.currentFolder ? this.currentFolder.id : null }).then(() => {
        this.fetch()
        this.newFolderName = null
        this.folderDialog = false
        this.loading = false
        this.$snotify.success("Folder created")
        this.getTree()
      }, () => {
        this.loading = false
      })
    },
    editFolder (folder) {
      const f = folder
      this.newFolderName = f.name
      this.editingFolder = f
      this.folderDialogEdit = true
      this.$nextTick(() => {
        this.$refs.editFolderName.focus()
      })
    },
    editFile (file) {
      const f = file
      this.newFileName = f.name
      this.editingFile = f
      this.fileDialogEdit = true
    },
    saveFolder () {
      this.loading = true
      Documents.updateFolder(this.editingFolder.id, { name: this.newFolderName }).then(() => {
        this.fetch()
        this.newFolderName = null
        this.editingFolder = {}
        this.folderDialogEdit = false
        this.loading = false
        this.$snotify.success("Folder updated")
        this.getTree()
      }, () => {
        this.loading = false
      })
    },
    saveFile () {
      this.loading = true
      Documents.updateFile(this.editingFile.id, { name: this.newFileName }).then(() => {
        this.fetch()
        this.newFileName = null
        this.editingFile = {}
        this.fileDialogEdit = false
        this.loading = false
        this.$snotify.success("File updated")
      }, () => {
        this.loading = false
      })
    },
  }
}
</script>

<style scoped>
.docs-table > table > tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.dropping {
  display: none;
  transition: opacity 0.3s ease;
}
.docs-table > table > tr {
  transition: transform 0.2s;
}
.zooming {
  -webkit-transform: scale(1.25); /* Safari et Chrome */
  -moz-transform: scale(1.25); /* Firefox */
  -ms-transform: scale(1.25); /* Internet Explorer 9 */
  -o-transform: scale(1.25); /* Opera */
  transform: scale(1.25);
}
.docs-table > table > tr.over {
  border: 1px solid #aaa;
  background: #ccc;
}
.ged {
  height: calc(100vh - 220px);
}
.panel {
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 16px;
}
.clickable {
  cursor: pointer;
}

@media screen and (min-width: 1280px) {
  .panel.half {
    width: calc(50% - 8px);
  }

  .panel.third {
    width: calc(33.33333% - 8px);
  }
}
.tree-col {
  border-right: 1px solid lightgray;
  margin-right: 20px;
  width: 350px;
  max-height: 100%;
  height: 100%;
  overflow-y: scroll;
}
.table-col {
  max-height: 100%;
  height: 100%;
}
.table-col > .v-table__overflow {
  overflow-y: auto;
  margin-bottom: 5px;
}
.body-container {
  overflow-y: hidden;
  max-height: 100%;
}
</style>
<style>
.selected-folder > .v-treeview-node__root {
  font-weight: bold;
  color: #f4b944;
}
</style>