<template>
  <v-dialog
    v-model="isVisible"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
        color="primary"
        fixed
      >
        <v-btn
          icon
          @click="cancel"
        >
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title v-if="contact.id">
          Edit the contact
        </v-toolbar-title>
        <v-toolbar-title v-else>
          New contact
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          flat
          :disabled="loading"
          @click="save"
        >
          Save the contact
        </v-btn>
      </v-toolbar>

      <v-card-text
        layout="row"
        layout-align="center center"
        class="mt-5 pt-5"
      >
        <v-form
          ref="contactForm"
          class="field-width"
        >
          <template v-if="info">
            <div class="title font-weight-light mb-4">
              Contact - erliewen.snj.lu
            </div>
            <div
              class="pa-2 mb-4 erliewen-block nl2br"
              v-text="info"
            />
          </template>
          <div class="title font-weight-light mb-4">
            Contact information
          </div>

          <div layout="row">
            <v-text-field
              v-model="contact.firstname"
              outline
              label="First name *"
              class="mr-1"
              :rules="[RULES.Required]"
            />
            <v-text-field
              v-model="contact.lastname"
              outline
              label="Last name *"
              class="ml-1"
              :rules="[RULES.Required]"
            />
          </div>
          <!--
                    <v-select v-focus outline label="Function" v-model="contact.function_id" item-value="id" item-text="name" :items="functions"></v-select>
                    <v-autocomplete outline label="Language" v-model="contact.lang_id" item-value="id" item-text="name" :items="langs"></v-autocomplete>
-->
          <v-text-field
            v-model="contact.phone"
            outline
            label="Phone number"
          />
          <v-text-field
            v-model="contact.email"
            outline
            label="E-mail address"
          />

          <div class="text-xs-right">
            <v-btn
              depressed
              color="primary"
              :disabled="loading"
              @click="save"
            >
              Save the contact
            </v-btn>
            or
            <a
              href="#"
              class="black--text"
              @click.prevent="cancel"
            >Cancel</a>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Params from '@/api/params'
import IsoLangs from '@/api/isolangs'
import Organizations from '@/api/organizations'
import RulesMixin from '@/mixins/rules'

export default {
  mixins: [RulesMixin],
  props: {
    value: {
      type: Object,
      required: false,
      default () { return {} }
    },
    visible: {
      type: Boolean,
      required: false,
      default: false
    },
    organization: {
      required: true
    },
    info: {
        type: String,
        required: false,
        default: ''
    }
  },
  data () {
    return {
      loading: false,
      functions: [],
      langs: []
    }
  },
  computed: {
    contact: {
      get () {
        return this.value
      },
      set (x) {
        this.$emit('input', x)
      }
    },
    isVisible: {
      get () {
        return this.visible
      },
      set (x) {
        this.$emit('update:visible', x)
      }
    }
  },
  watch: {
    isVisible (val) {
      if (val) {
        this.fetchParams()
      }
    }
  },
  mounted () {
    IsoLangs.getSpoken().then(res => {
      this.langs = res.data
    })
  },
  methods: {
    fetchParams () {
      Params.getList('functions', { is_enabled: 1, current_id: this.contact.function_id ? this.contact.function_id : null }).then(res => {
        this.functions = res.data
      })
    },
    cancel () {
      this.$nextTick(() => {
        this.$refs['contactForm'].reset()
      })
      this.isVisible = false
    },
    save () {
      if (!this.$refs['contactForm'].validate()) {
        this.$snotify.error('Please check the form for invalid values')
        return
      }

      this.loading = true

      if (!this.contact.id) {
        Organizations.createContact(this.organization, this.contact).then(res => {
          this.isVisible = false
          this.loading = false
          this.$emit('created', res.data)
          this.$nextTick(() => {
            this.$refs['contactForm'].reset()
          })
        }, () => {
          this.loading = false
        })
      } else {
        Organizations.updateContact(this.organization, this.contact.id, this.contact).then(res => {
          this.isVisible = false
          this.loading = false
          this.$emit('updated', res.data)
          this.$nextTick(() => {
            this.$refs['contactForm'].reset()
          })
        }, () => {
          this.loading = false
        })
      }
    }
  }
}
</script>
<style scoped>
.erliewen-block{
  background-color: #EEEEEE;
}
</style>