<template>
  <div class="ma-3">
    <div class="title mb-3">
      Sessions <v-icon>mdi-chevron-right</v-icon> Events activities management <v-icon>mdi-chevron-right</v-icon> {{ isNew ? 'New' : activity.name }}
    </div>

    <v-form
      ref="form"
      v-model="formValid"
      layout="column"
      layout-align="center center"
      @submit.prevent="save"
    >
      <div>
        <div class="title font-weight-light mb-3">
          Activity information
        </div>
        <v-text-field
          v-model="activity.name"
          outline
          label="Name"
          class="field-width"
          :rules="[ v => !!v || 'This field is required' ]"
        />
      </div>

      <v-btn
        depressed
        color="primary"
        @click="save"
      >
        Save
      </v-btn>
      <router-link
        :to="{name: 'admin-sessions-activities-index'}"
        class="grey--text"
      >
        Cancel
      </router-link>
    </v-form>
  </div>
</template>

<script>
    import Params from '@/api/params'

    export default {
        data () {
            return {
                activity: {
                    name: null
                },
                formValid: false
            }
        },
        computed: {
            isNew () {
                return this.$route.params.id === 'new';
            }
        },
        mounted () {
            if (!this.isNew) {
                this.fetch();
            }
        },
        methods: {
            fetch () {
                Params.get('activities', this.$route.params.id).then(res => {
                    this.activity = res.data;
                })
            },
            save () {
                if (!this.$refs.form.validate()) {
                    return;
                }

                if (this.isNew) {
                    Params.create('activities', this.activity).then(() => {
                        this.$router.push({name: 'admin-sessions-activities-index'});
                        this.$snotify.success(`The activity "${this.activity.name}" has been created`)
                    })
                } else {
                    Params.update('activities', this.activity.id, this.activity).then(() => {
                        this.$router.push({name: 'admin-sessions-activities-index'});
                        this.$snotify.success(`The activity "${this.activity.name}" has been updated`)
                    })
                }
            }
        }
    }
</script>