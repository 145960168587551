<template>
  <div>
    <v-subheader>
      Comment
    </v-subheader>
    <div
      layout="row"
      class="font-weight-light subheading"
    >
      {{ session.comments }}
    </div>
  </div>
</template>
<script>
export default {
	props: {
		session: {
			type: Object,
			required: true,
			default: () => {}
		}
	}
}
</script>
<style scoped>
	.v-subheader {
		padding: 0 10px;
	}
</style>