<template>
  <div
    layout="column"
    layout-align="center center"
  >
    <div layout="column">
      <div
        layout="row"
        layout-align="start center"
        class="mb-2"
      >
        <v-select
          v-model="filters.year"
          label="Year"
          :items="years"
          item-value="id"
          item-text="name"
          class="mr-1"
          solo
          hide-details
          @change="fetch()"
        />
        <v-text-field
          v-model="filters.keywords"
          solo
          label="Keywords"
          hide-details
          @keyup.enter="fetch()"
        />
        <v-btn
          depressed
          color="primary"
          @click="fetch()"
        >
          Filter
        </v-btn>
        or
        <a
          href="javascript:void(0);"
          class="ml-2 black--text"
          @click="resetFilters"
        >Cancel</a>
      </div>

      <v-data-table
        :items="contracts"
        :pagination.sync="pagination"
        @update:pagination="storeParams('pagination')"
      >
        <template
          slot="headers"
          slot-scope="{}"
        >
          <tr class="text-xs-left">
            <th rowspan="2">
              <v-layout
                align-content-center
                justify-center
                align-center
                class="mt-4"
              >
                <v-checkbox
                  v-model="allCheck"
                  :intermediate="
                    selected.length > 0 && selected.length !== contracts.length
                  "
                  @change="checkAll"
                />
              </v-layout>
            </th>
            <th rowspan="2">
              First name
            </th>
            <th rowspan="2">
              Last name
            </th>
            <th
              v-if="
                Auth.user.application === Consts.APPLICATION.ALL ||
                  Auth.user.application === Consts.APPLICATION.BEESECURE
              "
              class="text-xs-center"
              colspan="3"
            >
              BEE SECURE
            </th>
            <th
              v-if="
                Auth.user.application === Consts.APPLICATION.ALL ||
                  Auth.user.application === Consts.APPLICATION.FREELANCES
              "
              class="text-xs-center"
              colspan="3"
            >
              Freelances
            </th>
          </tr>
          <tr class="text-xs-left">
            <template
              v-if="
                Auth.user.application === Consts.APPLICATION.ALL ||
                  Auth.user.application === Consts.APPLICATION.BEESECURE
              "
            >
              <th style="width: 150px;white-space: initial;">
                Label
              </th>
              <th style="width: 150px;white-space: initial;">
                Extract from criminal records
              </th>
              <th style="width: 150px;white-space: initial;">
                Updated CV
              </th>
            </template>
            <template
              v-if="
                Auth.user.application === Consts.APPLICATION.ALL ||
                  Auth.user.application === Consts.APPLICATION.FREELANCES
              "
            >
              <th style="width: 150px;white-space: initial;">
                Label
              </th>
              <th style="width: 150px;white-space: initial;">
                Extract from criminal records
              </th>
              <th style="width: 150px;white-space: initial;">
                Updated CV
              </th>
            </template>
          </tr>
        </template>
        <template
          slot="items"
          slot-scope="{ item }"
        >
          <td>
            <v-layout
              align-content-center
              justify-center
              align-center
              class="pt-2"
            >
              <v-checkbox
                v-model="selected"
                :value="item.id"
                multiple
                class="mb-0"
                @change="changeAllCheck"
              />
            </v-layout>
          </td>
          <td>
            <router-link
              :to="{
                name: 'admin-settings-users-show-contracts',
                params: { id: item.id }
              }"
            >
              {{ item.firstname }}
            </router-link>
          </td>
          <td>{{ item.lastname }}</td>
          <template
            v-if="
              Auth.user.application === Consts.APPLICATION.ALL ||
                Auth.user.application === Consts.APPLICATION.BEESECURE
            "
          >
            <td class="text-xs-center">
              <v-icon
                :color="item.bs_label ? 'success' : 'error'"
                @click="switchDoc(item, 'bs_label')"
              >
                mdi-circle
              </v-icon>
            </td>
            <td class="text-xs-center">
              <v-icon
                :color="item.bs_criminal_records ? 'success' : 'error'"
                @click="switchDoc(item, 'bs_criminal_records')"
              >
                mdi-circle
              </v-icon>
            </td>
            <td class="text-xs-center">
              <v-icon
                :color="item.bs_resume ? 'success' : 'error'"
                @click="switchDoc(item, 'bs_resume')"
              >
                mdi-circle
              </v-icon>
            </td>
          </template>
          <template
            v-if="
              Auth.user.application === Consts.APPLICATION.ALL ||
                Auth.user.application === Consts.APPLICATION.FREELANCES
            "
          >
            <td class="text-xs-center">
              <v-icon
                :color="item.fl_label ? 'success' : 'error'"
                @click="switchDoc(item, 'fl_label')"
              >
                mdi-circle
              </v-icon>
            </td>
            <td class="text-xs-center">
              <v-icon
                :color="item.fl_criminal_records ? 'success' : 'error'"
                @click="switchDoc(item, 'fl_criminal_records')"
              >
                mdi-circle
              </v-icon>
            </td>
            <td class="text-xs-center">
              <v-icon
                :color="item.fl_resume ? 'success' : 'error'"
                @click="switchDoc(item, 'fl_resume')"
              >
                mdi-circle
              </v-icon>
            </td>
          </template>
        </template>
        <template slot="footer">
          <tr class="text-xs-left">
            <td>
              <v-layout
                align-content-center
                justify-center
                align-center
                class="mt-4"
              >
                <v-checkbox
                  v-model="allCheck"
                  :intermediate="
                    selected.length > 0 && selected.length !== contracts.length
                  "
                  @change="checkAll"
                />
              </v-layout>
            </td>
            <td
              :colspan="
                2 +
                  (Auth.user.application === Consts.APPLICATION.ALL
                    ? 6
                    : Auth.user.application === Consts.APPLICATION.BEESECURE ||
                      Auth.user.application === Consts.APPLICATION.FREELANCES
                      ? 3
                      : 0)
              "
            >
              <template
                v-if="
                  Auth.user.application === Consts.APPLICATION.ALL ||
                    Auth.user.application === Consts.APPLICATION.BEESECURE
                "
              >
                <a
                  v-if="selected.length > 0"
                  href="javascript:void(0);"
                  class="mr-2"
                  @click="validateAll(Consts.APPLICATION.BEESECURE)"
                >Validate BEE SECURE contracts ({{ selected.length }})</a>
                <span
                  v-else
                  class="mr-2"
                >Validate BEE SECURE contracts ({{ selected.length }})</span>
                <span
                  v-if="Auth.user.application === Consts.APPLICATION.ALL"
                  class="mr-2"
                >/</span>
              </template>
              <template
                v-if="
                  Auth.user.application === Consts.APPLICATION.ALL ||
                    Auth.user.application === Consts.APPLICATION.FREELANCES
                "
              >
                <a
                  v-if="selected.length > 0"
                  href="javascript:void(0);"
                  class="mr-2"
                  @click="validateAll(Consts.APPLICATION.FREELANCES)"
                >Validate Freelances contracts ({{ selected.length }})</a>
                <span
                  v-else
                  class="mr-2"
                >Validate Freelances contracts ({{ selected.length }})</span>
                <span
                  v-if="Auth.user.application === Consts.APPLICATION.ALL"
                  class="mr-2"
                >/</span>
              </template>
              <template v-if="Auth.user.application === Consts.APPLICATION.ALL">
                <a
                  v-if="selected.length > 0"
                  href="javascript:void(0);"
                  @click="validateAll(Consts.APPLICATION.ALL)"
                >Validate all contracts ({{ selected.length }})</a>
                <span v-else>Validate all contracts ({{ selected.length }})</span>
              </template>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import Params from "@/api/params";
import Auth from "@/plugins/auth";
import Consts from "@/consts";

export default {
  data() {
    return {
      pagination: {
        rowsPerPage: 25,
        sortBy: "firstname",
        page: 1
      },
      filters: {
        keywords: null,
        year: new Date().getFullYear()
      },
      contracts: [],
      years: [{ id: new Date().getFullYear(), name: new Date().getFullYear() }],
      selected: [],
      Auth,
      Consts,
      allCheck: false,
      storeKey: "beesecureContractsParams",
      storeKey2: "beesecureContractsPage",
      mounted: false
    };
  },
  mounted() {
    this.filters = Object.assign(
      this.filters,
      JSON.parse(localStorage.getItem(this.storeKey))
    );
    const pagination = Object.assign(
      this.pagination,
      JSON.parse(localStorage.getItem(this.storeKey2))
    );
    this.fetchYears();
    this.fetch(pagination);
  },
  methods: {
    fetchYears() {
      Params.getList("contracts/years", {}).then(res => {
        this.years = res.data.map(y => {
          return { name: y, id: y };
        });
        // this.filters.year = this.years[0] + 0;
      });
    },
    fetch(pagination) {
      this.storeParams("fetch");
      Params.getList("contracts", this.filters).then(res => {
        this.contracts = res.data;
        if (pagination) {
          this.pagination = Object.assign({}, pagination);
        }
        this.mounted = true;
      });
    },
    storeParams() {
      if (this.mounted) {
        localStorage.setItem(this.storeKey, JSON.stringify(this.filters));
        localStorage.setItem(this.storeKey2, JSON.stringify(this.pagination));
      }
    },
    resetFilters() {
      this.filters = { keywords: null, year: new Date().getFullYear() };
      this.pagination.page = 1;
      this.fetch();
    },
    switchDoc(user, field) {
      Params.create("contracts", {
        year: this.filters.year,
        id: user.id,
        field: field,
        value: !user[field]
      }).then(res => {
        if (res[field] !== user[field]) {
          this.fetch();
        }
      });
    },
    checkAll(checked) {
      if (checked) {
        this.selected = this.contracts.map(c => {
          return c.id;
        });
      } else {
        this.selected = [];
      }
      this.changeAllCheck();
    },
    changeAllCheck() {
      this.allCheck =
        this.contracts.length > 0 &&
        this.contracts.length === this.selected.length;
    },
    validateAll(application) {
      if (this.selected.length > 0) {
        Params.create("contracts/multi", {
          year: this.filters.year,
          ids: this.selected,
          application: application
        }).then(() => {
          this.selected = [];
          this.allCheck = false;
          this.fetch();
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.v-input.mb-0 {
  /deep/ .v-input__slot {
    margin-bottom: 0px !important;
  }
}
</style>
