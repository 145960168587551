<template>
  <div
    v-if="loading"
    class="ma-5 text-xs-center"
  >
    <v-progress-circular
      indeterminate
      :size="32"
      color="accent"
    />
  </div>
  <div
    v-else
    layout="column"
  >
    <div
      layout="row"
      class="subheading font-weight-light ml-2 mb-4"
    >
      School statistics for university
    </div>
    <div
      layout="row"
      layout-align="center center"
      class="pl-5 pr-5 ml-5 mr-5"
    >
      <div
        layout="column"
        flex="100"
      >
        <div
          layout="row"
          layout-align="start center"
        >
          <v-menu
            ref="searchStart"
            :close-on-content-click="false"
            :return-value.sync="searchStart"
            transition="scale-transition"
            offset-y
            full-width
            lazy
          >
            <v-icon slot="activator">
              event
            </v-icon>
            <v-date-picker
              v-model="searchStart"
              first-day-of-week="1"
              @input="$refs.searchStart.save(searchStart); searchStartText = americanizeDate(searchStart); updateStart()"
            />
          </v-menu>
          <v-text-field
            v-model="searchStartText"
            label="from"
            class="ml-2 mr-4"
            style="max-width: 200px"
            @blur="searchStart = parseDate(searchStartText)"
            @change="updateStart"
          />

          <v-menu
            ref="searchEnd"
            :close-on-content-click="false"
            :return-value.sync="searchEnd"
            transition="scale-transition"
            offset-y
            full-width
            lazy
          >
            <v-icon slot="activator">
              event
            </v-icon>
            <v-date-picker
              v-model="searchEnd"
              first-day-of-week="1"
              @input="$refs.searchEnd.save(searchEnd); searchEndText = americanizeDate(searchEnd); updateEnd()"
            />
          </v-menu>
          <v-text-field
            v-model="searchEndText"
            label="to"
            class="ml-2"
            style="max-width: 200px"
            @blur="searchEnd = parseDate(searchEndText)"
            @change="updateEnd"
          />
        </div>
        <v-autocomplete
          v-model="organization"
          label="School filter"
          outline
          prepend-inner-icon="mdi-magnify"
          single-line
          clearable
          :filter="() => true"
          :loading="loadingOrg"
          :items="organizations"
          :search-input.sync="searchOrg"
          hide-no-data
          return-object
        >
          <template
            slot="item"
            slot-scope="{ item }"
          >
            <div>
              {{ item.name }}
            </div>
          </template>
          <template
            slot="selection"
            slot-scope="{ item }"
          >
            {{ item.name }}
          </template>
        </v-autocomplete>
        <v-select
          v-model="dataType"
          v-focus
          outline
          label="Data type export"
          :items="dataTypes"
        />
        <v-select
          v-model="sessionType"
          v-focus
          outline
          label="Session type export"
          :items="sessionTypes"
        />
        <div class="text-xs-right">
          <v-btn
            depressed
            color="primary"
            :disabled="loading"
            @click="exportCSV"
          >
            Export
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Statistics from '@/api/statistics'
import Organizations from '@/api/organizations'
import Consts from '@/consts'
import moment from 'moment'

export default {
  props: ['start', 'end', 'toFilter', 'showDates', 'startText', 'endText'],
  data () {
    return {
      loading: false,
      searchOrg: null,
      loadingOrg: false,
      organization: null,
      organizations: [],
      dataType: 2,
      dataTypes: [
        { value: 1, text: 'Trainer evaluations' },
        { value: 2, text: 'Teacher evaluations' }
      ],
      sessionTypes: [
        { value: Consts.ADULTS, text: "Adults training" },
        { value: Consts.SCHOOL, text: "School training" },
        { value: Consts.TEACHERS, text: "Teachers' training" },
        { value: Consts.YOUTH, text: "Youth training" },
        { value: Consts.OTHER, text: "Others" },
      ],
      sessionType: Consts.SCHOOL,
      searchStart: this.start,
      searchEnd: this.end,
      searchStartText: this.startText,
      searchEndText: this.endText
    }
  },

  watch: {
    toFilter (val) {
      if (val) {
        this.$emit('update:toFilter', false)
      }
    },
    searchOrg (val) {
      val && val !== this.queryOrganizations(val)
    },
  },

  mounted () {
    this.$emit('update:showDates', false)
  },

  methods: {
    exportCSV () {
      this.loading = true
      Statistics.exportUniv({ start: this.start, end: this.end, session_type: this.sessionType, eval_type: this.dataType, organization_id: this.organization ? this.organization.id : null }).then(res => {
        this.loading = false
        let blob = new Blob([res.data], { type: "octet/stream" })

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, moment().format('YYYY-MM-DD-hh-mm-ss') + ".csv")
        } else {
          let a = document.createElement("a")
          document.body.appendChild(a)
          a.style = "display: none"
          let url = window.URL.createObjectURL(blob)
          a.href = url
          a.download = moment().format('YYYY-MM-DD-hh-mm-ss') + ".csv"
          a.click()
          window.URL.revokeObjectURL(url)
        }
      })

    },

    queryOrganizations (val) {
      this.loadingOrg = true

      Organizations.getList({ keywords: val, is_school: 1 }).then(res => {
        this.organizations = res.data
        this.loadingOrg = false
      })
    },

    updateStart () {
      this.$emit('update:start', this.searchStart)
      this.$emit('update:startText', this.searchStartText)
    },
    updateEnd () {
      this.$emit('update:end', this.searchEnd)
      this.$emit('update:endText', this.searchEndText)
    },
    americanizeDate (date) {
      if (!date) return null

      if (date.indexOf('.') >= 0) {
        return date
      }

      return moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY')
    },
    parseDate (date) {
      if (!date) return null

      if (date.indexOf('-') >= 0) {
        return date
      }

      return moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD')
    },
  }
}
</script>
<style scoped>
.number-color {
  color: #f4b944;
}
</style>