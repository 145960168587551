import Vue from 'vue'

import moment from 'moment'
//import 'moment/locale/fr'
//moment.locale('fr')

import DaySpanVuetify from 'dayspan-vuetify'
//import fr from 'dayspan-vuetify/src/locales/fr'
import 'dayspan-vuetify/dist/lib/dayspan-vuetify.min.css'

Vue.use(DaySpanVuetify, {
  methods: {
    getDefaultEventColor: () => '#f4b944',

  },
  data:
  {
    features: {
      exclude: true,
      include: true,
      cancel: false,
      move: false,
      moveDuplicate: false,
      moveInstance: false,
      moveAll: false,
      drag: false,
      forecast: true,
      addDay: false,
      addTime: false,
      hideOnMove: true
    },
    prompt: {
      actionRemove: false,
      actionExclude: true,
      actionCancel: true,
      actionUncancel: false,
      actionMove: false,
      actionInclude: true,
      actionSetStart: true,
      actionSetEnd: true,
      move: false,
      toggleAllDay: false,
      removeExistingTime: false
    },
    //locales: { fr }
  },
  defaults: {
    dsWeeksView: {
      weekdays: moment.weekdaysShort(true)
    },
    weekdays: moment.weekdaysShort(true)
  },
})
