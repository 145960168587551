<template>
  <div layout="column">
    <div
      layout="row"
      class="subheading font-weight-light ml-2 mb-2"
    >
      Session per target groups
    </div>
    <div
      layout="row"
      class="font-weight-bold title"
    >
      <canvas
        v-if="showGraph"
        id="myChart"
        height="400"
      />
    </div>
  </div>
</template>

<script>
import Statistics from '@/api/statistics'
import Consts from '@/consts'
import Chart from "chart.js"

export default {
  props: ['start', 'end', 'toFilter', 'showDates'],
  data () {
    return {
      targetGroups: [],
      loading: false,
      canva: null,
      labels: [],
      dataset: [],
      showGraph: true
    }
  },

  watch: {
    '$route.params.sessionType': function () {
      this.fetch()
    },
    toFilter (val) {
      if (val) {
        this.fetch()
        this.$emit('update:toFilter', false)
      }
    }
  },

  mounted () {
    this.fetch()
    this.$emit('update:showDates', true)
  },

  methods: {
    fetch () {
      this.loading = true
      this.showGraph = false
      switch (parseInt(this.$route.params.sessionType)) {
        case Consts.EVENTS:
          Statistics.getTargetGroupsEvents({ start: this.start, end: this.end }).then(res => {
            this.targetGroups = res.data
            this.loading = false
            this.loadGraph()
          })
          break
        case Consts.ADULTS:
          Statistics.getTargetGroupsAdults({ start: this.start, end: this.end }).then(res => {
            this.targetGroups = res.data
            this.loading = false
            this.loadGraph()
          })
          break
        case Consts.SCHOOL:
          Statistics.getTargetGroupsSchools({ start: this.start, end: this.end }).then(res => {
            this.targetGroups = res.data
            this.loading = false
            this.loadGraph()
          })
          break
        case Consts.TEACHERS:
          Statistics.getTargetGroupsTeachers({ start: this.start, end: this.end }).then(res => {
            this.targetGroups = res.data
            this.loading = false
            this.loadGraph()
          })
          break
        case Consts.YOUTH:
          Statistics.getTargetGroupsYouth({ start: this.start, end: this.end }).then(res => {
            this.targetGroups = res.data
            this.loading = false
            this.loadGraph()
          })
          break
        case Consts.OTHER:
          Statistics.getTargetGroupsOthers({ start: this.start, end: this.end }).then(res => {
            this.targetGroups = res.data
            this.loading = false
            this.loadGraph()
          })
          break
      }
    },

    loadGraph () {
      this.labels = []
      this.canva = null
      this.dataset = []
      if (this.targetGroups && this.targetGroups.length > 0) {
        for (let i = 0; i < this.targetGroups.length; i++) {
          this.labels.push(this.targetGroups[i].name)
          this.dataset.push(this.targetGroups[i].nb)
        }

        this.showGraph = true
        this.$nextTick(() => {
          let ctx = document.getElementById("myChart").getContext('2d')
          this.canva = new Chart(ctx, {
            type: 'bar',
            data: {
              labels: this.labels,
              datasets: [{
                data: this.dataset,
                backgroundColor: '#f4b944',
                borderColor: '#d9950d',
                borderWidth: 1,
                hoverBackgroundColor: '#f7c96e',
                hoverBorderColor: '#f2ae26',
                hoverBorderWidth: 1
              }]
            },
            options: {
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true
                  },
                  display: true
                }]
              },
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                display: false
              },
            },
          })
        })
      } else {
        this.showGraph = false
      }
    }
  }
}
</script>
<style scoped>
.number-color {
  color: #f4b944;
}
</style>