<template>
  <div
    layout="column"
    layout-align="start start"
    class="mt-2 pa-3"
  >
    <div class="title">
      Package {{ session.package ? session.package.name : '' }}
    </div>
    <div
      layout="column"
      class="field-width mx-auto"
    >
      <v-data-table
        :headers="headers"
        :items="lines"
        hide-actions
      >
        <template
          slot="items"
          slot-scope="{item}"
        >
          <td>{{ formatDate(item.day) }}</td>
          <td>
            <div
              v-for="s in item.sessions.morning"
              :key="item.day + s.id + 'morning'"
            >
              <div>
                <router-link :to="{name: 'sessions-show-info', params: {id: s.id}}">
                  #{{ s.reference }}
                </router-link> - {{ s.topic.name }}
              </div>
              <div>{{ s.start }} - {{ s.end }}</div>
            </div>
          </td>
          <td>
            <div
              v-for="s in item.sessions.afternoon"
              :key="item.day + s.id + 'afternoon'"
            >
              <div>
                <router-link :to="{name: 'sessions-show-info', params: {id: s.id}}">
                  #{{ s.reference }}
                </router-link> - {{ s.topic.name }}
              </div>
              <div>{{ s.start }} - {{ s.end }}</div>
            </div>
          </td>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import DateMixin from '@/mixins/date'
import Sessions from '@/api/sessions'
import moment from 'moment'

export default {
  mixins: [DateMixin],
  props: ['session'],
  data () {
    return {
			lines: [],
			headers: [
        { text: "Day", value: "day", sortable: false },
        { text: "Morning", value: "morning", sortable: false },
        { text: "Afternoon", value: "afternoon", sortable: false }
      ],
		}
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch(){
			Sessions.getList({group_id: this.session.group_id, sortBy: 'sd.start'}).then(res => {
				const lines = {}
				res.data.forEach(s => {
					const d = s.dates[0]
					const day = moment(d.start).format('YYYY-MM-DD')
					if(!lines[day]){
						lines[day] = {
							morning: [],
							afternoon: []
						}
					}

					let m = 'morning'

					if(parseInt(moment(d.start).format('HH')) >= 12){
						m = 'afternoon'
					}

					lines[day][m].push({
						start: this.formatTime(d.start),
						end: this.formatTime(d.end),
						id: s.id,
						reference: s.reference,
						topic: s.topic
					})
				})

				for(let d in lines){
					this.lines.push({
						day: d,
						sessions: lines[d]
					})
				}
			})
		}
  }
}
</script>
