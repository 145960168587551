<template>
  <div class="ma-3">
    <div class="title mb-3">
      Sessions <v-icon>mdi-chevron-right</v-icon> Target groups management <v-icon>mdi-chevron-right</v-icon> {{ isNew ? 'New' : target.name }}
    </div>

    <v-form
      ref="form"
      v-model="formValid"
      layout="column"
      layout-align="center center"
      @submit.prevent="save"
    >
      <div>
        <div class="title font-weight-light mb-3">
          Target group information
        </div>
        <v-text-field
          v-model="target.name"
          outline
          label="Name"
          class="field-width"
          :rules="[ v => !!v || 'This field is required' ]"
        />
        <v-select
          v-model="target.session_types"
          v-focus
          placeholder="Session types"
          :items="sessionTypes"
          item-text="text"
          item-value="value"
          class="field-width"
          multiple
          outline
          single-line
        />
        <v-checkbox
          v-model="target.show_in_stats"
          label="Show in stats"
          :true-value="1"
          :false-value="0"
        />
      </div>

      <v-btn
        depressed
        color="primary"
        @click="save"
      >
        Save
      </v-btn>
      <router-link
        :to="{name: 'admin-sessions-targets-index'}"
        class="grey--text"
      >
        Cancel
      </router-link>
    </v-form>
  </div>
</template>

<script>
import Params from '@/api/params'
import Consts from '@/consts'
import SessionMixin from '@/mixins/sessions'

export default {
  mixins: [SessionMixin],
  data () {
    return {
      target: {
        name: null,
        show_in_stats: false,
        session_types: []
      },
      formValid: false,
      sessionTypes: [
        { value: Consts.EVENTS, text: this.sessionTypeStrLong(Consts.EVENTS) },
        { value: Consts.ADULTS, text: this.sessionTypeStrLong(Consts.ADULTS) },
        { value: Consts.SCHOOL, text: this.sessionTypeStrLong(Consts.SCHOOL) },
        { value: Consts.TEACHERS, text: this.sessionTypeStrLong(Consts.TEACHERS) },
        { value: Consts.YOUTH, text: this.sessionTypeStrLong(Consts.YOUTH) },
        { value: Consts.INTERNAL, text: this.sessionTypeStrLong(Consts.INTERNAL) },
        { value: Consts.OTHER, text: this.sessionTypeStrLong(Consts.OTHER) },
        { value: Consts.ACTIVITY, text: this.sessionTypeStrLong(Consts.ACTIVITY) },
      ],
    }
  },
  computed: {
    isNew () {
      return this.$route.params.id === 'new'
    }
  },
  mounted () {
    if (!this.isNew) {
      this.fetch()
    }
  },
  methods: {
    fetch () {
      Params.get('targets-groups', this.$route.params.id).then(res => {
        this.target = res.data
      })
    },
    save () {
      if (!this.$refs.form.validate()) {
        return
      }

      if (this.isNew) {
        Params.create('targets-groups', this.target).then(() => {
          this.$router.push({ name: 'admin-sessions-targets-index' })
          this.$snotify.success(`The target group "${this.target.name}" has been created`)
        })
      } else {
        Params.update('targets-groups', this.target.id, this.target).then(() => {
          this.$router.push({ name: 'admin-sessions-targets-index' })
          this.$snotify.success(`The target group "${this.target.name}" has been updated`)
        })
      }
    }
  }
}
</script>