<template>
  <div class="ma-3">
    <div
      v-if="skill.topic"
      class="title"
    >
      {{ skill.topic.name }} <v-icon>mdi-chevron-right</v-icon> Seasons
    </div>
    <router-link
      :to="{name: 'admin-settings-users-show-skills'}"
      class="link-unstyled py-2 mb-3 no-printable"
      style="width: max-content"
    >
      <v-icon
        color="primary"
        size="16"
      >
        mdi-arrow-left
      </v-icon>
      Back to the skills list
    </router-link>
    <div
      layout="column"
      layout-align="center center"
    >
      <div
        layout="column"
        class="field-width"
      >
        <div>
          <v-menu
            v-model="menu"
            offset-y
          >
            <v-btn
              slot="activator"
              style="margin-left: 0"
              depressed
              color="primary"
              @click="openField"
            >
              <v-icon left>
                mdi-plus-box
              </v-icon>
              Add a new season
              <v-icon right>
                mdi-chevron-down
              </v-icon>
            </v-btn>
            <v-autocomplete
              ref="seasonField"
              v-model="newSeasonId"
              :items="availableSeasons"
              :item-text="item => item.center.name + ' (' + formatDate(item.start) + ' - ' + formatDate(item.end) + ')'"
              item-value="id"
              outline
              label="Season"
              style="background: #FFF"
              hide-details
              @change="addSeason"
            />
          </v-menu>
        </div>
        <v-data-table
          :headers="headers"
          :items="seasons"
          style="max-width: 1000px"
          :pagination.sync="pagination"
        >
          <template
            slot="items"
            slot-scope="{ item }"
          >
            <td>
              <v-icon
                :color="item.is_enabled ? 'success' : 'error'"
                :title="item.is_enabled ? 'Enabled' : 'Disabled'"
              >
                mdi-circle-outline
              </v-icon>
            </td>
            <td>{{ item.season.center.name }}</td>
            <td>{{ formatDate(item.season.start) }}</td>
            <td>{{ formatDate(item.season.end) }}</td>
            <td layout="row">
              <v-btn
                :title="item.is_enabled ? 'Disable': 'Enable'"
                class="mx-0"
                icon
                @click="toggle(item)"
              >
                <v-icon>{{ item.is_enabled ? 'mdi-lock': 'mdi-lock-open' }}</v-icon>
              </v-btn>
            </td>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
	import Params from '@/api/params'
	import DateMixin from '@/mixins/date'

	export default {
		mixins: [DateMixin],
		props: {
			user: {
				type: Object,
				required: true,
				default: () => {}
			}
		},
		data () {
				return {
						pagination: {
								rowsPerPage: 10
						},
						headers: [
								{ text: 'Status', value: 'is_enabled', sortable: false, width: 75 },
								{ text: 'Center', value: 'season.center.name' },
								{ text: 'Start', value: 'start' },
								{ text: 'End', value: 'end' },
								{ text: 'Actions', sortable: false, width: "80px" }
						],
						seasons: [],
						options: [],
						skill: {},
						newSeasonId: null,
						menu: false
				}
		},
		computed: {
			availableSeasons(){
				const arr = []

				this.options.forEach(o => {
					let found = false
					this.seasons.forEach(s => {
						if(s.season_id === o.id){
							found = true
						}
					})

					if(!found){
						arr.push(o)
					}
				})

				return arr
			}
		},
		created () {
				Params.get(`users/${this.user.id}/skills`, this.$route.params.skill_id).then(res => {
						this.skill = res.data;
						this.fetchParams()
				})

				this.fetch();
		},
		methods: {
				openField(){
					setTimeout(() => {
						this.$refs.seasonField.isMenuActive = true
						this.$refs.seasonField.focus()
					}, 100)
				},
				addSeason(){
					this.menu = false

					Params.create(`users/${this.user.id}/seasons`, {skill_id: this.skill.id, season_id: this.newSeasonId}).then(() => {
						this.fetch()
						this.$snotify.success(`Season has been attached to the user`)
						this.newSeasonId = null
						this.$refs.seasonField.lazySearch = ''
						this.$refs.seasonField.lazyValue = null
					})
				},
				fetchParams(){
						Params.getList('seasons', {topic_id: this.skill.topic_id, not_past: 1, is_enabled: 1}).then(res => {
								this.options = res.data;
						})
				},
				fetch () {
						Params.getList(`users/${this.user.id}/seasons`, {skill_id: this.$route.params.skill_id}).then(res => {
								this.seasons = res.data;
						})
				},
				toggle (item) {
						if (item.is_enabled) {
								Params.disable(`users/${this.user.id}/seasons`, item.id).then(() => {
										this.fetch();
										this.$snotify.success(`Season has been disabled`)
								})
						} else {
								Params.enable(`users/${this.user.id}/seasons`, item.id).then(() => {
										this.fetch();
										this.$snotify.success(`Season has been enabled`)
								})
						}
				}
		}
	}
</script>