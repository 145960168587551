<template>
  <v-menu
    v-if="displayed && isAdmin && mails !== ''"
    v-model="modal"
    :close-on-content-click="false"
  >
    <v-btn
      slot="activator"
      icon
      title="Daily Recap' en attente"
    >
      <v-badge
        left
        overlap
        color="primary"
      >
        <span
          slot="badge"
          style="font-size: 10px"
        >{{ dailyNumber }}</span>
        <v-icon
          :large="!$vuetify.breakpoint.smAndDown"
          color="grey darken-1"
        >
          mdi-email
        </v-icon>
      </v-badge>
    </v-btn>
    <v-card style="max-width: 400px">
      <v-card-text>
        Are you sure you want to send the Daily Recap' on hold to the test mail: {{ mails }} ?
      </v-card-text>
      <v-card-actions
        layout="row"
        layout-align="end center"
      >
        <v-btn
          flat
          @click="modal = false"
        >
          Cancel
        </v-btn>
        <v-btn
          flat
          color="green"
          @click="sendTestDailyRecap"
        >
          Send
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
  import axios from 'axios'
  import Auth from '@/plugins/auth'

  export default {
    data () {
      return {
        displayed: process.env.NODE_ENV !== 'production',
        dailyNumber: 0,
        mails: '',
        isAdmin: false,
        modal: false
      }
    },
    created () {
      if(Auth.user.is_admin){
          this.isAdmin = true;
      }
      try {
        if (process.env.NODE_ENV !== 'production') {
          axios.get('/development/daily-recap/count')
            .then((resp) => {
              this.dailyNumber = resp.data.nb
              if (this.dailyNumber > 99) {
                this.dailyNumber = '+99'
              }
              this.mails = resp.data.mails
            })
        }
      } catch (e) {
        return
      }
    },
    methods: {
      sendTestDailyRecap () {
        if (this.dailyNumber > 0) {
          axios.get('/development/daily-recap/send')
            .then((resp) => {
              this.dailyNumber = resp.data.nb
              if (this.dailyNumber > 99) {
                this.dailyNumber = '+99'
              }
              this.mails = resp.data.mails
            })
            this.modal = false;
        } else {
          alert('There is no Daily Recap\' to send.')
        }
      }
    }
  }
 </script>
