<template>
  <div
    layout="column"
    class="white toolbar-container"
  >
    <div
      layout="row"
      layout-align="start center"
      class="ma-2"
    >
      <img
        src="@/assets/logo.png"
        class="logo"
      >
      <span flex />
      <v-icon
        v-if="mailqueue"
        title="The server mail queue is not empty"
        color="error"
        size="45"
      >
        mdi-email-alert
      </v-icon>
      <test-daily-recap v-if="isAdmin" />
      <div class="search-bar hidden-sm-and-down">
        <v-autocomplete
          solo
          flat
          single-line
          hide-details
          label="Find a school, a session, ..."
          outline
          :filter="() => true"
          :loading="loadingSearch"
          :items="results"
          :search-input.sync="search"
          return-object
          hide-no-data
          box
          chips
          @input="onSelectRes"
        >
          <template
            slot="item"
            slot-scope="data"
          >
            <template v-if="typeof data.item !== 'object'">
              <v-list-tile-content>{{ data.item }}</v-list-tile-content>
            </template>
            <template v-else-if="data.item.type == 1">
              <v-list-tile-avatar>
                <v-avatar
                  :title="sessionStatusStrLong(data.item.status)"
                  :color="sessionStatusColor(data.item.status)"
                  :size="32"
                  class="black--text font-weight-medium"
                >
                  {{ sessionStatusStr(data.item.status) }}
                </v-avatar>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>
                  <div
                    layout="row"
                    layout-align="start center"
                  >
                    #<span class="black--text font-weight-medium">{{
                      data.item.reference
                    }}</span>
                    &nbsp;<span class="text-uppercase">
                      [{{ data.item.lang_id ? data.item.lang_id : "?" }}]</span>
                    <span flex />
                    <v-chip
                      style="font-size: 12px; height: 20px"
                      :color="sessionTypeColor(data.item.session_type)"
                      class="lighten-3"
                      small
                    >
                      {{ sessionTypeStr(data.item.session_type) }}
                    </v-chip>
                  </div>
                </v-list-tile-title>
                <v-list-tile-sub-title>
                  <span v-if="data.item.dates && data.item.dates.length > 0">
                    <span
                      v-if="
                        formatDate(data.item.dates[0].start) ==
                          formatDate(
                            data.item.dates[data.item.dates.length - 1].end
                          )
                      "
                    >
                      {{ formatDateHuman(data.item.dates[0].start) }} until
                      {{ formatTime(data.item.dates[0].end) }}
                    </span>
                    <span v-else>
                      {{ formatDateHuman(data.item.dates[0].start) }} -
                      {{
                        formatDateHuman(
                          data.item.dates[data.item.dates.length - 1].end
                        )
                      }}
                    </span>
                  </span>
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </template>
            <template v-else-if="data.item.type == 2">
              <v-list-tile-avatar>
                <v-icon v-if="data.item.is_school">
                  mdi-school
                </v-icon>
                <v-icon v-else>
                  mdi-domain
                </v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>
                  {{ data.item.name }}
                </v-list-tile-title>
                <v-list-tile-sub-title>
                  {{ data.item.number }} {{ data.item.street }}
                  {{ data.item.zip_code }} {{ data.item.city }}
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </template>
          </template>
        </v-autocomplete>
      </div>
      <a
        href="#"
        class="black--text mx-3 hidden-sm-and-down"
        @click.prevent="$refs.userInfo.open()"
      >{{
        fullName
      }}</a>
      <v-btn
        small
        flat
        class="hidden-sm-and-down"
        @click="logout"
      >
        <v-icon size="16">
          mdi-exit-to-app
        </v-icon>
        <span class="hidden-md-and-down">Logout</span>
      </v-btn>
      <v-btn
        class="mx-0 mr-2 hidden-md-and-up"
        icon
        @click="displaySidenav = !displaySidenav"
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </div>
    <div
      v-if="$vuetify.breakpoint.smAndDown && displaySidenav"
      class="mobile-sidenav"
    >
      <v-list style="background: transparent;">
        <v-list-tile
          v-if="isAdmin || isCoord || isTrainer"
          :to="{name: 'sessions-index'}"
          @click.prevent="displaySidenav = false"
        >
          <v-list-tile-action style="min-width: 56px">
            <v-icon>mdi-clipboard-text</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>
              Sessions
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile
          v-if="isAdmin || isCoord || isTrainer"
          :to="{name: 'planning-mobile'}"
          @click.prevent="displaySidenav = false"
        >
          <v-list-tile-action style="min-width: 56px">
            <v-icon>mdi-calendar</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>
              Planning
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile
          v-if="isAdmin || isCoord || isTrainer"
          :to="{name: 'address-index'}"
          @click.prevent="displaySidenav = false"
        >
          <v-list-tile-action style="min-width: 56px">
            <v-icon>mdi-clipboard-account</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>
              Address Book
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile
          v-if="isAdmin && isBeesecure"
          :to="{name: 'logistics-index'}"
          @click.prevent="displaySidenav = false"
        >
          <v-list-tile-action style="min-width: 56px">
            <v-icon>mdi-truck</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>
              Logistics
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile
          v-if="isAdmin || isCoord || isTrainer"
          :to="{name: 'stats-index'}"
          @click.prevent="displaySidenav = false"
        >
          <v-list-tile-action style="min-width: 56px">
            <v-icon>mdi-chart-line-variant</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>
              Statistics
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile
          v-if="isAdmin || isCoord"
          :to="{name: 'teams-index'}"
          @click.prevent="displaySidenav = false"
        >
          <v-list-tile-action style="min-width: 56px">
            <v-icon>mdi-account-group</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>
              Teams
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile
          v-if="isAdmin || isCoord || isTrainer"
          href="http://topten.bee-secure.lu/"
          target="_blank"
        >
          <v-list-tile-action style="min-width: 56px">
            <v-icon>mdi-bookmark</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>
              Top Ten Threats
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile
          :to="{name: 'documents-index'}"
          @click.prevent="displaySidenav = false"
        >
          <v-list-tile-action style="min-width: 56px">
            <v-icon>mdi-folder</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>
              Documents
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile
          v-if="isAdmin"
          :to="{name: 'admin-index'}"
          @click.prevent="displaySidenav = false"
        >
          <v-list-tile-action style="min-width: 56px">
            <v-badge
              v-if="waitingUsers"
              right
              bottom
              overlap
              color="red"
              class="user-badge"
            >
              <span
                slot="badge"
                style="font-size: 10px"
              >{{ waitingUsers }}</span>
              <v-icon>mdi-cog</v-icon>
            </v-badge>
            <v-icon v-else>
              mdi-cog
            </v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>
              Administration
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile
          @click.prevent="displaySidenav = false; $refs.userInfo.open()"
        >
          <v-list-tile-action style="min-width: 56px">
            <v-icon>mdi-account-circle</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title class="black--text">
              Profile
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile
          @click.prevent="displaySidenav = false; logout()"
        >
          <v-list-tile-action style="min-width: 56px">
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title class="black--text">
              Logout
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </div>
    <v-divider class="hidden-sm-and-down" />
    <div
      v-show="toolbar"
      layout="row"
      class="pt-2 pb-3 hidden-sm-and-down"
    >
      <toolbar-button
        v-if="isAdmin || isCoord || isTrainer"
        icon="mdi-clipboard-text"
        label="Sessions"
        :to="{ name: 'sessions-index' }"
      />
      <toolbar-button
        v-if="isAdmin || isCoord || isTrainer"
        icon="mdi-calendar"
        label="Planning"
        :to="{ name: 'planning-index' }"
      />
      <toolbar-button
        v-if="isAdmin || isCoord || isTrainer"
        icon="mdi-clipboard-account"
        label="Address Book"
        :to="{ name: 'address-index' }"
      />
      <toolbar-button
        v-if="isAdmin && isBeesecure"
        icon="mdi-truck"
        label="Logistics"
        :to="{ name: 'logistics-index' }"
      />
      <toolbar-button
        v-if="isAdmin || isCoord || isTrainer"
        icon="mdi-chart-line-variant"
        label="Statistics"
        :to="{ name: 'stats-index' }"
      />
      <toolbar-button
        v-if="isAdmin || isCoord"
        icon="mdi-account-group"
        label="Teams"
        :to="{ name: 'teams-index' }"
      />
      <span flex />
      <div
        v-if="isAdmin || isCoord || isTrainer"
        layout="column"
        layout-align="center center"
        class="mx-3"
      >
        <a
          href="http://topten.bee-secure.lu/"
          target="_blank"
          style="text-decoration: none"
        >
          <v-btn
            outline
            fab
            color="black"
          >
            <v-icon color="black">mdi-bookmark</v-icon>
          </v-btn>
        </a>
        <span>
          Top Ten Threats
        </span>
      </div>
      <toolbar-button
        icon="mdi-folder"
        label="Documents"
        :to="{ name: 'documents-index' }"
      />
      <toolbar-button
        v-if="isAdmin"
        icon="mdi-cog"
        label="Administration"
        :to="{ name: 'admin-index' }"
        :badge="waitingUsers"
      />
    </div>
    <v-divider
      v-show="toolbar"
      class="hidden-sm-and-down"
    />
    <div
      v-if="toolbar"
      class="toolbar-toggle hidden-sm-and-down"
      @click="toggleToolbar"
    >
      <v-icon size="18px">
        mdi-chevron-up
      </v-icon>
    </div>
    <div
      v-else
      class="toolbar-toggle down hidden-sm-and-down"
      @click="toggleToolbar"
    >
      <v-icon size="18px">
        mdi-chevron-down
      </v-icon>
    </div>

    <user-infos ref="userInfo" />
  </div>
</template>

<script>
import ToolbarButton from "./ToolbarButton";
import Auth from "@/plugins/auth";
import TestDailyRecap from "@/components/shared/TestDailyRecap.vue";
import UserInfos from "@/components/shared/UserInfos.vue";
import axios from "axios";
import DateMixin from "@/mixins/date";
import SessionsMixin from "@/mixins/sessions";
import BadgesMixin from "@/mixins/badges";
import GlobalMixin from "@/mixins/global";

export default {
  components: { ToolbarButton, TestDailyRecap, UserInfos },
  mixins: [DateMixin, SessionsMixin, BadgesMixin, GlobalMixin],
  data () {
    return {
      toolbar: true,
      loadingSearch: false,
      search: null,
      results: [],
      mailqueue: false,
      displaySidenav: false
    };
  },
  computed: {
    fullName () {
      return Auth.user.firstname + " " + Auth.user.lastname;
    }
  },
  watch: {
    search (val) {
      val &&
      (val.length > 2 || (val[0] === "#" && val.length > 1)) &&
      val !== this.query(val);
    }
  },
  beforeMount () {
    this.toolbar = !(localStorage.toolbar === "false");
  },
  mounted () {
    this.loadUser();

    if (this.isCoord || this.isAdmin) {
      this.getMailqueue();

      setInterval(
        function () {
          this.getMailqueue();
        }.bind(this),
        3600000
      ); //Refresh toutes les heures
    }
  },
  methods: {
    getMailqueue () {
      axios.get("/mailqueue").then(res => {
        this.mailqueue = res.data;
      });
    },
    toggleToolbar () {
      this.toolbar = !this.toolbar;
      localStorage.toolbar = this.toolbar;
    },
    logout () {
      Auth.logout();
      this.$router.push("/login");
    },
    onSelectRes (val) {
      if (val) {
        if (val.type == 1) {
          this.$router.push({
            name: "sessions-show-info",
            params: { id: val.id }
          });
          this.results = [];
          this.search = null;
        } else if (val.type == 2) {
          this.$router.push({
            name: "address-show-info",
            params: { id: val.id }
          });
        }
      }
      this.results = [];
      this.search = null;
    },
    query () {
      this.loadingSearch = true;
      const params = { keywords: this.search };
      axios.get("/search", { params: params }).then(res => {
        this.results = res.data;
        for (let i = 0; i < this.results.length; i++) {
          if (this.results[i].dates && this.results[i].dates.length > 0) {
            this.results[i].dates.sort((a, b) =>
              a.start > b.start ? 1 : b.start > a.start ? -1 : 0
            );
          }
        }
        this.loadingSearch = false;
      });
    }
  }
};
</script>

<style scoped>
.search-bar {
  width: 400px;
}

.search-bar .v-input {
  border: 1px solid #dddddd;
  border-radius: 4px;
}

.toolbar-container {
  position: relative;
}

.toolbar-toggle {
  cursor: pointer;
  position: absolute;
  background: white;
  width: 40px;
  height: 16px;
  line-height: 0;
  top: 211px;
  text-align: center;
  left: calc(50% - 20px);
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.toolbar-toggle.down {
  top: 97px;
}
.mobile-sidenav {
  height: 100%;
  width: 100%;
  z-index: 20;
  position: fixed;
  top: 50px;
  background: white;

  transition: all 0.25s;
  -webkit-transition: all 0.25s;
}

.logo {
  height: 50px;
}

@media screen and (min-width: 960px) {
  .logo {
    height: 80px;
  }
}

@media print {
  .toolbar-container {
    display: none;
  }
}
</style>
<style>
.user-badge > .v-badge__badge{
  width: 16px;
  height: 16px;
  bottom: -6px;
  right: -6px;
}
</style>