<template>
  <div>
    <div
      layout-gt-md="row"
      layout-wrap
      layout-align="start start"
      class="ml-4 mr-4 pr-4 pl-4"
    >
      <div
        layout="column"
        style="width: 100%"
      >
        <div class="title mb-3">
          Details of the event
        </div>
        <div
          layout="row"
          flex="80"
        >
          <div
            layout="column"
            flex
          >
            <v-subheader>
              Number of visitors
            </v-subheader>
            <div class="ml-5">
              {{ review.nb_visitors }}
            </div>
          </div>
        </div>
        <div layout="row">
          <div
            layout="column"
            flex="80"
          >
            <v-subheader>
              Comments
            </v-subheader>
            <div class="ml-5">
              <div style="white-space: pre-wrap;        word-wrap: break-word;font-family: inherit;">
                {{ review.comments }}
              </div>
            </div>
          </div>
        </div>
        <div layout="row">
          <div
            layout="column"
            flex="80"
          >
            <v-subheader>
              Summary
            </v-subheader>
            <div class="ml-5">
              <div style="white-space: pre-wrap;        word-wrap: break-word;font-family: inherit;">
                {{ review.summary }}
              </div>
            </div>
          </div>
        </div>
        <div layout="row">
          <div
            layout="column"
            flex="80"
          >
            <v-subheader>
              Special occurences
            </v-subheader>
            <div class="ml-5">
              <div style="white-space: pre-wrap;        word-wrap: break-word;font-family: inherit;">
                {{ review.occurences }}
              </div>
            </div>
          </div>
        </div>
        <div layout="row">
          <div
            layout="column"
            flex="80"
          >
            <v-subheader>
              Contact of special interest
            </v-subheader>
            <div class="ml-5">
              <div style="white-space: pre-wrap;        word-wrap: break-word;font-family: inherit;">
                {{ review.contact_interest }}
              </div>
            </div>
          </div>
        </div>
        <div layout="row">
          <div
            layout="column"
            flex="80"
          >
            <v-subheader>
              Website news
            </v-subheader>
            <div class="ml-5">
              <div style="white-space: pre-wrap;        word-wrap: break-word;font-family: inherit;">
                {{ review.website_news }}
              </div>
            </div>
          </div>
        </div>

        <div class="title mb-3 mt-5">
          Medias
        </div>

        <v-data-table
          :headers="headers_medias"
          :items="review.medias"
          hide-actions
          class="elevation-1 small-table-padding"
        >
          <template
            slot="headerCell"
            slot-scope="props"
          >
            {{ props.header.text }}<br v-if="props.header.desc">
            <span class="caption">{{ props.header.desc }}</span>
          </template>
          <template
            slot="items"
            slot-scope="props"
          >
            <td>{{ props.item.name }}</td>
            <td>
              {{ (props.item.date && props.item.date != '0000-00-00 00:00:00') ? formatDayHuman(props.item.date) : '-' }}
            </td>
            <td>{{ props.item.title }}</td>
            <td>{{ props.item.link }}</td>
            <td>
              {{ props.item.file ? props.item.file.name : '-' }}
            </td>
            <td>
              <v-btn
                v-if="props.item.file_id && props.item.file"
                flat
                icon
                @click="download(props.item)"
              >
                <v-icon color="info">
                  mdi-download
                </v-icon>
              </v-btn>
            </td>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
    import Sessions from '@/api/sessions'
    import DateMixin from '@/mixins/date'
    import axios from 'axios'

    export default {
        mixins: [DateMixin],
        props: ['session', 'isAdmin', 'isCoord'],
        data () {
            return {
                review: {
                    medias: []
                },
                headers_medias: [
                    { text: 'Name', align: 'left', sortable: false },
                    { text: 'Date', align: 'left', sortable: false},
                    { text: 'Title', align: 'left', sortable: false},
                    { text: 'Link', align: 'left', sortable: false },
                    { text: 'File', align: 'left', sortable: false},
                    { text: '', align: 'left', sortable: false, width: 40 },
                ],

                fileName: {},
                fileData: {},
                dates: [],
                existingFile: {},
                currentFilePick: null
            }
        },

        mounted() {
            this.fetch();
        },

        methods: {
            fetch () {
                Sessions.getReviews(this.session.id).then(res => {
                    if(res.data && res.data.length > 0){
                        this.review = res.data[0];
                        if(this.review.medias){
                            for(let i = 0; i < this.review.medias.length; i++){
                                if(this.review.medias[i].file){
                                    this.fileName[i] = this.review.medias[i].file.name;
                                }

                                /*let d = {};
                                d.date = this.review.medias[i].date;
                                d.date_day = moment(d.date).format('YYYY-MM-DD');
                                d.date_visible = moment(d.date).format('MM/DD/YYYY');
                                this.dates[i] = d;*/
                            }
                        }
                    }
                });
            },
            download (media) {
                axios.get('/assets/medias/' + media.id, {responseType: 'blob',}).then(res => {
                    let blob = new Blob([res.data], {type: "octet/stream"});

                    if (window.navigator && window.navigator.msSaveOrOpenBlob){
                        window.navigator.msSaveBlob(blob, media.file.name);
                    } else{
                        let a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";
                        let url = window.URL.createObjectURL(blob);
                        a.href = url;
                        a.download = media.file.name;
                        a.click();
                        window.URL.revokeObjectURL(url);
                    }
                });
            }
        }
    }
</script>
<style>
</style>
