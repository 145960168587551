<template>
  <div
    layout="row"
    layout-align="start center"
    :class="{'grey--text': disabled}"
  >
    <v-radio-group
      v-model="mark"
      :readonly="readonly"
      :disabled="disabled"
      hide-details
      class="mt-0 pt-0"
      row
    >
      <v-radio
        label="--"
        :value="1"
      />
      <v-radio
        label="-"
        :value="2"
      />
      <v-radio
        label="+/-"
        :value="3"
      />
      <v-radio
        label="+"
        :value="4"
      />
      <v-radio
        label="++"
        :value="5"
      />
    </v-radio-group>
    <span
      v-if="!readonly"
      class="clickable"
      style="text-decoration: underline; font-style: italic"
      @click="disabled ? () => {} : mark = 0"
    >
      No value
    </span>
  </div>
</template>
<script>
export default {
	props: {
		value: {
			default: null
		},
		readonly: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
		}
	},
	computed: {
		mark: {
				get () {
						return this.value;
				},
				set (x) {
						this.$emit('input', x);
						this.$emit('change', x);
				}
		},
	},
	mounted () {
	},
	methods: {

	}
}
</script>

<style>
</style>
