import Users from "@/api/users";

export default {
	data() {
		return {
			icsToken: null
		}
	},
	computed: {
		icsUrl() {
      if (this.icsToken) {
        return window.location.origin + "/published/ics/" + this.icsToken;
      }
      return null;
    }
	},
	methods: {
		fetchIcsToken() {
      Users.getIcsUri().then(res => {
        this.icsToken = res.data["ics-token"];
      });
    },
    copyURL() {
      let copyText = document.getElementById("ics-url");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");

      this.$snotify.success("The link has been copied");
    },
	},
}