<template>
  <div
    v-if="loading"
    class="ma-5 text-xs-center"
  >
    <v-progress-circular
      indeterminate
      :size="32"
      color="accent"
    />
  </div>
  <div
    v-else
    layout="column"
  >
    <div
      layout="row"
      class="subheading ml-2"
    >
      Sessions per concept (only for school trainings)
    </div>
    <v-data-table
      :headers="headers"
      :items="topics"
      style="max-width: 1000px"
      pagination.sync="pagination"
    >
      <template
        slot="items"
        slot-scope="{ item }"
      >
        <td>{{ item.topic }}</td>
        <td>{{ item.nb_sessions }}</td>
        <td>{{ item.hours }}</td>
        <td>{{ item.hours_per_session }}</td>
      </template>
    </v-data-table>

    <div
      layout="row"
      class="subheading ml-2"
    >
      Sessions per type
    </div>
    <v-data-table
      :headers="headers"
      :items="types"
      style="max-width: 1000px"
      hide-actions
    >
      <template
        slot="items"
        slot-scope="{ item }"
      >
        <td>{{ item.type }}</td>
        <td>{{ item.nb_sessions }}</td>
        <td>{{ item.hours }}</td>
        <td>{{ item.hours_per_session }}</td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Statistics from '@/api/statistics'

export default {
  props: ['start', 'end', 'toFilter', 'showDates'],
  data () {
    return {
      topics: [],
      types: [],
      pagination: {
        rowsPerPage: 10
      },
      headers: [
        { text: '', value: 'topic', sortable: false },
        { text: 'Sessions', value: 'nb_sessions', sortable: false },
        { text: 'Hours', value: 'hours', sortable: false },
        { text: 'h / session', value: 'hours_per_session', sortable: false }
      ],
      loading: false
    }
  },

  watch: {
    toFilter (val) {
      if (val) {
        this.fetch()
        this.$emit('update:toFilter', false)
      }
    }
  },

  mounted () {
    this.fetch()
    this.$emit('update:showDates', true)
  },

  methods: {
    fetch () {
      this.loading = true
      Statistics.getTopics({ start: this.start, end: this.end }).then(res => {
        this.topics = res.data
      })
      Statistics.getTypes({ start: this.start, end: this.end }).then(res => {
        this.types = res.data
        this.loading = false
      })
    },


  }
}
</script>
<style scoped>
.number-color {
  color: #f4b944;
}
</style>