<template>
  <div
    v-if="loading"
    class="ma-5 text-xs-center"
  >
    <v-progress-circular
      indeterminate
      :size="32"
      color="accent"
    />
  </div>
  <div
    v-else
    layout="column"
  >
    <div
      layout="row"
      class="subheading font-weight-light ml-2 mb-4"
    >
      Event review
    </div>
    <div
      layout="row"
      layout-align="center center"
      class="pl-5 pr-5 ml-5 mr-5"
    >
      <div
        layout="column"
        flex="100"
      >
        <div
          layout="row"
          layout-align="start center"
        >
          <v-menu
            ref="searchStart"
            :close-on-content-click="false"
            :return-value.sync="searchStart"
            transition="scale-transition"
            offset-y
            full-width
            lazy
          >
            <v-icon slot="activator">
              event
            </v-icon>
            <v-date-picker
              v-model="searchStart"
              first-day-of-week="1"
              @input="$refs.searchStart.save(searchStart); updateStart()"
            />
          </v-menu>
          <v-text-field
            v-model="searchStart"
            label="from"
            class="ml-2 mr-4"
            style="max-width: 200px"
            @change="updateStart"
          />

          <v-menu
            ref="searchEnd"
            :close-on-content-click="false"
            :return-value.sync="searchEnd"
            transition="scale-transition"
            offset-y
            full-width
            lazy
          >
            <v-icon slot="activator">
              event
            </v-icon>
            <v-date-picker
              v-model="searchEnd"
              first-day-of-week="1"
              @input="$refs.searchEnd.save(searchEnd); updateEnd()"
            />
          </v-menu>
          <v-text-field
            v-model="searchEnd"
            label="to"
            class="ml-2"
            style="max-width: 200px"
            @change="updateEnd"
          />
        </div>
        <v-select
          v-model="status"
          v-focus
          outline
          label="Session type export"
          :items="listStatus"
        />
        <div class="text-xs-right">
          <v-btn
            depressed
            color="primary"
            :disabled="loading"
            @click="exportCSV"
          >
            Export
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Statistics from '@/api/statistics'
import Organizations from '@/api/organizations'
import Consts from '@/consts'
import moment from 'moment'

export default {
  props: ['start', 'end', 'toFilter', 'showDates'],
  data () {
    return {
      loading: false,
      listStatus: [
        { value: null, text: "All" },
        { value: Consts.CANCELLED, text: "Cancelled" },
        { value: Consts.REQUEST, text: "Request" },
        { value: Consts.RELEASE, text: "Release" },
        { value: Consts.TOVALIDATE, text: "To validate" },
        { value: Consts.BLOCKED, text: "Blocked" },
        { value: Consts.TOEVALUATE, text: "To evaluate" },
        { value: Consts.CLOSED, text: "Closed" }
      ],
      status: null,
      searchStart: this.start,
      searchEnd: this.end
    }
  },

  watch: {
    toFilter (val) {
      if (val) {
        this.$emit('update:toFilter', false)
      }
    },
  },

  mounted () {
    this.$emit('update:showDates', false)
  },

  methods: {
    exportCSV () {
      this.loading = true
      Statistics.exportReviews({ start: this.start, end: this.end, status: this.status }).then(res => {
        this.loading = false
        let blob = new Blob([res.data], { type: "octet/stream" })

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, "Reviews-" + moment().format('YYYY-MM-DD-hh-mm-ss') + ".csv")
        } else {
          let a = document.createElement("a")
          document.body.appendChild(a)
          a.style = "display: none"
          let url = window.URL.createObjectURL(blob)
          a.href = url
          a.download = "Reviews-" + moment().format('YYYY-MM-DD-hh-mm-ss') + ".csv"
          a.click()
          window.URL.revokeObjectURL(url)
        }
      })
    },

    queryOrganizations (val) {
      this.loadingOrg = true

      Organizations.getList({ keywords: val, is_school: 1 }).then(res => {
        this.organizations = res.data
        this.loadingOrg = false
      })
    },

    updateStart () {
      this.$emit('update:start', this.searchStart)
    },
    updateEnd () {
      this.$emit('update:end', this.searchEnd)
    }
  }
}
</script>
<style scoped>
.number-color {
  color: #f4b944;
}
</style>