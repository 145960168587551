<template>
  <div class="ma-3">
    <div
      layout="column"
      layout-align="center center"
    >
      <div
        layout="column"
        class="field-width"
      >
        <div>
          <v-btn
            flat
            class="mt-3"
            :to="{name: 'admin-settings-users-show-skills-edit', params: {skill_id: 'new'}}"
          >
            <v-icon
              left
              color="primary"
            >
              mdi-plus-box
            </v-icon>
            Add a new skill
          </v-btn>
        </div>

        <v-data-table
          :headers="headers"
          :items="skills"
          style="max-width: 1000px"
          :pagination.sync="pagination"
        >
          <template
            slot="items"
            slot-scope="{ item }"
          >
            <td>
              <v-icon
                :color="item.is_enabled ? 'success' : 'error'"
                :title="item.is_enabled ? 'Enabled' : 'Disabled'"
              >
                mdi-circle-outline
              </v-icon>
            </td>
            <td>{{ item.topic.name }}</td>
            <td>{{ getSkillRoleLabel(item.role) }}</td>
            <td>
              <v-icon
                v-if="item.is_former"
                color="success"
              >
                mdi mdi-check
              </v-icon>
              <v-icon
                v-else
                color="error"
              >
                mdi mdi-close
              </v-icon>
            </td>
            <td layout="row">
              <v-btn
                title="Seasons"
                class="mx-0"
                icon
                :to="{name: 'admin-settings-users-show-skills-seasons', params: {skill_id: item.id}}"
                :disabled="!isFreelancesObj(item.topic) || (item.role == Consts.SKILL_ROLE.TEACHER)"
              >
                <v-icon>mdi-format-list-bulleted</v-icon>
              </v-btn>
              <v-btn
                class="mx-0"
                icon
                :to="{name: 'admin-settings-users-show-skills-edit', params: {skill_id: item.id}}"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                :title="item.is_enabled ? 'Disable': 'Enable'"
                class="mx-0"
                icon
                @click="toggle(item)"
              >
                <v-icon>{{ item.is_enabled ? 'mdi-lock': 'mdi-lock-open' }}</v-icon>
              </v-btn>
            </td>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
	import Params from '@/api/params'
	import TeamsMixin from '@/mixins/teams'
	import GlobalMixin from '@/mixins/global'
	import Consts from '@/consts'

	export default {
		mixins: [TeamsMixin, GlobalMixin],
		props: {
			user: {
				type: Object,
				required: true,
				default: () => {}
			}
		},
		data () {
				return {
						pagination: {
								rowsPerPage: 10
						},
						headers: [
								{ text: 'Status', value: 'is_enabled', sortable: false, width: 75 },
								{ text: 'Concept', value: 'topic.name' },
								{ text: 'Role', value: 'role' },
								{ text: 'Former', value: 'is_former' },
								{ text: 'Actions', sortable: false, width: "80px" }
						],
						skills: [],
						Consts
				}
		},
		mounted () {
				this.fetch();
		},
		methods: {
				fetch () {
						Params.getList(`users/${this.user.id}/skills`).then(res => {
								this.skills = res.data;
						})
				},

				toggle (item) {
						if (item.is_enabled) {
								Params.disable(`users/${this.user.id}/skills`, item.id).then(() => {
										this.fetch();
										this.$snotify.success(`Skill has been disabled`)
								})
						} else {
								Params.enable(`users/${this.user.id}/skills`, item.id).then(() => {
										this.fetch();
										this.$snotify.success(`Skill has been enabled`)
								})
						}
				}
		}
	}
</script>
