<template>
  <div class="ma-3">
    <div class="title mb-3">
      Planning <v-icon>mdi-chevron-right</v-icon> Period types <v-icon>mdi-chevron-right</v-icon> {{ isNew ? 'New' : period.name }}
    </div>

    <v-form
      ref="form"
      v-model="formValid"
      layout="column"
      layout-align="center center"
      @submit.prevent="save"
    >
      <div>
        <div class="title font-weight-light mb-3">
          Type information
        </div>
        <v-text-field
          v-model="period.name"
          outline
          label="Name"
          class="field-width"
          :rules="[ v => !!v || 'This field is required' ]"
        />
        <v-checkbox
          v-model="period.is_default"
          label="Set as default period type"
        />

        <div class="v-label grey--text mb-2">
          Period color
        </div>
        <color-picker v-model="period.color" />
      </div>

      <v-btn
        depressed
        color="primary"
        @click="save"
      >
        Save
      </v-btn>
      <router-link
        :to="{name: 'admin-planning-periodtypes-index'}"
        class="grey--text"
      >
        Cancel
      </router-link>
    </v-form>
  </div>
</template>

<script>
    import Params from '@/api/params'

    export default {
        data () {
            return {
                period: {
                    name: null,
                    is_default: 0,
                    color: '#FFFFFF'
                },
                formValid: false
            }
        },
        computed: {
            isNew () {
                return this.$route.params.id === 'new';
            }
        },
        mounted () {
            if (!this.isNew) {
                this.fetch();
            }
        },
        methods: {
            fetch () {
                Params.get('periods-types', this.$route.params.id).then(res => {
                    this.period = res.data;
                })
            },
            save () {
                if (!this.$refs.form.validate()) {
                    return;
                }

                if (this.period.color.hex) {
                    this.period.color = this.period.color.hex
                }

                if (this.isNew) {
                    Params.create('periods-types', this.period).then(() => {
                        this.$router.push({name: 'admin-planning-periodtypes-index'});
                        this.$snotify.success(`The period type "${this.period.name}" has been created`)
                    })
                } else {
                    Params.update('periods-types', this.period.id, this.period).then(() => {
                        this.$router.push({name: 'admin-planning-periodtypes-index'});
                        this.$snotify.success(`The period type "${this.period.name}" has been updated`)
                    })
                }
            }
        }
    }
</script>