<template>
  <div
    layout="row"
    layout-align="start start"
    class="py-2 px-4"
  >
    <iframe
      :src="
        'https://maps.google.com/maps?q=' +
          queryAddress +
          '&t=&z=13&ie=UTF8&iwloc=&output=embed'
      "
      width="100%"
      height="600"
      frameborder="0"
      style="border:0;"
      allowfullscreen=""
    />
    <div class="px-4">
      <h2>Address</h2>

      <div v-if="session.location !== ''">
        {{ session.location }}
      </div>
      <template v-else-if="session.organization">
        <div>
          {{ session.organization.number }} {{ session.organization.street }}
        </div>
        <div>
          {{ session.organization.zip_code }} {{ session.organization.city }}
        </div>
      </template>
      <div>
        <v-btn
          color="primary"
          class="ml-0 pr-4"
          :href="'https://www.google.com/maps/dir//' + queryAddress"
          target="_blank"
        >
          <v-icon
            class="mr-2"
            white
          >
            mdi-map-marker
          </v-icon>How to go there
          ?
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import DateMixin from "@/mixins/date";
import SessionsMixin from "@/mixins/sessions";

export default {
  mixins: [DateMixin, SessionsMixin],
  props: ["session", "isCoord", "isAdmin"],
  data() {
    return {};
  },

  computed: {
    queryAddress() {
      let address = "";
      if (this.session.location !== "") {
        address = this.session.location;
      } else if (this.session.organization) {
        address =
          this.session.organization.number +
          "+" +
          this.session.organization.street +
          "+" +
          this.session.organization.zip_code +
          "+" +
          this.session.organization.city;
      }
      return address.replace(/,/g, "").replace(/\s/g, "+");
    }
  },

  watch: {},

  mounted() {},

  methods: {}
};
</script>
