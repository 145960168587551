<template>
  <div
    v-if="loading"
    class="ma-5 text-xs-center"
  >
    <v-progress-circular
      indeterminate
      :size="32"
      color="accent"
    />
  </div>
  <div
    v-else
    layout="column"
  >
    <div layout="row">
      <div
        layout="column"
        flex="50"
        class="tuile font-weight-black grey--text pl-2 pt-3"
      >
        Entities
      </div>
      <div
        layout="column"
        flex="50"
        class="tuile font-weight-black grey--text ml-2 pl-4 pt-3"
      >
        School training sessions
      </div>
    </div>
    <div layout="row">
      <div
        layout="column"
        flex="25"
        class="tuile pt-2 pl-4"
      >
        <div
          layout="row"
          class="subheading"
        >
          Schools
        </div>
        <div
          layout="row"
          class="display-2 number-color font-weight-bold mb-2"
        >
          {{ entities.nb_schools }}
        </div>
        <div
          v-for="t in entities.nb_schools_type"
          :key="'st_'+t.name"
          layout="row"
        >
          <div
            layout="column"
            class="font-weight-bold text-xs-right"
            flex="10"
          >
            {{ t.nb }}
          </div>
          <div
            layout="column"
            class="ml-2"
            :class="{'disabled-type': !t.is_enabled}"
            flex
          >
            {{ t.name }}
          </div>
        </div>
      </div>
      <div
        layout="column"
        flex="25"
        class="tuile pt-2 pl-2"
      >
        <div
          layout="row"
          class="subheading"
        >
          Other organizations
        </div>
        <div
          layout="row"
          class="display-2 number-color font-weight-bold"
        >
          {{ entities.nb_other_orga }}
        </div>
        <div
          layout="row"
          class="subheading mt-2"
        >
          Contacts
        </div>
        <div
          layout="row"
          class="display-2 number-color font-weight-bold"
        >
          {{ entities.nb_contacts }}
        </div>
        <div
          layout="row"
          class="subheading mt-2"
        >
          Average (contacts / entity)
        </div>
        <div
          layout="row"
          class="display-2 number-color font-weight-bold"
        >
          {{ entities.contacts_per_school ? entities.contacts_per_school.toFixed(2) : 0 }}
        </div>
      </div>
      <div
        layout="column"
        flex="25"
        class="tuile pt-2 pl-5 ml-2"
      >
        <div
          layout="row"
          class="subheading"
        >
          Sessions
        </div>
        <div
          layout="row"
          class="display-2 number-color font-weight-bold mb-2"
        >
          {{ sessions.school_trainings.total }}
        </div>
        <div
          v-for="(t,k) in sessions.school_trainings.school_types"
          :key="'sst_'+k"
          layout="row"
        >
          <div
            layout="column"
            class="font-weight-bold text-xs-right"
            flex="10"
          >
            {{ t.total }}
          </div>
          <div
            layout="column"
            class="ml-2"
            :class="{'disabled-type': !t.is_enabled}"
            flex
          >
            {{ t.name }}
          </div>
        </div>
        <div
          layout="row"
          class="subheading mt-4"
        >
          % of cancelled sessions
        </div>
        <div
          layout="row"
          class="display-2 number-color font-weight-bold mb-2"
        >
          {{ sessions.school_trainings.percent_canceled ? sessions.school_trainings.percent_canceled.toFixed(2) : 0 }}&nbsp;%
        </div>
      </div>
      <div
        layout="column"
        flex="25"
        class="tuile pt-2 pl-2"
      >
        <div
          layout="row"
          class="subheading"
        >
          Average (sessions / school)
        </div>
        <div
          layout="row"
          class="display-2 number-color font-weight-bold mb-2"
        >
          {{ sessions.school_trainings.per_school }}
        </div>
        <div
          v-for="(t,k) in sessions.school_trainings.school_types"
          :key="'sst2_'+k"
          layout="row"
        >
          <div
            layout="column"
            class="font-weight-bold text-xs-right"
            flex="10"
          >
            {{ t.per_school }}
          </div>
          <div
            layout="column"
            class="ml-2"
            :class="{'disabled-type': !t.is_enabled}"
            flex
          >
            {{ t.name }}
          </div>
        </div>
        <div
          layout="row"
          class="subheading mt-4"
        >
          Participants
        </div>
        <div
          layout="row"
          class="display-2 number-color font-weight-bold mb-2"
        >
          {{ sessions.school_trainings.attendees }}
        </div>
      </div>
    </div>

    <div
      layout="row"
      class="mt-2"
    >
      <div
        layout="column"
        flex="70"
        class="font-weight-black grey--text pt-3 pl-2 tuile"
      >
        Other types of trainings
        <div
          layout="row"
          class="full-width"
        >
          <div
            layout="column"
            flex
            class="tuile pt-2 pl-4 clickable"
            @click="openDetail(Consts.ADULTS)"
          >
            <div
              layout="row"
              class="subheading"
            >
              Adults
            </div>
            <div
              layout="row"
              class="display-2 number-color font-weight-bold mb-2"
            >
              {{ sessions.adults_trainings.total }}
            </div>
            <div
              layout="row"
              class="font-weight-light mb-2 pl-1 grey--text"
            >
              sessions
            </div>
            <div
              layout="row"
              class="display-2 number-color font-weight-bold mt-4"
            >
              {{ sessions.adults_trainings.attendees }}
            </div>
            <div
              layout="row"
              class="font-weight-light mb-2 pl-1 grey--text"
            >
              participants
            </div>
          </div>
          <div
            layout="column"
            flex
            class="tuile pt-2 clickable"
            @click="openDetail(Consts.TEACHERS)"
          >
            <div
              layout="row"
              class="subheading"
            >
              Teacher’s
            </div>
            <div
              layout="row"
              class="display-2 number-color font-weight-bold mb-2"
            >
              {{ sessions.teacher_trainings.total }}
            </div>
            <div
              layout="row"
              class="font-weight-light mb-2 pl-1 grey--text"
            >
              sessions
            </div>
            <div
              layout="row"
              class="display-2 number-color font-weight-bold mt-4"
            >
              {{ sessions.teacher_trainings.attendees }}
            </div>
            <div
              layout="row"
              class="font-weight-light mb-2 pl-1 grey--text"
            >
              participants
            </div>
          </div>
          <div
            layout="column"
            flex
            class="tuile pt-2 clickable"
            @click="openDetail(Consts.YOUTH)"
          >
            <div
              layout="row"
              class="subheading"
            >
              Youth
            </div>
            <div
              layout="row"
              class="display-2 number-color font-weight-bold mb-2"
            >
              {{ sessions.youth_trainings.total }}
            </div>
            <div
              layout="row"
              class="font-weight-light mb-2 pl-1 grey--text"
            >
              sessions
            </div>
            <div
              layout="row"
              class="display-2 number-color font-weight-bold mt-4"
            >
              {{ sessions.youth_trainings.attendees }}
            </div>
            <div
              layout="row"
              class="font-weight-light mb-2 pl-1 grey--text"
            >
              participants
            </div>
          </div>
          <div
            layout="column"
            flex
            class="tuile pt-2 clickable"
            @click="openDetail(Consts.INTERNAL)"
          >
            <div
              layout="row"
              class="subheading"
            >
              Internal meetings
            </div>
            <div
              layout="row"
              class="display-2 number-color font-weight-bold mb-2"
            >
              {{ sessions.internal_meetings.total }}
            </div>
            <div
              layout="row"
              class="font-weight-light mb-2 pl-1 grey--text"
            >
              sessions
            </div>
            <div
              layout="row"
              class="display-2 number-color font-weight-bold mt-4"
            >
              {{ sessions.internal_meetings.attendees }}
            </div>
            <div
              layout="row"
              class="font-weight-light mb-2 pl-1 grey--text"
            >
              participants
            </div>
          </div>
          <div
            layout="column"
            flex
            class="tuile pt-2 clickable"
            @click="openDetail(Consts.OTHER)"
          >
            <div
              layout="row"
              class="subheading"
            >
              Others
            </div>
            <div
              layout="row"
              class="display-2 number-color font-weight-bold mb-2"
            >
              {{ sessions.others.total }}
            </div>
            <div
              layout="row"
              class="font-weight-light mb-2 pl-1 grey--text"
            >
              sessions
            </div>
            <div
              layout="row"
              class="display-2 number-color font-weight-bold mt-4"
            >
              {{ sessions.others.attendees }}
            </div>
            <div
              layout="row"
              class="font-weight-light mb-2 pl-1 grey--text"
            >
              participants
            </div>
          </div>
        </div>
      </div>
      <div
        layout="column"
        flex="30"
        class="font-weight-black grey--text tuile ml-2 pl-4 pt-3"
      >
        Events
        <div
          layout="row"
          class="full-width"
        >
          <div
            layout="column"
            flex
            class="pt-2 pl-4 tuile"
          >
            <div layout="row">
              <div
                layout="column"
                flex="50"
              >
                <div
                  layout="row"
                  class="subheading"
                >
                  Events
                </div>
                <div
                  layout="row"
                  class="display-2 number-color font-weight-bold"
                >
                  {{ sessions.events.total }}
                </div>
              </div>
              <div
                layout="column"
                flex="50"
              >
                <div
                  layout="row"
                  class="subheading"
                >
                  Visitors / session
                </div>
                <div
                  layout="row"
                  class="display-2 number-color font-weight-bold"
                >
                  <span v-if="sessions.events.total">
                    {{ Math.round(sessions.events.attendees) }}
                  </span>
                  <span v-else>
                    -
                  </span>
                </div>
              </div>
            </div>
            <div
              layout="row"
              class="subheading mt-4"
            >
              Time spent
            </div>
            <div
              layout="row"
              class="title number-color mt-2"
            >
              {{ sessions.events.nice_time }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      layout="row"
      class="mt-2 mb-5 pb-5"
    >
      <session-map
        class="ml-2"
        height="500px"
        :from="start"
        :to="end"
      />
    </div>
    <v-dialog
      v-if="dialog.type"
      v-model="sessionTypeDialog"
      max-width="750px"
      @keydown.esc="sessionTypeDialog = false"
    >
      <v-card>
        <div layout="column">
          <div layout="row">
            <div
              layout="column"
              flex
              class="tuile font-weight-black grey--text ml-2 pl-4 pt-3"
            >
              {{ dialog.title }}
            </div>
            <v-btn
              icon
              @click="sessionTypeDialog = false"
            >
              <v-icon
                color="red"
              >
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <div layout="row">
            <div
              layout="column"
              flex="50"
              class="tuile pt-2 pl-5 ml-2"
            >
              <div
                layout="row"
                class="subheading"
              >
                Sessions
              </div>
              <div
                layout="row"
                class="display-2 number-color font-weight-bold mb-2"
              >
                {{ sessions[dialog.type].total }}
              </div>
              <div
                v-for="(t,k) in sessions[dialog.type].school_types"
                :key="'sst_'+k"
                layout="row"
              >
                <div
                  layout="column"
                  class="font-weight-bold text-xs-right"
                  flex="10"
                >
                  {{ t.total }}
                </div>
                <div
                  layout="column"
                  class="ml-2"
                  :class="{'disabled-type': !t.is_enabled}"
                  flex
                >
                  {{ t.name }}
                </div>
              </div>
              <div
                layout="row"
                class="subheading mt-4"
              >
                % of cancelled sessions
              </div>
              <div
                layout="row"
                class="display-2 number-color font-weight-bold mb-2"
              >
                {{ sessions[dialog.type].percent_canceled ? sessions[dialog.type].percent_canceled.toFixed(2) : 0 }}&nbsp;%
              </div>
            </div>
            <div
              layout="column"
              flex
              class="tuile pt-2 pl-2"
            >
              <div
                layout="row"
                class="subheading"
              >
                Average (sessions / school)
              </div>
              <div
                layout="row"
                class="display-2 number-color font-weight-bold mb-2"
              >
                {{ sessions[dialog.type].per_school }}
              </div>
              <div
                v-for="(t,k) in sessions[dialog.type].school_types"
                :key="'sst2_'+k"
                layout="row"
              >
                <div
                  layout="column"
                  class="font-weight-bold text-xs-right"
                  flex="10"
                >
                  {{ t.per_school }}
                </div>
                <div
                  layout="column"
                  class="ml-2"
                  :class="{'disabled-type': !t.is_enabled}"
                  flex
                >
                  {{ t.name }}
                </div>
              </div>
              <div
                layout="row"
                class="subheading mt-4"
              >
                Participants
              </div>
              <div
                layout="row"
                class="display-2 number-color font-weight-bold mb-2"
              >
                {{ sessions[dialog.type].attendees }}
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Statistics from '@/api/statistics'
import SessionMap from "@/components/shared/Map.vue"
import SessionsMixin from "@/mixins/sessions"
import Consts from '@/consts'

export default {
  components: { SessionMap },
  mixins: [SessionsMixin],
  props: ['start', 'end', 'toFilter', 'showDates'],
  data () {
    return {
      entities: {},
      sessions: {
        events: {},
        school_trainings: {},
        adults_trainings: {},
        teacher_trainings: {},
        youth_trainings: {},
        others: {},
        internal_meetings: {}
      },
      sessionTypeDialog: false,
      dialog: {
        title: '',
        type: 0
      },
      loading: false,
      Consts
    }
  },
  watch: {
    toFilter (val) {
      if (val) {
        this.fetch()
        this.$emit('update:toFilter', false)
      }
    }
  },
  mounted () {
    this.fetch()
    this.$emit('update:showDates', true)
  },
  methods: {
    openDetail(t){
      this.dialog.title = this.sessionTypeStrLong(t)

      switch(t){
          case Consts.EVENTS:
            this.dialog.type = 'events'
            break;
          case Consts.ADULTS:
            this.dialog.type = 'adults_trainings'
            break;
          case Consts.SCHOOL:
            this.dialog.type = 'school_trainings'
            break;
          case Consts.TEACHERS:
            this.dialog.type = 'teacher_trainings'
            break;
          case Consts.YOUTH:
            this.dialog.type = 'youth_trainings'
            break;
          case Consts.OTHER:
            this.dialog.type = 'others'
            break;
          case Consts.INTERNAL:
            this.dialog.type = 'internal_meetings'
            break;
      }

      this.sessionTypeDialog = true
    },
    fetch () {
      this.loading = true
      Statistics.getEntities({ start: this.start, end: this.end }).then(res => {
        this.entities = res.data
      })
      Statistics.getSessionsEvents({ start: this.start, end: this.end }).then(res => {
        this.sessions.events = res.data.sessions
      })
      Statistics.getSessionsSchoolTrainings({ start: this.start, end: this.end }).then(res => {
        this.loading = false
        this.sessions.school_trainings = Object.assign({}, res.data.sessions)
      })
      Statistics.getSessionsTeacherTrainings({ start: this.start, end: this.end }).then(res => {
        this.sessions.teacher_trainings = res.data.sessions
      })
      Statistics.getSessionsYouthTrainings({ start: this.start, end: this.end }).then(res => {
        this.sessions.youth_trainings = res.data.sessions
      })
      Statistics.getSessionsAdultsTrainings({ start: this.start, end: this.end }).then(res => {
        this.sessions.adults_trainings = res.data.sessions
      })
      Statistics.getSessionsOthers({ start: this.start, end: this.end }).then(res => {
        this.sessions.others = res.data.sessions
      })
      Statistics.getSessionsInternalMeetings({ start: this.start, end: this.end }).then(res => {
        this.sessions.internal_meetings = res.data.sessions
      })
    }
  }
}
</script>
<style scoped>
.number-color {
  color: #f4b944;
}
.tuile {
  background-color: white;
}
.disabled-type{
  text-decoration: line-through;
}
</style>