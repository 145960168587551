import axios from 'axios'
import './common'

export default {
    login (username, password) {
        return axios.post(`/users/signin`, {
            'login': username,
            'password': password
        })
    },
    logout () {
        return axios.delete(`/users/signout`)
    },
    info () {
        return axios.get(`/users/info`)
    },
    requestLostReset (email) {
        return axios.post(`/losts-passwords`, {'email': email})
    },
    verifyLostCode (id, code) {
        return axios.patch(`/losts-passwords/${id}/verify-code`, {'code': code})
    },
    resetPassword (id, password) {
        return axios.patch(`/losts-passwords/${id}/reset-password`, {'password': password})
    }
}