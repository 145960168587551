<template>
  <v-menu
    v-if="(isFreelancesObj(item) && item.information) || (!isFreelancesObj(item) && item.comments)"
    open-on-hover
    :close-on-content-click="false"
    offset-y
    bottom
    lazy
  >
    <div
      slot="activator"
      layout="column"
      flex
    >
      <span flex />
      <v-icon
        color="primary"
        :small="small"
      >
        mdi-comment-text
      </v-icon>
    </div>
    <v-card style="width: 600px">
      <v-card-title
        layout="column"
        layout-align="start start"
        class="pb-0"
      >
        <div
          layout="row"
          layout-align="start center"
          class="ml-1 mr-1"
          style="width: 100%"
        >
          <v-avatar
            :title="sessionStatusStrLong(item.status)"
            :color="sessionStatusColor(item.status)"
            :size="32"
            class="black--text font-weight-medium"
          >
            {{ sessionStatusStr(item.status) }}
          </v-avatar>
          <div class="title ml-2">
            <b>#{{ item.reference }} /</b>
            <span
              style="font-weight: 400 !important;"
              class="ml-2"
            >{{
              item.organization ? item.organization.name : ""
            }}</span>
          </div>
        </div>
        <div
          layout="row"
          layout-align="start center"
        >
          <v-chip
            :color="sessionTypeColor(item.session_type)"
            class="lighten-3"
            small
          >
            {{ sessionTypeStr(item.session_type) }}
          </v-chip>
          <v-rating
            v-if="item.organization"
            background-color="#AAAAAA"
            dense
            small
            class="mr-1"
            readonly
            :value="parseInt(item.organization.mark)"
          />
          <v-rating
            v-else
            background-color="#AAAAAA"
            dense
            small
            class="mr-1"
            readonly
            :value="0"
          />
        </div>
      </v-card-title>
      <v-card-text>
        <v-divider />
        <template v-if="isFreelancesObj(item)">
          <div class="subheading font-weight-medium mt-3 mb-2">
            Instructions for specialized animators
          </div>
          <div>
            {{ item.information }}
          </div>
        </template>
        <template v-else>
          <div class="subheading font-weight-medium mt-3 mb-2">
            Session's comment:
          </div>
          <div>
            {{ item.comments }}
          </div>
        </template>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
import SessionsMixin from "@/mixins/sessions";
import GlobalMixin from "@/mixins/global";
export default {
  mixins: [SessionsMixin, GlobalMixin],
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {}
    },
    small: {
      type: Boolean,
      default: false
    }
  }
};
</script>
