<template>
  <div
    v-if="loading"
    class="ma-5 text-xs-center"
  >
    <v-progress-circular
      indeterminate
      :size="32"
      color="accent"
    />
  </div>
  <div
    v-else
    layout="column"
  >
    <div
      layout="row"
      class="subheading font-weight-light ml-2 mb-2"
    >
      Trainer evaluations {{ trainersEvals.nb_evals ? trainersEvals.nb_evals : 0 }} / {{ trainersEvals.nb_evals_expected }} answers
    </div>
    <div
      v-if="trainersEvals && trainersEvals.evals_stats && trainersEvals.evals_stats != {}"
      layout-gt-md="row"
      layout-align="start stretch"
      layout-wrap
    >
      <div
        v-if="trainersEvals.evals_stats.discipline"
        layout="column"
        flex="50"
        class="mb-4"
      >
        <div
          layout="row"
          class="subheading"
        >
          1. Discipline in class (answers: {{ trainersEvals.evals_stats.discipline.nb }}, average: {{ trainersEvals.evals_stats.discipline.average.toFixed(2) }})
        </div>
        <div
          v-for="i in (0,5)"
          :key="'mark'+i"
          layout="row"
        >
          <div
            layout="column"
            flex="5"
            class="mt-2"
          >
            {{ i }} :
          </div>
          <div
            layout="column"
            flex="70"
          >
            <v-progress-linear
              v-model="trainersEvals.evals_stats.discipline['percent_' + i]"
              height="14"
              color="primary"
            />
          </div>
          <div
            layout="column"
            flex
            class="mt-2 ml-2"
          >
            {{ trainersEvals.evals_stats.discipline[i] }} answer{{ trainersEvals.evals_stats.discipline[i] > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
      <div
        v-if="trainersEvals.evals_stats.interactivity"
        layout="column"
        flex="50"
        class="mb-4"
      >
        <div
          layout="row"
          class="subheading"
        >
          2. Interactivity (answers: {{ trainersEvals.evals_stats.interactivity.nb }}, average: {{ trainersEvals.evals_stats.interactivity.average.toFixed(2) }})
        </div>
        <div
          v-for="i in (0,5)"
          :key="'mark2_'+i"
          layout="row"
        >
          <div
            layout="column"
            flex="5"
            class="mt-2"
          >
            {{ i }} :
          </div>
          <div
            layout="column"
            flex="70"
          >
            <v-progress-linear
              v-model="trainersEvals.evals_stats.interactivity['percent_' + i]"
              height="14"
              color="primary"
            />
          </div>
          <div
            layout="column"
            flex
            class="mt-2 ml-2"
          >
            {{ trainersEvals.evals_stats.interactivity[i] }} answer{{ trainersEvals.evals_stats.interactivity[i] > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
      <div
        v-if="trainersEvals.evals_stats.knowledge"
        layout="column"
        flex="50"
        class="mb-4"
      >
        <div
          layout="row"
          class="subheading"
        >
          3. Knowledge of the Internet (answers: {{ trainersEvals.evals_stats.knowledge.nb }}, average: {{ trainersEvals.evals_stats.knowledge.average.toFixed(2) }})
        </div>
        <div
          v-for="i in (0,5)"
          :key="'mark3_'+i"
          layout="row"
        >
          <div
            layout="column"
            flex="5"
            class="mt-2"
          >
            {{ i }} :
          </div>
          <div
            layout="column"
            flex="70"
          >
            <v-progress-linear
              v-model="trainersEvals.evals_stats.knowledge['percent_' + i]"
              height="14"
              color="primary"
            />
          </div>
          <div
            layout="column"
            flex
            class="mt-2 ml-2"
          >
            {{ trainersEvals.evals_stats.knowledge[i] }} answer{{ trainersEvals.evals_stats.knowledge[i] > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
      <div
        v-if="trainersEvals.evals_stats.experience"
        layout="column"
        flex="50"
        class="mb-4 grey--text"
      >
        <div
          layout="row"
          class="subheading"
        >
          4. Experiences of the participants (answers: {{ trainersEvals.evals_stats.experience.nb }}, average: {{ trainersEvals.evals_stats.experience.average.toFixed(2) }})
        </div>
        <div
          v-for="i in (0,5)"
          :key="'mark4_'+i"
          layout="row"
        >
          <div
            layout="column"
            flex="5"
            class="mt-2"
          >
            {{ i }} :
          </div>
          <div
            layout="column"
            flex="70"
          >
            <v-progress-linear
              v-model="trainersEvals.evals_stats.experience['percent_' + i]"
              height="14"
              color="primary"
            />
          </div>
          <div
            layout="column"
            flex
            class="mt-2 ml-2"
          >
            {{ trainersEvals.evals_stats.experience[i] }} answer{{ trainersEvals.evals_stats.experience[i] > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
      <div
        v-if="trainersEvals.evals_stats.organization"
        layout="column"
        flex="50"
        class="mb-4"
      >
        <div
          layout="row"
          class="subheading"
        >
          4. Organisation on behalf the school (answers: {{ trainersEvals.evals_stats.organization.nb }}, average: {{ trainersEvals.evals_stats.organization.average.toFixed(2) }})
        </div>
        <div
          v-for="i in (0,5)"
          :key="'mark5_'+i"
          layout="row"
        >
          <div
            layout="column"
            flex="5"
            class="mt-2"
          >
            {{ i }} :
          </div>
          <div
            layout="column"
            flex="70"
          >
            <v-progress-linear
              v-model="trainersEvals.evals_stats.organization['percent_' + i]"
              height="14"
              color="primary"
            />
          </div>
          <div
            layout="column"
            flex
            class="mt-2 ml-2"
          >
            {{ trainersEvals.evals_stats.organization[i] }} answer{{ trainersEvals.evals_stats.organization[i] > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
      <div
        v-if="trainersEvals.evals_stats.involvement"
        layout="column"
        flex="50"
        class="mb-4"
      >
        <div
          layout="row"
          class="subheading"
        >
          5. Involvement of the teachers (answers: {{ trainersEvals.evals_stats.involvement.nb }}, average: {{ trainersEvals.evals_stats.involvement.average.toFixed(2) }})
        </div>
        <div
          v-for="i in (0,5)"
          :key="'mark6_'+i"
          layout="row"
        >
          <div
            layout="column"
            flex="5"
            class="mt-2"
          >
            {{ i }} :
          </div>
          <div
            layout="column"
            flex="70"
          >
            <v-progress-linear
              v-model="trainersEvals.evals_stats.involvement['percent_' + i]"
              height="14"
              color="primary"
            />
          </div>
          <div
            layout="column"
            flex
            class="mt-2 ml-2"
          >
            {{ trainersEvals.evals_stats.involvement[i] }} answer{{ trainersEvals.evals_stats.involvement[i] > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
      <div
        v-if="trainersEvals.evals_stats.climate"
        layout="column"
        flex="50"
        class="mb-4"
      >
        <div
          layout="row"
          class="subheading"
        >
          6. Climate in the class (answers: {{ trainersEvals.evals_stats.climate.nb }}, average: {{ trainersEvals.evals_stats.climate.average.toFixed(2) }})
        </div>
        <div
          v-for="i in (0,5)"
          :key="'mark7a_'+i"
          layout="row"
        >
          <div
            layout="column"
            flex="5"
            class="mt-2"
          >
            {{ i }} :
          </div>
          <div
            layout="column"
            flex="70"
          >
            <v-progress-linear
              v-model="trainersEvals.evals_stats.climate['percent_' + i]"
              height="14"
              color="primary"
            />
          </div>
          <div
            layout="column"
            flex
            class="mt-2 ml-2"
          >
            {{ trainersEvals.evals_stats.climate[i] }} answer{{ trainersEvals.evals_stats.climate[i] > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
      <div
        v-if="trainersEvals.evals_stats.pupils_exp"
        layout="column"
        flex="100"
        class="mb-4"
      >
        <div layout="row">
          <v-expansion-panel>
            <v-expansion-panel-content>
              <div slot="header">
                I noticed the following in this class (positive and/or negative) (answers: {{ trainersEvals.evals_stats.pupils_exp.nb }})
              </div>
              <v-card>
                <v-card-text>
                  <div
                    v-for="(comm,id) in trainersEvals.evals_stats.pupils_exp.content"
                    :key="id"
                    layout="row"
                  >
                    <div
                      layout="column"
                      flex="20"
                    >
                      Session #{{ id }}
                    </div>
                    <div
                      layout="column"
                      flex
                    >
                      {{ comm }}
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </div>
      </div>
      <div
        v-if="trainersEvals.evals_stats.orga_aspects"
        layout="column"
        flex="100"
        class="mb-4"
      >
        <div layout="row">
          <v-expansion-panel>
            <v-expansion-panel-content>
              <div
                slot="header"
                class="grey--text"
              >
                Organisational aspects (answers: {{ trainersEvals.evals_stats.orga_aspects.nb }})
              </div>
              <v-card>
                <v-card-text>
                  <div
                    v-for="(comm,id) in trainersEvals.evals_stats.orga_aspects.content"
                    :key="id"
                    layout="row"
                  >
                    <div
                      layout="column"
                      flex="20"
                    >
                      Session #{{ id }}
                    </div>
                    <div
                      layout="column"
                      flex
                    >
                      {{ comm }}
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </div>
      </div>
      <div
        v-if="trainersEvals.evals_stats.other"
        layout="column"
        flex="100"
        class="mb-4"
      >
        <div layout="row">
          <v-expansion-panel>
            <v-expansion-panel-content>
              <div
                slot="header"
                class="grey--text"
              >
                Other aspects (answers: {{ trainersEvals.evals_stats.other.nb }})
              </div>
              <v-card>
                <v-card-text>
                  <div
                    v-for="(comm,id) in trainersEvals.evals_stats.other.content"
                    :key="id"
                    layout="row"
                  >
                    <div
                      layout="column"
                      flex="20"
                    >
                      Session #{{ id }}
                    </div>
                    <div
                      layout="column"
                      flex
                    >
                      {{ comm }}
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </div>
      </div>
    </div>

    <div
      layout="row"
      class="subheading font-weight-light ml-2 mb-2"
    >
      Teachers evaluations {{ teachersEvals.nb_evals ? teachersEvals.nb_evals : 0 }} / {{ teachersEvals.nb_evals_expected }} answers
    </div>
    <div
      v-if="teachersEvals && teachersEvals.evals_stats && teachersEvals.evals_stats != {}"
      layout-gt-md="row"
      layout-align="start stretch"
      layout-wrap
    >
      <div
        v-if="teachersEvals.evals_stats.nb_pupils"
        layout="column"
        flex="100"
        class="mb-4"
      >
        <div layout="row">
          <v-expansion-panel>
            <v-expansion-panel-content>
              <div slot="header">
                Number of pupils (answers: {{ teachersEvals.evals_stats.nb_pupils.nb }})
              </div>
              <v-card>
                <v-card-text>
                  <div
                    v-for="(comm,id) in teachersEvals.evals_stats.nb_pupils.content"
                    :key="id"
                    layout="row"
                  >
                    <div
                      layout="column"
                      flex="20"
                    >
                      Session #{{ id }}
                    </div>
                    <div
                      layout="column"
                      flex
                    >
                      {{ comm }}
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </div>
      </div>
      <div
        v-if="teachersEvals.evals_stats.form_structure"
        layout="column"
        flex="50"
        class="mb-4"
      >
        <div
          layout="row"
          class="subheading"
        >
          1. Training takes place in a clear structure (answers: {{ teachersEvals.evals_stats.form_structure.nb }}, average: {{ teachersEvals.evals_stats.form_structure.average.toFixed(2) }})
        </div>
        <div
          v-for="i in (0,5)"
          :key="'mark7_'+i"
          layout="row"
        >
          <div
            layout="column"
            flex="5"
            class="mt-2"
          >
            {{ i }} :
          </div>
          <div
            layout="column"
            flex="70"
          >
            <v-progress-linear
              v-model="teachersEvals.evals_stats.form_structure['percent_' + i]"
              height="14"
              color="primary"
            />
          </div>
          <div
            layout="column"
            flex
            class="mt-2 ml-2"
          >
            {{ teachersEvals.evals_stats.form_structure[i] }} answer{{ teachersEvals.evals_stats.form_structure[i] > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
      <div
        v-if="teachersEvals.evals_stats.preparation"
        layout="column"
        flex="50"
        class="mb-4"
      >
        <div
          layout="row"
          class="subheading"
        >
          2. Training is adequately prepared (answers: {{ teachersEvals.evals_stats.preparation.nb }}, average: {{ teachersEvals.evals_stats.preparation.average.toFixed(2) }})
        </div>
        <div
          v-for="i in (0,5)"
          :key="'mark9_'+i"
          layout="row"
        >
          <div
            layout="column"
            flex="5"
            class="mt-2"
          >
            {{ i }} :
          </div>
          <div
            layout="column"
            flex="70"
          >
            <v-progress-linear
              v-model="teachersEvals.evals_stats.preparation['percent_' + i]"
              height="14"
              color="primary"
            />
          </div>
          <div
            layout="column"
            flex
            class="mt-2 ml-2"
          >
            {{ teachersEvals.evals_stats.preparation[i] }} answer{{ teachersEvals.evals_stats.preparation[i] > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
      <div
        v-if="teachersEvals.evals_stats.expression"
        layout="column"
        flex="50"
        class="mb-4"
      >
        <div
          layout="row"
          class="subheading"
        >
          3. The trainer expresses himself in a clear and understandable way (answers: {{ teachersEvals.evals_stats.expression.nb }}, average: {{ teachersEvals.evals_stats.expression.average.toFixed(2) }})
        </div>
        <div
          v-for="i in (0,5)"
          :key="'mark10_'+i"
          layout="row"
        >
          <div
            layout="column"
            flex="5"
            class="mt-2"
          >
            {{ i }} :
          </div>
          <div
            layout="column"
            flex="70"
          >
            <v-progress-linear
              v-model="teachersEvals.evals_stats.expression['percent_' + i]"
              height="14"
              color="primary"
            />
          </div>
          <div
            layout="column"
            flex
            class="mt-2 ml-2"
          >
            {{ teachersEvals.evals_stats.expression[i] }} answer{{ teachersEvals.evals_stats.expression[i] > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
      <div
        v-if="teachersEvals.evals_stats.topics"
        layout="column"
        flex="50"
        class="mb-4"
      >
        <div
          layout="row"
          class="subheading"
        >
          4. The trainer approaches the topics in an understandable way (answers: {{ teachersEvals.evals_stats.topics.nb }}, average: {{ teachersEvals.evals_stats.topics.average.toFixed(2) }})
        </div>
        <div
          v-for="i in (0,5)"
          :key="'mark11_'+i"
          layout="row"
        >
          <div
            layout="column"
            flex="5"
            class="mt-2"
          >
            {{ i }} :
          </div>
          <div
            layout="column"
            flex="70"
          >
            <v-progress-linear
              v-model="teachersEvals.evals_stats.topics['percent_' + i]"
              height="14"
              color="primary"
            />
          </div>
          <div
            layout="column"
            flex
            class="mt-2 ml-2"
          >
            {{ teachersEvals.evals_stats.topics[i] }} answer{{ teachersEvals.evals_stats.topics[i] > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
      <div
        v-if="teachersEvals.evals_stats.knowledge"
        layout="column"
        flex="50"
        class="mb-4"
      >
        <div
          layout="row"
          class="subheading"
        >
          5. Training improves students' knowledge (answers: {{ teachersEvals.evals_stats.knowledge.nb }}, average: {{ teachersEvals.evals_stats.knowledge.average.toFixed(2) }})
        </div>
        <div
          v-for="i in (0,5)"
          :key="'mark12_'+i"
          layout="row"
        >
          <div
            layout="column"
            flex="5"
            class="mt-2"
          >
            {{ i }} :
          </div>
          <div
            layout="column"
            flex="70"
          >
            <v-progress-linear
              v-model="teachersEvals.evals_stats.knowledge['percent_' + i]"
              height="14"
              color="primary"
            />
          </div>
          <div
            layout="column"
            flex
            class="mt-2 ml-2"
          >
            {{ teachersEvals.evals_stats.knowledge[i] }} answer{{ teachersEvals.evals_stats.knowledge[i] > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
      <div
        v-if="teachersEvals.evals_stats.interest"
        layout="column"
        flex="50"
        class="mb-4"
      >
        <div
          layout="row"
          class="subheading"
        >
          6. The training increases students' interest in the topic (answers: {{ teachersEvals.evals_stats.interest.nb }}, average: {{ teachersEvals.evals_stats.interest.average.toFixed(2) }})
        </div>
        <div
          v-for="i in (0,5)"
          :key="'mark13_'+i"
          layout="row"
        >
          <div
            layout="column"
            flex="5"
            class="mt-2"
          >
            {{ i }} :
          </div>
          <div
            layout="column"
            flex="70"
          >
            <v-progress-linear
              v-model="teachersEvals.evals_stats.interest['percent_' + i]"
              height="14"
              color="primary"
            />
          </div>
          <div
            layout="column"
            flex
            class="mt-2 ml-2"
          >
            {{ teachersEvals.evals_stats.interest[i] }} answer{{ teachersEvals.evals_stats.interest[i] > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
      <div
        v-if="teachersEvals.evals_stats.climate"
        layout="column"
        flex="50"
        class="mb-4"
      >
        <div
          layout="row"
          class="subheading"
        >
          7. The trainer creates a pleasant working atmosphere (answers: {{ teachersEvals.evals_stats.climate.nb }}, average: {{ teachersEvals.evals_stats.climate.average.toFixed(2) }})
        </div>
        <div
          v-for="i in (0,5)"
          :key="'mark15_'+i"
          layout="row"
        >
          <div
            layout="column"
            flex="5"
            class="mt-2"
          >
            {{ i }} :
          </div>
          <div
            layout="column"
            flex="70"
          >
            <v-progress-linear
              v-model="teachersEvals.evals_stats.climate['percent_' + i]"
              height="14"
              color="primary"
            />
          </div>
          <div
            layout="column"
            flex
            class="mt-2 ml-2"
          >
            {{ teachersEvals.evals_stats.climate[i] }} answer{{ teachersEvals.evals_stats.climate[i] > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
      <div
        v-if="teachersEvals.evals_stats.correspond"
        layout="column"
        flex="50"
        class="mb-4"
      >
        <div
          layout="row"
          class="subheading"
        >
          8. The training corresponds to my expectations (answers: {{ teachersEvals.evals_stats.correspond.nb }}, average: {{ teachersEvals.evals_stats.correspond.average.toFixed(2) }})
        </div>
        <div
          v-for="i in (0,5)"
          :key="'mark16_'+i"
          layout="row"
        >
          <div
            layout="column"
            flex="5"
            class="mt-2"
          >
            {{ i }} :
          </div>
          <div
            layout="column"
            flex="70"
          >
            <v-progress-linear
              v-model="teachersEvals.evals_stats.correspond['percent_' + i]"
              height="14"
              color="primary"
            />
          </div>
          <div
            layout="column"
            flex
            class="mt-2 ml-2"
          >
            {{ teachersEvals.evals_stats.correspond[i] }} answer{{ teachersEvals.evals_stats.correspond[i] > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
      <div
        v-if="teachersEvals.evals_stats.useful"
        layout="column"
        flex="50"
        class="mb-4"
      >
        <div
          layout="row"
          class="subheading"
        >
          9. I consider that training is useful (answers: {{ teachersEvals.evals_stats.useful.nb }}, average: {{ teachersEvals.evals_stats.useful.average.toFixed(2) }})
        </div>
        <div
          v-for="i in (0,5)"
          :key="'mark17_'+i"
          layout="row"
        >
          <div
            layout="column"
            flex="5"
            class="mt-2"
          >
            {{ i }} :
          </div>
          <div
            layout="column"
            flex="70"
          >
            <v-progress-linear
              v-model="teachersEvals.evals_stats.useful['percent_' + i]"
              height="14"
              color="primary"
            />
          </div>
          <div
            layout="column"
            flex
            class="mt-2 ml-2"
          >
            {{ teachersEvals.evals_stats.useful[i] }} answer{{ teachersEvals.evals_stats.useful[i] > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
      <div
        v-if="teachersEvals.evals_stats.implication"
        layout="column"
        flex="50"
        class="mb-4"
      >
        <div
          layout="row"
          class="subheading"
        >
          10. Students were sufficiently involved in training (answers: {{ teachersEvals.evals_stats.implication.nb }}, average: {{ teachersEvals.evals_stats.implication.average.toFixed(2) }})
        </div>
        <div
          v-for="i in (0,5)"
          :key="'mark17b_'+i"
          layout="row"
        >
          <div
            layout="column"
            flex="5"
            class="mt-2"
          >
            {{ i }} :
          </div>
          <div
            layout="column"
            flex="70"
          >
            <v-progress-linear
              v-model="teachersEvals.evals_stats.implication['percent_' + i]"
              height="14"
              color="primary"
            />
          </div>
          <div
            layout="column"
            flex
            class="mt-2 ml-2"
          >
            {{ teachersEvals.evals_stats.implication[i] }} answer{{ teachersEvals.evals_stats.implication[i] > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
      <div
        v-if="teachersEvals.evals_stats.internet"
        layout="column"
        flex="50"
        class="mb-4"
      >
        <div
          layout="row"
          class="subheading"
        >
          ...understand the operation and basic functionality of the Internet (answers: {{ teachersEvals.evals_stats.internet.nb }}, average: {{ teachersEvals.evals_stats.internet.average.toFixed(2) }})
        </div>
        <div
          v-for="i in (0,5)"
          :key="'mark18_'+i"
          layout="row"
        >
          <div
            layout="column"
            flex="5"
            class="mt-2"
          >
            {{ i }} :
          </div>
          <div
            layout="column"
            flex="70"
          >
            <v-progress-linear
              v-model="teachersEvals.evals_stats.internet['percent_' + i]"
              height="14"
              color="primary"
            />
          </div>
          <div
            layout="column"
            flex
            class="mt-2 ml-2"
          >
            {{ teachersEvals.evals_stats.internet[i] }} answer{{ teachersEvals.evals_stats.internet[i] > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
      <div
        v-if="teachersEvals.evals_stats.security"
        layout="column"
        flex="50"
        class="mb-4"
      >
        <div
          layout="row"
          class="subheading"
        >
          ...respect the safety rules and their social conduct (answers: {{ teachersEvals.evals_stats.security.nb }}, average: {{ teachersEvals.evals_stats.security.average.toFixed(2) }})
        </div>
        <div
          v-for="i in (0,5)"
          :key="'mark19_'+i"
          layout="row"
        >
          <div
            layout="column"
            flex="5"
            class="mt-2"
          >
            {{ i }} :
          </div>
          <div
            layout="column"
            flex="70"
          >
            <v-progress-linear
              v-model="teachersEvals.evals_stats.security['percent_' + i]"
              height="14"
              color="primary"
            />
          </div>
          <div
            layout="column"
            flex
            class="mt-2 ml-2"
          >
            {{ teachersEvals.evals_stats.security[i] }} answer{{ teachersEvals.evals_stats.security[i] > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
      <div
        v-if="teachersEvals.evals_stats.prudence"
        layout="column"
        flex="50"
        class="mb-4"
      >
        <div
          layout="row"
          class="subheading"
        >
          ...use the internet in a critical and careful way (answers: {{ teachersEvals.evals_stats.prudence.nb }}, average: {{ teachersEvals.evals_stats.prudence.average.toFixed(2) }})
        </div>
        <div
          v-for="i in (0,5)"
          :key="'mark20_'+i"
          layout="row"
        >
          <div
            layout="column"
            flex="5"
            class="mt-2"
          >
            {{ i }} :
          </div>
          <div
            layout="column"
            flex="70"
          >
            <v-progress-linear
              v-model="teachersEvals.evals_stats.prudence['percent_' + i]"
              height="14"
              color="primary"
            />
          </div>
          <div
            layout="column"
            flex
            class="mt-2 ml-2"
          >
            {{ teachersEvals.evals_stats.prudence[i] }} answer{{ teachersEvals.evals_stats.prudence[i] > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
      <div
        v-if="teachersEvals.evals_stats.danger"
        layout="column"
        flex="50"
        class="mb-4"
      >
        <div
          layout="row"
          class="subheading grey--text"
        >
          ...recognize concrete risks and hazards and respond appropriately (answers: {{ teachersEvals.evals_stats.danger.nb }}, average: {{ teachersEvals.evals_stats.danger.average.toFixed(2) }})
        </div>
        <div
          v-for="i in (0,5)"
          :key="'mark21_'+i"
          layout="row"
        >
          <div
            layout="column"
            flex="5"
            class="mt-2"
          >
            {{ i }} :
          </div>
          <div
            layout="column"
            flex="70"
          >
            <v-progress-linear
              v-model="teachersEvals.evals_stats.danger['percent_' + i]"
              height="14"
              color="primary"
            />
          </div>
          <div
            layout="column"
            flex
            class="mt-2 ml-2"
          >
            {{ teachersEvals.evals_stats.danger[i] }} answer{{ teachersEvals.evals_stats.danger[i] > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
      <div
        v-if="teachersEvals.evals_stats.protection"
        layout="column"
        flex="50"
        class="mb-4"
      >
        <div
          layout="row"
          class="subheading"
        >
          ...recognize the basic principles of data protection and their application (answers: {{ teachersEvals.evals_stats.protection.nb }}, average: {{ teachersEvals.evals_stats.protection.average.toFixed(2) }})
        </div>
        <div
          v-for="i in (0,5)"
          :key="'mark22_'+i"
          layout="row"
        >
          <div
            layout="column"
            flex="5"
            class="mt-2"
          >
            {{ i }} :
          </div>
          <div
            layout="column"
            flex="70"
          >
            <v-progress-linear
              v-model="teachersEvals.evals_stats.protection['percent_' + i]"
              height="14"
              color="primary"
            />
          </div>
          <div
            layout="column"
            flex
            class="mt-2 ml-2"
          >
            {{ teachersEvals.evals_stats.protection[i] }} answer{{ teachersEvals.evals_stats.protection[i] > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
      <div
        v-if="teachersEvals.evals_stats.risks_recognize"
        layout="column"
        flex="50"
        class="mb-4"
      >
        <div
          layout="row"
          class="subheading"
        >
          ...recognize concrete risks and dangers (answers: {{ teachersEvals.evals_stats.risks_recognize.nb }}, average: {{ teachersEvals.evals_stats.risks_recognize.average.toFixed(2) }})
        </div>
        <div
          v-for="i in (0,5)"
          :key="'mark22_'+i"
          layout="row"
        >
          <div
            layout="column"
            flex="5"
            class="mt-2"
          >
            {{ i }} :
          </div>
          <div
            layout="column"
            flex="70"
          >
            <v-progress-linear
              v-model="teachersEvals.evals_stats.risks_recognize['percent_' + i]"
              height="14"
              color="primary"
            />
          </div>
          <div
            layout="column"
            flex
            class="mt-2 ml-2"
          >
            {{ teachersEvals.evals_stats.risks_recognize[i] }} answer{{ teachersEvals.evals_stats.risks_recognize[i] > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
      <div
        v-if="teachersEvals.evals_stats.risks_respond"
        layout="column"
        flex="50"
        class="mb-4"
      >
        <div
          layout="row"
          class="subheading"
        >
          ...respond to risks and dangers in an appropriate way (answers: {{ teachersEvals.evals_stats.risks_respond.nb }}, average: {{ teachersEvals.evals_stats.risks_respond.average.toFixed(2) }})
        </div>
        <div
          v-for="i in (0,5)"
          :key="'mark22_'+i"
          layout="row"
        >
          <div
            layout="column"
            flex="5"
            class="mt-2"
          >
            {{ i }} :
          </div>
          <div
            layout="column"
            flex="70"
          >
            <v-progress-linear
              v-model="teachersEvals.evals_stats.risks_respond['percent_' + i]"
              height="14"
              color="primary"
            />
          </div>
          <div
            layout="column"
            flex
            class="mt-2 ml-2"
          >
            {{ teachersEvals.evals_stats.risks_respond[i] }} answer{{ teachersEvals.evals_stats.risks_respond[i] > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
      <div
        v-if="teachersEvals.evals_stats.appreciate"
        layout="column"
        flex="100"
        class="mb-4"
      >
        <div layout="row">
          <v-expansion-panel>
            <v-expansion-panel-content>
              <div
                slot="header"
                class="grey--text"
              >
                12. What do you particulary like about the training? (answers: {{ teachersEvals.evals_stats.appreciate.nb }})
              </div>
              <v-card>
                <v-card-text>
                  <div
                    v-for="(comm,id) in teachersEvals.evals_stats.appreciate.content"
                    :key="id"
                    layout="row"
                  >
                    <div
                      layout="column"
                      flex="20"
                    >
                      Session #{{ id }}
                    </div>
                    <div
                      layout="column"
                      flex
                    >
                      {{ comm }}
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </div>
      </div>
      <div
        v-if="teachersEvals.evals_stats.competent"
        layout="column"
        flex="50"
        class="mb-4"
      >
        <div
          layout="row"
          class="subheading"
        >
          12. The Trainer is competent (answers: {{ teachersEvals.evals_stats.competent.nb }}, average: {{ teachersEvals.evals_stats.competent.average.toFixed(2) }})
        </div>
        <div
          v-for="i in (0,5)"
          :key="'mark17b_'+i"
          layout="row"
        >
          <div
            layout="column"
            flex="5"
            class="mt-2"
          >
            {{ i }} :
          </div>
          <div
            layout="column"
            flex="70"
          >
            <v-progress-linear
              v-model="teachersEvals.evals_stats.competent['percent_' + i]"
              height="14"
              color="primary"
            />
          </div>
          <div
            layout="column"
            flex
            class="mt-2 ml-2"
          >
            {{ teachersEvals.evals_stats.competent[i] }} answer{{ teachersEvals.evals_stats.competent[i] > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
      <div
        v-if="teachersEvals.evals_stats.improvement"
        layout="column"
        flex="100"
        class="mb-4"
      >
        <div layout="row">
          <v-expansion-panel>
            <v-expansion-panel-content>
              <div
                slot="header"
                class="grey--text"
              >
                13. In your opinion, what improvements should be made to the training? (answers: {{ teachersEvals.evals_stats.improvement.nb }})
              </div>
              <v-card>
                <v-card-text>
                  <div
                    v-for="(comm,id) in teachersEvals.evals_stats.improvement.content"
                    :key="id"
                    layout="row"
                  >
                    <div
                      layout="column"
                      flex="20"
                    >
                      Session #{{ id }}
                    </div>
                    <div
                      layout="column"
                      flex
                    >
                      {{ comm }}
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </div>
      </div>
      <div
        v-if="teachersEvals.evals_stats.age_apprpriate"
        layout="column"
        flex="50"
        class="mb-4"
      >
        <div
          layout="row"
          class="subheading"
        >
          13. The training is age-apprpriate (answers: {{ teachersEvals.evals_stats.age_apprpriate.nb }}, average: {{ teachersEvals.evals_stats.age_apprpriate.average.toFixed(2) }})
        </div>
        <div
          v-for="i in (0,5)"
          :key="'mark17b_'+i"
          layout="row"
        >
          <div
            layout="column"
            flex="5"
            class="mt-2"
          >
            {{ i }} :
          </div>
          <div
            layout="column"
            flex="70"
          >
            <v-progress-linear
              v-model="teachersEvals.evals_stats.age_apprpriate['percent_' + i]"
              height="14"
              color="primary"
            />
          </div>
          <div
            layout="column"
            flex
            class="mt-2 ml-2"
          >
            {{ teachersEvals.evals_stats.age_apprpriate[i] }} answer{{ teachersEvals.evals_stats.age_apprpriate[i] > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
      <div
        v-if="teachersEvals.evals_stats.relevant"
        layout="column"
        flex="50"
        class="mb-4"
      >
        <div
          layout="row"
          class="subheading"
        >
          14. The training is designed to be relevant for everyday life (answers: {{ teachersEvals.evals_stats.relevant.nb }}, average: {{ teachersEvals.evals_stats.relevant.average.toFixed(2) }})
        </div>
        <div
          v-for="i in (0,5)"
          :key="'mark17b_'+i"
          layout="row"
        >
          <div
            layout="column"
            flex="5"
            class="mt-2"
          >
            {{ i }} :
          </div>
          <div
            layout="column"
            flex="70"
          >
            <v-progress-linear
              v-model="teachersEvals.evals_stats.relevant['percent_' + i]"
              height="14"
              color="primary"
            />
          </div>
          <div
            layout="column"
            flex
            class="mt-2 ml-2"
          >
            {{ teachersEvals.evals_stats.relevant[i] }} answer{{ teachersEvals.evals_stats.relevant[i] > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
      <div
        v-if="teachersEvals.evals_stats.practical"
        layout="column"
        flex="50"
        class="mb-4"
      >
        <div
          layout="row"
          class="subheading"
        >
          15. The training consists of enough practical elements (answers: {{ teachersEvals.evals_stats.practical.nb }}, average: {{ teachersEvals.evals_stats.practical.average.toFixed(2) }})
        </div>
        <div
          v-for="i in (0,5)"
          :key="'mark17b_'+i"
          layout="row"
        >
          <div
            layout="column"
            flex="5"
            class="mt-2"
          >
            {{ i }} :
          </div>
          <div
            layout="column"
            flex="70"
          >
            <v-progress-linear
              v-model="teachersEvals.evals_stats.practical['percent_' + i]"
              height="14"
              color="primary"
            />
          </div>
          <div
            layout="column"
            flex
            class="mt-2 ml-2"
          >
            {{ teachersEvals.evals_stats.practical[i] }} answer{{ teachersEvals.evals_stats.practical[i] > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
      <div
        v-if="teachersEvals.evals_stats.premises"
        layout="column"
        flex="50"
        class="mb-4"
      >
        <div
          layout="row"
          class="subheading"
        >
          16. The premises are appropriate (answers: {{ teachersEvals.evals_stats.premises.nb }}, average: {{ teachersEvals.evals_stats.premises.average.toFixed(2) }})
        </div>
        <div
          v-for="i in (0,5)"
          :key="'mark17b_'+i"
          layout="row"
        >
          <div
            layout="column"
            flex="5"
            class="mt-2"
          >
            {{ i }} :
          </div>
          <div
            layout="column"
            flex="70"
          >
            <v-progress-linear
              v-model="teachersEvals.evals_stats.premises['percent_' + i]"
              height="14"
              color="primary"
            />
          </div>
          <div
            layout="column"
            flex
            class="mt-2 ml-2"
          >
            {{ teachersEvals.evals_stats.premises[i] }} answer{{ teachersEvals.evals_stats.premises[i] > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
      <div
        v-if="teachersEvals.evals_stats.satisfied"
        layout="column"
        flex="50"
        class="mb-4"
      >
        <div
          layout="row"
          class="subheading"
        >
          17. I am satisfied with the training (answers: {{ teachersEvals.evals_stats.satisfied.nb }}, average: {{ teachersEvals.evals_stats.satisfied.average.toFixed(2) }})
        </div>
        <div
          v-for="i in (0,5)"
          :key="'mark17b_'+i"
          layout="row"
        >
          <div
            layout="column"
            flex="5"
            class="mt-2"
          >
            {{ i }} :
          </div>
          <div
            layout="column"
            flex="70"
          >
            <v-progress-linear
              v-model="teachersEvals.evals_stats.satisfied['percent_' + i]"
              height="14"
              color="primary"
            />
          </div>
          <div
            layout="column"
            flex
            class="mt-2 ml-2"
          >
            {{ teachersEvals.evals_stats.satisfied[i] }} answer{{ teachersEvals.evals_stats.satisfied[i] > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
      <div
        v-if="teachersEvals.evals_stats.topic_already"
        layout="column"
        flex="50"
        class="mb-4"
      >
        <div
          layout="row"
          class="subheading"
        >
          18. Have you ever covered the topic of training in your own class? (answers: {{ teachersEvals.evals_stats.topic_already.nb }}, average: {{ teachersEvals.evals_stats.topic_already.average.toFixed(2) }})
        </div>
        <div
          v-for="i in (0,5)"
          :key="'mark23_'+i"
          layout="row"
        >
          <div
            layout="column"
            flex="5"
            class="mt-2"
          >
            {{ i }} :
          </div>
          <div
            layout="column"
            flex="70"
          >
            <v-progress-linear
              v-model="teachersEvals.evals_stats.topic_already['percent_' + i]"
              height="14"
              color="primary"
            />
          </div>
          <div
            layout="column"
            flex
            class="mt-2 ml-2"
          >
            {{ teachersEvals.evals_stats.topic_already[i] }} answer{{ teachersEvals.evals_stats.topic_already[i] > 1 ? 's': '' }}
          </div>
        </div>
      </div>
      <div
        v-if="teachersEvals.evals_stats.topic_future"
        layout="column"
        flex="50"
        class="mb-4"
      >
        <div
          layout="row"
          class="subheading"
        >
          19. Are you planning to treat the topic of training in your own class? (answers: {{ teachersEvals.evals_stats.topic_future.nb }}, average: {{ teachersEvals.evals_stats.topic_future.average.toFixed(2) }})
        </div>
        <div
          v-for="i in (0,5)"
          :key="'mark24_'+i"
          layout="row"
        >
          <div
            layout="column"
            flex="5"
            class="mt-2"
          >
            {{ i }} :
          </div>
          <div
            layout="column"
            flex="70"
          >
            <v-progress-linear
              v-model="teachersEvals.evals_stats.topic_future['percent_' + i]"
              height="14"
              color="primary"
            />
          </div>
          <div
            layout="column"
            flex
            class="mt-2 ml-2"
          >
            {{ teachersEvals.evals_stats.topic_future[i] }} answer{{ teachersEvals.evals_stats.topic_future[i] > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
      <div
        v-if="teachersEvals.evals_stats.comments"
        layout="column"
        flex="100"
        class="mb-4"
      >
        <div layout="row">
          <v-expansion-panel>
            <v-expansion-panel-content>
              <div slot="header">
                20. Comments (answers: {{ teachersEvals.evals_stats.comments.nb }})
              </div>
              <v-card>
                <v-card-text>
                  <div
                    v-for="(comm,id) in teachersEvals.evals_stats.comments.content"
                    :key="id"
                    layout="row"
                  >
                    <div
                      layout="column"
                      flex="20"
                    >
                      Session #{{ id }}
                    </div>
                    <div
                      layout="column"
                      flex
                    >
                      {{ comm }}
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Statistics from '@/api/statistics'
import Consts from '@/consts'

export default {
  props: ['start', 'end', 'toFilter', 'showDates'],
  data () {
    return {
      trainersEvals: {
        evals_stats: {}
      },
      teachersEvals: {
        evals_stats: {}
      },
      loading: false,

    }
  },

  watch: {
    '$route.params.sessionType': function () {
      this.fetch()
    },
    toFilter (val) {
      if (val) {
        this.fetch()
        this.$emit('update:toFilter', false)
      }
    }
  },

  mounted () {
    this.fetch()
    this.$emit('update:showDates', true)
  },

  methods: {
    fetch () {
      this.loading = true
      switch (parseInt(this.$route.params.sessionType)) {
        case Consts.ADULTS:
          Statistics.getTrainersEvalsAdults({ start: this.start, end: this.end }).then(res => {
            this.trainersEvals = res.data
          })
          Statistics.getTeachersEvalsAdults({ start: this.start, end: this.end }).then(res => {
            this.teachersEvals = res.data
            this.loading = false
          })
          break
        case Consts.SCHOOL:
          Statistics.getTrainersEvalsSchools({ start: this.start, end: this.end }).then(res => {
            this.trainersEvals = res.data
          })
          Statistics.getTeachersEvalsSchools({ start: this.start, end: this.end }).then(res => {
            this.teachersEvals = res.data
            this.loading = false
          })
          break
        case Consts.TEACHERS:
          Statistics.getTrainersEvalsTeachers({ start: this.start, end: this.end }).then(res => {
            this.trainersEvals = res.data
          }); Statistics.getTeachersEvalsTeachers({ start: this.start, end: this.end }).then(res => {
            this.teachersEvals = res.data
            this.loading = false
          })
          break
        case Consts.YOUTH:
          Statistics.getTrainersEvalsYouth({ start: this.start, end: this.end }).then(res => {
            this.trainersEvals = res.data
          })
          Statistics.getTeachersEvalsYouth({ start: this.start, end: this.end }).then(res => {
            this.teachersEvals = res.data
            this.loading = false
          })
          break
        case Consts.INTERNAL:
          Statistics.getTrainersEvalsInternals({ start: this.start, end: this.end }).then(res => {
            this.trainersEvals = res.data
          })
          Statistics.getTeachersEvalsInternals({ start: this.start, end: this.end }).then(res => {
            this.teachersEvals = res.data
            this.loading = false
          })
          break
        case Consts.OTHER:
          Statistics.getTrainersEvalsOthers({ start: this.start, end: this.end }).then(res => {
            this.trainersEvals = res.data
          })
          Statistics.getTeachersEvalsOthers({ start: this.start, end: this.end }).then(res => {
            this.teachersEvals = res.data
            this.loading = false
          })
          break
      }

    },


  }
}
</script>
<style scoped>
.number-color {
  color: #f4b944;
}
</style>