<template>
  <div
    layout="column"
    layout-align="center center"
  >
    <div layout="column">
      <v-data-table :items="contracts">
        <template
          slot="headers"
          slot-scope="{}"
        >
          <tr class="text-xs-left">
            <th
            rowspan="2"
            style="border-right: 1px solid rgba(0,0,0,.12);"
            >
              Year
            </th>
            <th
              v-if="Auth.user.application === Consts.APPLICATION.ALL || Auth.user.application === Consts.APPLICATION.BEESECURE"
              class="text-xs-center"
              colspan="3"
            >
              BEE SECURE
            </th>
            <th
              v-if="Auth.user.application === Consts.APPLICATION.ALL || Auth.user.application === Consts.APPLICATION.FREELANCES"
              class="text-xs-center"
              colspan="3"
              :style="Auth.user.application === Consts.APPLICATION.ALL ? 'border-left: 1px solid rgba(0,0,0,.12);' : ''"
            >
              Freelances
            </th>
          </tr>
          <tr class="text-xs-left">
            <template v-if="Auth.user.application === Consts.APPLICATION.ALL || Auth.user.application === Consts.APPLICATION.BEESECURE">
              <th style="width: 150px;white-space: initial;">
                Label
              </th>
              <th style="width: 150px;white-space: initial;">
                Extract from criminal records
              </th>
              <th style="width: 150px;white-space: initial;">
                Updated CV
              </th>
            </template>
            <template v-if="Auth.user.application === Consts.APPLICATION.ALL || Auth.user.application === Consts.APPLICATION.FREELANCES">
              <th :style="(Auth.user.application === Consts.APPLICATION.ALL ? 'border-left: 1px solid rgba(0,0,0,.12);' : '') + 'width: 150px;white-space: initial;'">
                Label
              </th>
              <th style="width: 150px;white-space: initial;">
                Extract from criminal records
              </th>
              <th style="width: 150px;white-space: initial;">
                Updated CV
              </th>
            </template>
          </tr>
        </template>
        <template
          slot="items"
          slot-scope="{ item }"
        >
          <td style="border-right: 1px solid rgba(0,0,0,.12);">{{ item.year }}</td>
          <template v-if="Auth.user.application === Consts.APPLICATION.ALL || Auth.user.application === Consts.APPLICATION.BEESECURE">
            <td class="text-xs-center">
              <v-icon
                :color="item.bs_label ? 'success' : 'error'"
                @click="switchDoc(item, 'bs_label')"
              >
                mdi-circle
              </v-icon>
            </td>
            <td class="text-xs-center">
              <v-icon
                :color="item.bs_criminal_records ? 'success' : 'error'"
                @click="switchDoc(item, 'bs_criminal_records')"
              >
                mdi-circle
              </v-icon>
            </td>
            <td class="text-xs-center">
              <v-icon
                :color="item.bs_resume ? 'success' : 'error'"
                @click="switchDoc(item, 'bs_resume')"
              >
                mdi-circle
              </v-icon>
            </td>
          </template>
          <template v-if="Auth.user.application === Consts.APPLICATION.ALL || Auth.user.application === Consts.APPLICATION.FREELANCES">
            <td class="text-xs-center" :style="Auth.user.application === Consts.APPLICATION.ALL ? 'border-left: 1px solid rgba(0,0,0,.12);' : ''">
              <v-icon
                :color="item.fl_label ? 'success' : 'error'"
                @click="switchDoc(item, 'fl_label')"
              >
                mdi-circle
              </v-icon>
            </td>
            <td class="text-xs-center">
              <v-icon
                :color="item.fl_criminal_records ? 'success' : 'error'"
                @click="switchDoc(item, 'fl_criminal_records')"
              >
                mdi-circle
              </v-icon>
            </td>
            <td class="text-xs-center">
              <v-icon
                :color="item.fl_resume ? 'success' : 'error'"
                @click="switchDoc(item, 'fl_resume')"
              >
                mdi-circle
              </v-icon>
            </td>
          </template>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import Params from "@/api/params";
import Auth from "@/plugins/auth";
import Consts from "@/consts";

export default {
  props: {
    user: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      contracts: [],
      Auth,
      Consts
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      Params.getList(`users/${this.$route.params.id}/contracts`, {}).then(
        res => {
          this.contracts = res.data.slice(0);
        }
      );
    },
    switchDoc(contract, field) {
      Params.create("contracts", {
        year: contract.year,
        id: this.user.id,
        field: field,
        value: !contract[field]
      }).then(res => {
        if (res[field] !== contract[field]) {
          this.fetch();
        }
      });
    }
  }
};
</script>
