<template>
  <div class="ma-3">
    <div class="title mb-3">
      Sessions <v-icon>mdi-chevron-right</v-icon> Centers management
      <v-icon>mdi-chevron-right</v-icon> {{ isNew ? "New" : center.name }}
    </div>

    <v-form
      ref="form"
      v-model="formValid"
      layout="column"
      layout-align="center center"
      @submit.prevent="save"
    >
      <div>
        <div class="title font-weight-light mb-3">
          Center information
        </div>
        <v-text-field
          v-model="center.name"
          outline
          label="Name"
          class="field-width"
          :rules="[v => !!v || 'This field is required']"
        />
        <v-text-field
          v-model="center.sync_id"
          outline
          label="Sync code"
          class="field-width"
        />
        <v-checkbox
          v-if="center.sync_id === 'sport'"
          v-model="center.include_divers"
          label="Include &quot;divers&quot;"
          :true-value="1"
          :false-value="0"
        />
      </div>

      <v-btn
        depressed
        color="primary"
        @click="save"
      >
        Save
      </v-btn>
      <router-link
        :to="{ name: 'admin-sessions-centers-index' }"
        class="grey--text"
      >
        Cancel
      </router-link>
    </v-form>
  </div>
</template>

<script>
import Params from "@/api/params";

export default {
  data() {
    return {
      center: {
        name: null,
        sync_id: null,
        include_divers: null
      },
      formValid: false
    };
  },
  computed: {
    isNew() {
      return this.$route.params.id === "new";
    }
  },
  mounted() {
    if (!this.isNew) {
      this.fetch();
    }
  },
  methods: {
    fetch() {
      Params.get("centers", this.$route.params.id).then(res => {
        this.center = res.data;
      });
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.isNew) {
        Params.create("centers", this.center).then(() => {
          this.$router.push({ name: "admin-sessions-centers-index" });
          this.$snotify.success(
            `Center "${this.center.name}" has been created`
          );
        });
      } else {
        Params.update("centers", this.center.id, this.center).then(() => {
          this.$router.push({ name: "admin-sessions-centers-index" });
          this.$snotify.success(
            `Center "${this.center.name}" has been updated`
          );
        });
      }
    }
  }
};
</script>
