<template>
  <div
    layout="column"
    layout-align="center center"
  >
    <div
      layout="row"
      layout-align="start center"
      class="full-width"
    >
      <v-select
        v-model="filters.application"
        v-focus
        outline
        hide-details
        label="Application"
        :items="[
          {value: Consts.APPLICATION.BEESECURE, text: 'BEE SECURE'},
          {value: Consts.APPLICATION.FREELANCES, text: 'FREELANCES'}
        ]"
        class="mr-2"
        style="max-width: 400px"
        @change="fetch(false); fetchTopics()"
      />
      <v-select
        v-model="filters.users_application"
        v-focus
        outline
        hide-details
        label="Profile of the trainer"
        :items="[
          {value: '', text: 'All'},
          {value: 'null', text: 'None'},
          {value: Consts.APPLICATION.BEESECURE, text: 'BEE SECURE'},
          {value: Consts.APPLICATION.FREELANCES, text: 'FREELANCES'}
        ]"
        class="mr-2"
        @change="fetch(true)"
      />
      <v-text-field
        v-model="filters.keywords"
        outline
        label="Keywords"
        hide-details
        @keyup.enter="fetch"
      />
      <v-btn
        depressed
        color="primary"
        @click="fetch"
      >
        Filter
      </v-btn>
      or
      <a
        href="#"
        class="ml-2 black--text"
        @click="resetFilters"
      >Cancel</a>
    </div>

    <v-data-table
      ref="table"
      :items="users"
      :pagination.sync="pagination"
      class="mt-3"
      style="min-width: 300px; max-width: calc(100vw - 385px)"
    >
      <template
        slot="headers"
      >
        <tr class="text-xs-left">
          <th>
            <div
              layout="row"
              layout-align="start center"
            >
              <v-menu
                v-scroll
                offset-y
                max-height="400"
              >
                <v-btn
                  slot="activator"
                  icon
                  color="transparent"
                  class="elevation-0 mx-0 px-0"
                >
                  <v-icon
                    v-if="selectedUsersIds.length === users.length"
                    color="primary"
                  >
                    mdi-close-box
                  </v-icon>
                  <v-icon
                    v-else-if="selectedUsersIds.length"
                    color="primary"
                  >
                    mdi-minus-box
                  </v-icon>
                  <v-icon
                    v-else
                    color="grey darken-1"
                  >
                    mdi-checkbox-blank-outline
                  </v-icon>
                </v-btn>
                <v-list>
                  <v-list-tile
                    ripple
                    @click="selectUsers($refs.table.filteredItems)"
                  >
                    <v-list-tile-content>
                      <v-list-tile-title>
                        Select this page
                      </v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-list-tile
                    ripple
                    @click="selectUsers(users)"
                  >
                    <v-list-tile-content>
                      <v-list-tile-title>
                        Select All
                      </v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-list-tile
                    ripple
                    @click="selectedUsersIds = []"
                  >
                    <v-list-tile-content>
                      <v-list-tile-title>
                        Unselect all
                      </v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-menu>
              <span class="ml-2">Full name</span>
              <v-menu
                v-scroll
                offset-y
                :close-on-content-click="false"
                max-height="400"
                class="ml-auto"
              >
                <v-btn
                  slot="activator"
                  color="primary"
                  class="elevation-0 ml-5"
                >
                  <v-icon class="mr-2">
                    mdi-format-list-checks
                  </v-icon>
                  <span style="padding-top: 2px">
                    Concepts
                  </span>
                </v-btn>
                <v-list>
                  <v-list-tile
                    ripple
                    @click="selectedTopicsIds = selectedTopicsIds.length === topics.length ? [] : topics.map(t => t.id)"
                  >
                    <v-list-tile-action>
                      <v-icon
                        v-if="selectedTopicsIds.length === topics.length"
                        color="primary"
                      >
                        mdi-close-box
                      </v-icon>
                      <v-icon
                        v-else-if="selectedTopicsIds.length"
                        color="primary"
                      >
                        mdi-minus-box
                      </v-icon>
                      <v-icon v-else>
                        mdi-checkbox-blank-outline
                      </v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{ selectedTopicsIds.length === topics.length ? 'Unselect all' : 'Select All' }}
                      </v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-divider class="mt-2" />
                  <v-list-tile
                    v-for="topic in topics"
                    :key="topic.id"
                    @click="toggleTopic(topic)"
                  >
                    <v-list-tile-action>
                      <v-checkbox
                        v-model="selectedTopicsIds"
                        :value="topic.id"
                        color="primary"
                        hide-details
                        readonly
                      />
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{ topic.name }}
                      </v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-menu>
            </div>
          </th>
          <th
            v-for="(topic, i) in selectedTopics"
            :key="'header_' + topic.id"
            :colspan="filters.application === Consts.APPLICATION.FREELANCES ? 2 : 1"
          >
            <div layout="row">
              <div
                class="grey lighten-1 white--text text-xs-center title pa-2 mt-1 grow mx-0"
              >
                <span>
                  {{ topic.name }}
                </span>
                <v-icon
                  right
                  color="error"
                  @click="selectedTopicsIds.splice(i, 1)"
                >
                  mdi-close-circle-outline
                </v-icon>
              </div>
            </div>
            <div
              layout="row"
              layout-align="center center"
            >
              <v-menu
                v-if="filters.application === Consts.APPLICATION.BEESECURE"
                offset-y
              >
                <template #activator="{ on }">
                  <v-btn
                    class="mx-0 full-width"
                    depressed
                    small
                    v-on="on"
                  >
                    <v-icon
                      color="grey darken-2"
                      class="mr-1"
                    >
                      mdi-checkbox-multiple-blank-outline
                    </v-icon>
                    Choice
                  </v-btn>
                </template>
                <v-list>
                  <v-list-tile @click="changeTopicForSelectedUsers(topic.id, 1)">
                    <v-list-tile-title>Select</v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile @click="changeTopicForSelectedUsers(topic.id, 0)">
                    <v-list-tile-title>Unselect</v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>
              <template v-if="filters.application === Consts.APPLICATION.FREELANCES">
                <div
                  class="pr-2"
                  style="width: 250px;"
                >
                  <v-menu offset-y>
                    <template #activator="{ on }">
                      <v-btn
                        class="mx-0 full-width"
                        depressed
                        small
                        v-on="on"
                      >
                        Role
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-tile
                        @click="changeTopicForSelectedUsers(topic.id, null)"
                      >
                        <v-list-tile-title>Undefined</v-list-tile-title>
                      </v-list-tile>
                      <v-list-tile
                        v-for="role in skillRoles"
                        :key="role.value"
                        @click="changeTopicForSelectedUsers(topic.id, role.value)"
                      >
                        <v-list-tile-title>{{ role.text }}</v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-menu>
                </div>
                <div
                  class="pl-2"
                  style="width: 350px;"
                  :title="!seasonByTopicsId[topic.id] ? 'No season available for this concept' : ''"
                >
                  <v-menu
                    offset-y
                    :disabled="!seasonByTopicsId[topic.id]"
                  >
                    <template #activator="{ on }">
                      <v-btn
                        class="mx-0 full-width"
                        depressed
                        small
                        :disabled="!seasonByTopicsId[topic.id]"
                        v-on="on"
                      >
                        Seasons
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-tile
                        v-for="season in seasonByTopicsId[topic.id]"
                        :key="season.id"
                      >
                        <v-list-tile-title>
                          {{ season.center.name + ' (' + formatDate(season.start) + ' - ' + formatDate(season.end) + ')' }}
                        </v-list-tile-title>
                        <v-list-tile-action
                          class="ml-4 mr-0"
                          style="min-width: unset;"
                        >
                          <v-btn
                            icon
                            @click="removeSeasonForSelectedUsers(topic.id, season.id)"
                          >
                            <v-icon color="error">
                              mdi-minus-box
                            </v-icon>
                          </v-btn>
                        </v-list-tile-action>
                        <v-list-tile-action
                          class="ml-3 mr-0"
                          style="min-width: unset;"
                        >
                          <v-btn
                            icon
                            @click="addSeasonForSelectedUsers(topic.id, season.id)"
                          >
                            <v-icon color="success">
                              mdi-plus-box
                            </v-icon>
                          </v-btn>
                        </v-list-tile-action>
                      </v-list-tile>
                    </v-list>
                  </v-menu>
                </div>
              </template>
            </div>
          </th>
        </tr>
      </template>
      <template
        slot="items"
        slot-scope="{ item }"
      >
        <td style="z-index: 2">
          <div
            layout="row"
            layout-align="start center"
            class="text-no-wrap"
          >
            <v-checkbox
              v-model="selectedUsersIds"
              :value="item.id"
              color="primary"
              hide-details
              style="flex: none; margin-left: 6px; margin-right: 6px;"
            />
            <router-link :to="{name: 'admin-settings-users-show', params: {id: item.id}}">
              {{ item.firstname }} {{ item.lastname }}
            </router-link>
          </div>
        </td>
        <template v-for="topic in selectedTopics">
          <template v-if="filters.application === Consts.APPLICATION.FREELANCES">
            <td
              :key="'role' + item.id + '_' + topic.id"
              style="min-width: 250px; max-width: 250px;"
            >
              <v-select
                v-model="item.skills[topic.id]"
                :items="[
                  {text: 'Undefined', value: null},
                  ...skillRoles
                ]"
                hide-details
                dense
                required
                style="min-width: 150px; width: 100%"
                class="my-2 pa-0"
                @change="updateUserMatrix(item)"
              />
            </td>
            <td
              :key="'season' + item.id + '_' + topic.id"
              style="min-width: 350px; max-width: 350px;"
            >
              <div
                v-if="item.skills[topic.id] === Consts.SKILL_ROLE.TEACHER || !seasonByTopicsId[topic.id]"
                class="text-xs-center full-width full-height"
                style="background-color: #DDDDDD"
              />
              <v-select
                v-else
                v-model="item.seasons[topic.id]"
                :items="seasonByTopicsId[topic.id]"
                :disabled="item.skills[topic.id] === null"
                :item-text="s => s.center.name + ' (' + formatDate(s.start) + ' - ' + formatDate(s.end) + ')'"
                item-value="id"
                hide-details
                dense
                small-chips
                required
                multiple
                chips
                style="min-width: 150px; width: 100%"
                class="my-2 pa-0"
                @change="updateUserMatrix(item)"
              />
            </td>
          </template>
          <td
            v-else
            :key="'role' + item.id + '_' + topic.id"
          >
            <div
              layout="row"
              layout-align="center center"
            >
              <div>
                <v-checkbox
                  v-model="item.skills[topic.id]"
                  :true-value="1"
                  :false-value="0"
                  hide-details
                  @change="updateUserMatrix(item)"
                />
              </div>
            </div>
          </td>
        </template>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Params from '@/api/params'
import Consts from '@/consts'
import DateMixin from '@/mixins/date'
import GlobalMixin from "@/mixins/global"
import TeamsMixin from "@/mixins/teams"
import Auth from '@/plugins/auth'

export default {
  mixins: [DateMixin, GlobalMixin, TeamsMixin],
  data () {
    return {
      pagination: {
        rowsPerPage: 10
      },
      filters: {
        keywords: null,
        application: Consts.APPLICATION.BEESECURE,
        users_application: '',
      },
      users: [],
      topics: [],
      seasonByTopicsId: {},
      selectedTopicsIds: [],
      selectedUsersIds: [],
      Auth,
      Consts
    }
  },
  computed: {
    selectedTopics () {
      return this.topics.filter(t => this.selectedTopicsIds.includes(t.id))
    },
    selectedUsers () {
      return this.users.filter(u => this.selectedUsersIds.includes(u.id))
    }
  },
  mounted () {
    this.fetch(true);
    this.fetchTopics()
    this.fetchSeason()
  },
  methods: {
    fetch (resetSelected = false) {
      if (resetSelected) {
        this.selectedUsersIds = []
      }

      Params.getList('users', {
        role_id: [Consts.TRAINER],
        include_profil_matrix: 1,
        is_enabled: 1,
        sortBy: 'fullname',
        keywords: this.filters.keywords,
        application: this.filters.users_application,
      }).then(res => {
        let users = [];
        res.data.forEach(u => {

          let skills = {}
          if (this.filters.application === Consts.APPLICATION.FREELANCES) {
            this.topics.forEach(t => {
              skills[t.id] = null
            })
          }
          u.skills.forEach(s => {
            if (this.filters.application === Consts.APPLICATION.BEESECURE) {
              skills[s.topic_id] = s.is_enabled
            } else {
              skills[s.topic_id] = s.is_enabled ? s.role : null
            }
          })
          u.skills = skills

          let seasons = {}
          u.seasons.forEach(s => {
            if(s.skill){
              if (!seasons[s.skill.topic_id]) {
                seasons[s.skill.topic_id] = []
              }
              if (s.is_enabled) {
                seasons[s.skill.topic_id].push(s.season_id)
              }
            }
          })
          u.seasons = seasons

          users.push(u)
        })
        this.users = users
      })
    },
    fetchTopics () {
      this.selectedTopicsIds = []
      Params.getList('topics', {
        application: this.filters.application,
        is_enabled: 1,
      }).then(res => {
        this.topics = res.data;
        this.topics.sort((a, b) => a.name.localeCompare(b.name))

        if (this.filters.application === Consts.APPLICATION.FREELANCES) {
          this.users.forEach(u => {
            this.topics.forEach(t => {
              if (!u.skills[t.id]) {
                u.skills[t.id] = null
              }
            })
          })
        }
      })
    },
    fetchSeason () {
      Params.getList('seasons', {
        not_past: 1,
        is_enabled: 1,
      }).then(res => {
        this.seasonByTopicsId = {};
        res.data.forEach(s => {
          if (!this.seasonByTopicsId[s.topic_id]) {
            this.seasonByTopicsId[s.topic_id] = []
          }
          this.seasonByTopicsId[s.topic_id].push(s)
        })
      })
    },
    resetFilters () {
      this.filters = {
        keywords: null,
        application: Consts.APPLICATION.BEESECURE,
        users_application: ''
      };
      this.fetch(true);
    },
    selectUsers(users) {
      users.forEach(u => {
        let index = this.selectedUsersIds.indexOf(u.id)
        if (index === -1) {
          this.selectedUsersIds.push(u.id)
        }
      })
    },
    toggleTopic (topic) {
      let index = this.selectedTopicsIds.indexOf(topic.id)
      if (index !== -1) {
        this.selectedTopicsIds.splice(index, 1)
      } else {
        this.selectedTopicsIds.push(topic.id)
      }
    },
    updateUserMatrix (u) {
      Params.create('users/profiles-matrix', {
        application: this.filters.application,
        users: {
          [u.id]: {
            skills: u.skills,
            seasons: u.seasons,
          }
        }
      }).then(res => {
        if(res.data.skill_errors){
          this.$snotify.error(res.data.skill_errors)
        }
        if(res.data.season_errors){
          this.$snotify.error(res.data.season_errors)
        }
        this.fetch()
      })
    },
    changeTopicForSelectedUsers (topicId, value) {
      let data = {}
      this.selectedUsers.forEach(u => {
        data[u.id] = {
          skills: { [topicId]: value }
        }
      })
      if (Object.keys(data).length === 0) {
        this.$snotify.info('No user selected');
        return
      }
      Params.create('users/profiles-matrix', {
        application: this.filters.application,
        users: data
      }).then(res => {
        if(res.data.total){
          this.$snotify.success(`Role has been successfully applied. ${res.data.done}/${res.data.total} persons have been updated`)
        }
        if(res.data.skill_errors){
          this.$snotify.warning(res.data.skill_errors)
        }
        this.fetch()
      })
    },
    removeSeasonForSelectedUsers (topicId, seasonId) {
      let data = {}
      this.selectedUsers.forEach(u => {
        let seasons = u.seasons
        if (seasons[topicId]) {
          const index = seasons[topicId].indexOf(seasonId);
          if (index !== -1) {
            seasons[topicId].splice(index, 1);
          }
        }
        data[u.id] = {
          seasons: seasons
        }
      })
      if (Object.keys(data).length === 0) {
        this.$snotify.info('No user selected');
        return
      }
      Params.create('users/profiles-matrix', {
        application: this.filters.application,
        users: data
      }).then(res => {
        if(res.data.total){
          this.$snotify.success(`Season has been successfully removed. ${res.data.done}/${res.data.total} persons have been updated`)
        }
        if(res.data.season_errors){
          this.$snotify.warning(res.data.season_errors)
        }
        this.fetch()
      })
    },
    addSeasonForSelectedUsers (topicId, seasonId) {
      let data = {}
      this.selectedUsers.forEach(u => {
        let seasons = u.seasons
        if (seasons[topicId] === undefined) {
          seasons[topicId] = []
        }
        if (u.skills[topicId] !== null && seasons[topicId].indexOf(seasonId) === -1) {
          seasons[topicId].push(seasonId);
        }
        data[u.id] = {
          seasons: seasons
        }
      })
      if (Object.keys(data).length === 0) {
        this.$snotify.info('No user selected');
        return
      }
      Params.create('users/profiles-matrix', {
        application: this.filters.application,
        users: data
      }).then(res => {
        if(res.data.total){
          this.$snotify.success(`Season has been successfully applied. ${res.data.done}/${res.data.total} persons have been updated`)
        }
        if(res.data.season_errors){
          this.$snotify.warning(res.data.season_errors)
        }
        this.fetch()
      })
    }
  }
}
</script>

<style>
table > tbody > tr > td:nth-child(1),
table > thead > tr > th:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 1;
  background: white;
}

table > tbody > tr > td {
  background: white;
  padding: 0 8px !important;
}

table > thead > tr > th {
  padding: 0 8px !important;
}

table > tbody > tr:hover > td {
  background: #eee;
  -webkit-transition: background .3s cubic-bezier(.25, .8, .5, 1);
  transition: background .3s cubic-bezier(.25, .8, .5, 1);
  will-change: background;
}
</style>