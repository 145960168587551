import axios from 'axios'
import './common'

export default {
    getList: (params) => axios.get(`/organizations`, { params: params }),
    get: (id) => axios.get(`/organizations/${id}`),
    create: (data) => axios.post(`/organizations`, data),
    update: (id, data) => axios.put(`/organizations/${id}`, data),
    delete: (id) => axios.delete(`/organizations/${id}`),
    enable: (id) => axios.patch(`/organizations/${id}/enable`),
    disable: (id) => axios.patch(`/organizations/${id}/disable`),
    getContactList: (organization_id, params) => axios.get(`/organizations/${organization_id}/contacts`, { params: params }),
    createContact: (organization_id, data) => axios.post(`/organizations/${organization_id}/contacts`, data),
    updateContact: (organization_id, contact_id, data) => axios.put(`/organizations/${organization_id}/contacts/${contact_id}`, data),
    disableContact: (organization_id, contact_id) => axios.patch(`/organizations/${organization_id}/contacts/${contact_id}/disable`),
    enableContact: (organization_id, contact_id) => axios.patch(`/organizations/${organization_id}/contacts/${contact_id}/enable`),
    getEvals: (organization_id, params) => axios.get(`/organizations/${organization_id}/evaluations`, { params: params }),
}