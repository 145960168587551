<template>
  <div
    layout="row"
    layout-align="center center"
    flex
  >
    <v-card
      layout="column"
      layout-align="center center"
      class="login-card"
    >
      <v-card-text
        layout="column"
        class="text-xs-center"
      >
        <div>
          <img
            src="@/assets/logo.png"
            height="80"
          >
        </div>
        <div class="title font-weight-regular my-4">
          Welcome on POP!
        </div>

        <v-form
          layout="column"
          :class="{'mx-5': !$vuetify.breakpoint.smAndDown}"
        >
          <v-text-field
            v-model="auth.login"
            outline
            label="Login or email address"
            single-line
            flex
          />
          <v-text-field
            v-model="auth.password"
            outline
            label="Password"
            single-line
            type="password"
            @keyup.enter="login"
          />
        </v-form>
      </v-card-text>

      <v-card-actions
        layout="row"
        layout-align="end center"
        class="mx-5 mb-3"
      >
        <div class="mr-3">
          <router-link
            class="grey--text"
            :to="{ name: 'create-account-index' }"
          >
            Create an account
          </router-link>
          <br>
          <router-link
            class="grey--text"
            :to="{ name: 'reset-password-index' }"
          >
            I forgot my password
          </router-link>
        </div>
        <v-btn
          depressed
          color="primary"
          large
          @click="login"
        >
          Login
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Auth from "@/plugins/auth";
import Consts from "@/consts";

export default {
  data() {
    return {
      auth: {
        login: null,
        password: null
      }
    };
  },

  mounted() {
    this.$emit("activeChanged", false);

    if (this.$route.query.autologin) {
      Auth.autologin(this.$route.query.autologin);
      this.$router.push({ name: "desktop-index" });
    }
  },

  methods: {
    login() {
      Auth.login(this.auth.login, this.auth.password).then(() => {
        if (
          Auth.user.roles.length == 1 &&
          Auth.user.roles[0] == Consts.TRAINER
        ) {
          if(window.outerWidth < 960){
            this.$router.push({ name: "planning-mobile" });
          } else {
            this.$router.push({ name: "planning-index" });
          }
        } else {
          this.$router.push({ name: "sessions-index" });
        }
      });

      return false;
    },

    passwordForgotten() {
      this.$router.push({ name: "login-forgot" });
    }
  }
};
</script>

<style scoped>
.login-card {
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 960px) {
  .login-card {
    width: 800px;
    height: unset;
  }
}
</style>
