<template>
  <div>
    <div
      layout="column"
      class="full-width pl-0"
    >
      <div
        layout="row"
        layout-align="start center"
        class="full-width"
      >
        <v-subheader v-if="isActivity">
          Interns
        </v-subheader>
        <v-subheader v-else-if="isTraining">
          Participants
        </v-subheader>
        <v-subheader v-else>
          Visitors
        </v-subheader>
        <span flex />
        <template v-if="!isPastTraining">
          <v-btn
            v-if="canParticipate(session)"
            @click="participate(session)"
          >
            I want to participate
          </v-btn>
          <v-btn
            v-if="canUnparticipate(session)"
            @click="participate(session)"
          >
            I want to unregister
          </v-btn>
        </template>
      </div>
      <div
        layout="column"
        layout-align="start start"
        flex
        style="padding-left: 15px"
      >
        <ul
          v-if="session.session_visitor && session.session_visitor.length"
          class="mb-3"
        >
          <li
            v-for="v in session.session_visitor"
            :key="'visitor_'+v.id"
          >
            <v-icon
              v-if="isQueued(v.user_id, session, session.session_visitor)"
              class="mr-1"
              small
              title="In the queue"
            >
              mdi-human-queue
            </v-icon>
            <span>{{ v.user.firstname }} {{ v.user.lastname }}</span>
          </li>
        </ul>

        <div
          v-if="isPastTraining && (canParticipate(session) || canUnparticipate(session))"
          class="grey--text font-italic"
          v-text="params.training_close_message"
        />
      </div>
    </div>
  </div>
</template>
<script>
import sessionsMixin from '@/mixins/sessions'
import Consts from '@/consts'
import Params from '@/api/params'
import moment from 'moment'

export default {
	mixins: [sessionsMixin],
	props: {
		session: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	data() {
		return {
			params: {
				nb_days_training_close: 7,
				training_close_message: 'For organizational reasons it is impossible to register for the training via the site, if the deadline of one week before the training has passed. If you are interested in participating in this training, you can nevertheless contact the SNJ team at contact@snj.lu or call 247-81234.'
			}
		}
	},
	computed: {
    isTraining() {
      if (parseInt(this.session.session_type) == Consts.FREELANCES_TRAINING) {
        return true;
      }
      return false;
		},
		isActivity() {
      if (parseInt(this.session.session_type) == Consts.ACTIVITY) {
        return true;
      }
      return false;
		},
		isPastTraining(){
			if(!this.isTraining){
				return false
			}

			let past = false

			this.session.dates.forEach(d => {
				if(moment(d.start).isBefore(moment().add(this.params.nb_days_training_close, 'days'))){
					past = true
				}
			})

			return past
		}
	},
	mounted() {
		Params.getList('params').then(res => {
			res.data.forEach(l => {
				this.params[l.name] = l.value
			})
		})
	},
}
</script>
<style scoped>
	.v-subheader {
		padding: 0 10px;
	}
</style>