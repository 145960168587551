<template>
  <user-form
    :value="user"
  />
</template>

<script>
import UserForm from "../Edit.vue"

export default {
  components: { UserForm },
  props: {
		user: {
			type: Object,
			required: true,
			default: () => {}
		}
	}
}
</script>
