<template>
  <div class="ma-3">
    <div class="title mb-3">
      Sessions <v-icon>mdi-chevron-right</v-icon> Target groups management
    </div>

    <div
      layout="column"
      layout-align="center center"
    >
      <div
        layout="column"
        class="field-width"
      >
        <div
          layout="row"
          layout-align="start center"
          class="ml-3"
        >
          <v-select
            v-model="filters.session_type"
            v-focus
            solo
            single-line
            hide-details
            label="Session type"
            :items="sessionTypes"
            class="mr-2"
            @change="fetch"
          />
          <v-text-field
            v-model="filters.keywords"
            solo
            label="Keywords"
            hide-details
            @keyup.enter="fetch"
          />
          <v-btn
            depressed
            color="primary"
            @click="fetch"
          >
            Filter
          </v-btn>
          or
          <a
            href="#"
            class="ml-2 black--text"
            @click="resetFilters"
          >Cancel</a>
        </div>

        <div>
          <v-btn
            flat
            class="mt-3"
            :to="{name: 'admin-sessions-targets-edit', params: {id: 'new'}}"
          >
            <v-icon
              left
              color="primary"
            >
              mdi-plus-box
            </v-icon>
            Add a new target group
          </v-btn>
        </div>

        <v-data-table
          :headers="headers"
          :items="targets"
          style="max-width: 1000px"
          :pagination.sync="pagination"
        >
          <template
            slot="items"
            slot-scope="{ item }"
          >
            <td>{{ item.name }}</td>
            <td>
              <span
                v-for="(st, ti) in item.session_types"
                :key="item.id + st.id"
              >
                {{ (ti > 0) ? ', ' : '' }}{{ sessionTypeStrLong(st.session_type) }}
              </span>
            </td>
            <td>
              <v-checkbox
                v-model="item.show_in_stats"
                readonly
                :true-value="1"
                :false-value="0"
                hide-details
              />
            </td>
            <td layout="row">
              <v-btn
                class="mx-0"
                icon
                :to="{name: 'admin-sessions-targets-edit', params: {id: item.id}}"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>

              <delete-button
                :label="item.name"
                @confirm="deleteImpl(item)"
              />
            </td>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import Params from '@/api/params'
import DeleteButton from "@/components/shared/DeleteButton.vue"
import SessionMixin from '@/mixins/sessions'
import Consts from '@/consts'

export default {
  components: { DeleteButton },
  mixins: [SessionMixin],
  data () {
    return {
      sessionTypes: [
        { value: Consts.EVENTS, text: this.sessionTypeStrLong(Consts.EVENTS) },
        { value: Consts.ADULTS, text: this.sessionTypeStrLong(Consts.ADULTS) },
        { value: Consts.SCHOOL, text: this.sessionTypeStrLong(Consts.SCHOOL) },
        { value: Consts.TEACHERS, text: this.sessionTypeStrLong(Consts.TEACHERS) },
        { value: Consts.YOUTH, text: this.sessionTypeStrLong(Consts.YOUTH) },
        { value: Consts.INTERNAL, text: this.sessionTypeStrLong(Consts.INTERNAL) },
        { value: Consts.OTHER, text: this.sessionTypeStrLong(Consts.OTHER) },
        { value: Consts.ACTIVITY, text: this.sessionTypeStrLong(Consts.ACTIVITY) },
      ],
      pagination: {
        rowsPerPage: 10
      },
      headers: [
        { text: 'Target group', value: 'name' },
        { text: 'Session types', value: 'session_types', sortable: false },
        { text: 'Show in stats', value: 'show_in_stats', width: "150px" },
        { text: 'Actions', sortable: false, width: "80px" }
      ],
      filters: {
        keywords: null,
        session_type: null
      },
      targets: []
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      Params.getList('targets-groups', this.filters).then(res => {
        this.targets = res.data
      })
    },

    resetFilters () {
      this.filters = { keywords: null, session_type: null }
      this.fetch()
    },

    deleteImpl (item) {
      Params.delete('targets-groups', item.id).then(() => {
        this.fetch()
        this.$snotify.success(`The target group "${item.name}" has been deleted`)
      })
    }
  }
}
</script>