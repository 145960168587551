<template>
  <div
    layout="row"
    layout-align="center center"
    flex
  >
    <v-card
      layout="column"
      layout-align="center center"
      class="login-card"
    >
      <v-card-text
        layout="column"
        class="text-xs-center"
      >
        <div>
          <img
            src="@/assets/logo.png"
            height="80"
          >
        </div>
        <div class="title font-weight-regular my-4">
          Reset your password
        </div>

        <v-form
          layout="column"
          :class="{'mx-5': !$vuetify.breakpoint.smAndDown}"
        >
          <v-subheader>
            Enter your new password
          </v-subheader>
          <v-text-field
            v-model="form.password"
            type="password"
            outline
            label="Password"
            single-line
            flex
            required
          />
        </v-form>
      </v-card-text>

      <v-card-actions
        layout="row"
        layout-align="end center"
        class="mx-5 mb-3"
      >
        <router-link
          href="#"
          class="grey--text mr-3"
          :to="{name: 'login'}"
        >
          Back to login page
        </router-link>
        <v-btn
          depressed
          color="primary"
          large
          @click="validate"
        >
          Validate
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
    import ResetPasswords from '@/api/reset-passwords'
    export default {
        data () {
            return {
                form: {
                    password: ''
                }
            }
        },
        methods: {
            validate () {
                ResetPasswords.resetPassword(this.$route.params.id, this.form).then(() => {
                    this.$snotify.success('Password edited');
                    this.$router.push('/login');
                })
            }
        }
    }
</script>
<style scoped>
.login-card {
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 960px) {
  .login-card {
    width: 800px;
    height: unset;
  }
}
</style>