<template>
  <v-dialog
    v-model="isVisible"
    persistent
    width="1000"
  >
    <v-card>
      <v-card-title>
        <p class="title mt-2 ml-2">
          Do you want to upgrade the session #{{ session.reference }}? Its status will be to Evaluate?
        </p>
      </v-card-title>
      <v-card-text
        layout="column"
        layout-align="start start"
        class="full-wodth"
      >
        <div
          layout="row"
          class="full-wodth"
          flex="100"
          style="width: 100%"
        >
          <v-textarea
            v-model="comment"
            outline
            label="Comment"
          />
        </div>
      </v-card-text>
      <v-card-actions
        layout="row"
        layout-align="end center"
        class="pr-4 pb-2"
      >
        <v-btn
          depressed
          color="error"
          @click="isVisible = false"
        >
          No
        </v-btn>
        <v-btn
          depressed
          color="success"
          @click="save(0)"
        >
          Yes without notifying
        </v-btn>
        <v-btn
          depressed
          color="primary"
          @click="save(1)"
        >
          Yes
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
    import Sessions from '@/api/sessions'

    export default {
        props: {
            visible: {
                type: Boolean,
                required: false,
                default: false
            },
            session: {
                required: true
            },
        },
        data () {
            return {
                comment: ""
            }
        },
        computed: {
            isVisible: {
                get () {
                    return this.visible;
                },
                set (x) {
                    this.$emit('update:visible', x);
                }
            }
        },
        mounted () {

        },
        methods: {
            save(notif){
                Sessions.validate(this.session.id, {to_send: notif, comment: this.comment}).then(res => {
                    this.$snotify.success('The session has been upgraded');
                    this.isVisible = false;
                    this.comment = "";
                    this.$emit('updated', res.data);
                }, () => {
                    this.loading = false;
                });
            },
        }
    }
</script>