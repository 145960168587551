<template>
  <div
    layout="column"
    class="mr-2 ml-2"
    flex
    flex-order-gt-md="0"
  >
    <div
      layout="row"
      layout-align="start start"
      class="mt-3"
    >
      <div
        layout="column"
        class="mr-4"
        flex
      >
        <div
          layout="row"
          class="title"
        >
          <span class="mr-2">Planning</span>

          <v-menu
            v-if="isTrainer"
            v-model="icsModal"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
            offset-y
          >
            <template slot="activator">
              <v-icon title="ICalendar publishing link">
                mdi-access-point
              </v-icon>
            </template>

            <v-card style="max-width: 300px">
              <v-card-title class="title">
                ICalendar publishing link
              </v-card-title>
              <v-card-text>
                <p>
                  You can use the following URL to publish an ICS version of
                  your planning.
                </p>
                <v-text-field
                  id="ics-url"
                  v-model="icsUrl"
                  solo
                  readonly
                  label="URL"
                  append-icon="mdi mdi-content-copy"
                  @click:append="copyURL"
                />
              </v-card-text>

              <v-card-actions>
                <v-spacer />

                <v-btn
                  flat
                  @click="icsModal = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>

          <v-icon
            v-show="!showFilters"
            @click="showFilters = true"
          >
            mdi-filter
          </v-icon>
        </div>
      </div>
    </div>
    <div
      v-if="init"
      layout-gt-md="row"
      class="my-3"
      style="height: 100%"
    >
      <div style="width:100%; height: auto; display: flex">
        <div
          v-show="loading"
          class="ma-5 text-xs-center full-width"
        >
          <v-progress-circular
            indeterminate
            :size="32"
            color="accent"
          />
        </div>
        <p-calendar
          v-show="!loading"
          ref="calendar"
          :mode="mode"
          :date="params.date"
          :events="events"
          :periods="periods"
          :end-hour="20"
          is-manual-style
          class="calendar"
          @prev="updateDates"
          @next="updateDates"
          @mode-change="updateDates"
          @go-today="updateDates"
          @event-click="onSessionClick"
        >
          <template slot="today-btn">
            <v-btn
              depressed
              @click="$refs.calendar.goToday()"
            >
              Today
            </v-btn>
          </template>
          <template slot="prev-btn">
            <v-btn
              icon
              @click="$refs.calendar.prev()"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </template>
          <template slot="next-btn">
            <v-btn
              icon
              @click="$refs.calendar.next()"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </template>
          <template slot="mode-select">
            <v-select
              v-if="$refs.calendar"
              v-model="$refs.calendar.currentMode"
              :items="modes"
              flat
              single-line
              solo
              style="max-width: 100px"
              @change="changeMode"
            />
          </template>
          <template
            slot="dm-event"
            slot-scope="{ event }"
          >
            <div
              layout="row"
              layout-align="start center"
              class="overflow-line"
            >
              <v-avatar
                :color="event.data.status.color"
                :size="14"
                class="black--text font-weight-medium mr-1"
                :class="{ 'black--text': event.data.session.status == 0 }"
                style="font-size: 7px"
              >
                {{ event.data.status.str }}
              </v-avatar>
              #{{ event.data.ref }}
              <v-icon
                slot="badge"
                small
                class="mr-1"
              >
                <template
                  v-if="
                    event.data.session.application ===
                      Consts.APPLICATION.BEESECURE
                  "
                >
                  icon-bee48
                </template>
                <template v-else>
                  icon-kayak48
                </template>
              </v-icon>
              <comment-icon
                :item="event.data.session"
                small
                class="info-icon"
              />
              <special-training-icon
                :item="event.data.session"
                small
                class="info-icon"
              />

              <div
                v-if="event.data.session.is_inclusive"
                class="v-menu info-icon v-menu--inline"
              >
                <div
                  layout="column"
                  flex
                >
                  <v-icon
                    class="my-auto"
                    title="Is inclusive"
                    small
                    color="primary"
                  >
                    mdi-all-inclusive
                  </v-icon>
                </div>
              </div>
              <div
                v-if="event.data.session.is_online"
                class="v-menu info-icon v-menu--inline"
              >
                <div
                  layout="column"
                  flex
                >
                  <v-icon
                    class="my-auto"
                    title="Is online"
                    small
                    color="primary"
                  >
                    mdi-cast-education
                  </v-icon>
                </div>
              </div>

              <template
                v-if="
                  isTrainer &&
                    (!isCandidate || isFreelancesObj(event.data.session)) &&
                    (event.data.status.val == Consts.RELEASE ||
                      event.data.status.val == Consts.TOVALIDATE ||
                      event.data.status.val == Consts.BLOCKED ||
                      event.data.status.val == Consts.TOEVALUATE)
                "
              >
                <span flex />
                <v-checkbox
                  v-if="isTrainer && (!isCandidate || isFreelancesObj(event.data.session)) && (event.data.session.status == Consts.RELEASE || event.data.session.status == Consts.TOVALIDATE || event.data.session.status == Consts.BLOCKED || event.data.session.status == Consts.TOEVALUATE) && canTrain(event.data.session, event.data)"
                  v-model="event.data.is_trainer"
                  class="presence-toggle"
                  hide-details
                  :ripple="false"
                  :disabled="!canTrain(event.data.session, event.data)"
                  @click.prevent.stop="setPresence(event)"
                />
                <template v-else>
                  <v-checkbox
                    v-if="canParticipate(event.data.session)"
                    v-model="event.data.is_intern"
                    class="presence-toggle"
                    hide-details
                    :ripple="false"
                    @click.prevent.stop="setIntern(event)"
                  />
                  <v-checkbox
                    v-else-if="canUnparticipate(event.data.session)"
                    v-model="event.data.is_intern"
                    class="presence-toggle"
                    hide-details
                    :ripple="false"
                    @click.prevent.stop="setIntern(event)"
                  />
                  <v-checkbox
                    v-else-if="event.data.is_intern"
                    v-model="event.data.is_intern"
                    class="presence-toggle"
                    hide-details
                    :ripple="false"
                    disabled
                    color="grey"
                  />
                  <v-checkbox
                    v-else-if="event.data.is_trainer"
                    v-model="event.data.is_trainer"
                    class="presence-toggle"
                    hide-details
                    :ripple="false"
                    disabled
                    color="grey"
                  />
                </template>
              </template>
            </div>
            <div>
              <span class="text-nowrap">{{ event.data.hours }}</span>
            </div>
            <div
              v-if="event.data.city"
              layout="row"
              class="overflow-line"
            >
              <b>{{ event.data.city }}</b>
            </div>
            <div
              v-if="event.data.location"
              layout="row"
              class="overflow-line"
            >
              {{ event.data.location }}
            </div>
            <div
              v-if="event.data.session.center"
              layout="row"
              class="overflow-line"
            >
              {{ event.data.session.center.name }}
            </div>
            <div
              v-if="event.data.session.topic"
              layout="row"
              layout-align="start center"
              class="overflow-line"
            >
              <em>{{ event.data.session.topic.name }}</em>
              <v-icon
                v-if="event.data.session.topic.color"
                class="ml-1"
                small
                :color="event.data.session.topic.color"
              >
                mdi-circle
              </v-icon>
            </div>
            <div
              v-for="t in event.data.trainers"
              :key="'pt_' + t.id"
              layout="row"
              class="overflow-line"
            >
              {{ t.firstname }} {{ t.lastname }}
            </div>
            <template v-if="event.data.status.val === Consts.DRAFT">
              <span>(Draft)</span>
            </template>
            <template v-else-if="event.data.status.val === Consts.ONHOLD">
              <span>(On hold)</span>
            </template>
          </template>
        </p-calendar>
      </div>
      <div
        v-show="showFilters"
        layout="column"
        class="sidebar ml-4"
      >
        <div
          layout="row"
          class="title font-weight-light pl-2 mb-2"
        >
          Filters
          <span flex />
          <v-icon
            v-if="isAdmin"
            title="Planning settings"
            @click="settings"
          >
            mdi-cog
          </v-icon>
          <v-icon
            title="Reset filters"
            @click="reset"
          >
            mdi-reload
          </v-icon>
          <v-icon
            v-show="showFilters"
            title="Hide filters"
            @click="showFilters = false"
          >
            mdi-eye-off
          </v-icon>
        </div>
        <div
          layout="row"
          class="mb-2"
        >
          <v-text-field
            v-model="keywords"
            prepend-inner-icon="mdi-magnify"
            placeholder="Keywords: organization, contact..."
            outline
            single-line
            hide-details
          />
        </div>
        <div
          layout="row"
          class="mb-2"
        >
          <v-select
            v-model="params.application"
            v-focus
            :items="applications"
            hide-details
            single-line
            label="Application"
            class="pt-0 mb-2 mx-2"
            @input="
              params.session_type = [];
              fetch();
            "
          />
        </div>
        <v-expansion-panel
          v-model="expandStatus"
          popout
          expand
          style="width: 280px"
        >
          <v-expansion-panel-content>
            <div slot="header">
              Status
            </div>
            <div
              v-for="s in status"
              :key="`status_${s.value}`"
              layout="row"
              layout-align="start center"
              class="px-3 pb-3"
            >
              <v-avatar
                :title="s.text"
                :color="sessionStatusColor(s.value)"
                :size="32"
                class="black--text font-weight-medium"
              >
                {{ sessionStatusStr(s.value) }}
              </v-avatar>
              <v-checkbox
                v-model="params.status[s.value]"
                hide-details
                :label="s.text"
                class="my-0"
                @change="fetch"
              />
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          v-model="expandTypes"
          popout
          expand
          style="width: 280px"
        >
          <v-expansion-panel-content>
            <div slot="header">
              Session type
            </div>
            <div
              v-for="s in sessionTypes"
              :key="`session_type_${s.value}`"
              layout="row"
              layout-align="start center"
              class="px-3 pb-3"
            >
              <v-checkbox
                v-model="params.session_type[s.value]"
                hide-details
                class="my-0 pt-0"
                style="flex: unset"
                @change="fetch"
              />
              <v-chip
                :color="sessionTypeColor(s.value)"
                class="lighten-3"
                small
              >
                {{ sessionTypeStr(s.value) }}
              </v-chip>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          v-model="expandTargetGroups"
          popout
          expand
          style="width: 280px"
        >
          <v-expansion-panel-content>
            <div slot="header">
              Target group
            </div>
            <div class="px-3 pb-3">
              <v-checkbox
                v-for="tg in targetGroups"
                :key="`tg_${tg.id}`"
                v-model="params.target_group_id"
                hide-details
                class="my-0 pt-0"
                :value="tg.id"
                style="flex: unset"
                :label="tg.name"
                @change="fetch"
              />
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          popout
          expand
          style="width: 280px"
        >
          <v-expansion-panel-content>
            <div slot="header">
              Period types
            </div>
            <div class="px-3 pb-3">
              <span
                v-for="p in periodsTypes"
                :key="'period_' + p.id"
                class="ml-2"
              >
                <v-avatar
                  small
                  :size="20"
                  :color="p.color"
                />&nbsp;{{
                  p.name
                }}
              </span>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          v-model="expandLangs"
          popout
          expand
          style="width: 280px"
        >
          <v-expansion-panel-content>
            <div slot="header">
              Languages
            </div>
            <div class="px-3 pb-3">
              <v-checkbox
                v-for="l in langs"
                :key="`lang_${l.id}`"
                v-model="params.langs"
                hide-details
                :label="l.name"
                :value="l.id"
                @change="fetch"
              />
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          v-model="expandCenters"
          popout
          expand
          style="width: 280px"
        >
          <v-expansion-panel-content>
            <div slot="header">
              Center
            </div>
            <div class="px-3 pb-3">
              <v-checkbox
                v-for="c in centers"
                :key="`center_${c.id}`"
                v-model="params.center_id"
                hide-details
                class="my-0 pt-0"
                :value="c.id"
                style="flex: unset"
                :label="c.name"
                @change="fetch"
              />
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </div>
    </div>
    <v-menu
      v-model="eventMenu"
      lazy
      :close-on-content-click="false"
      :close-on-click="false"
      offset-y
      offset-x
      :position-x="popup.x"
      :position-y="popup.y"
      absolute
      bottom
      right
      z-index="20"
    >
      <event-popup
        :read-only="readOnly"
        :edit="editSession"
        :calendar-event="displayedSession"
        @close="onClose"
      />
    </v-menu>
  </div>
</template>

<script>
import moment from "moment";
import Sessions from "@/api/sessions";
import Users from "@/api/users";
import Params from "@/api/params";
import DateMixin from "@/mixins/date";
import SessionsMixin from "@/mixins/sessions";
import Consts from "@/consts";
import Auth from "@/plugins/auth";
import EventPopup from "@/components/sessions/planning/Event";
import CommentIcon from "@/components/shared/CommentIcon";
import SpecialTrainingIcon from "@/components/shared/SpecialTrainingIcon";
import Isolangs from "@/api/isolangs";
import GlobalMixin from "@/mixins/global";

export default {
  components: {
    EventPopup,
    CommentIcon,
    SpecialTrainingIcon
  },
  mixins: [DateMixin, SessionsMixin, GlobalMixin],
  beforeRouteEnter (to, from, next) {
    if(window.outerWidth < 960){
      next({name: 'planning-mobile'})
    } else {
      next()
    }
  },
  data() {
    return {
      storeKey: "dayspanState",
      readOnly: true,
      sessions: [],
      loading: false,
      isTrainer: false,
      isCandidate: false,
      isCoord: false,
      isAdmin: false,
      params: {
        start: null,
        end: null,
        trainers: [],
        status: [],
        session_type: [],
        langs: [],
        target_group_id: [],
        center_id: [],
        application: '',
        reference: null,
        date: moment().format("YYYY-MM-DD")
      },
      langs: [],
      teams: [],
      targetGroups: [],
      centers: [],
      keywords: null,
      init: false,
      showFilters: true,
      storeKey2: "beesecurePlanningMode",
      storeKey3: "beesecurePlanningParams",
      expandStatus: [],
      expandTrainers: [],
      expandLangs: [],
      expandTargetGroups: [],
      expandCenters: [],
      expandTypes: [],
      trainers: [],
      icsModal: false,
      icsToken: null,
      moment,
      Consts,
      periodsTypes: [],
      mode: "month",
      periods: [],
      events: [],
      modes: [
        { text: "Month", value: "month" },
        { text: "Week", value: "week" }
      ],
      eventMenu: false,
      displayedSession: {},
      popup: {}
    };
  },
  computed: {
    sessionTypes() {
      const bs = [
        { value: Consts.EVENTS, text: this.sessionTypeStr(Consts.EVENTS) },
        { value: Consts.ADULTS, text: this.sessionTypeStr(Consts.ADULTS) },
        { value: Consts.SCHOOL, text: this.sessionTypeStr(Consts.SCHOOL) },
        { value: Consts.TEACHERS, text: this.sessionTypeStr(Consts.TEACHERS) },
        { value: Consts.YOUTH, text: this.sessionTypeStr(Consts.YOUTH) },
        { value: Consts.INTERNAL, text: this.sessionTypeStr(Consts.INTERNAL) },
        { value: Consts.OTHER, text: this.sessionTypeStr(Consts.OTHER) }
      ];

      const fl = [
        {
          value: Consts.FREELANCES_TRAINING,
          text: this.sessionTypeStr(Consts.FREELANCES_TRAINING)
        },
        { value: Consts.ACTIVITY, text: this.sessionTypeStr(Consts.ACTIVITY) }
      ];

      switch (this.params.application) {
        case Consts.APPLICATION.BEESECURE:
          return bs;
        case Consts.APPLICATION.FREELANCES:
          return fl;
        case Consts.APPLICATION.ALL:
        default:
          return bs.concat(fl);
      }
    },
    status() {
      let out = [
        { value: Consts.CANCELLED, text: "Cancelled" },
        { value: Consts.REQUEST, text: "Request" },
        { value: Consts.RELEASE, text: "Release" },
        { value: Consts.TOVALIDATE, text: "To validate" },
        { value: Consts.BLOCKED, text: "Blocked" },
        { value: Consts.TOEVALUATE, text: "To evaluate" },
        { value: Consts.CLOSED, text: "Closed" }
      ];
      if (this.isCoord || this.isAdmin) {
        out = [
          ...[
            { value: Consts.ONHOLD, text: "On hold" },
            { value: Consts.DRAFT, text: "Draft" }
          ],
          ...out
        ];
      }
      return out;
    },
    filtersArray() {
      let params = Object.assign(
        {},
        this.params,
        { keywords: this.keywords },
        { planning: true }
      );

      params.status = [];
      params.session_type = [];

      for (let i in this.params.status) {
        const enabled = this.params.status[i];

        if (enabled) {
          params.status.push(i);
        }
      }

      for (let i in this.params.session_type) {
        const enabled = this.params.session_type[i];

        if (enabled) {
          params.session_type.push(i);
        }
      }

      return params;
    },
    icsUrl() {
      if (this.icsToken) {
        return window.location.origin + "/published/ics/" + this.icsToken;
      }
      return null;
    }
  },
  watch: {
    keywords(val) {
      if (val && this.isInt(val)) {
        this.params.reference = val;
      } else if (val && val.length > 2) {
        if (this.params.reference) {
          this.params.reference = null;
        } else {
          this.fetch();
        }
      } else if (val == "") {
        if (this.params.reference) {
          this.params.reference = null;
        } else {
          this.fetch();
        }
      }
    },
    icsModal(val) {
      if (val && !this.icsToken) {
        this.fetchIcsToken();
      }
    }
  },
  async mounted() {
    this.params.application = this.getStoredApp()
    this.fetchParams();
    this.fetch();
    await Auth.refresh();
    this.loadUser();
  },
  methods: {
    setIntern(event){
      if (
        event.data.is_blocked
      ) {
        return;
      }

      this.loading = true
      this.participate(event.data.session, () => {
        this.fetch()
        this.loading = false
      })
    },
    setPresence(event) {
      if (
        event.data.is_blocked ||
        event.data.status.val == Consts.BLOCKED ||
        event.data.status.val == Consts.TOEVALUATE
      ) {
        return;
      }

      this.loading = true;

      event.data.is_trainer = !event.data.is_trainer;
      let s = event.data.session;
      s.presences = {};

      if (s.dates) {
        for (let k = 0; k < s.dates.length; k++) {
          s.presences[s.dates[k].id] = {
            start: s.dates[k].start,
            end: s.dates[k].end,
            is: false,
            is_blocked: false
          };
        }
      }

      if (s.trainers_presences && s.trainers_presences.length) {
        let is = s.trainers_presences
          .map(function(e) {
            return e.user_id;
          })
          .indexOf(Auth.user.id);

        if (
          is > -1 &&
          s.trainers_presences[is].presences &&
          s.trainers_presences[is].presences.length
        ) {
          for (let l = 0; l < s.trainers_presences[is].presences.length; l++) {
            // La date pourrait avoir été supprimée
            if (
              s.presences[s.trainers_presences[is].presences[l].session_date_id]
            ) {
              s.presences[
                s.trainers_presences[is].presences[l].session_date_id
              ].is = true;
              s.presences[
                s.trainers_presences[is].presences[l].session_date_id
              ].is_blocked = s.trainers_presences[is].presences[l].is_blocked
                ? true
                : false;
            }
          }
        }
      }

      let pres = {};

      for (let i in s.presences) {
        if (i == event.data.date_id) {
          s.presences[i].is = !s.presences[i].is;
        }
        if (s.presences[i].is == true) {
          pres[i] = true;
        }
      }

      Sessions.updatePresences(s.id, { presences: pres }).then(
        () => {
          this.fetch();
          this.$snotify.success("The presence has been updated");
        },
        () => {
          this.loading = false;
          this.fetch();
        }
      );
    },
    onClose() {
      this.eventMenu = false;
      this.resetColors();
    },
    resetColors() {
      if (
        !this.displayedSession ||
        !this.displayedSession.data ||
        !this.displayedSession.data.session
      ) {
        return;
      }

      const sess = this.displayedSession.data.session;
      if (sess) {
        const color = this.sessionTypeColorLighten(sess.session_type, true);
        for (let i = 0; i < this.events.length; i++) {
          if (
            this.events[i].data.session &&
            (this.events[i].data.session.id == sess.id || (sess.group_id && sess.group_id == this.events[i].data.session.group_id))
          ) {
            this.events[i].data.color = color;
            this.events[i].color = color;
          }
        }
      }
    },
    changeMode() {
      this.$refs.calendar.onModeChange();
      localStorage.setItem(
        this.storeKey2,
        JSON.stringify(this.$refs.calendar.currentMode)
      );
    },
    onSessionClick(s, e) {
      this.resetColors();
      this.popup.x = e.clientX;
      this.popup.y = e.clientY;
      this.displayedSession = s;
      this.eventMenu = true;

      const sess = s.data.session;
      if (sess) {
        const color = this.sessionTypeColorLight(sess.session_type, true);
        for (let i = 0; i < this.events.length; i++) {
          if (
            this.events[i].data.session &&
            (this.events[i].data.session.id == sess.id || (sess.group_id && sess.group_id == this.events[i].data.session.group_id))
          ) {
            this.events[i].data.color = color;
            this.events[i].color = color;
          }
        }
      }
    },
    isInt(value) {
      var x;
      if (isNaN(value)) {
        return false;
      }
      x = parseFloat(value);
      return (x | 0) === x;
    },
    reset() {
      this.params = {
        start: this.params.start,
        end: this.params.end,
        date: this.params.date,
        trainers: [],
        langs: [],
        status: [],
        session_type: [],
        target_group_id: [],
        center_id: [],
        application: this.getDefaultApp(),
        reference: null
      };

      this.fetch();
    },
    fetchIcsToken() {
      Users.getIcsUri().then(res => {
        this.icsToken = res.data["ics-token"];
      });
    },
    copyURL() {
      let copyText = document.getElementById("ics-url");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");

      this.$snotify.success("The link has been copied");
    },
    editSession(id) {
      this.$router.push({ name: "sessions-show-info", params: { id: id } });
    },
    updateDates() {
      this.params = Object.assign({}, this.params, {
        start: this.$refs.calendar.start.sql,
        end: this.$refs.calendar.end.sql,
        date: this.$refs.calendar.currentDate
      });

      this.fetch();
      this.fetchPeriods();
    },
    fetch() {
      if (!this.init) {
        let json = JSON.parse(localStorage.getItem(this.storeKey2));
        if (json && json.length) {
          this.mode = json;
        } else {
          this.mode = "month";
        }

        let json2 = JSON.parse(localStorage.getItem(this.storeKey3));
        if (json2) {
          this.params = json2;
          this.params.application = this.getStoredApp();
          if (this.params.date) {
            this.date = this.params.date;
          }

          if (json2.session_type.length) {
            let et = [];
            for (let i in json2.session_type) {
              const enabled = json2.session_type[i];
              if (enabled) {
                et.push(i);
              }
            }
            if (et.length) {
              this.expandTypes = [...Array(et).keys()].map(() => true);
            }
          }
          if (json2.status.length) {
            let et = [];
            for (let i in json2.status) {
              const enabled = json2.status[i];
              if (enabled) {
                et.push(i);
              }
            }
            if (et.length) {
              this.expandStatus = [...Array(et).keys()].map(() => true);
            }
          }
          if (json2.trainers.length) {
            this.expandTrainers = [...Array(json2.trainers).keys()].map(
              () => true
            );
          }
          if (json2.langs.length) {
            this.expandLangs = [...Array(json2.langs).keys()].map(() => true);
          }
          if (json2.target_group_id.length) {
            this.expandTargetGroups = [
              ...Array(json2.target_group_id).keys()
            ].map(() => true);
          }
          if (json2.center_id.length) {
            this.expandCenters = [...Array(json2.center_id).keys()].map(
              () => true
            );
          }

          this.init = true;
          this.fetch();
          this.fetchPeriods();
        } else {
          this.init = true;

          this.$nextTick(() => {
            this.params.start = this.$refs.calendar.start.sql;
            this.params.end = this.$refs.calendar.end.sql;
            this.fetch();
            this.fetchPeriods();
          });
        }
      } else {
        this.loading = true;
        this.events = [];

        Sessions.getList(this.filtersArray).then(res => {
          for (let i = 0; i < res.data.length; i++) {
            let e = res.data[i];
            e.ref = e.data.ref;
            let m = parseInt(e.schedule.month[0]) + 1;
            if (m < 10) {
              m = "0" + m;
            }
            let d = e.schedule.dayOfMonth[0];
            if (d < 10) {
              d = "0" + d;
            }
            e.date = moment(e.schedule.year + "-" + m + "-" + d).format(
              "YYYY-MM-DD"
            );
            e.color = e.data.color;
            if (e.data.is_trainer) {
              e.border = "2px solid red";
            }

            e.startHour = parseInt(e.schedule.startHour);
            e.endHour = parseInt(e.schedule.endHour);
            e.startMinute = parseInt(e.schedule.startMinute);
            e.endMinute = parseInt(e.schedule.endMinute);

            this.events.push(e);
          }

          if(this.$refs.calendar.currentMode == 'week'){
            this.$refs.calendar.buildStyles();
          }

          this.loading = false;
          localStorage.setItem(this.storeKey3, JSON.stringify(this.params));
          this.setStoredApp(this.params.application);
        });
      }
    },
    fetchParams() {
      Params.getList("teams", { role: Consts.TRAINER }).then(res => {
        this.teams = res.data;

        let teams = res.data;
        for (let i = 0; i < teams.length; i++) {
          if (teams[i].users && teams[i].users.length > 0) {
            for (let j = 0; j < teams[i].users.length; j++) {
              let is = this.trainers
                .map(function(e) {
                  return e.user_id;
                })
                .indexOf(teams[i].users[j].id);

              if (is == -1 && teams[i].users[j].is_enabled) {
                teams[i].users[j]["fullname"] =
                  teams[i].users[j]["firstname"] +
                  " " +
                  teams[i].users[j]["lastname"];
                this.trainers.push(teams[i].users[j]);
              }
            }
          }
        }
        this.trainers = this.trainers.sort((a, b) =>
          a.firstname > b.firstname ? 1 : b.firstname > a.firstname ? -1 : 0
        );

        //Supprime les doublons dans plusieurs groupes
        var uniq = new Set(this.trainers.map(e => JSON.stringify(e)));
        var result = Array.from(uniq).map(e => JSON.parse(e));
        this.trainers = result;
      });
      Params.getList("periods-types", { is_enabled: 1 }).then(res => {
        this.periodsTypes = res.data;
      });
      Params.getList("targets-groups", { is_enabled: 1 }).then(res => {
        this.targetGroups = res.data;
      });
      Params.getList("centers", { is_enabled: 1 }).then(res => {
        this.centers = res.data;
        this.centers.unshift({ id: null, name: "None" });
      });

      Isolangs.getSpoken().then(res => {
        this.langs = res.data;
      });
    },
    fetchPeriods() {
      Params.getList("periods", {
        start: this.params.start,
        end: this.params.end
      }).then(res => {
        let periods = res.data;
        this.periods = [];
        for (let i = 0; i < periods.length; i++) {
          let p = periods[i];
          this.periods.push({
            start: p.start,
            end: p.end,
            color: p.period_type.color,
            name: p.period_type.name
          });
        }
      });
    },
    settings() {
      this.$router.push({ name: "admin-planning-periodtypes-index" });
    },
    loadUser() {
      if (Auth.user.is_admin) {
        this.isAdmin = true;
        this.readOnly = false;
      }
      if (Auth.user.roles.indexOf(Consts.COORDINATION) > -1) {
        this.isCoord = true;
        this.readOnly = false;
      }
      if (Auth.user.roles.indexOf(Consts.TRAINER) > -1) {
        this.isTrainer = true;
        if (Auth.user.is_candidate) {
          this.isCandidate = true;
        }
      }
      if (this.isTrainer && !(this.isAdmin || this.isCoord)) {
        this.params.status = {
          2: true,
          3: true,
          4: true,
          5: true,
          6: true
        };
      }
    }
  }
};
</script>

<style scoped>
.presence-toggle {
  flex: unset !important;
  padding: 0 !important;
  margin: 0 !important;
}
.calendar {
  background: white;
}
.v-btn--flat,
.v-text-field--solo .v-input__slot {
  background-color: #f5f5f5 !important;
  margin-bottom: 8px !important;
}

#dayspan {
  margin-top: 0;
}
.ds-day .ds-dom.ds-today-dom {
  background-color: #f4b944;
}
/deep/ .week-container .booked:hover {
  z-index: 10;
}
</style>
<style>
.info-icon > div {
  background-color: white;
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
  min-width: 20px !important;
  min-height: 20px !important;
  max-width: 20px !important;
  max-height: 20px !important;
  margin-left: 4px !important;
}
.presence-toggle
  > .v-input__control
  > .v-input__slot
  > .v-input--selection-controls__input {
  margin-right: 0 !important;
}
.dm-tile > .is-today {
  background-color: #f4b944 !important;
}
.period-title {
  font-size: 20px !important;
  font-weight: 500;
}
.hide {
  display: none;
}
.v-toolbar--absolute,
.v-toolbar--fixed {
  top: unset;
  position: relative;
}

.ds-calendar-event-menu > .v-menu__activator > .ds-calendar-event {
  padding-left: 0;
}

.ds-week > .ds-day > .ds-calendar-event,
.ds-calendar-event > .v-menu__activator > .ds-calendar-event-span {
  padding-left: 0;
}

.v-navigation-drawer[data-booted="true"] {
  width: 0;
  display: none;
}

.v-toolbar__content > :first-child,
.v-toolbar__extension > :first-child {
  width: 0;
  display: none;
}

.v-content[data-booted="true"] {
  padding: 0 0 0 0;
}

.container.fill-height
  > .theme--light.v-btn:not(.v-btn--icon):not(.v-btn--flat) {
  display: none;
}

.ds-week-view
  > .ds-week-view-container
  > .ds-week-header
  > .ds-day-today
  > .ds-week-date,
.ds-week-view
  > .ds-week-view-container
  > .ds-week-header
  > .ds-day-today
  > .ds-week-weekday {
  color: #f4b944;
}

.ds-day .ds-today > * > .ds-dom.ds-today-dom {
  background-color: #f4b944;
}

.ds-calendar-event .v-menu__activator {
  height: 100%;
}
.badge-draft {
  display: inline-block;
  height: 14px;
  width: 14px;
  background: repeating-linear-gradient(
    135deg,
    #fff7d1,
    #fff7d1 4px,
    #ffee96 4px,
    #ffee96 8px
  );
}
.ds-calendar-event-menu > .v-menu__activator > .ds-calendar-event {
  position: relative;
  width: 100%;
}
.ds-week > .ds-day > .ds-calendar-event-menu {
  height: auto;
}
</style>
