<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    min
  >
    <v-card>
      <v-card-title
        class="pb-0"
        flex
      >
        <v-toolbar-title>{{ fullName }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-tabs
        ref="tabs"
        v-model="tabActive"
        class="mx-3"
        flex
        slider-color="#f4b944"
      >
        <v-tab style="width: 0" />

        <v-tab
          v-if="isTrainer"
          ripple
        >
          Personal Information
        </v-tab>

        <v-tab
          v-if="isTrainer"
          ripple
        >
          Skills
        </v-tab>

        <v-tab ripple>
          Update your password
        </v-tab>
      </v-tabs>
      <v-tabs-items
        v-model="tabActive"
        class="px-3"
      >
        <v-tab-item />

        <v-tab-item
          v-if="isTrainer"
          class="pt-3"
        >
          <v-card-text class="mx-0 px-0">
            <v-form
              ref="informationForm"
              layout="column"
              layout-align="center center"
            >
              <v-text-field
                v-model="userInfoFormData.firstname"
                label="First name *"
                :rules="[ v => !!v || 'This field is required' ]"
                outline
                class="full-width"
              />
              <v-text-field
                v-model="userInfoFormData.lastname"
                label="Last name *"
                :rules="[ v => !!v || 'This field is required' ]"
                outline
                class="full-width"
              />
              <v-text-field
                v-model="userInfoFormData.email"
                label="E-mail"
                outline
                class="full-width"
              />
              <v-text-field
                v-model="userInfoFormData.phone"
                label="Phone number"
                outline
                class="full-width"
              />
              <v-checkbox
                v-model="userInfoFormData.daily_recap"
                label="Receive mails in daily recap mode"
                :true-value="1"
                :false-value="0"
                class="full-width"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <span flex />
            <v-btn
              depressed
              color="primary"
              @click="saveInfo"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-tab-item>

        <v-tab-item
          v-if="isTrainer"
          class="pb-4"
        >
          <v-data-table
            :headers="[
              { text: 'Status', value: 'is_enabled', sortable: false, width: 75 },
              { text: 'Concept', value: 'topic.name' },
              { text: 'Role', value: 'role' },
              { text: 'Former', value: 'is_former' },
              { text: 'Seasons', value: 'seasons', sortable: false },
            ]"
            :items="skills"
            hide-actions
            :pagination.sync="pagination"
          >
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td>
                <v-icon
                  :color="getSkillColor(item)"
                  :title="getSkillTitle(item)"
                >
                  mdi-circle-outline
                </v-icon>
              </td>
              <td>{{ item.topic.name }}</td>
              <td>{{ getSkillRoleLabel(item.role) }}</td>
              <td>
                <v-icon
                  v-if="item.is_former"
                  color="success"
                >
                  mdi mdi-check
                </v-icon>
                <v-icon
                  v-else
                  color="error"
                >
                  mdi mdi-close
                </v-icon>
              </td>
              <td>
                <div
                  v-for="s in item.seasons"
                  :key="item.id + s.id"
                  layout="row"
                >
                  {{ s.center.name + ' (' + formatDate(s.start) + ' - ' + formatDate(s.end) + ')' }}
                </div>
              </td>
            </template>
          </v-data-table>
        </v-tab-item>

        <v-tab-item class="pt-3">
          <v-card-text class="mx-0 px-0">
            <v-form
              ref="passwordForm"
              layout="column"
              layout-align="center center"
            >
              <v-text-field
                v-model="userPasswordFormData.current_password"
                label="Current password *"
                type="password"
                :rules="[v => !!v || 'This field is required']"
                outline
                class="full-width"
              />

              <v-text-field
                v-model="userPasswordFormData.new_password"
                label="New password *"
                type="password"
                :rules="[v => !!v || 'This field is required']"
                outline
                class="full-width"
              />

              <v-text-field
                v-model="userPasswordFormData.conf_password"
                label="Confirm the password *"
                type="password"
                :rules="confRules"
                outline
                class="full-width"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <span flex />
            <v-btn
              depressed
              color="primary"
              @click="savePassword"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import Auth from "@/plugins/auth";
import Consts from "@/consts";
import Params from '@/api/params'
import TeamsMixin from '@/mixins/teams'
import DateMixin from '@/mixins/date'
import Users from "@/api/users"
import moment from 'moment'

export default {
  mixins: [TeamsMixin, DateMixin],
  data () {
    return {
      dialog: false,
      tabActive: 0,
      skills: [],
      pagination: {},
      userInfoFormData: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        daily_recap: 1,
      },
      userPasswordFormData: {
        current_password: "",
        new_password: "",
        conf_password: "",
      },
    };
  },
  computed: {
    fullName () {
      return Auth.user.firstname + " " + Auth.user.lastname;
    },
    confRules () {
      return [
        v => !!v || "This field is required",
        v =>
          (v && v === this.userPasswordFormData.new_password) ||
          "Confirmation does not match the input"
      ];
    },
    isTrainer () {
      return Auth.user.roles.indexOf(Consts.TRAINER) > -1
    }
  },
  methods: {
    open () {
      this.fetchSkills()
      this.dialog = true;
      this.$nextTick(() => {
        this.tabActive = 1
        this.userInfoFormData = {
          firstname: Auth.user.firstname,
          lastname: Auth.user.lastname,
          email: Auth.user.email,
          phone: Auth.user.phone,
          daily_recap: Auth.user.daily_recap,
        }
        this.userPasswordFormData = {
          current_password: "",
          new_password: "",
          conf_password: ""
        }
        this.$refs.passwordForm.resetValidation()
      })
    },
    fetchSkills () {
      Params.getList(`users/${Auth.user.id}/skills`).then(res => {
        this.skills = res.data;
      })
    },
    getSkillColor(s){
      if(!s.is_enabled){
        return 'error'
      }
      let found = false
      if(s.seasons && s.seasons.length){
        s.seasons.forEach(us => {
          if(moment(us.end).isSameOrAfter(moment()) && moment(us.start).isSameOrBefore(moment())){
            found = true
          }
        })
      }
      if(found){
        return 'success'
      }

      return 'warning'
    },
    getSkillTitle(s){
      if(!s.is_enabled){
        return 'Disabled'
      }
      let found = false
      if(s.seasons && s.seasons.length){
        s.seasons.forEach(us => {
          if(moment(us.end).isSameOrAfter(moment()) && moment(us.start).isSameOrBefore(moment())){
            found = true
          }
        })
      }
      if(found){
        return 'Enabled'
      }

      return 'Enabled but no active season'
    },
    getNextSeasons(s){
      const arr = []

      if(s.seasons && s.seasons.length){
        s.seasons.forEach(us => {
          if(moment(us.end).isSameOrAfter(moment())){
            arr.push(us)
          }
        })
      }

      return arr
    },
    savePassword () {
      if (!this.$refs.passwordForm.validate()) {
        this.$snotify.error("Please verify the required fields");
        return;
      }

      Users.updatePassword(this.userPasswordFormData).then(() => {
        this.$snotify.success("Password updated");
        this.dialog = false;
      });
    },
    saveInfo () {
      if (!this.$refs.informationForm.validate()) {
        this.$snotify.error("Please verify the required fields");
        return;
      }
      Users.updatePersonalInfo(this.userInfoFormData).then(() => {
        this.$nextTick(() => {
          Auth.user = Object.assign(Auth.user, this.userInfoFormData)
          localStorage.setItem("user", JSON.stringify(Auth.user))
        })
        this.$snotify.success(`Your information has been updated`)
      })
    }
  },
};
</script>