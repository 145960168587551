<template>
  <div class="ma-3">
    <div class="title mb-3">
      Settings
      <v-icon>mdi-chevron-right</v-icon>
      Users
    </div>

    <div
      layout="row"
      layout-align="start start"
      class="mb-3 no-printable"
      style="background-color: #fff"
    >
      <v-tabs
        class="ml-3 grey"
        flex
      >
        <v-tabs-slider color="#f4b944" />
        <v-tab
          :to="{name: 'admin-settings-users-list'}"
          router-link
        >
          Users list
        </v-tab>
        <v-tab
          :to="{name: 'admin-settings-users-contracts'}"
          router-link
        >
          Contracts
        </v-tab>
        <v-tab
          :to="{name: 'admin-settings-users-profiles-matrix'}"
          router-link
        >
          Profiles Matrix
        </v-tab>
      </v-tabs>
    </div>

    <router-view />
  </div>
</template>

<script>
import Auth from '@/plugins/auth'

export default {
  data () {
    return {
      Auth
    }
  }
}
</script>