export default {
    methods: {
        _convertObjectToFormData(FormData, data, name) {
            name = name || '';
            if (typeof data === 'object') {
                for (let index in data) {
                    const value = data[index];

                    if (name === '') {
                        this._convertObjectToFormData(FormData, value, index);
                    } else {
                        this._convertObjectToFormData(FormData, value, name + '[' + index + ']');
                    }
                }
            } else {
                FormData.append(name, data);
            }
        },

        _filterTimeFromDates(obj, keys) {
            for (let i = 0; i < keys.length; ++i) {
                const key = keys[i];
                if (obj[key] === undefined) {
                    continue;
                }

                obj[key] = obj[key].substring(0, obj[key].indexOf(' '));
            }
        }
    }
}