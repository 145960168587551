<template>
  <div
    layout="row"
    layout-align="center center"
    flex
  >
    <v-card
      layout="column"
      layout-align="center center"
      class="login-card"
    >
      <v-card-text
        layout="column"
        class="text-xs-center"
      >
        <div>
          <img
            src="@/assets/logo.png"
            height="80"
          >
        </div>
        <div class="title font-weight-regular my-4">
          Confirm email account
        </div>
        <div
          v-if="loading"
          layout="row"
          class="ma-5"
          layout-align="center center"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            size="64"
          />
        </div>
        <template v-else-if="user.id">
          Your email ({{ user.email }}) has been confirmed.<br>
          Our team will validate your account as soon as possible
        </template>
        <template v-else>
          An error occured. Please contact your team.
        </template>
      </v-card-text>
      <v-card-actions
        layout="row"
        layout-align="end center"
        class="mx-5 mb-3"
      >
        <router-link
          href="#"
          class="grey--text mr-3"
          :to="{ name: 'login' }"
        >
          Back to login page
        </router-link>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";

export default {
  beforeRouteEnter(to, _, next) {
    next(vm => {
      vm.checkToken(to.params.token);
    });
  },
  data() {
    return {
      loading: true,
      user: {}
    };
  },
  methods: {
    checkToken(token) {
      axios
        .post(`/users/confirm`, { token: token })
        .then(res => {
          // eslint-disable-next-line
          this.user = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>
.login-card {
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 960px) {
  .login-card {
    width: 800px;
    height: unset;
  }
}
</style>
