import Consts from "@/consts";
import Auth from "@/plugins/auth";

export default {
  data() {
    return {
      appStoredKey: "application",
      isTrainer: false,
      isCandidate: false,
      isCoord: false,
      isAdmin: false,
    };
  },
  computed: {
    // Pour les forms
    apps() {
      const arr = this.applications;
      arr.shift();
      return arr;
    },
    // Pour les filtres
    applications() {
      return [
        { value: Consts.APPLICATION.ALL, text: "All" },
        { value: Consts.APPLICATION.BEESECURE, text: "BEE SECURE" },
        { value: Consts.APPLICATION.FREELANCES, text: "FREELANCES" }
      ];
    },
    isFreelances() {
      return (
        Auth.user.application == Consts.APPLICATION.ALL ||
        Auth.user.application == Consts.APPLICATION.FREELANCES
      );
    },
    isBeesecure() {
      return (
        Auth.user.application == Consts.APPLICATION.ALL ||
        Auth.user.application == Consts.APPLICATION.BEESECURE
      );
    }
  },
  methods: {
    isAppCoord(o) {
      return (
        Auth.user.roles.indexOf(Consts.COORDINATION) > -1 &&
        (Auth.user.application == Consts.APPLICATION.ALL ||
          o.application == Auth.user.application)
      );
    },
    getDefaultApp() {
      if (Auth.user.application) {
        return Auth.user.application;
      }

      return Consts.APPLICATION.ALL;
    },
    setStoredApp (v) {
      localStorage.setItem(this.appStoredKey, JSON.stringify(v));
    },
    getStoredApp () {
      const json = JSON.parse(localStorage.getItem(this.appStoredKey));

      if (json || json == Consts.APPLICATION.ALL) {
        return json;
      }

      this.setStoredApp(this.getDefaultApp());
      return this.getDefaultApp();
    },
    isFreelancesObj(o) {
      return o.application == Consts.APPLICATION.FREELANCES;
    },
    loadUser() {
      if (Auth.user.is_admin) {
        this.isAdmin = true;
      }
      if (Auth.user.roles.indexOf(Consts.COORDINATION) > -1) {
        this.isCoord = true;
      }
      if (Auth.user.roles.indexOf(Consts.TRAINER) > -1) {
        this.isTrainer = true;
        if (Auth.user.is_candidate) {
          this.isCandidate = true;
        }
      }
    }
  }
};
