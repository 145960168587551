<template>
  <session-form
    v-if="isAdmin || isAppCoord(session)"
    :value="session"
    @save="onSessionSave"
  />
  <div v-else>
    <div
      v-if="!isTraining"
      layout-gt-md="row"
      layout-align="start stretch"
      layout-wrap
      class="sheet-line"
    >
      <dates
        layout="column"
        flex="25"
        class="tuile ml-2 mt-2 sheet-block"
        style="order: 1"
        :session="session"
      />
      <organization
        layout="column"
        flex="25"
        class="tuile ml-2 mt-2 sheet-block"
        style="order: 2"
        :session="session"
      />
      <contacts
        v-if="!isInternal"
        layout="column"
        flex="25"
        class="tuile ml-2 mt-2 sheet-block"
        style="order: 3"
        :session="session"
      />
      <trainers
        layout="column"
        flex
        class="tuile ml-2 mt-2 sheet-block"
        style="order: 4"
        :session="session"
        :trainers="trainers"
      />
      <description
        layout="column"
        style="flex: 0 0 calc(50% + 7px); order: 5"
        class="large-tuile ml-2 mt-2 sheet-line"
        :session="session"
      />
      <comment
        layout="column"
        flex="25"
        class="large-tuile ml-2 mt-2 sheet-line"
        style="order: 5"
        :session="session"
      />
      <div
        layout="column"
        flex
        class="team-line mt-2"
        style="order: 6"
      >
        <teams
          v-if="isEvent"
          layout="row"
          class="tuile ml-2 mb-2 sheet-block"
          :session="session"
          :teams="teams"
        />
        <visitors
          v-if="session.session_type !== Consts.EVENTS && session.session_type !== Consts.OTHER"
          layout="row"
          class="tuile ml-2 mb-2 sheet-block"
          :session="session"
        />
        <todos
          layout="row"
          class="tuile ml-2 sheet-block"
          :session="session"
          :all-users="allUsers"
        />
      </div>
    </div>
    <div
      v-else
      layout="row"
    >
      <div
        layout="column"
        flex="50"
      >
        <div layout="row">
          <dates
            layout="column"
            flex="50"
            class="tuile ml-2 mt-2 sheet-block"
            :session="session"
          />
          <trainers
            layout="column"
            flex
            class="tuile ml-2 mt-2 sheet-block"
            :session="session"
            :trainers="trainers"
          />
        </div>
        <div layout="row">
          <description
            layout="column"
            flex
            class="large-tuile ml-2 mt-2 sheet-line"
            :session="session"
          />
        </div>
      </div>
      <div
        layout="column"
        flex
      >
        <div layout="row">
          <comment
            layout="column"
            flex="50"
            class="largest-tuile ml-2 mt-2 sheet-line"
            :session="session"
          />
          <div
            layout="column"
            flex
            class="team-line mt-2"
          >
            <visitors
              layout="row"
              class="tuile ml-2 mb-2 sheet-block"
              :session="session"
            />
            <todos
              layout="row"
              class="large-tuile ml-2 mb-2 sheet-block"
              :session="session"
              :all-users="allUsers"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Params from '@/api/params'
import dateMixin from '@/mixins/date'
import GlobalMixin from '@/mixins/global'
import SessionsMixin from '@/mixins/sessions'
import Consts from '@/consts'
import SessionForm from "@/components/shared/SessionForm.vue"
import Dates from './Dates'
import Description from './Description'
import Comment from './Comment'
import Contacts from './Contacts'
import Trainers from './Trainers'
import Organization from './Organization'
import Todos from './Todos'
import Visitors from './Visitors'
import Teams from './Teams'

export default {
  components: { SessionForm, Dates, Description, Contacts, Organization, Trainers, Comment, Todos, Visitors, Teams },
  mixins: [dateMixin, GlobalMixin, SessionsMixin],
  props: ['session'],
  data () {
    return {
      list: [],
      trainers: [],
      users: [],
      teams: [],
      allUsers: [],
      isEvent: false,
      Consts
    }
  },
  computed: {
    isInternal () {
      if (parseInt(this.session.session_type) == Consts.INTERNAL) {
        return true
      }
      return false
    },
    isTraining() {
      if (parseInt(this.session.session_type) == Consts.FREELANCES_TRAINING) {
        return true;
      }
      return false;
    }
  },
  mounted () {
    this.loadUser()
    if (!this.isAdmin && !this.isCoord) {
      this.fetchParams()
      this.loadTrainersPresences()
      let arr = []
      for (let i = 0; i < this.session.users_teams.length; i++) {
        arr.push(this.session.users_teams[i].user_id)
      }
      this.session.users_teams = arr

      if (this.session.session_type == Consts.EVENTS) {
        this.isEvent = true
      }
    }
  },
  methods: {
    fetchParams () {
      Params.getList('teams', { role: Consts.TRAINER }).then(res => {
        let teams = res.data
        for (let i = 0; i < teams.length; i++) {
          if (teams[i].users && teams[i].users.length > 0) {
            for (let j = 0; j < teams[i].users.length; j++) {
              let is = this.trainers.map(function (e) { return e.user_id }).indexOf(teams[i].users[j].id)

              if (is == -1) {
                teams[i].users[j]['fullname'] = teams[i].users[j]['firstname'] + " " + teams[i].users[j]['lastname']
                this.users.push(teams[i].users[j])
              }
            }
          }
        }
        this.users = this.users.sort((a, b) => (a.firstname > b.firstname) ? 1 : ((b.firstname > a.firstname) ? -1 : 0))
      })
      Params.getList('teams', { is_enabled: 1, is_event: 1 }).then(res => {
        this.teams = res.data
      })
      Params.getList('users', { is_enabled: 1 }).then(res => {
        this.allUsers = res.data
        for (let i = 0; i < this.allUsers.length; i++) {
          this.allUsers[i]['fullname'] = this.allUsers[i].firstname + " " + this.allUsers[i].lastname
        }
      })
    },
    loadTrainersPresences () {
      if (this.session.dates && this.session.dates.length > 0) {
        if (this.session.trainers_presences && this.session.trainers_presences.length > 0) {
          for (let i = 0; i < this.session.trainers_presences.length; i++) {
            this.list[this.session.trainers_presences[i].user_id] = {
              user_id: this.session.trainers_presences[i].user_id,
              firstname: this.session.trainers_presences[i].user.firstname,
              lastname: this.session.trainers_presences[i].user.lastname,
              dates: []
            }
            for (let j = 0; j < this.session.dates.length; j++) {
              let is = this.session.trainers_presences[i].presences.map(function (e) { return e.session_date_id }).indexOf(this.session.dates[j].id)
              if (is > -1) {
                this.list[this.session.trainers_presences[i].user_id].dates.push({
                  date_id: this.session.dates[j].id,
                  start: this.session.dates[j].start,
                  en: this.session.dates[j].end,
                  is: true,
                  is_blocked: this.session.trainers_presences[i].presences[is].is_blocked
                })
              }
              else {
                this.list[this.session.trainers_presences[i].user_id].dates.push({
                  date_id: this.session.dates[j].id,
                  start: this.session.dates[j].start,
                  en: this.session.dates[j].end,
                  is: false,
                  is_blocked: false
                })
              }
            }
          }

          for (let i in this.list) {
            this.trainers.push(this.list[i])
          }
        }
      }
    },
    onSessionSave () {
      this.$emit('save')
    }
  }
}
</script>

<style scoped>
.tuile {
  background-color: white;
  min-height: 280px;
  overflow-y: scroll;
}
.large-tuile {
  background-color: white;
  min-height: 568px;
}
.largest-tuile {
  background-color: white;
  min-height: 840px;
  max-height: calc(100% - 16px);
}

@media print {
  .sheet-line {
    display: block;
    width: 100%;
  }
  .sheet-block {
    display: inline-block;
    width: 48%;
  }
  .team-line {
    display: block;
    max-width: none;
  }
  [layout="row"] > [flex="25"] {
    max-width: none;
  }
  .large-tuile {
    min-height: 280px;
  }
}
</style>
<style>
.tuile > [layout="column"],
.tuile > [layout="row"],
.tuile > [layout-gt-md="row"],
.large-tuile > [layout="column"],
.large-tuile > [layout="row"],
.large-tuile > [layout-gt-md="row"] {
  padding-left: 15px;
  padding-right: 15px;
}
</style>