<template>
  <v-dialog
    v-model="isVisible"
    persistent
    width="1000"
  >
    <v-card>
      <v-card-text>
        <v-form ref="joinForm">
          <p class="title">
            {{ isEdit ? 'Edit my presence for the session' : 'Join the session' }} #{{ session.reference }}
          </p>
          <v-checkbox
            v-for="(p,i) in presences"
            :key="'pres_'+i"
            v-model="p.is"
            :label="getDates(p.start, p.end)"
            :disabled="p.is_blocked"
          />
        </v-form>
      </v-card-text>

      <v-card-actions
        layout="row"
        layout-align="end center"
      >
        <v-btn
          flat
          @click.prevent="cancel"
        >
          Cancel
        </v-btn>
        <v-btn
          flat
          color="primary"
          @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
    import Sessions from '@/api/sessions'
    import RulesMixin from '@/mixins/rules'
    import Auth from '@/plugins/auth'
    import moment from 'moment'

    export default {
        mixins: [RulesMixin],
        props: {
            value: {
                type: Object,
                required: false,
                default () { return {} }
            },
            visible: {
                type: Boolean,
                required: false,
                default: false
            },
            sessionId: {
                required: true
						},
						isEdit: {
							required: false,
							type: Boolean,
							default: false
						}
        },
        data () {
            return {
                loading: false,
								session: {},
								params: {
									presences: {}
								}
            }
        },
        computed: {
            presences: {
                get () {
                    return this.value;
                },
                set (x) {
                    this.$emit('input', x);
                }
            },
            isVisible: {
                get () {
                    return this.visible;
                },
                set (x) {
                    this.$emit('update:visible', x);
                }
            }
        },

				watch: {
            isVisible(val){
                if(val){
									this.presences = [];
									this.fetch();
                }
            }
        },
        mounted () {

        },
        methods: {
            cancel () {
                this.$nextTick(() => {
                    this.$refs['joinForm'].reset();
                });
                this.isVisible = false
            },
            save () {
                this.loading = true;

								for(let i in this.presences){
									if(this.presences[i].is == true){
										this.params.presences[i] = true;
									}else{
										delete this.params.presences[i]
									}
								}

								let params =  Object.assign({}, this.params);
								Sessions.updatePresences(this.sessionId, params).then(res => {
										this.isVisible = false;
										this.loading = false;
										this.$emit('updated', res.data);
								}, () => {
										this.loading = false;
								})
                this.$nextTick(() => {
                    this.$refs['joinForm'].reset();
                });
            },

						fetch(){
							this.loading = true;
                Sessions.get(this.sessionId).then(res => {
                    this.session = res.data;
										if(this.session.dates){
											for(let i = 0; i < this.session.dates.length; i++){
												this.presences[this.session.dates[i].id] = {
													start: this.session.dates[i].start,
													end: this.session.dates[i].end,
													is: false,
													is_blocked: false
												}
											}
										}
										if(this.session.trainers_presences){
											let is = this.session.trainers_presences.map(function(e) { return e.user_id; }).indexOf(Auth.user.id);

											if(is > -1){
												for(let i = 0; i < this.session.trainers_presences[is].presences.length; i++){
													this.presences[this.session.trainers_presences[is].presences[i].session_date_id].is = true;
													this.presences[this.session.trainers_presences[is].presences[i].session_date_id].is_blocked = this.session.trainers_presences[is].presences[i].is_blocked ? true : false;
												}
											}
										}
                    this.loading = false;
                })
						},

						formatDate(x) {
								return moment(x).format('YYYY.MM.DD')
						},
						formatTime(x) {
								return moment(x).format('HH:mm')
						},
						formatDateHuman(date) {
								return moment(date).format('ddd, MMM DD YYYY, HH:mm');
						},

						getDates(start, end){
							if(this.formatDate(start) == this.formatDate(end)){
								return this.formatDateHuman(start) + " until " + this.formatTime(end);
							}
							else{
								return this.formatDateHuman(start) + " - " + this.formatDateHuman(end);
							}
						}
        }
    }
</script>