<template>
  <div>
    <v-subheader v-if="!isInternal">
      Organization / Location &nbsp;
      <router-link
        class="edit-icon"
        :to="{name: 'address-show-info', params: {id: session.organization_id, edit: 1}}"
      >
        <v-icon>mdi-eye</v-icon>
      </router-link>
    </v-subheader>
    <v-subheader v-else>
      Location
    </v-subheader>
    <div
      v-if="!isInternal && session.organization"
      layout="column"
      layout-align="start start"
    >
      <div class="title font-weight-bold mt-2">
        {{ session.organization.name }}
      </div>
      <div
        v-if="!session.location || session.location.length == 0"
        class="font-weight-light title mt-4"
      >
        {{ session.organization.number }} {{ session.organization.street }}<br>
        {{ session.organization.zip_code }} {{ session.organization.city }}

        <a
          :href="'https://www.google.com/maps/search/?api=1&query=' + session.organization.number + ' '+ session.organization.street + ' ' + session.organization.zip_code + ' ' + session.organization.city"
          target="_blank"
        >
          <v-icon
            class="ml-4 right"
            color="primary"
            size="32"
          >mdi-map-marker</v-icon>
        </a>
      </div>
      <div
        v-else
        class="font-weight-light title mt-4"
      >
        {{ session.location }} *
      </div>
      <div class="font-weight-light title mt-4">
        {{ session.organization.phone }}
      </div>
      <div
        v-if="session.location && session.location.length > 0"
        class="sub font-weight-light caption mt-4"
      >
        * Different of the organization address
      </div>
    </div>
    <div
      v-else
      layout="column"
      layout-align="start start"
    >
      <div class="font-weight-light title mt-4">
        {{ session.location }}
      </div>
    </div>
  </div>
</template>
<script>
import Consts from '@/consts'
export default {
	props: {
		session: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	computed: {
		isInternal () {
      if (parseInt(this.session.session_type) == Consts.INTERNAL) {
        return true
      }
      return false
    },
	},
}
</script>
<style scoped>
	.v-subheader {
		padding: 0 10px;
	}
	.edit-icon {
		text-decoration: none;
	}
	@media print {
		.edit-icon {
			display: none;
		}
	}
</style>