var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"layout":"column","layout-align":"center center"}},[_c('div',{attrs:{"layout":"column"}},[_c('div',{staticClass:"mb-2",attrs:{"layout":"row","layout-align":"start center"}},[_c('v-select',{staticClass:"mr-1",attrs:{"label":"Year","items":_vm.years,"item-value":"id","item-text":"name","solo":"","hide-details":""},on:{"change":function($event){return _vm.fetch()}},model:{value:(_vm.filters.year),callback:function ($$v) {_vm.$set(_vm.filters, "year", $$v)},expression:"filters.year"}}),_c('v-text-field',{attrs:{"solo":"","label":"Keywords","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.fetch()}},model:{value:(_vm.filters.keywords),callback:function ($$v) {_vm.$set(_vm.filters, "keywords", $$v)},expression:"filters.keywords"}}),_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.fetch()}}},[_vm._v(" Filter ")]),_vm._v(" or "),_c('a',{staticClass:"ml-2 black--text",attrs:{"href":"javascript:void(0);"},on:{"click":_vm.resetFilters}},[_vm._v("Cancel")])],1),_c('v-data-table',{attrs:{"items":_vm.contracts,"pagination":_vm.pagination},on:{"update:pagination":[function($event){_vm.pagination=$event},function($event){return _vm.storeParams('pagination')}]},scopedSlots:_vm._u([{key:"headers",fn:function({}){return [_c('tr',{staticClass:"text-xs-left"},[_c('th',{attrs:{"rowspan":"2"}},[_c('v-layout',{staticClass:"mt-4",attrs:{"align-content-center":"","justify-center":"","align-center":""}},[_c('v-checkbox',{attrs:{"intermediate":_vm.selected.length > 0 && _vm.selected.length !== _vm.contracts.length},on:{"change":_vm.checkAll},model:{value:(_vm.allCheck),callback:function ($$v) {_vm.allCheck=$$v},expression:"allCheck"}})],1)],1),_c('th',{attrs:{"rowspan":"2"}},[_vm._v(" First name ")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v(" Last name ")]),(
              _vm.Auth.user.application === _vm.Consts.APPLICATION.ALL ||
                _vm.Auth.user.application === _vm.Consts.APPLICATION.BEESECURE
            )?_c('th',{staticClass:"text-xs-center",attrs:{"colspan":"3"}},[_vm._v(" BEE SECURE ")]):_vm._e(),(
              _vm.Auth.user.application === _vm.Consts.APPLICATION.ALL ||
                _vm.Auth.user.application === _vm.Consts.APPLICATION.FREELANCES
            )?_c('th',{staticClass:"text-xs-center",attrs:{"colspan":"3"}},[_vm._v(" Freelances ")]):_vm._e()]),_c('tr',{staticClass:"text-xs-left"},[(
              _vm.Auth.user.application === _vm.Consts.APPLICATION.ALL ||
                _vm.Auth.user.application === _vm.Consts.APPLICATION.BEESECURE
            )?[_c('th',{staticStyle:{"width":"150px","white-space":"initial"}},[_vm._v(" Label ")]),_c('th',{staticStyle:{"width":"150px","white-space":"initial"}},[_vm._v(" Extract from criminal records ")]),_c('th',{staticStyle:{"width":"150px","white-space":"initial"}},[_vm._v(" Updated CV ")])]:_vm._e(),(
              _vm.Auth.user.application === _vm.Consts.APPLICATION.ALL ||
                _vm.Auth.user.application === _vm.Consts.APPLICATION.FREELANCES
            )?[_c('th',{staticStyle:{"width":"150px","white-space":"initial"}},[_vm._v(" Label ")]),_c('th',{staticStyle:{"width":"150px","white-space":"initial"}},[_vm._v(" Extract from criminal records ")]),_c('th',{staticStyle:{"width":"150px","white-space":"initial"}},[_vm._v(" Updated CV ")])]:_vm._e()],2)]}},{key:"items",fn:function({ item }){return [_c('td',[_c('v-layout',{staticClass:"pt-2",attrs:{"align-content-center":"","justify-center":"","align-center":""}},[_c('v-checkbox',{staticClass:"mb-0",attrs:{"value":item.id,"multiple":""},on:{"change":_vm.changeAllCheck},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c('td',[_c('router-link',{attrs:{"to":{
              name: 'admin-settings-users-show-contracts',
              params: { id: item.id }
            }}},[_vm._v(" "+_vm._s(item.firstname)+" ")])],1),_c('td',[_vm._v(_vm._s(item.lastname))]),(
            _vm.Auth.user.application === _vm.Consts.APPLICATION.ALL ||
              _vm.Auth.user.application === _vm.Consts.APPLICATION.BEESECURE
          )?[_c('td',{staticClass:"text-xs-center"},[_c('v-icon',{attrs:{"color":item.bs_label ? 'success' : 'error'},on:{"click":function($event){return _vm.switchDoc(item, 'bs_label')}}},[_vm._v(" mdi-circle ")])],1),_c('td',{staticClass:"text-xs-center"},[_c('v-icon',{attrs:{"color":item.bs_criminal_records ? 'success' : 'error'},on:{"click":function($event){return _vm.switchDoc(item, 'bs_criminal_records')}}},[_vm._v(" mdi-circle ")])],1),_c('td',{staticClass:"text-xs-center"},[_c('v-icon',{attrs:{"color":item.bs_resume ? 'success' : 'error'},on:{"click":function($event){return _vm.switchDoc(item, 'bs_resume')}}},[_vm._v(" mdi-circle ")])],1)]:_vm._e(),(
            _vm.Auth.user.application === _vm.Consts.APPLICATION.ALL ||
              _vm.Auth.user.application === _vm.Consts.APPLICATION.FREELANCES
          )?[_c('td',{staticClass:"text-xs-center"},[_c('v-icon',{attrs:{"color":item.fl_label ? 'success' : 'error'},on:{"click":function($event){return _vm.switchDoc(item, 'fl_label')}}},[_vm._v(" mdi-circle ")])],1),_c('td',{staticClass:"text-xs-center"},[_c('v-icon',{attrs:{"color":item.fl_criminal_records ? 'success' : 'error'},on:{"click":function($event){return _vm.switchDoc(item, 'fl_criminal_records')}}},[_vm._v(" mdi-circle ")])],1),_c('td',{staticClass:"text-xs-center"},[_c('v-icon',{attrs:{"color":item.fl_resume ? 'success' : 'error'},on:{"click":function($event){return _vm.switchDoc(item, 'fl_resume')}}},[_vm._v(" mdi-circle ")])],1)]:_vm._e()]}}])},[_c('template',{slot:"footer"},[_c('tr',{staticClass:"text-xs-left"},[_c('td',[_c('v-layout',{staticClass:"mt-4",attrs:{"align-content-center":"","justify-center":"","align-center":""}},[_c('v-checkbox',{attrs:{"intermediate":_vm.selected.length > 0 && _vm.selected.length !== _vm.contracts.length},on:{"change":_vm.checkAll},model:{value:(_vm.allCheck),callback:function ($$v) {_vm.allCheck=$$v},expression:"allCheck"}})],1)],1),_c('td',{attrs:{"colspan":2 +
                (_vm.Auth.user.application === _vm.Consts.APPLICATION.ALL
                  ? 6
                  : _vm.Auth.user.application === _vm.Consts.APPLICATION.BEESECURE ||
                    _vm.Auth.user.application === _vm.Consts.APPLICATION.FREELANCES
                    ? 3
                    : 0)}},[(
                _vm.Auth.user.application === _vm.Consts.APPLICATION.ALL ||
                  _vm.Auth.user.application === _vm.Consts.APPLICATION.BEESECURE
              )?[(_vm.selected.length > 0)?_c('a',{staticClass:"mr-2",attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.validateAll(_vm.Consts.APPLICATION.BEESECURE)}}},[_vm._v("Validate BEE SECURE contracts ("+_vm._s(_vm.selected.length)+")")]):_c('span',{staticClass:"mr-2"},[_vm._v("Validate BEE SECURE contracts ("+_vm._s(_vm.selected.length)+")")]),(_vm.Auth.user.application === _vm.Consts.APPLICATION.ALL)?_c('span',{staticClass:"mr-2"},[_vm._v("/")]):_vm._e()]:_vm._e(),(
                _vm.Auth.user.application === _vm.Consts.APPLICATION.ALL ||
                  _vm.Auth.user.application === _vm.Consts.APPLICATION.FREELANCES
              )?[(_vm.selected.length > 0)?_c('a',{staticClass:"mr-2",attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.validateAll(_vm.Consts.APPLICATION.FREELANCES)}}},[_vm._v("Validate Freelances contracts ("+_vm._s(_vm.selected.length)+")")]):_c('span',{staticClass:"mr-2"},[_vm._v("Validate Freelances contracts ("+_vm._s(_vm.selected.length)+")")]),(_vm.Auth.user.application === _vm.Consts.APPLICATION.ALL)?_c('span',{staticClass:"mr-2"},[_vm._v("/")]):_vm._e()]:_vm._e(),(_vm.Auth.user.application === _vm.Consts.APPLICATION.ALL)?[(_vm.selected.length > 0)?_c('a',{attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.validateAll(_vm.Consts.APPLICATION.ALL)}}},[_vm._v("Validate all contracts ("+_vm._s(_vm.selected.length)+")")]):_c('span',[_vm._v("Validate all contracts ("+_vm._s(_vm.selected.length)+")")])]:_vm._e()],2)])])],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }