<template>
  <v-menu
    v-if="
      item.special_training ||
        item.location ||
        (item.visitors && item.visitors.length)
    "
    open-on-hover
    :close-on-content-click="false"
    offset-y
    bottom
    lazy
  >
    <div
      slot="activator"
      layout="column"
      flex
    >
      <v-icon
        color="primary"
        :small="small"
      >
        mdi-alert
      </v-icon>
    </div>
    <v-card style="width: 400px">
      <v-card-text>Special training, visitors or other location</v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {}
    },
    small: {
      type: Boolean,
      default: false
    }
  }
};
</script>
