<template>
  <div
    :class="{'selected-folder': (active == item.id), 'opened-folder': opened, 'root-node': item.is_root, 'dropping': item.dropping}"
    class="v-treeview-node v-treeview-node--click"
  >
    <div
      class="v-treeview-node__root"
      layout="row"
      layout-align="start center"
    >
      <i
        v-if="item.children && item.children.length"
        :class="[opened ? 'v-treeview-node__toggle--open' : 'v-treeview-node__toggle']"
        class="v-icon v-treeview-node__toggle v-icon--link material-icons theme--light"
        @click="onArrowClick"
      >arrow_drop_down</i>
      <div
        v-else
        style="width: 24px; min-width: 24px"
      />
      <drag
        tag="div"
        layout="row"
        layout-align="start center"
        :transfer-data="{file: item}"
        drop-effect="move"
        effect-allowed="move"
        :class="{ 'over': (item.over && !item.path), 'zooming': (item.zooming && !item.path) }"
        class="v-treeview-node__content"
        @dragover.native="onDragOver(item)"
        @dragleave="onDragLeave(item)"
        @click.native="onContentClick"
      >
        <drop
          layout-align="start center"
          class="full-width"
          @drop="item.over = false; handleDropTree(item, ...arguments)"
        >
          <v-icon :color="(active == item.id) ? 'primary' : ''">
            {{ opened ? 'mdi-folder-open' : 'mdi-folder' }}
          </v-icon>
          <label
            class="v-treeview-node__label clickable"
            style="vertical-align: text-bottom;"
          >{{ item.name }}</label>
        </drop>
      </drag>
    </div>
    <div
      v-if="item.children"
      v-show="opened"
      class="v-treeview-node__children"
      :style="'--size: ' + (item.children.length * 34) + 'px'"
    >
      <div
        v-for="c in item.children"
        :key="'item_' + c.id"
        class="box"
      >
        <tree-item
          :item.sync="c"
          :active="active"
          :open="open"
          @openUpdated="onToggleClick"
          @activeUpdated="onFolderClick"
          @drop="handleDropTree"
          @dragleave="onDragLeave"
          @dragover="onDragOver"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { Drag, Drop } from 'vue-drag-drop'
export default {
  name: 'TreeItem',
  components: {
    Drop,
    Drag
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {}
    },
    active: {
      type: String,
      required: false,
      default: ''
    },
    open: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data () {
    return {
      from: null,
      to: null
    }
  },
  computed: {
    opened () {
      if (this.open && this.open.indexOf(this.item.id) > -1 && this.item.children && this.item.children.length) {
        return true
      }
      return false
    }
  },
  methods: {
    onDragOver (i) {
      this.$emit('dragover', i)
    },
    onDragLeave (i) {
      this.$emit('dragleave', i)
    },
    handleDropTree (to, from) {
      this.$emit('drop', to, from)
    },
    onArrowClick () {
      this.$emit('openUpdated', this.item.id)
    },
    onContentClick () {
      this.$emit('activeUpdated', this.item)
    },
    onToggleClick (item) {
      this.$emit('openUpdated', item)
    },
    onFolderClick (item) {
      this.$emit('activeUpdated', item)
    }
  }
}
</script>
<style lang="scss">
.v-treeview-node.dropping {
  display: none;
  transition: opacity 0.3s ease;
}
.over {
  border: 1px solid #aaa;
  background: #ccc;
}
.zooming {
  -webkit-transform: scale(1.25); /* Safari et Chrome */
  -moz-transform: scale(1.25); /* Firefox */
  -ms-transform: scale(1.25); /* Internet Explorer 9 */
  -o-transform: scale(1.25); /* Opera */
  transform: scale(1.25);
}
.box {
  cursor: move;
}
.v-treeview-node__children {
  position: relative;
  &::before {
    background: url("~@/assets/dot.svg") left top/2px 2px;
    content: "";
    width: 10px;
    display: block;
    position: absolute;
    top: 0;
    background-repeat: repeat-y;
    height: calc(var(--size) - 17px);
    margin-left: 11px;
  }
}
.v-treeview-node:not(.root-node) {
  position: relative;
  &::before {
    background: url("~@/assets/dot.svg") left top/2px 2px;
    content: "";
    width: 12px;
    display: block;
    position: absolute;
    top: 17px;
    left: -15px;
    background-repeat: repeat-x;
    height: calc(100% - 8px);
  }
}
.v-treeview-node__content,
.v-treeview-node__root {
  overflow: hidden;
  text-overflow: ellipsis;
}
.v-treeview-node__label {
  font-size: 13px;
}
</style>
