<template>
  <v-app layout="column">
    <vue-snotify />
    <router-view name="top" />
    <div
      layout="row"
      class="body-container"
      flex
    >
      <router-view
        name="body"
        flex
      />
    </div>
  </v-app>
</template>

<script>
import EventBus from "@/plugins/eventbus";
import BadgesMixin from "@/mixins/badges";

export default {
  name: "App",
  mixins: [BadgesMixin],
  data() {
    return {};
  },
  mounted() {
    EventBus.$on("notifyError", e => {
      this.$snotify.error(e);
    });
    this.createBadgesTimeout();
  }
};
</script>
