<template>
  <div
    v-if="loading"
    class="ma-5 text-xs-center"
  >
    <v-progress-circular
      indeterminate
      :size="32"
      color="accent"
    />
  </div>
  <div
    v-else
    layout="column"
  >
    <div
      layout="row"
      layout-align="start center"
    >
      <span class="subheading ml-2 mr-3">Sessions per trainers (status up to 'validate')</span>

      <v-checkbox
        v-model="is_enabled"
        hide-details
        prepend-icon="mdi-lock-open"
        :true-value="1"
        :false-value="null"
        label="Trainers enabled only"
        class="mt-0 pt-0"
        style="display: contents"
        @change="fetchTrainers"
      />
      <span class="mx-2" />
      <v-checkbox
        v-model="is_enabled"
        hide-details
        prepend-icon="mdi-lock"
        :true-value="0"
        :false-value="null"
        label="Trainers disabled only"
        class="mt-0 pt-0"
        style="display: contents"
        @change="fetchTrainers"
      />
    </div>
    <v-data-table
      :headers="headers"
      :items="sessions"
      style="max-width: 1000px"
      hide-actions
    >
      <template
        slot="items"
        slot-scope="{ item }"
      >
        <td>{{ item.label }}</td>
        <td>{{ item.nb_sessions }}</td>
        <td>{{ item.hours }}</td>
        <td>{{ item.hours_per_session }}</td>
      </template>
    </v-data-table>

    <div
      layout="row"
      layout-align="start center"
      class="mt-2"
    >
      <span class="subheading ml-2 mr-3">Sessions per type</span>
      <v-checkbox
        v-model="is_enabled_type"
        hide-details
        prepend-icon="mdi-lock-open"
        :true-value="1"
        :false-value="null"
        label="Trainers enabled only"
        class="mt-0 pt-0"
        style="display: contents"
        @change="fetchTypes"
      />
      <span class="mx-2" />
      <v-checkbox
        v-model="is_enabled_type"
        hide-details
        prepend-icon="mdi-lock"
        :true-value="0"
        :false-value="null"
        label="Trainers disabled only"
        class="mt-0 pt-0"
        style="display: contents"
        @change="fetchTypes"
      />
    </div>
    <v-data-table
      :headers="headers_types"
      :items="session_types"
      style="max-width: 1000px"
      hide-actions
    >
      <template
        slot="items"
        slot-scope="{ item }"
      >
        <td>{{ item.label }}</td>
        <td>{{ item.session_types[events] ? item.session_types[events] : 0 }}</td>
        <td>{{ item.session_types[adults] ? item.session_types[adults] : 0 }}</td>
        <td>{{ item.session_types[school] ? item.session_types[school] : 0 }}</td>
        <td>{{ item.session_types[teachers] ? item.session_types[teachers] : 0 }}</td>
        <td>{{ item.session_types[youth] ? item.session_types[youth] : 0 }}</td>
        <td>{{ item.session_types[internal] ? item.session_types[internal] : 0 }}</td>
        <td>{{ item.session_types[other] ? item.session_types[other] : 0 }}</td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Statistics from '@/api/statistics'
import Consts from '@/consts'

export default {
  props: ['start', 'end', 'toFilter', 'showDates'],
  data () {
    return {
      sessions: [],
      session_types: [],
      headers: [
        { text: '', value: 'topic', sortable: false },
        { text: 'Sessions', value: 'nb_sessions', sortable: false },
        { text: 'Hours', value: 'hours', sortable: false },
        { text: 'h / session', value: 'hours_per_session', sortable: false }
      ],
      loading: false,
      events: Consts.EVENTS,
      school: Consts.SCHOOL,
      adults: Consts.ADULTS,
      teachers: Consts.TEACHERS,
      youth: Consts.YOUTH,
      other: Consts.OTHER,
      internal: Consts.INTERNAL,
      headers_types: [
        { text: '', sortable: false },
        { text: 'Events', sortable: false },
        { text: 'Adults training', sortable: false },
        { text: 'School training', sortable: false },
        { text: 'Teachers training', sortable: false },
        { text: 'Youth training', sortable: false },
        { text: 'Internal meeting', sortable: false },
        { text: 'Others', sortable: false },
      ],
      is_enabled: null,
      is_enabled_type: null
    }
  },

  watch: {
    toFilter (val) {
      if (val) {
        this.fetch()
        this.$emit('update:toFilter', false)
      }
    }
  },

  mounted () {
    this.fetch()
    this.$emit('update:showDates', true)
  },

  methods: {
    fetch () {
      this.fetchTrainers()
      this.fetchTypes()
    },
    fetchTrainers () {
      this.loading = true
      Statistics.getSessionsTrainers({ start: this.start, end: this.end, is_enabled: this.is_enabled }).then(res => {
        this.sessions = res.data
        this.loading = false
      })
    },
    fetchTypes () {
      this.loading = true
      Statistics.getSessionTypesTrainers({ start: this.start, end: this.end, is_enabled: this.is_enabled_type }).then(res => {
        this.session_types = res.data
        this.loading = false
      })
    }
  }
}
</script>
<style scoped>
.number-color {
  color: #f4b944;
}
</style>