<template>
  <v-dialog
    v-model="isVisible"
    persistent
    width="1000"
  >
    <v-card>
      <v-card-title>
        <p class="title mt-2 ml-2">
          Evaluate the session #{{ session.reference }} - Teacher evaluation
        </p>
      </v-card-title>
      <v-card-text
        layout="row"
        layout-align="center center"
      >
        <v-form
          ref="evalForm"
          class="field-width"
        >
          <div
            layout="row"
            layout-align="start center"
            class="subheading mb-1"
          >
            <div
              layout="column"
              flex="50"
            >
              Number of pupils
            </div>
            <div layout="column">
              <v-text-field
                v-model="eval.nb_pupils"
                value="0"
                outline
                single-line
                hide-details
                class="small-text-field"
                type="number"
              />
            </div>
          </div>
          <div
            layout="row"
            class="subheading"
          >
            <div
              layout="column"
              flex="50"
            >
              1. Training takes place in a clear structure
            </div>
            <div layout="column">
              <eval-radio v-model="eval.form_structure" />
            </div>
          </div>
          <div
            layout="row"
            class="subheading"
          >
            <div
              layout="column"
              flex="50"
            >
              2. Training is adequately prepared
            </div>
            <div layout="column">
              <eval-radio v-model="eval.preparation" />
            </div>
          </div>
          <div
            layout="row"
            class="subheading"
          >
            <div
              layout="column"
              flex="50"
            >
              3. The trainer expresses himself in a clear and understandable way
            </div>
            <div layout="column">
              <eval-radio v-model="eval.expression" />
            </div>
          </div>
          <div
            layout="row"
            class="subheading"
          >
            <div
              layout="column"
              flex="50"
            >
              4. The trainer approaches the topics in an understandable way
            </div>
            <div layout="column">
              <eval-radio v-model="eval.topics" />
            </div>
          </div>
          <div
            layout="row"
            class="subheading"
          >
            <div
              layout="column"
              flex="50"
            >
              5. Training improves students' knowledge
            </div>
            <div layout="column">
              <eval-radio v-model="eval.knowledge" />
            </div>
          </div>
          <div
            layout="row"
            class="subheading"
          >
            <div
              layout="column"
              flex="50"
            >
              6. The training increases students' interest in the topic
            </div>
            <div layout="column">
              <eval-radio v-model="eval.interest" />
            </div>
          </div>
          <div
            layout="row"
            class="subheading"
          >
            <div
              layout="column"
              flex="50"
            >
              7. The trainer creates a pleasant working atmosphere
            </div>
            <div layout="column">
              <eval-radio v-model="eval.climate" />
            </div>
          </div>
          <div
            layout="row"
            class="subheading"
          >
            <div
              layout="column"
              flex="50"
            >
              8. The training corresponds to my expectations
            </div>
            <div layout="column">
              <eval-radio v-model="eval.correspond" />
            </div>
          </div>
          <div
            layout="row"
            class="subheading"
          >
            <div
              layout="column"
              flex="50"
            >
              9. I consider that training is useful
            </div>
            <div layout="column">
              <eval-radio v-model="eval.useful" />
            </div>
          </div>
          <div
            layout="row"
            class="subheading"
          >
            <div
              layout="column"
              flex="50"
            >
              10. Students were sufficiently involved in training
            </div>
            <div layout="column">
              <eval-radio v-model="eval.implication" />
            </div>
          </div>
          <div
            layout="row"
            class="subheading"
          >
            <div
              layout="column"
              flex="50"
            >
              11. The training makes students aware of:
            </div>
          </div>
          <div
            layout="row"
            class="subheading"
          >
            <div
              layout="column"
              flex="50"
            >
              ...understand the operation and basic functionality of the Internet
            </div>
            <div layout="column">
              <eval-radio v-model="eval.internet" />
            </div>
          </div>
          <div
            layout="row"
            class="subheading"
          >
            <div
              layout="column"
              flex="50"
            >
              ...respect the safety rules and their social conduct
            </div>
            <div layout="column">
              <eval-radio v-model="eval.security" />
            </div>
          </div>
          <div
            layout="row"
            class="subheading"
          >
            <div
              layout="column"
              flex="50"
            >
              ...use the internet in a critical and careful way
            </div>
            <div layout="column">
              <eval-radio v-model="eval.prudence" />
            </div>
          </div>
          <div
            v-if="eval.danger"
            layout="row"
            class="subheading"
          >
            <div
              layout="column"
              flex="50"
              class="grey--text"
            >
              ...recognize concrete risks and hazards and respond appropriately
            </div>
            <div layout="column">
              <eval-radio
                v-model="eval.danger"
                disabled
              />
            </div>
          </div>
          <div
            layout="row"
            class="subheading"
          >
            <div
              layout="column"
              flex="50"
            >
              ...recognize the basic principles of data protection and their application
            </div>
            <div layout="column">
              <eval-radio v-model="eval.protection" />
            </div>
          </div>
          <div
            layout="row"
            class="subheading"
          >
            <div
              layout="column"
              flex="50"
            >
              ...recognize concrete risks and dangers
            </div>
            <div layout="column">
              <eval-radio v-model="eval.risks_recognize" />
            </div>
          </div>
          <div
            layout="row"
            class="subheading"
          >
            <div
              layout="column"
              flex="50"
            >
              ...respond to risks and dangers in an appropriate way
            </div>
            <div layout="column">
              <eval-radio v-model="eval.risks_respond" />
            </div>
          </div>
          <div
            layout="row"
            class="subheading"
          >
            <div
              layout="column"
              flex="50"
            >
              12. The Trainer is competent
            </div>
            <div layout="column">
              <eval-radio v-model="eval.competent" />
            </div>
          </div>
          <div
            layout="row"
            class="subheading"
          >
            <div
              layout="column"
              flex="50"
            >
              13. The training is age-apprpriate
            </div>
            <div layout="column">
              <eval-radio v-model="eval.age_apprpriate" />
            </div>
          </div>
          <div
            layout="row"
            class="subheading"
          >
            <div
              layout="column"
              flex="50"
            >
              14. The training is designed to be relevant for everyday life
            </div>
            <div layout="column">
              <eval-radio v-model="eval.relevant" />
            </div>
          </div>
          <div
            layout="row"
            class="subheading"
          >
            <div
              layout="column"
              flex="50"
            >
              15. The training consists of enough practical elements
            </div>
            <div layout="column">
              <eval-radio v-model="eval.practical" />
            </div>
          </div>
          <div
            layout="row"
            class="subheading"
          >
            <div
              layout="column"
              flex="50"
            >
              16. The premises are appropriate
            </div>
            <div layout="column">
              <eval-radio v-model="eval.premises" />
            </div>
          </div>
          <div
            layout="row"
            class="subheading"
          >
            <div
              layout="column"
              flex="50"
            >
              17. I am satisfied with the training
            </div>
            <div layout="column">
              <eval-radio v-model="eval.satisfied" />
            </div>
          </div>
          <div
            layout="row"
            class="subheading mt-3"
          >
            <div
              layout="column"
              flex="50"
            >
              18. Have you ever covered the topic of training in your own class?
            </div>
            <div
              layout="column"
            >
              <div
                layout="row"
                layout-align="start start"
              >
                <v-radio-group
                  v-model="eval.topic_already"
                  row
                  style="margin-top: 0; padding-top: 0"
                >
                  <v-radio
                    label="Not at all"
                    :value="1"
                  />
                  <v-radio
                    label="Rarely"
                    :value="2"
                  />
                  <v-radio
                    label="Sometimes"
                    :value="4"
                  />
                  <v-radio
                    label="Often"
                    :value="5"
                  />
                  <v-radio
                    label="No value"
                    value="0"
                  />
                </v-radio-group>
              </div>
            </div>
          </div>
          <div
            layout="row"
            class="subheading"
          >
            <div
              layout="column"
              flex="50"
            >
              19. Are you planning to treat the topic of training in your own class?
            </div>
            <div
              layout="column"
            >
              <div
                layout="row"
                layout-align="start start"
              >
                <v-radio-group
                  v-model="eval.topic_future"
                  row
                  style="margin-top: 0; padding-top: 0"
                >
                  <v-radio
                    label="Not at all"
                    :value="1"
                  />
                  <v-radio
                    label="Rarely"
                    :value="2"
                  />
                  <v-radio
                    label="Sometimes"
                    :value="4"
                  />
                  <v-radio
                    label="Often"
                    :value="5"
                  />
                  <v-radio
                    label="No value"
                    value="0"
                  />
                </v-radio-group>
              </div>
            </div>
          </div>
          <div
            layout="row"
            class="subheading"
          >
            <v-textarea
              v-model="eval.comments"
              outline
              label="20. Comments"
              :counter="255"
            />
          </div>
          <div
            v-if="eval.appreciate"
            layout="row"
            class="subheading mt-3"
          >
            <v-textarea
              v-model="eval.appreciate"
              outline
              label="12. What do you particularly like about the training?"
              :counter="255"
              disabled
            />
          </div>
          <div
            v-if="eval.improvement"
            layout="row"
            class="subheading mt-3"
          >
            <v-textarea
              v-model="eval.improvement"
              outline
              label="13. In your opinion, what improvements should be made to the training?"
              :counter="255"
              disabled
            />
          </div>
          <div class="text-xs-right mt-2">
            <v-btn
              depressed
              color="primary"
              :disabled="loading"
              @click="save"
            >
              Save the evaluation
            </v-btn>
            or
            <a
              href="#"
              class="black--text"
              @click.prevent="cancel"
            >Cancel</a>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Sessions from '@/api/sessions'
import RulesMixin from '@/mixins/rules'
import EvalRadio from "@/components/shared/EvalRadio.vue"

export default {
  components: { EvalRadio },
  mixins: [RulesMixin],
  props: {
    value: {
      type: Object,
      required: false,
      default () { return {} }
    },
    visible: {
      type: Boolean,
      required: false,
      default: false
    },
    session: {
      required: true
    },
  },
  data () {
    return {
      loading: false,
    }
  },
  computed: {
    eval: {
      get () {
        return this.value
      },
      set (x) {
        this.$emit('input', x)
      }
    },
    isVisible: {
      get () {
        return this.visible
      },
      set (x) {
        this.$emit('update:visible', x)
      }
    }
  },
  mounted () {

  },
  methods: {
    cancel () {
      this.$nextTick(() => {
        this.$refs['evalForm'].reset()
      })
      this.isVisible = false
    },
    save () {
      if (!this.$refs['evalForm'].validate()) {
        this.$snotify.error('Please check the form for invalid values')
        return
      }

      this.loading = true

      if (!this.eval.id) {
        Sessions.createTeacherEval(this.session.id, this.eval).then(res => {
          this.isVisible = false
          this.loading = false
          this.$emit('created', res.data)

          this.$nextTick(() => {
            this.$refs['evalForm'].reset()
          })
        }, () => {
          this.loading = false
        })
      } else {
        Sessions.updateTeacherEval(this.session.id, this.eval.id, this.eval).then(res => {
          this.isVisible = false
          this.loading = false
          this.$emit('updated', res.data)
          this.$nextTick(() => {
            this.$refs['evalForm'].reset()
          })
        }, () => {
          this.loading = false
        })
      }
    }
  }
}
</script>