<template>
  <div class="my-2">
    <v-data-table
      :headers="headers"
      :items="sessions"
      :pagination.sync="pagination"
      :total-items="total"
      :rows-per-page-items="[10, 20, 30, 50]"
      @update:pagination="fetch"
    >
      <template
        slot="items"
        slot-scope="{ item }"
      >
        <td>
          <router-link
            class="black--text"
            :to="{name: 'sessions-show-info', params: {id: item.id}}"
          >
            {{ item.reference }}
          </router-link>
        </td>
        <td>
          <v-avatar
            :title="sessionStatusStrLong(item.status)"
            :color="sessionStatusColor(item.status)"
            :size="32"
            class="black--text font-weight-medium"
          >
            {{ sessionStatusStr(item.status) }}
          </v-avatar>
        </td>
        <td>{{ formatDate(item.updated_at ? item.updated_at : item.created_at) }}</td>
        <td>
          <v-chip
            :color="sessionTypeColor(item.session_type)"
            class="lighten-3"
            small
          >
            {{ sessionTypeStr(item.session_type) }}
          </v-chip>
        </td>
        <td>
          <v-menu
            v-model="item.date_popover_open"
            :close-on-content-click="false"
            offset-y
            offset-x
          >
            <div
              slot="activator"
              layout="row"
              @click="item.date_popover_editmode = false"
              @dblclick="onDateDblClick($event, item)"
            >
              {{ getSessionDate(item.dates) }}
            </div>

            <v-card style="width: 600px">
              <v-card-title
                layout="column"
                layout-align="start start"
                class="pb-0"
              >
                <div
                  layout="row"
                  layout-align="start center"
                  class="ml-1"
                >
                  <v-avatar
                    :title="sessionStatusStrLong(item.status)"
                    :color="sessionStatusColor(item.status)"
                    :size="32"
                    class="black--text font-weight-medium"
                  >
                    {{ sessionStatusStr(item.status) }}
                  </v-avatar>

                  <div class="title ml-2">
                    <b>#{{ item.reference }} /</b>
                    <span
                      style="font-weight: 400 !important;"
                      class="ml-2"
                    >{{ item.organization ? item.organization.name : '' }}</span>
                  </div>
                </div>

                <div
                  layout="row"
                  layout-align="start center"
                >
                  <v-chip
                    :color="sessionTypeColor(item.session_type)"
                    class="lighten-3"
                    small
                  >
                    {{ sessionTypeStr(item.session_type) }}
                  </v-chip>

                  <v-rating
                    background-color="#AAAAAA"
                    dense
                    small
                    class="mr-1"
                    readonly
                  />
                </div>
              </v-card-title>

              <v-card-text v-if="item.date_popover_editmode">
                <v-divider />
                <div class="subheading font-weight-regular mt-3">
                  Change the session's dates:
                </div>

                <div
                  v-for="(d, i) in item.dates"
                  :key="item.id + '_d' + i"
                  layout="row"
                  layout-align="start center"
                >
                  <v-menu
                    ref="startDateMenu"
                    v-model="d.date_start_visible"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    :return-value.sync="d.date_start"
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <v-icon slot="activator">
                      event
                    </v-icon>
                    <v-date-picker
                      v-model="d.date_start"
                      first-day-of-week="1"
                      @input="$refs.startDateMenu[i].save(d.date_start)"
                    />
                  </v-menu>
                  <v-text-field
                    v-model="d.date_start"
                    label="date"
                    class="mr-4"
                  />

                  <v-menu
                    ref="startClockMenu"
                    v-model="d.clock_start_visible"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="d.clock_start"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    max-width="290px"
                    min-width="290px"
                  >
                    <v-icon slot="activator">
                      access_time
                    </v-icon>
                    <v-time-picker
                      v-if="d.clock_start_visible"
                      v-model="d.clock_start"
                      format="24hr"
                      @change="$refs.startClockMenu[i].save(d.clock_start)"
                    />
                  </v-menu>
                  <v-text-field
                    v-model="d.clock_start"
                    label="from"
                    class="mr-4"
                  />

                  <v-menu
                    ref="endClockMenu"
                    v-model="d.clock_end_visible"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="d.clock_end"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    max-width="290px"
                    min-width="290px"
                  >
                    <v-icon slot="activator">
                      access_time
                    </v-icon>
                    <v-time-picker
                      v-if="d.clock_end_visible"
                      v-model="d.clock_end"
                      format="24hr"
                      @change="$refs.endClockMenu[i].save(d.clock_end)"
                    />
                  </v-menu>
                  <v-text-field
                    v-model="d.clock_end"
                    label="to"
                  />

                  <v-btn
                    icon
                    color="error"
                    @click="removeDate(item, i)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </v-card-text>
              <v-card-text v-else>
                <v-divider />
                <div class="subheader font-weight-regular mt-3">
                  Session's dates:
                </div>

                <v-list>
                  <v-list-tile
                    v-for="(d, i) in item.dates"
                    :key="item.id + '_date' + i"
                  >
                    <v-list-tile-avatar>
                      <v-icon>mdi-calendar</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-title>
                      {{ formatDateTime(d.start) }} <v-icon :size="18">
                        mdi-arrow-right
                      </v-icon> {{ formatDateTime(d.end) }}
                    </v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-card-text>

              <v-card-actions
                v-if="item.date_popover_editmode"
                layout-align="start center"
              >
                <v-btn
                  flat
                  @click="addDate(item)"
                >
                  <v-icon
                    color="primary"
                    left
                  >
                    mdi-plus-box
                  </v-icon> Add a new date
                </v-btn>
                <span flex />
                <v-btn
                  flat
                  @click="saveEvent(item)"
                >
                  <v-icon
                    color="primary"
                    left
                  >
                    mdi-floppy
                  </v-icon>
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </td>
        <td>{{ item.room }}</td>
        <td>{{ item.target_group ? item.target_group.name : '' }}</td>
        <td>{{ item.nb_participants }}</td>
        <td>
          <div v-if="item.contact">
            {{ item.contact.firstname }} {{ item.contact.lastname }}
          </div>
        </td>
        <td>
          <template v-for="t in item.trainers">
            <div
              v-if="item.trainers"
              :key="'trainer_'+t.id"
            >
              {{ t.firstname[0] }}. {{ t.lastname }}
            </div>
          </template>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from 'moment'
import Sessions from '@/api/sessions'
import DateMixin from '@/mixins/date'
import SessionsMixin from '@/mixins/sessions'

export default {
  mixins: [DateMixin, SessionsMixin],
  props: {
    organization: {
      type: Object,
      required: true
    },
  },

  data () {
    return {
      pagination: {
        sortBy: 'sd.start',
        descending: true,
        page: 1,
        rowsPerPage: 10,
        totalItems: 0
      },
      sessionTypes: [
        { value: 1, text: this.sessionTypeStr(1) },
        { value: 2, text: this.sessionTypeStr(2) },
        { value: 3, text: this.sessionTypeStr(3) },
        { value: 4, text: this.sessionTypeStr(4) },
        { value: 5, text: this.sessionTypeStr(5) },
        { value: 6, text: this.sessionTypeStr(6) },
        { value: 7, text: this.sessionTypeStr(7) },
      ],
      headers: [
        { text: '#', value: '', sortable: false, width: 70 },
        { text: 'Status', value: 'status', width: 80 },
        { text: 'Last update', value: 'updated_at', width: 100 },
        { text: 'Type', value: 'session_type', width: 100 },
        { text: 'Date(s)', value: 'sd.start' },
        { text: 'Class / Room', value: 'room' },
        { text: 'Target group', value: 'tg.name' },
        { text: 'Pupils', value: 'nb_participants', width: 70 },
        { text: 'Contact(s)', value: 'c.firstname' },
        { text: 'Trainer(s)', value: 'trainer.lastname' },
      ],
      sessions: [],
      total: 0
    }
  },
  mounted () {
    // this.fetch()
  },
  methods: {
    fetch () {
      const p = Object.assign({}, this.pagination, { organization_id: this.$route.params.id })
      Sessions.getList(p).then(res => {
        this.sessions = res.data.items
        this.pagination.totalItems = res.data.total
        this.total = res.data.total

        for (let i = 0; i < this.sessions.length; ++i) {
          let s = this.sessions[i]

          // Parse dates into split date/time
          for (let i = 0; i < s.dates.length; ++i) {
            let d = s.dates[i]

            d.date_start = moment(d.start).format('YYYY-MM-DD')
            d.clock_start = moment(d.start).format('HH:mm:ss')
            d.clock_end = moment(d.end).format('HH:mm:ss')

            s.dates[i] = d
          }

          this.sessions[i] = s
        }
      })
    },
    getSessionDate (s) {
      let out = ''

      if (s.length === 0) {
        out += '-'
      } else if (s.length >= 1) {
        const d = s[0]
        out += this.formatDate(d.start) + ' [' + this.formatTime(d.start) + ' - ' + this.formatTime(d.end) + ']'
      }

      if (s.length > 1) {
        out += ' (+' + (s.length - 1) + ' other)'
      }

      return out
    },

    onDateDblClick (ev, item) {
      ev.preventDefault()
      this.$nextTick(() => {
        item.date_popover_open = true
      })
      item.date_popover_editmode = true
    },

    removeDate (item, i) {
      item.dates.splice(i)
    },

    addDate (item) {
      item.dates.push({})
    },

    saveEvent (item) {
      for (let i = 0; i < item.dates.length; ++i) {
        const d = item.dates[i]

        d.start = d.date_start + ' ' + d.clock_start
        d.end = d.date_start + ' ' + d.clock_end

        item.dates[i] = d
      }

      Sessions.update(item.id, item).then(() => {
        this.$snotify.success('The session has been updated')
        this.fetch()
      })
    },
  }
}
</script>

<style scoped>
.eval-block {
  background: #d6d6d6;
  width: 8px;
  height: 24px;
}
</style>