<template>
  <div layout="column">
    <div layout="row">
      <div class="title mb-3">
        Teacher evaluations
      </div>
    </div>
    <div
      layout="column"
      layout-align="start start"
    >
      <v-card
        v-if="isCoord || isAdmin"
        ripple
        class="ml-5"
        style="background: unset; height: fit-content"
        flat
      >
        <v-card-title primary-title>
          <v-avatar
            size="80"
            style="cursor: pointer"
            @click="newEval()"
          >
            <v-icon
              size="60"
              :class="{'circle-container': true, 'primary': true}"
              color="#FFFFFF"
            >
              mdi-plus
            </v-icon>
          </v-avatar>
        </v-card-title>
      </v-card>
      <v-card
        v-for="e in evaluations"
        :key="e.id"
        class="ml-5 mt-2"
        style="width: 80%"
      >
        <v-card-text>
          <div layout="column">
            <div layout="row">
              <div
                layout="column"
                flex="50"
              >
                Number of pupils
              </div>
              <div layout="column">
                {{ e.nb_pupils }}
              </div>
            </div>
            <div layout="row">
              <div
                layout="column"
                flex="50"
              >
                1. Training takes place in a clear structure
              </div>
              <div layout="column">
                <eval-radio
                  v-model="e.form_structure"
                  :readonly="true"
                />
              </div>
            </div>
            <div layout="row">
              <div
                layout="column"
                flex="50"
              >
                2. Training is adequately prepared
              </div>
              <div layout="column">
                <eval-radio
                  v-model="e.preparation"
                  :readonly="true"
                />
              </div>
            </div>
            <div layout="row">
              <div
                layout="column"
                flex="50"
              >
                3. The trainer expresses himself in a clear and understandable way
              </div>
              <div layout="column">
                <eval-radio
                  v-model="e.expression"
                  :readonly="true"
                />
              </div>
            </div>
            <div layout="row">
              <div
                layout="column"
                flex="50"
              >
                4. The trainer approaches the topics in an understandable way
              </div>
              <div layout="column">
                <eval-radio
                  v-model="e.topics"
                  :readonly="true"
                />
              </div>
            </div>
            <div layout="row">
              <div
                layout="column"
                flex="50"
              >
                5. Training improves students' knowledge
              </div>
              <div layout="column">
                <eval-radio
                  v-model="e.knowledge"
                  :readonly="true"
                />
              </div>
            </div>
            <div layout="row">
              <div
                layout="column"
                flex="50"
              >
                6. The training increases students' interest in the topic
              </div>
              <div layout="column">
                <eval-radio
                  v-model="e.interest"
                  :readonly="true"
                />
              </div>
            </div>
            <div layout="row">
              <div
                layout="column"
                flex="50"
              >
                7. The trainer creates a pleasant working atmosphere
              </div>
              <div layout="column">
                <eval-radio
                  v-model="e.climate"
                  :readonly="true"
                />
              </div>
            </div>
            <div layout="row">
              <div
                layout="column"
                flex="50"
              >
                8. The training corresponds to my expectations
              </div>
              <div layout="column">
                <eval-radio
                  v-model="e.correspond"
                  :readonly="true"
                />
              </div>
            </div>
            <div layout="row">
              <div
                layout="column"
                flex="50"
              >
                9. I consider that training is useful
              </div>
              <div layout="column">
                <eval-radio
                  v-model="e.useful"
                  :readonly="true"
                />
              </div>
            </div>
            <div layout="row">
              <div
                layout="column"
                flex="50"
              >
                10. Students were sufficiently involved in training
              </div>
              <div layout="column">
                <eval-radio
                  v-model="e.implication"
                  :readonly="true"
                />
              </div>
            </div>
            <div layout="row">
              <div
                layout="column"
                flex="50"
              >
                11. The training makes students aware of:
              </div>
            </div>
            <div layout="row">
              <div
                layout="column"
                flex="50"
              >
                ...understand the operation and basic functionality of the Internet
              </div>
              <div layout="column">
                <eval-radio
                  v-model="e.internet"
                  :readonly="true"
                />
              </div>
            </div>
            <div layout="row">
              <div
                layout="column"
                flex="50"
              >
                ...respect the safety rules and their social conduct
              </div>
              <div layout="column">
                <eval-radio
                  v-model="e.security"
                  :readonly="true"
                />
              </div>
            </div>
            <div layout="row">
              <div
                layout="column"
                flex="50"
              >
                ...use the internet in a critical and careful way
              </div>
              <div layout="column">
                <eval-radio
                  v-model="e.prudence"
                  :readonly="true"
                />
              </div>
            </div>
            <div
              v-if="e.danger"
              layout="row"
            >
              <div
                layout="column"
                flex="50"
                class="grey--text"
              >
                ...recognize concrete risks and hazards and respond appropriately
              </div>
              <div layout="column">
                <eval-radio
                  v-model="e.danger"
                  :readonly="true"
                  disabled
                />
              </div>
            </div>
            <div layout="row">
              <div
                layout="column"
                flex="50"
              >
                ...recognize the basic principles of data protection and their application
              </div>
              <div layout="column">
                <eval-radio
                  v-model="e.protection"
                  :readonly="true"
                />
              </div>
            </div>
            <div layout="row">
              <div
                layout="column"
                flex="50"
              >
                ...recognize concrete risks and dangers
              </div>
              <div layout="column">
                <eval-radio
                  v-model="e.risks_recognize"
                  :readonly="true"
                />
              </div>
            </div>
            <div layout="row">
              <div
                layout="column"
                flex="50"
              >
                ...respond to risks and dangers in an appropriate way
              </div>
              <div layout="column">
                <eval-radio
                  v-model="e.risks_respond"
                  :readonly="true"
                />
              </div>
            </div>
            <div layout="row">
              <div
                layout="column"
                flex="50"
              >
                12. The Trainer is competent
              </div>
              <div layout="column">
                <eval-radio
                  v-model="e.competent"
                  :readonly="true"
                />
              </div>
            </div>
            <div layout="row">
              <div
                layout="column"
                flex="50"
              >
                13. The training is age-apprpriate
              </div>
              <div layout="column">
                <eval-radio
                  v-model="e.age_apprpriate"
                  :readonly="true"
                />
              </div>
            </div>
            <div layout="row">
              <div
                layout="column"
                flex="50"
              >
                14. The training is designed to be relevant for everyday life
              </div>
              <div layout="column">
                <eval-radio
                  v-model="e.relevant"
                  :readonly="true"
                />
              </div>
            </div>
            <div layout="row">
              <div
                layout="column"
                flex="50"
              >
                15. The training consists of enough practical elements
              </div>
              <div layout="column">
                <eval-radio
                  v-model="e.practical"
                  :readonly="true"
                />
              </div>
            </div>
            <div layout="row">
              <div
                layout="column"
                flex="50"
              >
                16. The premises are appropriate
              </div>
              <div layout="column">
                <eval-radio
                  v-model="e.premises"
                  :readonly="true"
                />
              </div>
            </div>
            <div layout="row">
              <div
                layout="column"
                flex="50"
              >
                17. I am satisfied with the training
              </div>
              <div layout="column">
                <eval-radio
                  v-model="e.satisfied"
                  :readonly="true"
                />
              </div>
            </div>
            <div layout="row">
              <div
                layout="column"
                flex="50"
              >
                18. Have you ever covered the topic of training in your own class?
              </div>
              <div layout="column">
                {{ transformMark(e.topic_already) }}
              </div>
            </div>
            <div layout="row">
              <div
                layout="column"
                flex="50"
              >
                19. Are you planning to treat the topic of training in your own class?
              </div>
              <div layout="column">
                {{ transformMark(e.topic_future) }}
              </div>
            </div>
            <div layout="row">
              <div
                layout="column"
                flex="50"
              >
                20. Comments
              </div>
              <div layout="column">
                {{ e.comments }}
              </div>
            </div>
            <div
              v-if="e.appreciate"
              layout="row"
              class="grey--text"
            >
              <div
                layout="column"
                flex="50"
              >
                12. What do you particularly like about the training?
              </div>
              <div layout="column">
                {{ e.appreciate }}
              </div>
            </div>
            <div
              v-if="e.improvement"
              layout="row"
              class="grey--text"
            >
              <div
                layout="column"
                flex="50"
              >
                13. In your opinion, what improvements should be made to the training?
              </div>
              <div layout="column">
                {{ e.improvement }}
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions
          layout="row"
          layout-align="end end"
        >
          Created by {{ e.created_by }} the {{ formatDateHuman(e.created_at) }}
          <v-btn
            v-if="isCoord || isAdmin"
            flat
            color="primary"
            @click="editEval(e)"
          >
            <v-icon
              right
              color="primary"
            >
              mdi-pencil
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
    <eval-dialog
      :visible.sync="evalDialog"
      :session="session"
      :value="editingEval"
      @created="fetch"
      @updated="fetch"
    />
  </div>
</template>

<script>
import Sessions from '@/api/sessions'
import DateMixin from '@/mixins/date'
import EvalDialog from "@/components/shared/TeacherEvalDialog.vue"
import EvalRadio from "@/components/shared/EvalRadio.vue"

export default {
  components: { EvalDialog, EvalRadio },
  mixins: [DateMixin],
  props: ['session', 'isAdmin', 'isCoord'],
  data () {
    return {
      evaluations: [],
      evalDialog: false,
      editingEval: {}
    }
  },

  computed: {},

  watch: {
    evalDialog (val) {
      if (!val) {
        this.editingEval = {}
      }
    }
  },

  mounted () {
    this.fetch()
  },

  methods: {
    fetch () {
      Sessions.getTeacherEvals(this.$route.params.id, {sortBy: 'created_at', descending: true}).then(res => {
        this.evaluations = res.data
        if (this.evaluations.length == 0 && !this.isAdmin && !this.isCoord) {
          this.newEval()
        }
      })
    },
    newEval () {
      this.evalDialog = true
    },
    editEval (e) {
      this.editingEval = Object.assign({}, e)
      this.evalDialog = true
    },
    canAddNewEval () {
      if (this.isCoord || this.isAdmin) {
        return true
      }
      return false
    },
    transformMark (mark) {
      switch (mark) {
        case 1:
          return "Not at all"
        case 2:
          return "Rarely"
        case 4:
          return "Sometimes"
        case 5:
          return "Often"
      }
    }
  },
}
</script>