<template>
  <div layout="column">
    <div layout="row">
      <div class="title mb-3">
        Trainer evaluations
      </div>
    </div>
    <div
      layout-gt-md="row"
      layout-align="start stretch"
      layout-wrap
      style="width: 100%"
    >
      <v-card
        v-for="e in evaluations"
        :key="e.id"
        flex="40"
      >
        <v-card-title
          primary-title
          class="headline font-weight-bold"
        >
          {{ e.user ? e.session_trainer.user.firstname + " " + e.session_trainer.user.lastname : e.created_by }} - <v-rating
            background-color="#AAAAAA"
            dense
            large
            readonly
            :value="parseInt(e.school_mark)"
          />
        </v-card-title>
        <v-card-text>
          <div layout="row">
            <div
              layout="column"
              flex="40"
            >
              1. Discipline in class
            </div>
            <div
              layout="column"
              class="ml-4"
              flex
            >
              <eval-radio
                v-model="e.discipline"
                :readonly="true"
              />
            </diV>
          </div>
          <div layout="row">
            <div
              layout="column"
              flex="40"
            >
              2. Interactivity
            </div>
            <div
              layout="column"
              class="ml-4"
              flex
            >
              <eval-radio
                v-model="e.interactivity"
                :readonly="true"
              />
            </diV>
          </div>
          <div layout="row">
            <div
              layout="column"
              flex="40"
            >
              3. Knowledge of the Internet
            </div>
            <div
              layout="column"
              class="ml-4"
              flex
            >
              <eval-radio
                v-model="e.knowledge"
                :readonly="true"
              />
            </diV>
          </div>
          <div
            v-if="e.experience"
            layout="row"
          >
            <div
              layout="column"
              flex="40"
              class="grey--text"
            >
              4. Experiences of the participants
            </div>
            <div
              layout="column"
              class="ml-4"
              flex
            >
              <eval-radio
                v-model="e.experience"
                :readonly="true"
                disabled
              />
            </diV>
          </div>
          <div layout="row">
            <div
              layout="column"
              flex="40"
            >
              4. Organisation on behalf the school
            </div>
            <div
              layout="column"
              class="ml-4"
              flex
            >
              <eval-radio
                v-model="e.organization"
                :readonly="true"
              />
            </diV>
          </div>
          <div layout="row">
            <div
              layout="column"
              flex="40"
            >
              5. Involvement of the teachers
            </div>
            <div
              layout="column"
              class="ml-4"
              flex
            >
              <eval-radio
                v-model="e.involvement"
                :readonly="true"
              />
            </diV>
          </div>
          <div layout="row">
            <div
              layout="column"
              flex="40"
            >
              6. Climate in the class
            </div>
            <div
              layout="column"
              class="ml-4"
              flex
            >
              <eval-radio
                v-model="e.climate"
                :readonly="true"
              />
            </diV>
          </div>
          <div layout="row">
            <div
              layout="column"
              flex="40"
            >
              I noticed the following in this class (positive and/or negative)
            </div>
            <div
              layout="column"
              class="ml-4"
              flex
            >
              <div style="white-space: pre-wrap;        word-wrap: break-word;font-family: inherit;">
                {{ e.pupils_exp }}
              </div>
            </diV>
          </div>
          <div
            v-if="e.orga_aspects"
            layout="row"
            class="grey--text"
          >
            <div
              layout="column"
              flex="40"
            >
              Organisational aspects
            </div>
            <div
              layout="column"
              class="ml-4"
              flex
            >
              <div style="white-space: pre-wrap;        word-wrap: break-word;font-family: inherit;">
                {{ e.orga_aspects }}
              </div>
            </diV>
          </div>
          <div
            v-if="e.other"
            layout="row"
            class="grey--text"
          >
            <div
              layout="column"
              flex="40"
            >
              Other aspects
            </div>
            <div
              layout="column"
              class="ml-4"
              flex
            >
              <div style="white-space: pre-wrap;        word-wrap: break-word;font-family: inherit;">
                {{ e.other }}
              </div>
            </diV>
          </div>
        </v-card-text>
        <v-card-actions
          layout="row"
          layout-align="end end"
        >
          <span v-if="e.updated_at">Updated on {{ formatDateHuman(e.updated_at) }}</span>
          <span v-else>Created on {{ formatDateHuman(e.created_at) }}</span>
          <v-btn
            v-if="isCoord || isAdmin || e.session_trainer.user_id == user"
            flat
            color="primary"
            @click="editEval(e)"
          >
            <v-icon
              right
              color="primary"
            >
              mdi-pencil
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card
        v-if="canAddNewEval()"
        ripple
        class="ml-5"
        style="background: unset; height: fit-content"
        flat
      >
        <v-card-title primary-title>
          <v-avatar
            size="80"
            style="cursor: pointer"
            @click="newEval()"
          >
            <v-icon
              size="60"
              :class="{'circle-container': true, 'primary': true}"
              color="#ffffff"
            >
              mdi-plus
            </v-icon>
          </v-avatar>
        </v-card-title>
      </v-card>
    </div>

    <eval-dialog
      :visible.sync="evalDialog"
      :session="session"
      :value="editingEval"
      @created="fetch"
      @updated="fetch"
    />
  </div>
</template>

<script>
import Sessions from '@/api/sessions'
import DateMixin from '@/mixins/date'
import EvalDialog from "@/components/shared/TrainerEvalDialog.vue"
import Auth from '@/plugins/auth'
import EvalRadio from "@/components/shared/EvalRadio.vue"

export default {
  components: { EvalDialog, EvalRadio },
  mixins: [DateMixin],
  props: ['session', 'isAdmin', 'isCoord'],
  data () {
    return {
      evaluations: [],
      evalDialog: false,
      editingEval: {},
      user: null
    }
  },

  computed: {

  },

  watch: {
    evalDialog (val) {
      if (!val) {
        this.editingEval = {}
      }
    }
  },

  mounted () {
    this.fetch()
    this.user = Auth.user.id
  },

  methods: {
    fetch () {
      Sessions.getTrainerEvals(this.$route.params.id).then(res => {
        this.evaluations = res.data
      })
    },
    newEval () {
      this.evalDialog = true
    },
    editEval (e) {
      this.editingEval = Object.assign({}, e)
      this.evalDialog = true
    },
    canAddNewEval () {
      if (this.isCoord || this.isAdmin) {
        return true
      }
      else if ('trainers' in this.session) {
        let is = this.session.trainers.map(function (e) { return e.id }).indexOf(Auth.user.id)

        if (is > -1) {
          let even = this.evaluations.map(function (e) { return e.session_trainer.user_id }).indexOf(Auth.user.id)
          if (even == -1) {
            return true
          }
          return false
        }
        return false
      }
      return false
    },
  },
}
</script>