<template>
  <div
    v-if="session.session_type === Consts.EVENTS"
    class="ml-5"
  >
    <div class="title mb-3">
      Settings
    </div>

    <div
      layout="column"
      layout-align="start center"
    >
      <v-data-table
        v-if="session.events_settings && session.events_settings.length > 0"
        :items="session.events_settings"
        :headers="headers"
        hide-actions
        class="field-width"
      >
        <template
          slot="items"
          slot-scope="{ item }"
        >
          <td class="text-xs-right">
            {{ item.event_setting.name }}
          </td>
          <td class="py-2">
            {{ item.qty }}
          </td>
          <td>
            {{ item.comment }}
          </td>
        </template>
      </v-data-table>
    </div>

    <div class="title mb-3 mt-5">
      Special activities
    </div>

    <div
      layout="column"
      layout-align="start center"
    >
      <v-data-table
        v-if="session.activities && session.activities.length > 0"
        :items="session.activities"
        :headers="headers"
        hide-actions
        class="field-width"
      >
        <template
          slot="items"
          slot-scope="{ item }"
        >
          <td class="text-xs-right">
            {{ item.activity.name }}
          </td>
          <td class="py-2">
            {{ item.qty }}
          </td>
          <td>
            {{ item.comment }}
          </td>
        </template>
      </v-data-table>
    </div>
  </div>
  <div
    v-else
    class="ml-5"
  >
    <div class="title mb-3">
      Technical setup available
    </div>

    <v-checkbox
      v-model="session.beamer"
      disabled
    >
      <template slot="label">
        <v-icon>mdi-projector</v-icon>
        <span class="font-weight-regular black--text">Video-projector</span>
      </template>
    </v-checkbox>

    <v-checkbox
      v-model="session.screen"
      disabled
    >
      <template slot="label">
        <v-icon>mdi-projector-screen</v-icon>
        <span class="font-weight-regular black--text">Projection screen</span>
      </template>
    </v-checkbox>

    <v-checkbox
      v-model="session.loudspeak"
      disabled
    >
      <template slot="label">
        <v-icon>mdi-speaker-wireless</v-icon>
        <span class="font-weight-regular black--text">Speakers</span>
      </template>
    </v-checkbox>

    <v-checkbox
      v-model="session.internet"
      disabled
    >
      <template slot="label">
        <v-icon>mdi-web</v-icon>
        <span class="font-weight-regular black--text">Internet connection</span>
      </template>
    </v-checkbox>

    <v-checkbox
      v-model="session.socket"
      disabled
    >
      <template slot="label">
        <v-icon>mdi-power-plug</v-icon>
        <span class="font-weight-regular black--text">Power plug</span>
      </template>
    </v-checkbox>

    <v-checkbox
      v-model="session.usb"
      disabled
    >
      <template slot="label">
        <v-icon>mdi-usb</v-icon>
        <span class="font-weight-regular black--text">USB stick</span>
      </template>
    </v-checkbox>
  </div>
</template>

<script>
    import Params from '@/api/params'
    import Consts from '@/consts'

    export default {
        props: ['session', 'isAdmin', 'isCoord'],
        data () {
            return {
                settings: [],
                activities: [],
                headers: [
                    { text: 'Setting', value: 'name', sortable: false },
                    { text: 'Qty', value: 'qty', sortable: false, width: 100 },
                    { text: 'Comment', sortable: false }
                ],
                settingsLoaded: false,
                activitiesLoaded: false,
                Consts
            }
        },
        mounted () {
            this.fetchParams();
        },
        methods: {
            fetchParams () {
                Params.getList('events-settings', {is_enabled: 1}).then(res => {
                    this.settings = res.data;
                    this.loadSettings();
                });

                Params.getList('activities', {is_enabled: 1}).then(res => {
                    this.activities = res.data;
                    this.loadActivities();
                });
            },
            loadSettings(){
                if(this.settingsLoaded == false){
                    for (let i = 0; i < this.settings.length; ++i) {
                        let s = this.settings[i];
                        let is = this.session.events_settings.map(function(e) { return e.event_setting_id; }).indexOf(s.id);
                        if(is == -1){
                            this.session.events_settings.push({
                                event_setting_id: s.id,
                                event_setting: {
                                    name: s.name,
                                    id: s.id
                                },
                                qty: 0,
                                comment: ""
                            });
                        }
                    }
                    this.settingsLoaded = true;
                }
            },
            loadActivities(){
                if(this.activitiesLoaded == false){
                    for (let i = 0; i < this.activities.length; ++i) {
                        let a = this.activities[i];
                        let is = this.session.activities.map(function(e) { return e.activity_id; }).indexOf(a.id);
                        if(is == -1){
                            this.session.activities.push({
                                activity_id: a.id,
                                activity: {
                                    name: a.name,
                                    id: a.id
                                },
                                qty: 0,
                                comment: ""
                            });
                        }
                    }
                    this.activitiesLoaded = true;
                }
            },
        }
    }
</script>