<template>
  <div
    layout="row"
    layout-align="start start"
    class="mt-2"
  >
    <div
      layout="column"
      class="field-width"
    >
      <div
        v-if="loading"
        layout="row"
        class="ma-5 full-width"
        layout-align="center center"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          size="64"
        />
      </div>
      <v-timeline
        v-else
        align-top
      >
        <template v-for="(h,i) in historic">
          <v-timeline-item
            v-if="getEvent(h)"
            :key="i"
            color="primary"
            small
            fill-dot
            right
          >
            <div
              slot="opposite"
              class="mt-3 caption"
            >
              {{ formatDateHuman(h.created_at) }}
            </div>
            <template slot="icon">
              <v-avatar
                :title="h.created_by ? h.created_by : 'System'"
                color="primary"
                size="32"
                style="min-width: 32px"
              >
                <span class="white--text body-2">
                  <template v-if="h.created_by">
                    {{ h.created_by[0] }}{{ h.created_by.split(' ')[1][0] }}
                  </template>
                  <template v-else>S</template>
                </span>
              </v-avatar>
            </template>

            <v-card class="mt-1">
              <v-card-text class="pa-2">
                {{ getEvent(h) }}
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </template>
      </v-timeline>
    </div>
  </div>
</template>

<script>
import DateMixin from '@/mixins/date'
import SessionsMixin from '@/mixins/sessions'
import Sessions from '@/api/sessions'

export default {
  mixins: [DateMixin, SessionsMixin],
  props: ['session', 'isCoord', 'isAdmin'],
  data () {
    return {
      historic: [],
      loading: false
    }
  },

  computed: {},

  watch: {},

  mounted () {
    if (!this.isCoord && !this.isAdmin) {
      this.$router.push({ name: 'sessions-index' })
    } else {
      this.loading = true
      Sessions.getHistoric(this.session.id).then(res => {
        this.historic = res.data
        this.loading = false
      })
    }
  },

  methods: {
    getEvent (historic) {
      if (historic.type == 'U') {
        switch (historic.historisable_type) {
          case 'App\\Models\\EventSetting':
            if (historic.change.field == 'name') {
              return 'The event setting name "' + historic.change.before + '" has been edited to "' + historic.change.after + '".'
            }
            break
          case 'App\\Models\\TargetGroup':
            if (historic.change.field == 'name') {
              return 'The target group name "' + historic.change.before + '" has been edited to "' + historic.change.after + '".'
            }
            break
          case 'App\\Models\\Topic':
            if (historic.change.field == 'name') {
              return 'The concept name "' + historic.change.before + '" has been edited to "' + historic.change.after + '".'
            }
            break
          case 'App\\Models\\Activity':
            if (historic.change.field == 'name') {
              return 'The activity name "' + historic.change.before + '" has been edited to "' + historic.change.after + '".'
            }
            break
          case 'App\\Models\\Organization':
            if (historic.change.field == 'is_enabled') {
              if (historic.change.after == 0) {
                return "The organization has been disabled."
              } else {
                return "The organization has been enabled."
              }
            } else if (historic.change.field == 'mark') {
              return 'The organization mark ' + parseInt(historic.change.before) + ' has been updated to ' + parseInt(historic.change.after) + '.'
            } else if (historic.change.field == 'phone') {
              return "The organization phone has been changed to " + historic.change.after + "."
            } else if (historic.change.field == 'name') {
              return 'The organization name "' + historic.change.before + '" has been edited to "' + historic.change.after + '".'
            } else if (historic.change.field == 'number') {
              return 'The organization street number "' + historic.change.before + '" has been edited to "' + historic.change.after + '".'
            } else if (historic.change.field == 'street') {
              return 'The organization street "' + historic.change.before + '" has been edited to "' + historic.change.after + '".'
            } else if (historic.change.field == 'is_school') {
              return 'The organization type has been changed.'
            } else if (historic.change.field == 'city') {
              return 'The organization city "' + historic.change.before + '" has been edited to "' + historic.change.after + '".'
            } else if (historic.change.field == 'fax') {
              return 'The organization fax "' + historic.change.before + '" has been edited to "' + historic.change.after + '".'
            } else if (historic.change.field == 'email') {
              return 'The organization email "' + historic.change.before + '" has been edited to "' + historic.change.after + '".'
            } else if (historic.change.field == 'parking') {
              return 'The organization parking facilities "' + historic.change.before + '" has been edited to "' + historic.change.after + '".'
            } else if (historic.change.field == 'school_type_id') {
              return 'The organization school type has been changed.'
            }
            break
          case 'App\\Models\\Contact':
            if (historic.change.field == 'lang_id') {
              return "The contact language has been edited."
            } else if (historic.change.field == 'name') {
              return 'The contact function has been changed.'
            } else if (historic.change.field == 'phone') {
              return "The contact phone has been changed to " + historic.change.after + "."
            } else if (historic.change.field == 'email') {
              return 'The contact email "' + historic.change.before + '" has been edited to "' + historic.change.after + '".'
            } else if (historic.change.field == 'lastname') {
              return 'The contact lastname "' + historic.change.before + '" has been edited to "' + historic.change.after + '".'
            } else if (historic.change.field == 'firstname') {
              return 'The contact firstname "' + historic.change.before + '" has been edited to "' + historic.change.after + '".'
            }
            break
          case 'App\\Models\\SessionComment':
            return historic.change.after
          case 'App\\Models\\SessionTodo':
            if (historic.change.field == 'is_done') {
              return 'A todo has been updated.'
            }
            break
          default:
            if (historic.change.field == 'is_fully_eval') {
              if (historic.change.after) {
                return "The session is fully evaluated."
              } else {
                return "The session is not fully evaluated."
              }
            } else if (historic.change.field == 'status') {
              let before = this.sessionStatusStrLong(historic.change.before)
              let after = this.sessionStatusStrLong(historic.change.after)

              let str = "Status changed from " + before + " to " + after + "."

              if (historic.comment) {
                str += "\n" + historic.comment
              }
              return str
            } else if (historic.change.field == 'internal_type') {
              return 'The internal type has been changed.'
            } else if (historic.change.field == 'trainer_eval_submited') {
              if (historic.change.after > historic.change.before) {
                return "A new trainer evaluation has been submitted."
              } else {
                return "A trainer evaluation has been deleted."
              }
            } /* else if (historic.change.field == 'trainer_eval_expected') {
              return "The number of trainer evaluations expected has been updated to " + historic.change.after + "."
            } */ else if (historic.change.field == 'comments') {
              return 'The session comments have been edited to "' + historic.change.after + '".'
            } else if (historic.change.field == 'objectives') {
              return 'The session objectives have been edited to "' + historic.change.after + '".'
            } else if (historic.change.field == 'partner') {
              return 'The session partner has been edited to "' + historic.change.after + '".'
            } else if (historic.change.field == 'press_media') {
              return 'Press and media have been edited to "' + historic.change.after + '".'
            } else if (historic.change.field == 'website_news') {
              return 'Website news have been edited to "' + historic.change.after + '".'
            } else if (historic.change.field == 'nb_participants') {
              return 'The number of participants of the session has been changed to ' + historic.change.after + '.'
            } else if (historic.change.field == 'organization_id') {
              return 'The organization has been changed.'
            } else if (historic.change.field == 'contact_id') {
              return 'The main contact has been changed.'
            } else if (historic.change.field == 'contact2_id') {
              return 'The secondary contact has been changed.'
            } else if (historic.change.field == 'target_group_id') {
              return 'The target group has been changed.'
            } else if (historic.change.field == 'topic_id') {
              return 'The concept has been changed.'
            } else if (historic.change.field == 'lang_id') {
              return 'The language has been changed.'
            } else if (historic.change.field == 'class_name') {
              return 'The class name has been changed.'
            } else if (historic.change.field == 'event_name') {
              return 'The event name has been changed.'
            } else if (historic.change.field == 'location') {
              return 'The location has been changed.'
            } else if (historic.change.field == 'room') {
              return 'The room has been changed.'
            } else if (historic.change.field == 'referent_firstname') {
              return 'The referent firstname has been changed.'
            } else if (historic.change.field == 'referent_lastname') {
              return 'The referent lastname has been changed.'
            } else if (historic.change.field == 'referent_fullname') {
              return 'The referent fullname has been changed.'
            } else if (historic.change.field == 'referent_phone') {
              return 'The referent phone has been changed.'
            } else if (historic.change.field == 'beamer') {
              return 'The technical setup (Video-projector) has been changed.'
            } else if (historic.change.field == 'screen') {
              return 'The technical setup (Projection screen) has been changed.'
            } else if (historic.change.field == 'loudspeak') {
              return 'The technical setup (Speakers) has been changed.'
            } else if (historic.change.field == 'internet') {
              return 'The technical setup (Internet connection) has been changed.'
            } else if (historic.change.field == 'socket') {
              return 'The technical setup (Power plug) has been changed.'
            } else if (historic.change.field == 'usb') {
              return 'The technical setup (USB stick) has been changed.'
            }
            break
        }
      } else if (historic.type == 'C') {
        switch (historic.historisable_type) {
          case 'App\\Models\\TrainerPresence':
            // return "A trainer has changed his presence dates."
            return null
          case 'App\\Models\\SessionTrainer':
            if (historic.historisable) {
              if (historic.historisable.is_request || (historic.historisable.id == historic.creator_id)) {
                return historic.created_by + ' asked to be assigned to the session.'
              } else {
                let fullname = ''
                if (historic.historisable.user) {
                  fullname = historic.historisable.user.firstname + ' ' + historic.historisable.user.lastname
                } else {
                  fullname = historic.historisable.firstname + ' ' + historic.historisable.lastname
                }

                return fullname + " has been assigned to the session."
              }
            } else {
              return "A new trainer has been assigned to the session."
            }
          case 'App\\Models\\SessionActivity':
            return "A new activity has been added."
          case 'App\\Models\\SessionSetting':
            return "A new event setting has been added."
          case 'App\\Models\\SessionTodo':
            return "A new todo has been added."
          case 'App\\Models\\SessionUserTeam':
            return "A new team member has been added."
          case 'App\\Models\\SessionDate':
            return "A new date has been defined."
          default:
            return "The session has been created."
        }
      } else if (historic.type == 'D') {
        switch (historic.historisable_type) {
          case 'App\\Models\\SessionTrainer':
            if (historic.historisable) {
              if (historic.historisable.id == historic.creator_id) {
                return historic.created_by + ' has left the session.'
              } else {
                return historic.historisable.firstname + ' ' + historic.historisable.lastname + ' has been removed from the session.'
              }
            } else {
              return 'A trainer has left the session.'
            }
          default:
            break
        }
      }
    },
  }
}
</script>

<style>
.v-timeline-item__opposite {
  align-self: start;
}
</style>