import axios from 'axios'
import './common'

export default {
    getRoot: () => axios.get(`/folders`),
    newFolder: (params) => axios.post(`/folders`, { param: params }),
    getFolder: (id) => axios.get(`/folders/${id}`),
    updateFolder: (id, params) => axios.put(`/folders/${id}`, { param: params }),
    deleteFolder: (id) => axios.delete(`/folders/${id}`),
    deleteFolderContent: (id) => axios.delete(`/folders/${id}/multi`),
    newFile (params) {
        return axios.post(`/files`, params, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    },
    getFile: (id) => axios.get(`/files/${id}`),
    updateFile: (id, params) => axios.put(`/files/${id}`, { param: params }),
    deleteFile: (id) => axios.delete(`/files/${id}`),
    getTree: () => axios.get(`/folders/tree`),
}