<template>
  <div
    layout="column"
    layout-align="center center"
    class="mx-3"
  >
    <v-badge
      v-if="badge > 0"
      color="red"
      overlap
    >
      <template #badge>
        <span>{{ badge }}</span>
      </template>
      <router-link
        class="btn-toolbar link-unstyled"
        :color="active ? 'primary' : 'black'"
        :class="{ active: active }"
        :to="to"
      >
        <v-icon :color="active ? 'primary' : 'black'">
          {{ icon }}
        </v-icon>
      </router-link>
    </v-badge>
    <router-link
      v-else
      class="btn-toolbar link-unstyled"
      :color="active ? 'primary' : 'black'"
      :class="{ active: active }"
      :to="to"
    >
      <v-icon :color="active ? 'primary' : 'black'">
        {{ icon }}
      </v-icon>
    </router-link>
    <span :class="active ? 'primary--text' : ''">
      {{ label }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    to: {
      type: [String, Object],
      required: true
    },
    badge: {
      type: Number,
      default: 0
    }
  },
  computed: {
    active() {
      return this.$route.path.startsWith(this.$router.resolve(this.to).href);
    }
  }
};
</script>
<style scoped>
.btn-toolbar {
  height: 56px;
  width: 56px;
  border: 1px solid;
  border-radius: 50%;
  justify-content: center;
  margin: 6px 8px;
  vertical-align: middle;
  position: relative;
  align-items: center;
  display: inline-flex;
  cursor: pointer;
}
.active {
  border-color: #f4b944;
}
.btn-toolbar:before {
  border-radius: inherit;
  color: inherit;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  opacity: 0.12;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
.btn-toolbar:hover,
.btn-toolbar:focus {
  position: relative;
  background-color: #e6e6e6;
}
</style>
